import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { OrgRenameUserLoggedInConfig } from "../../../components/rn-business-components/rename-error/rename-error-config";
import { RnUsersVM } from "../../../services/rnapi2-service/models/models";

@Component({
  selector: "app-rename-error",
  templateUrl:
    "../../../components/rn-business-components/rename-error/rename-error.component.html",
  styleUrls: [
    "../../../components/rn-business-components/rename-error/rename-error.component.scss",
  ],
})
export class OrgRenameUserLoggedInDialogNotificationComponent
  implements OnInit
{
  type: string;
  usersLoggedIn: RnUsersVM[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OrgRenameUserLoggedInConfig,
    private mdDialogRef: MatDialogRef<OrgRenameUserLoggedInDialogNotificationComponent>,
  ) {}

  ngOnInit(): void {
    this.type = this.data.type;
    this.usersLoggedIn = this.data.users.sort((u1, u2) => {
      const orgComp = u1.OrganizationName.localeCompare(u2.OrganizationName);
      if (orgComp === 0) {
        return u1.FullName.localeCompare(u2.FullName);
      }
      return orgComp;
    });
  }

  close() {
    this.mdDialogRef.close();
  }
}
