import { Pipe, PipeTransform } from "@angular/core";
import {
  OrgFlatNode,
  orgTreeItem,
} from "../../../feature-modules/organization/organization-details/organization-tree/organization-tree.component";

@Pipe({
  name: "rnOrgTree",
})
export class RnOrgTreePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (args && args.length > 0) {
      const param = args[0].toString().toLowerCase();
      switch (param) {
        case "candeletefolder":
          {
            if (value) {
              const node = value as OrgFlatNode;
              return (
                node?.isFolder &&
                node.treeItem.children.length === 0 &&
                !node.treeItem.rnTreeNode.hasUsersMapped
              );
            }
          }
          break;
        case "canrenamefolder":
          {
            if (value) {
              const node = value as OrgFlatNode;
              return node?.isFolder;
            }
          }
          break;
        case "showloading": {
          if (value) {
            const treeNodes = value as orgTreeItem[];
            return treeNodes || treeNodes.length === 0;
          }
        }
      }
    }
    return null;
  }
}
