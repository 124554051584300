import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
  ) {}

  canActivate() {
    if (this.authService.userIsLoggedIn()) {
      return true;
    }

    return this.router.parseUrl("/login");
  }
}
