<div class="modal-content wizard-main-content">
  <div class="row">
    <div class="col-11">
      <p class="wizard-title">Download and Run Setup Wizard</p>
    </div>
    <div class="col-1">
      <button
        type="button"
        data-testid="btnDownloadSetupWizClose"
        class="close close-wizard"
        (click)="close()"
      >
        <app-icon [source]="'rn-x'"></app-icon>
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-10">
      <p class="wizard-subtitle">
        The Rightworks Setup Wizard configures your PC or Mac to connect to your
        new cloud-hosted remote desktop. It will also setup your computer to
        print and scan. Select your computer platform to get started.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="wizard-box left">
        <div class="row">
          <div class="col-12">
            <div class="wizard-logo">
              <img
                alt="Download button image"
                style="max-width: 100%"
                src="{{ asset_url }}/images/DownloadButton_Windows_Angular.png"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-1"></div>
          <div class="col-10">
            <div class="wizard-text">
              Download the Setup Wizard, launch it, and follow the instructions
              to configure your computer.
            </div>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="wizard-button-div">
              <app-button
                id="btnCreateProfile"
                buttonStyle="primary"
                label="DOWNLOAD"
                showIcon="true"
                isImageBackward="true"
                imgSource="rn-download"
                imgClass="filter-white"
                (clickActionEmitter)="trackWindowsDownload()"
                tooltipPosition="bottom"
                positionStyle="absolute"
                tooltipStyleClass="swTooltipBottom"
                tooltipZIndex="20000"
                pTooltip="Download and run the Setup Wizard for your PC."
              >
              </app-button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="wizard-help">
              <a
                tabindex="0"
                (keyup.enter)="getWindowsHelp()"
                class="getHelpSetupWiz"
                (click)="getWindowsHelp()"
                positionStyle="absolute"
                tooltipPosition="bottom"
                tooltipStyleClass="swTooltipBottom"
                tooltipZIndex="20000"
                pTooltip="Watch a video to get help on the setup."
                >Get Help</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="wizard-box right">
        <div class="row">
          <div class="col-12">
            <div class="wizard-logo">
              <img
                alt="Download Button image"
                style="max-width: 100%"
                src="{{ asset_url }}/images/DownloadButton_Mac_Angular.png"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-1"></div>
          <div class="col-10">
            <div class="wizard-text">
              Download the Setup Wizard, launch it, and follow the instructions
              to configure your computer.
            </div>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="wizard-button-div">
              <app-button
                id="btnCreateProfile"
                buttonStyle="primary"
                label="DOWNLOAD"
                showIcon="true"
                imgSource="rn-download"
                imgClass="filter-white"
                (clickActionEmitter)="trackMacDownload()"
                positionStyle="absolute"
                tooltipPosition="bottom"
                isImageBackward="true"
                tooltipStyleClass="swTooltipBottom"
                tooltipZIndex="20000"
                pTooltip="Download and run the Setup Wizard for your Mac."
              >
              </app-button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="wizard-help">
              <a
                tabindex="0"
                (keyup.enter)="getMacHelp()"
                class="getHelpSetupWiz"
                data-testid="btnDownloadSetupWizGetMacHelp"
                (click)="getMacHelp()"
                positionStyle="absolute"
                tooltipPosition="bottom"
                tooltipStyleClass="swTooltipBottom"
                tooltipZIndex="20000"
                pTooltip="Visit our Knowledge Base to get help on the setup"
                >Get Help</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-8">
      <div class="wizard-note">
        Have an iPad, iPhone, Android, or Chromebook?
        <a
          class="getHelpSetupWiz"
          [ngbTooltip]="tipContent"
          tooltipClass="sw-tooltiptop"
          [closeDelay]="2000"
          >Get Help</a
        >

        <ng-template #tipContent>
          <div>
            <p>
              We don't have a Setup Wizard for these devices, but we can still
              help you set them up to connect to your new desktop.
            </p>
            <p>Check out our knowledge base articles for a walkthrough:</p>
            <br />
            <p>
              <a
                class="z-index-2000"
                href="https://helpdesk.rightnetworks.com/s/article/Connect-from-an-iPhone-iPad"
                >iPhone or iPad</a
              >
            </p>
            <p>
              <a
                class="z-index-2000"
                href="https://helpdesk.rightnetworks.com/s/article/Connect-from-an-Android-Device"
                >Android</a
              >
            </p>
            <p>
              <a
                class="z-index-2000"
                href="https://helpdesk.rightnetworks.com/s/article/Connect-from-a-Google-Chromebook"
                >Chromebook</a
              >
            </p>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
