<div class="overlay-container bgColWhite">
  <app-loader *ngIf="isLoading" text="Loading DUO..."></app-loader>
  <div class="mainDiv">
    <div *ngIf="isDuoLoginError">
      <p-message
        severity="info"
        text=" Duo is unavailable. Please retry in few minutes."
        styleClass="p-mr-2"
      ></p-message>
    </div>
    <iframe id="duo_iframe" class="duoiframe" *ngIf="!isDuoLoginError"></iframe>
    <div class="setupWizard-supportMsg" *ngIf="!isDuoLoginError && !isLoading">
      <div class="lostResetDeviceText bgColWhite">
        If you require a change to the device you are using for multi-factor
        authentication,<br />
        please contact support for assistance.
      </div>
    </div>
    <div class="well side-margins-15" *ngIf="isDuoLoginError">
      <h4>Duo is unavailable please retry in few Seconds</h4>
    </div>
  </div>
</div>
