import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { SearchControlConfiguration } from "./configuration/search-control-configuration";

@Component({
  selector: "app-search-control",
  templateUrl: "./search-control.component.html",
  styleUrls: ["./search-control.component.scss"],
})
export class SearchControlComponent implements OnInit {
  inputSearchText: Subject<string>;
  ContainerClass: string;
  EditClass: string;
  searchIconClass: string;
  @Input() Configuration: SearchControlConfiguration;
  @Input() clearEvent: Observable<void>;
  @Output() SearchOccurred = new EventEmitter<string>();
  @Input() public searchText: string;
  @Output() SearchEditFocus = new EventEmitter<any>();
  @Output() SearchEditBlur = new EventEmitter<any>();
  @Output() SearchEditSelect = new EventEmitter<any>();
  @Input() id = "";

  ngOnInit(): void {
    if (!this.id) throw new Error();
    this.inputSearchText = new Subject<string>();
    this.inputSearchText
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((inputText) => {
        this.searchText = inputText;
        this.SearchOccurred.emit(inputText);
      });
    this.ContainerClass =
      this.Configuration.ContainerClass &&
      this.Configuration.ContainerClass !== ""
        ? this.Configuration.ContainerClass
        : "";
    this.EditClass =
      this.Configuration.EditClass && this.Configuration.EditClass !== ""
        ? this.Configuration.EditClass
        : "";
    this.EditClass += this.Configuration.UseDefaultEditClass
      ? " default-search-edit"
      : "";
    this.searchIconClass =
      this.Configuration.MagnifyingGlassClass &&
      this.Configuration.MagnifyingGlassClass !== ""
        ? this.Configuration.MagnifyingGlassClass
        : "internal-landing-search-button";
    // optional @Input to subscribe to for clearing the search under certain conditions
    if (this.clearEvent) {
      this.clearEvent.subscribe(() => this.clear());
    }
  }

  searchIsAssigned(): boolean {
    return this.searchText && this.searchText.length > 0;
  }

  editFocus($event): void {
    this.SearchEditFocus.emit($event);
  }

  editBlur($event): void {
    this.SearchEditBlur.emit($event);
  }

  editSelect($event): void {
    this.SearchEditSelect.emit($event);
  }

  performSearch(): void {
    if (this.searchIsAssigned()) {
      this.SearchOccurred.emit(this.searchText);
    }
  }

  public searchEditKeyPress(event): void {
    if (event.keyCode === 13 && this.searchText && this.searchText !== "") {
      this.performSearch();
    }
  }

  public clear(): void {
    this.searchText = "";
  }
}
