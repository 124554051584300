import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { ConstantsService } from "src/app/shared/services/constants/constants.service";

@Component({
  selector: "app-payment-form-ach",
  templateUrl: "./payment-form-ach.component.html",
  styleUrls: ["./payment-form-ach.component.scss"],
})
export class PaymentFormAchComponent implements OnInit {
  @Input() parentForm!: FormGroup;
  @Input() public submitted = false;

  public invalidRoutingNumber = false;

  accountTypes: SelectItem[];

  constructor(private constantsService: ConstantsService) {}

  ngOnInit(): void {
    this.bankAccountType.markAsUntouched();
    this.loadAccountType();
    this.parentForm
      .get("bankName")
      .setValidators([Validators.required.bind(Validators)]);
    this.parentForm
      .get("bankAccountType")
      .setValidators([Validators.required.bind(Validators)]);
    this.parentForm
      .get("bankAccountHolderName")
      .setValidators([Validators.required.bind(Validators)]);
    this.parentForm
      .get("bankAccountRoutingNumber")
      .setValidators([Validators.required.bind(Validators)]);
    this.parentForm
      .get("bankAccountNumber")
      .setValidators([Validators.required.bind(Validators)]);
  }

  private loadAccountType(): void {
    this.accountTypes = [{ label: "", value: "" }];

    const accTypes = this.constantsService.accountTypes;
    for (let i = 0; i < accTypes.length; i++) {
      this.accountTypes.push({
        label: accTypes[i].label,
        value: accTypes[i].value,
      });
    }
  }

  public onChangeBankRoutingNumber() {
    this.invalidRoutingNumber =
      this.bankAccountRoutingNumber.value?.length !== 9 ||
      !this.isInt(this.bankAccountRoutingNumber.value);
  }

  public isInt(value) {
    return (
      !isNaN(value) &&
      ((x) => {
        return (x | 0) === x;
      })(parseFloat(value))
    );
  }

  get bankName() {
    return this.parentForm.get("bankName");
  }

  get bankAccountType() {
    return this.parentForm.get("bankAccountType");
  }

  get bankAccountHolderName() {
    return this.parentForm.get("bankAccountHolderName");
  }

  get bankAccountRoutingNumber() {
    return this.parentForm.get("bankAccountRoutingNumber");
  }

  get bankAccountNumber() {
    return this.parentForm.get("bankAccountNumber");
  }
}
