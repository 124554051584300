<div [ngClass]="ContainerClass">
  <span
    *ngIf="Configuration.ShowMagnifyingGlassIcon"
    [ngClass]="searchIconClass"
    ><app-icon source="rn-search" [imgClass]="'searchImage'"></app-icon
  ></span>
  <input
    [(ngModel)]="searchText"
    [ngClass]="EditClass"
    (ngModelChange)="inputSearchText.next($event)"
    [(placeholder)]="Configuration.EditFieldPlaceholderText"
    (focus)="editFocus($event)"
    (blur)="editBlur($event)"
    (select)="editSelect($event)"
    [attr.data-testid]="id"
  />
</div>
