import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  Renderer2,
  ElementRef,
  ViewChild,
  forwardRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-quantity-control",
  templateUrl: "./quantity-control.component.html",
  styleUrls: ["./quantity-control.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuantityControlComponent),
      multi: true,
    },
  ],
})
export class QuantityControlComponent implements OnInit {
  @Output() quantityChangeActionEmitter: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() quantitySelectActionEmitter: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() quantityEnteredActionEmitter: EventEmitter<number> =
    new EventEmitter<number>();

  @Input() MinValue: number;
  @Input() MaxRemaining: number;
  @Input() MaxUsedMessage: string;
  @Input() InternalExclusiveTooltip: string;
  @Input() DefaultValue: number;
  @Input() Values: number[];
  @Input() DisallowInput: boolean;
  @Input() btnStyleClass: string;
  @Input() inputStyleClass: string;
  @Input() QtyControlsDisabled = false;
  @Input() AddQtyControlsDisabled = false;
  @Input() MaxAllowed = -1;

  @ViewChild("decreaseBtn") decreaseBtn: ElementRef;
  @ViewChild("edit") edit: ElementRef;
  @ViewChild("increaseBtn") increaseBtn: ElementRef;

  isReadOnly: boolean;
  previousValue: number;
  selectedValue: number;
  addDisabled: boolean =
    this.AddQtyControlsDisabled || this.QtyControlsDisabled;
  tooltipText = "";
  disabled = this.QtyControlsDisabled;

  constructor(private renderer: Renderer2) {
    this.allowedValues = this.Values || [];
    this.isReadOnly = this.DisallowInput == true;
  }
  writeValue(obj: any): void {
    this.selectedValue = obj;
  }

  GetTooltip(): string {
    if (
      this.InternalExclusiveTooltip &&
      this.InternalExclusiveTooltip.length > 0
    ) {
      return this.InternalExclusiveTooltip;
    } else if (
      this.selectedValue >= this.MaxAllowed &&
      this.MaxAllowed > 0 &&
      this.addDisabled === true
    ) {
      return "Maximum allowed quantity reached.";
    }
    return this.MaxUsedMessage;
  }
  DisableTooltip(): boolean {
    if (
      this.InternalExclusiveTooltip &&
      this.InternalExclusiveTooltip.length > 0
    ) {
      return false;
    }
    return !(this.disabled || this.addDisabled);
  }
  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(
      this.decreaseBtn.nativeElement,
      "disabled",
      isDisabled || this.QtyControlsDisabled,
    );
    this.renderer.setProperty(
      this.edit.nativeElement,
      "disabled",
      isDisabled || this.QtyControlsDisabled,
    );
    this.renderer.setProperty(
      this.increaseBtn.nativeElement,
      "disabled",
      isDisabled || this.QtyControlsDisabled,
    );
  }

  ngOnInit(): void {
    this.selectedValue = this.DefaultValue != null ? this.DefaultValue : 1;
    this.previousValue = this.selectedValue;
    this.addDisabled = this.AddQtyControlsDisabled || this.QtyControlsDisabled;
    if (this.MaxAllowed > 0 && this.selectedValue >= this.MaxAllowed) {
      this.addDisabled = true;
    }
    this.tooltipText = this.GetTooltip();
    this.disabled = this.QtyControlsDisabled;
  }

  isAddButtonDisabled() {
    return (
      (this.MaxRemaining != null && this.MaxRemaining <= 0) ||
      this.QtyControlsDisabled ||
      this.AddQtyControlsDisabled ||
      (this.selectedValue >= this.MaxAllowed && this.MaxAllowed > 0)
    );
  }

  onInputBlur(event) {
    //min value check
    if (this.selectedValue > this.MaxAllowed && this.MaxAllowed > 0) {
      this.selectedValue = this.MaxAllowed;
    }

    const delta = this.selectedValue - this.previousValue;
    if (
      !this.selectedValue ||
      isNaN(this.selectedValue) ||
      this.selectedValue < this.MinValue
    ) {
      this.selectedValue = this.MinValue;
    } else if (this.MaxRemaining != null && delta > this.MaxRemaining) {
      this.selectedValue = this.previousValue + this.MaxRemaining;
    }

    this.previousValue = this.selectedValue;
    this.quantityEnteredActionEmitter.emit(this.selectedValue);
  }

  disallowNonNumbers(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;
  }

  disallowPaste() {
    return false;
  }

  changeQuantity(delta: number) {
    if (this.disabled) {
      this.addDisabled = true;
      return;
    }
    if (this.selectedValue + delta >= this.MaxAllowed && this.MaxAllowed > 0) {
      this.addDisabled = true;
    }
    if (this.allowedValues.length > 0) {
      let index = this.allowedValues.indexOf(this.selectedValue);
      index += delta;
      if (index > -1 && index < this.allowedValues.length) {
        this.selectedValue = this.allowedValues[index];
      }
      this.quantitySelectActionEmitter.emit(this.selectedValue);
    } else {
      const newValue = this.selectedValue + delta;
      this.selectedValue =
        newValue >= this.MinValue ? newValue : this.selectedValue;
      this.quantityChangeActionEmitter.emit(delta);
    }
    this.previousValue = this.selectedValue;
    if (delta === 1) {
      this.addDisabled = this.isAddButtonDisabled();
    } else {
      this.addDisabled = false;
    }
    if (this.addDisabled) {
      this.tooltipText = this.GetTooltip();
    }
  }
  private allowedValues: number[];
}
