export class SalesforceChatConfigInfo {
  public SalesforceChatEnabled?: boolean;
  public DeploymentId?: string;
  public ButtonId?: string;
  public BaseLiveAgentUrl?: string;
  public BaseURL?: string;
  public SupportURL?: string;
  public ESWLiveAgentDevName?: string;
  public JSSource?: string;
  public InitId?: string;
  public BaseLiveAgentContentURL?: string;
  public DeploymentName?: string;
  public FirstName?: string;
  public LastName?: string;
  public Email?: string;
  public OrganizationName?: string;
  public showHelpBtn: boolean;
  public chatHasLoaded: boolean;
}
