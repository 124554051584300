/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnPackageItemsVM } from "../models/./packageItemsVM-d";

export class RnPackagesVM {
  ID?: number;
  Name?: string | null;
  SKU?: string | null;
  Slug?: string | null;
  Desc?: string | null;
  DescHtml?: string | null;
  ListPrice?: number;
  DiscountPrice?: number;
  EffStartDate?: string;
  EffEndDate?: string;
  CreatedDate?: string;
  CreatedBy?: string | null;
  PackageTypeID?: number;
  PackageCategoryID?: number;
  PackageType?: string | null;
  PackageItems?: Array<RnPackageItemsVM> | null;
  SortOrder?: number;
  IsOverrideable?: boolean;
  PackageCategoryName?: string | null;
}
