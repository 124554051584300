import { Injectable } from "@angular/core";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { Subject } from "rxjs";
import { RnSignalRConnection } from "../services/rnapi2-service/models/models";

@Injectable({
  providedIn: "root",
})
export class SignalRService {
  private hubConnection: HubConnection | undefined;
  messages: Subject<string> = new Subject();

  init(signalRConnection?: RnSignalRConnection) {
    const MAXRETRYCOUNT = 5;
    const options = {
      accessTokenFactory: () => signalRConnection.AccessToken,
    };

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(signalRConnection.Url, options)
      .withAutomaticReconnect()
      .build();

    this.hubConnection.serverTimeoutInMilliseconds = 300000;

    this.hubConnection
      .start()
      .then((r) => {
        this.connectCounter = 0;
      })
      .catch((err) => {
        if (this.connectCounter < MAXRETRYCOUNT) {
          this.connectCounter++;
          setTimeout(() => {
            this.init(signalRConnection);
          }, 1000);
        } else {
          const errorText = JSON.stringify(err);
          console.error("SignalR:Could not connect to SignalR", errorText);
        }
      });

    this.hubConnection.off("notify");

    this.hubConnection.on("notify", (data: any) => {
      console.info(data);
      this.messages.next(data);
    });

    this.hubConnection.onclose((error) => {
      console.warn("SignalR: Trying to reconnect again to SignalR", error);
      this.connectCounter = 0;
      this.init(signalRConnection);
      console.warn("SignalR:Reconnected successfully to SignalR");
    });
  }

  private connectCounter = 0;
}
