import { Pipe, PipeTransform } from "@angular/core";
import { RnOrganizationsProfileVM } from "../../services/rnapi2-service/models/models";

@Pipe({
  name: "rnOrganizationInfo",
})
export class RnOrganizationInfoPipe implements PipeTransform {
  transform(orgProfile: RnOrganizationsProfileVM, ...args: unknown[]): unknown {
    if (orgProfile && args && args.length > 0) {
      const prop = args[0].toString();
      switch (prop.toLowerCase()) {
        case "accountownername": {
          return orgProfile.AccountOwner && orgProfile.AccountOwner !== ""
            ? orgProfile.AccountOwner
            : "None";
        }
        case "orgdefaultshareddrive": {
          return orgProfile.DefaultSharedDrive &&
            orgProfile.DefaultSharedDrive !== ""
            ? orgProfile.DefaultSharedDrive + ":\\"
            : "";
        }
        case "folderedittext": {
          const lastSlash = orgProfile.DefaultConnectPoint.lastIndexOf("\\");
          return orgProfile.DefaultConnectPoint.substring(lastSlash + 1);
        }
        case "orginfoisacctowner": {
          return orgProfile.AccountOwnerID > 0;
        }
        case "orghasexternalid": {
          return orgProfile.ExternalID && orgProfile.ExternalID !== "";
        }
        case "orghaswlpstatus": {
          return orgProfile.WlpStatusDesc && orgProfile.WlpStatusDesc !== "";
        }
        case "orghascan": {
          return orgProfile.WlpStatusDesc && orgProfile.WlpStatusDesc !== "";
        }
      }
    }
    return "";
  }
}
