/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export class RnPaymentOnFileVM {
  PaymentOnFileID?: number;
  OrganizationPaymentName?: string | null;
  PaymentMethodID?: number;
  OrganizationID?: number;
  PaymentCompanyName?: string | null;
  PaymentPersonName?: string | null;
  AccountLast4?: string | null;
  CCExpireOn?: string;
  PaymentMethodType?: string | null;
  EffStartDate?: string;
  EffEndDate?: string;
  CreatedDate?: string;
  CreatedBy?: string | null;
  Zuora_PaymentMethod_ID?: string | null;
  ACHTypeID?: number | null;
  ACHTypeName?: string | null;
  StateName?: string | null;
}
