import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { DataRetrievalParameters } from "../../../../core/models/data-retrieval-parameters";
import { OrganizationService } from "../../../services/rnapi2-service/apis/api";
import {
  RnCommonId,
  RnOrganizationsProfileVM,
} from "../../../services/rnapi2-service/models/models";
import { SelectionService } from "../../../services/selection/selection.service";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { GridConfiguration } from "../../third-party-wrappers/grid/configuration/grid-configuration";
import { GridFormatDateColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-format-date";
import { GridLinkButtonColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-linkbutton-column-configuration";

@Component({
  selector: "detailed-billing-reports",
  templateUrl: "./detailed-billing-reports.component.html",
  styleUrls: ["./detailed-billing-reports.component.scss"],
})
export class DetailedBillingReportsComponent implements OnInit, OnDestroy {
  public gridConfiguration: GridConfiguration;
  public selectedOrg: RnOrganizationsProfileVM = new RnOrganizationsProfileVM();
  private subscriptions: Subscription[] = [];
  private detailedBillingReports: any[];

  constructor(
    protected datePipe: DatePipe,
    private selectionService: SelectionService,
    private organizationService: OrganizationService,
    private rnToastService: RnToastService,
  ) {}

  ngOnInit(): void {
    const selectedOrgSub = this.selectionService.SelectedOrgProfile.subscribe(
      (result) => {
        this.selectedOrg = result;
        if (this.selectedOrg === null) return;

        this.initGridAndWatchTableParams();
      },
    );
    this.subscriptions.push(selectedOrgSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  private initGridAndWatchTableParams() {
    //sets up the basic grid definition
    this.gridConfiguration = new GridConfiguration();
    this.gridConfiguration.FirstRow = 0;
    this.gridConfiguration.UsePaginator = false;
    this.gridConfiguration.Loading = true;
    this.gridConfiguration.SortField = "ReportDate";
    this.gridConfiguration.SortOrder = -1;
    this.gridConfiguration.ShowCountHeader = false;

    this.gridConfiguration.setUrlData = (params: DataRetrievalParameters) => {
      if (this.detailedBillingReports === undefined) {
        this.organizationService
          .apiV2OrganizationsGetCurrentDetailedBillingReportInfoListingPost(
            rnCommonId,
          )
          .subscribe((result) => {
            this.detailedBillingReports = result.data;
            this.populateGrid(params);
          });
      } else {
        this.populateGrid(params);
      }
    };

    this.gridConfiguration.ColumnConfiguration = [];
    const reportDate = new GridFormatDateColumnConfiguration(
      "ReportDate",
      "Report Date",
      "col-50-width",
      "ReportDate",
      this.datePipe,
    );
    reportDate.FormatDate = "MM/d/yyyy";
    reportDate.GetDateCol = (rowData: any) => {
      return "";
    };
    this.gridConfiguration.ColumnConfiguration.push(reportDate);

    const userOrgName = new GridLinkButtonColumnConfiguration(
      "Download",
      "Download",
      "col-50-width",
      "",
    );
    userOrgName.Icon = "fa fa-file linkbutton";
    userOrgName.GetAction = (rowData: any) => {
      this.getDetailedBillingReport(
        rowData.ReportID,
        rowData.ReportDate,
        rowData.FileDate,
      );
      return false;
    };

    this.gridConfiguration.ColumnConfiguration.push(userOrgName);

    const rnCommonId: RnCommonId = {
      Id: this.selectedOrg.ID.toString(),
    };
  }

  private populateGrid(params: DataRetrievalParameters) {
    if (params.IsDescendingOrder) {
      this.detailedBillingReports.sort((a, b) =>
        a.ReportDate < b.ReportDate ? 1 : -1,
      );
    } else {
      this.detailedBillingReports.sort((a, b) =>
        a.ReportDate > b.ReportDate ? 1 : -1,
      );
    }
    this.detailedBillingReports.forEach((record) => {
      record.Download = "Detailed Report";
    });
    this.gridConfiguration.GridData = this.detailedBillingReports;
    this.gridConfiguration.Loading = false;
  }

  private getDetailedBillingReport(reportId, reportDate, fileDate: string) {
    const rnCommonId: RnCommonId = {
      Id: reportId,
      AffectedOrganizationId: this.selectedOrg.ID,
    };

    this.organizationService
      .apiV2OrganizationsGetDetailedBillingReportContentPost(rnCommonId)
      .subscribe(
        (result) => {
          const filename = this.selectedOrg.Name + " " + fileDate + ".xlsx";
          const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

          const linkElement = document.createElement("a");
          try {
            const blob = new Blob([result], { type: contentType });
            const url = window.URL.createObjectURL(blob);

            linkElement.setAttribute("href", url);
            linkElement.setAttribute("download", filename);

            const clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            linkElement.dispatchEvent(clickEvent);
          } catch (ex) {
            console.log(ex);
          }
        },
        (error) => {
          this.rnToastService.showError(
            "There was an error in loading the data for the report. Please contact support for further assistance.",
          );
        },
      );
  }
}
