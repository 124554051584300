import { HttpResponse } from "@angular/common/http";
import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { of, forkJoin, Observable, Subscription } from "rxjs";
import { Dropdown } from "src/app/core/models/Dropdown";
import { AddressService } from "src/app/shared/services/address/address.service";
import { LoggedInInfoService } from "src/app/shared/services/loggedInInfo/logged-in-info.service";
import { OrganizationLogicService } from "src/app/shared/services/organization-logic/organization-logic.service";
import {
  LookupService,
  OrganizationService,
} from "src/app/shared/services/rnapi2-service/apis/api";
import {
  RnCommonId,
  RnIndustryVM,
  RnObjectRNResponseRelay,
  RnOrganizationCreateUpdateXid,
  RnOrganizationRename,
  RnOrganizationTreeNodeListRNResponseRelay,
  RnOrganizationUpdateBusinessAddress,
  RnRNOrderHeader,
  RnRNSubOrganization,
  RnSubOrganizationCreate,
} from "src/app/shared/services/rnapi2-service/models/models";
import { RnToastService } from "src/app/shared/services/toast/rntoast.service";
import { OrganizationInfoConfig } from "../../../../core/models/organization-info-configuration";
import { NavigationService } from "src/app/shared/services/navigation/navigation.service";
import { SelectionService } from "src/app/shared/services/selection/selection.service";
import { DialogTrackingService } from "../../../services/dialog/dialog-tracking.service";
import { SignalrPollingService } from "../../../services/signalr-polling/signalr-polling.service";
import { Guid } from "../../../utilities/guid/guid.utilities";
import { SignalrPollingBaseMessage } from "../../../../core/models/signalr/signalr-polling-base-message";
import { GenericPropertyPayload } from "../../../../core/models/signalr/generic-property-payload";
import { ProcessingEventData } from "../../../../core/models/processing-event-data";
import { processEventStateTypes } from "../../../../core/enums/processEventStateTypes";

@Component({
  selector: "app-organization-info-create",
  templateUrl: "./organization-info-create.component.html",
  styleUrls: ["./organization-info-create.component.scss"],
})
export class OrganizationInfoCreateComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public frmGroup!: FormGroup;
  public submitted = false;

  xidHelp =
    "<span data-testid='tooltipMsg'>If you have your own client tracking/ID system at your organization, you can enter a unique identifier for your client (or user) here. Otherwise, leave it blank. XIDs appear on certain reports and invoices if they are set.</span>";
  public isLoadingIndustry: boolean;
  public normalButtonText: string;
  public pageTitle;
  public orgInfo: OrganizationInfoConfig;
  public canNotEditOrgNameMessage = "";
  public address;
  public working = false;
  public successMessage = false;
  public subOrgClientData: any;
  public isUserInfoData = false;
  public technical_support_phone: string;
  public readOnly = false;
  public orgNameDisabled = false;
  public segments: string[] = [];
  public canShowBusinessIndustry = false;
  public industries: RnIndustryVM[] = [];
  public eventData = new ProcessingEventData();
  @Output() ProcessingEvent = new EventEmitter<ProcessingEventData>();

  @ViewChild("businessNamelInput")
  emailInputElement!: ElementRef<HTMLInputElement>;

  @Output() OrgUpdated = new EventEmitter<number>();

  constructor(
    private selectionService: SelectionService,
    private navigationService: NavigationService,
    public ref: DynamicDialogRef,
    private loggedInInfoService: LoggedInInfoService,
    public config: DynamicDialogConfig,
    private organizationService: OrganizationService,
    private rnToastService: RnToastService,
    private addressService: AddressService,
    private organizationLogicService: OrganizationLogicService,
    private formBuilder: FormBuilder,
    private dialogTrackService: DialogTrackingService,
    private signalrPollingService: SignalrPollingService,
    private lookupService: LookupService,
  ) {
    this.orgInfo = this.config.data;
  }

  ngOnInit(): void {
    if (this.loggedInInfoService.GetWLPConfig) {
      const wlpConfigData = this.loggedInInfoService?.GetWLPConfig()?.data;
      if (wlpConfigData) {
        this.technical_support_phone =
          Object.values(wlpConfigData)[
            Object.keys(wlpConfigData).indexOf("technical_support_phone_number")
          ];
      }
    }
    this.frmGroup = this.formBuilder.group({
      businessName: ["", Validators.required.bind(Validators)],
      country: [
        {
          value: {
            label: "USA",
            value: "United States",
          },
          disabled: this.addressDisabled(),
        },
        Validators.required.bind(Validators),
      ],
      address1: [
        {
          value: "",
          disabled: this.addressDisabled(),
        },
        Validators.required.bind(Validators),
      ],
      address2: [
        {
          value: "",
          disabled: this.addressDisabled(),
        },
      ],
      postalCode: [
        {
          value: "",
          disabled: this.addressDisabled(),
        },
        Validators.required.bind(Validators),
      ],
      city: [
        {
          value: "",
          disabled: this.addressDisabled(),
        },
        Validators.required.bind(Validators),
      ],
      state: [
        {
          value: "",
          disabled: this.addressDisabled(),
        },
      ],
      stateText: [
        {
          value: "",
          disabled: this.addressDisabled(),
        },
      ],
      xid: ["", Validators.maxLength(50)],
      segment: [""],
      industry: [""],
      otherIndustryTypeName: [""],
    });

    if (this.orgInfo.editOrg !== true) {
      this.pageTitle =
        '<h3 class="info-modal-title">Add Client Organization</h3>';
      this.normalButtonText = "ADD";
      this.address = {
        country: {
          label: "USA",
          value: "United States",
        },
      };
    } else {
      this.pageTitle =
        '<h3 class="info-modal-title">Edit Organization Information</h3>';
      this.normalButtonText = "Save";
      this.initUpdateForm();
    }

    this.frmGroup.valueChanges.subscribe((formData) => {
      if (
        this.compareUserInfo(this.orgInfo.OrgInfoConfig, formData) &&
        formData.xid === this.orgInfo.OrgInfoConfig.ExternalID
      ) {
        this.isUserInfoData = false;
      } else if (formData.xid === "") {
        this.isUserInfoData = true;
      } else {
        this.isUserInfoData = true;
      }
    });

    this.canShowBusinessIndustry = true;

    // get list of Customer Segments and industries
    this.readOnly = this.orgInfo.editOrg && !this.orgInfo.canEdit;
    this.orgNameDisabled = this.getOrgNameDisabled();
    const userOrg: any = this.loggedInInfoService.GetLoggedInUserOrg();
    this.canShowBusinessIndustry =
      this.loggedInInfoService.loggedInUserHasRights(["EDTORGSGMT"]) &&
      userOrg.CustomerType.toLowerCase() === "rightworks";
    if (this.canShowBusinessIndustry) {
      this.isLoadingIndustry = true;
      const lookupIndustries = this.lookupService
        .apiV2LookupIndustriesGet()
        .subscribe(
          (response) => {
            if (response && response.data) {
              this.segments = response.data.map((a) => a.SegmentName);
              this.industries = response.data.find(
                (a) => a.Industries.length > 0,
              ).Industries;
            }
          },
          (error) => {},
          () => {
            this.isLoadingIndustry = false;
            this.eventData.type = processEventStateTypes.completed;
            this.ProcessingEvent.emit(this.eventData);
            this.subscriptions.push(lookupIndustries);
          },
        );
    } else {
      this.isLoadingIndustry = false;
      this.eventData.type = processEventStateTypes.completed;
      this.ProcessingEvent.emit(this.eventData);
    }
  }

  ngAfterViewInit(): void {
    if (this.orgInfo.editOrg) {
      this.emailInputElement.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());

    if (this.signalRHandlerID) {
      this.signalrPollingService.RemoveMessageHandler(this.signalRHandlerID);
    }
  }

  public addressDisabled(): boolean {
    if (this.orgInfo.editOrg === true) {
      return !this.loggedInInfoService.loggedInUserHasRights(["CHNGORGINF"]);
    } else {
      return false;
    }
  }

  public getOrgNameDisabled(): boolean {
    const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    if (!loggedInUser) {
      return false;
    }

    if (this.orgInfo.editOrg === true) {
      const isInternal = loggedInUser.IsInternal();
      const loggedInUserOrgID = loggedInUser.OrganizationID;
      const RNMSUBORG = this.loggedInInfoService.loggedInUserHasRights([
        "RNMSUBORG",
      ]);
      const RENAMEORG = this.loggedInInfoService.loggedInUserHasRights([
        "RENAMEORG",
      ]);

      const isDisabledOrgName = !this.organizationLogicService.canShowRenameOrg(
        RNMSUBORG,
        RENAMEORG,
        isInternal,
        loggedInUserOrgID,
        this.orgInfo.OrgInfoConfig,
      );

      if (isDisabledOrgName) {
        if (
          this.orgInfo &&
          (this.orgInfo.OrgInfoConfig.CustomerType === "Rightworks" ||
            (this.orgInfo.OrgInfoConfig.CustomerType !== "Rightworks" &&
              this.orgInfo.OrgInfoConfig.ParentID > 0))
        ) {
          this.canNotEditOrgNameMessage = `If you need to change this organization's business name call ${this.technical_support_phone}.`;
        } else {
          this.canNotEditOrgNameMessage = `If you need to change this organization's business name, please contact support.`;
        }
      }

      return isDisabledOrgName;
    } else {
      return false;
    }
  }

  private initUpdateForm() {
    this.isLoadingIndustry = true;
    const getCountry = this.addressService
      .getCountryInfo()
      .allCountries.find(
        (c) => c.code == this.orgInfo.OrgInfoConfig.Address.Country,
      );
    const CountryCombo: Dropdown = {
      label: getCountry.code,
      value: getCountry?.name,
    };
    let stateCombo: Dropdown;
    let getState;
    if (
      CountryCombo.value === "United States" ||
      CountryCombo.value === "Canada"
    ) {
      if (CountryCombo.value === "United States") {
        getState = this.addressService
          .getCountryInfo()
          .usStates.find(
            (s) => s.code == this.orgInfo.OrgInfoConfig.Address.StateProvince,
          );
      } else {
        getState = this.addressService
          .getCountryInfo()
          .caRegions.find(
            (s) => s.code == this.orgInfo.OrgInfoConfig.Address.StateProvince,
          );
      }
      stateCombo = {
        label: getState?.name,
        value: getState?.code,
      };
    }

    this.businessName.setValue(this.orgInfo?.OrgInfoConfig?.Name);
    this.address = {
      country: CountryCombo,
      address1: this.orgInfo.OrgInfoConfig.Address.Address1,
      address2: this.orgInfo.OrgInfoConfig.Address.Address2,
      postalCode: this.orgInfo.OrgInfoConfig.Address.PostalCodeID,
      city: this.orgInfo.OrgInfoConfig.Address.City,
      state: stateCombo ? stateCombo.label : undefined,
      stateText: this.orgInfo.OrgInfoConfig.Address.StateProvince,
    };
    this.xid.setValue(this.orgInfo.OrgInfoConfig.ExternalID);
    this.orgInfo.OrgInfoConfig["countryName"] = getCountry?.name;
    this.segment.setValue(this.orgInfo.OrgConfig.CustomerSegment);
    this.industry.setValue(this.orgInfo.OrgConfig.IndustryType);
    this.otherIndustryTypeName.setValue(
      this.orgInfo.OrgConfig.OtherIndustryTypeName,
    );
  }

  private renameOrg(payload: RnOrganizationRename): Observable<boolean> {
    if (
      this.loggedInInfoService.loggedInUserHasRights(["RENAMEORG"]) &&
      this.orgInfo?.OrgInfoConfig?.Name !== payload.UpdatedName
    ) {
      const updateOrgNameSub = this.organizationService
        .apiV2OrganizationsRenamePost(payload, "response")
        .subscribe(
          (result) => {
            if (result.body.Success) return of(true);
          },
          (err) => {
            return of(false);
          },
        );
      this.subscriptions.push(updateOrgNameSub);
    }
    return of(true);
  }

  private updateOrgAddress(payload: RnOrganizationUpdateBusinessAddress) {
    const infoChanged =
      payload.Address1 !== this.orgInfo.OrgInfoConfig.Address.Address1 ||
      payload.Address2 !== this.orgInfo.OrgInfoConfig.Address.Address2 ||
      payload.City !== this.orgInfo.OrgInfoConfig.Address.City ||
      payload.PostalCodeID !==
        this.orgInfo.OrgInfoConfig.Address.PostalCodeID ||
      payload.Country !== this.orgInfo.OrgInfoConfig.Address.Country ||
      payload.StateProvince !==
        this.orgInfo.OrgInfoConfig.Address.StateProvince;

    if (
      this.loggedInInfoService.loggedInUserHasRights(["RENAMEORG"]) &&
      infoChanged
    ) {
      const updateAddressSub = this.organizationService
        .apiV2OrganizationsUpdatebusinessaddressPost(payload, "response")
        .subscribe(
          (result) => {
            if (result.body.Success) return of(true);
          },
          (err) => {
            return of(false);
          },
        );
      this.subscriptions.push(updateAddressSub);
    }
    return of(true);
  }

  private updateXID(
    payload: RnOrganizationCreateUpdateXid,
  ): Observable<boolean> {
    if (this.orgInfo.OrgInfoConfig.ExternalID !== payload.Xid) {
      //if an org did not have an XID
      if (!this.orgInfo.OrgInfoConfig.ExternalID) {
        payload.IsCreate = true;
      }
      if (payload.Xid !== null) {
        const updateXidSub = this.organizationService
          .apiV2OrganizationsUpdateXidPost(payload, "response")
          .subscribe(
            (result) => {
              if (result.body.Success) return of(true);
            },
            (err) => {
              return of(false);
            },
          );
        this.subscriptions.push(updateXidSub);
      }
    }
    return of(true);
  }

  onSubmit(): void {
    if (this.frmGroup.valid) {
      this.working = true;
      const form = this.frmGroup.getRawValue();
      const updates = false;
      if (this.orgInfo.editOrg === true) {
        // XID update payload
        const updateXidOrgPayload: RnOrganizationCreateUpdateXid = {
          OrganizationID: this.orgInfo.OrgInfoConfig.ID,
          Xid: form.xid,
        };

        //Rename Organization
        const updateNameOrgPayload: RnOrganizationRename = {
          OrganizationID: this.orgInfo.OrgInfoConfig.ID,
          UpdatedName: form.businessName,
        };

        //update Address
        const updateAddressOrgPayload =
          new RnOrganizationUpdateBusinessAddress();
        updateAddressOrgPayload.OrganizationID = this.orgInfo.OrgInfoConfig.ID;
        updateAddressOrgPayload.Address1 = form.address1;
        updateAddressOrgPayload.Address2 = form.address2;
        updateAddressOrgPayload.City = form.city;
        const countryJson = form.country;
        updateAddressOrgPayload.Country = countryJson.label;
        updateAddressOrgPayload.PostalCodeID = form.postalCode;
        const stateTextValue = form.stateText;
        if (
          countryJson.value !== "Canada" &&
          countryJson.value !== "United States"
        ) {
          updateAddressOrgPayload.StateProvince = stateTextValue;
        } else {
          const stateJson = form.state;
          updateAddressOrgPayload.StateProvince = stateJson;
        }

        //update customer segment (Firm or SMB)
        if (
          form.segment &&
          this.orgInfo.OrgConfig.CustomerSegment !== form.segment
        ) {
          this.organizationService
            .apiV2OrganizationsCreateorupdateorgconfigurationPost(
              this.orgInfo.OrgInfoConfig.ID,
              "CustomerSegment",
              [form.segment],
            )
            .subscribe((r) => {
              // succeed or fail silently
            });
        }
        if (
          form.industry &&
          this.orgInfo.OrgConfig.IndustryType !== form.industry
        ) {
          this.organizationService
            .apiV2OrganizationsCreateorupdateorgconfigurationPost(
              this.orgInfo.OrgInfoConfig.ID,
              "IndustryType",
              [form.industry],
            )
            .subscribe((r) => {
              // succeed or fail silently
            });
        }
        if (
          form.otherIndustryTypeName &&
          this.orgInfo.OrgConfig.OtherIndustryTypeName !==
            form.otherIndustryTypeName
        ) {
          this.organizationService
            .apiV2OrganizationsCreateorupdateorgconfigurationPost(
              this.orgInfo.OrgInfoConfig.ID,
              "OtherIndustryTypeName",
              [form.otherIndustryTypeName],
            )
            .subscribe((r) => {
              // succeed or fail silently
            });
        }

        const updateSub = forkJoin(
          this.renameOrg(updateNameOrgPayload),
          this.updateOrgAddress(updateAddressOrgPayload),
          this.updateXID(updateXidOrgPayload),
        ).subscribe(([rename, address, xid]) => {
          if (rename && address && xid) {
            setTimeout(() => {
              this.successMessage = true;
              this.orgUpdateSuccess("Organization updated successfully");
            }, 6000);
          } else {
            this.successMessage = false;
            this.orgUpdateFailed();
          }
        });
        this.subscriptions.push(updateSub);
      } else {
        //load form for create
        const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
        if (!loggedInUser) {
          return;
        }
        const subPayload = new RnSubOrganizationCreate();
        subPayload.Header = new RnRNOrderHeader();
        subPayload.Header.Order_XID = "-1";
        subPayload.Header.Order_Date = new Date().toISOString();
        subPayload.Header.Order_Source = "AppHub";
        subPayload.Header.SalesAgent_TAG = loggedInUser.UserID.toString();
        subPayload.SubOrganization = {};
        const subOrgData = new RnRNSubOrganization();
        subOrgData.Parent_ID = this.orgInfo.OrgInfoConfig.ID;
        subOrgData.ParentFolderID = this.orgInfo.OrgInfoConfig.OrgFolderId;
        subOrgData.Display_Name = (form.businessName as string).trim();
        subOrgData.Address1 = form.address1;
        subOrgData.Address2 = form.address2;
        subOrgData.City = form.city;
        const countryValue = form.country;
        subOrgData.Country = countryValue.label;
        const stateTextValue = form.stateText;
        if (
          countryValue.value !== "Canada" &&
          countryValue.value !== "United States"
        ) {
          subOrgData.State = stateTextValue;
        } else {
          subOrgData.State = form.state;
        }
        subOrgData.PostalCode = form.postalCode;
        subOrgData.XID = form.xid;
        subPayload.SubOrganization = subOrgData;
        this.subOrgClientData = subOrgData;
        const updateOrgSub = this.organizationService
          .apiV2OrganizationsSuborganizationPost(subPayload, "response")
          .subscribe(
            (result) => {
              if (result.body.Success === true) {
                this.successMessage = true;
                this.signalRHandlerID =
                  this.signalrPollingService.AddMessageHandler((message) => {
                    const notification = JSON.parse(
                      message,
                    ) as SignalrPollingBaseMessage;
                    if (
                      notification &&
                      notification.Payload &&
                      notification.Payload.AreaDataType.toUpperCase() ===
                        "GENERIC_PROPERTY_PAYLOAD" &&
                      notification.Payload.Area.toUpperCase() === "ORG" &&
                      notification.Payload.AreaID ===
                        this.orgInfo.OrgInfoConfig.ID &&
                      notification.Payload.Action.toUpperCase() === "ORGCREATED"
                    ) {
                      const data = JSON.parse(
                        notification.Payload.AreaData,
                      ) as GenericPropertyPayload;
                      const success = data.Properties.find(
                        (p) => p.PropertyName === "Success",
                      ).PropertyValue;
                      if (success.toUpperCase() === "TRUE") {
                        this.dialogTrackService.closeDialog(this.ref, null);
                        this.rnToastService.showSuccess(
                          "Client organization created successfully",
                        );
                        // add segment/industry
                        const neworgid = +data.Properties.find(
                          (p) => p.PropertyName === "NewOrgID",
                        ).PropertyValue;
                        if (form.segment) {
                          this.organizationService
                            .apiV2OrganizationsCreateorupdateorgconfigurationPost(
                              neworgid,
                              "CustomerSegment",
                              [form.segment],
                            )
                            .subscribe();
                        }
                        if (form.industry) {
                          this.organizationService
                            .apiV2OrganizationsCreateorupdateorgconfigurationPost(
                              neworgid,
                              "IndustryType",
                              [form.industry],
                            )
                            .subscribe();
                        }
                        if (form.otherIndustryTypeName) {
                          this.organizationService
                            .apiV2OrganizationsCreateorupdateorgconfigurationPost(
                              neworgid,
                              "OtherIndustryTypeName",
                              [form.otherIndustryTypeName],
                            )
                            .subscribe();
                        }

                        this.working = false;
                      } else {
                        this.rnToastService.showSuccess(
                          "Organization creation failed",
                        );
                        this.working = false;
                      }
                      this.signalrPollingService.RemoveMessageHandler(
                        this.signalRHandlerID,
                      );
                    }
                  });
              }
            },
            (err) => {
              const errResp = err as RnObjectRNResponseRelay;
              if (
                errResp &&
                errResp?.Messages &&
                errResp?.Messages.length > 0
              ) {
                this.orgUpdateFailedWithMessage(
                  errResp?.Messages[0]?.Description,
                );
              } else {
                this.orgUpdateFailed();
              }
            },
          );
        this.subscriptions.push(updateOrgSub);
      }
    }
  }

  loadUsersOrgTree(): Observable<
    HttpResponse<RnOrganizationTreeNodeListRNResponseRelay>
  > {
    const payload = new RnCommonId();
    const orgId = this.loggedInInfoService.GetLoggedInUserOrg();
    payload.Id = orgId.ID.toString();
    return this.organizationService.apiV2OrganizationsSuborgtreePost(
      payload,
      "response",
    );
  }

  orgUpdateFailed() {
    this.orgUpdateFailedWithMessage("Organization creation Failed");
  }
  orgUpdateFailedWithMessage(message: string) {
    this.working = false;
    this.rnToastService.showError(message);
    this.dialogTrackService.closeDialog(this.ref, null);
  }

  orgUpdateSuccess(message: string) {
    const sub = this.loadUsersOrgTree().subscribe((result) => {
      const orgTree = result.body.data;
      const topLevelOrg = this.loggedInInfoService.GetLoggedInUserOrg();
      const updatedOrg = this.orgInfo.OrgInfoConfig.ID;

      //this.redirectSuccess(message, topLevelOrg, updatedOrg, updatedOrg);

      this.selectionService.openSlideBarPanel(true);
      this.selectionService.selectedOrgTreeRefresh(orgTree);

      this.dialogTrackService.closeDialog(this.ref, updatedOrg);

      this.rnToastService.showSuccess(message);
      this.working = false;
    });
    this.subscriptions.push(sub);
  }

  updatedSuccessfully(message: string) {
    this.subscriptions.push(
      this.loadUsersOrgTree().subscribe(async (result) => {
        const orgTree = result.body.data;
        const topLevelOrg = orgTree[0];
        const updatedOrg = this.orgInfo.OrgInfoConfig.ID;
        let newCreatedOrgID;
        const last = orgTree[0].nodes.length - 1;
        let redirectId = -1;
        if (last !== -1) {
          newCreatedOrgID = orgTree[0].nodes[last].orgId;
        }
        if (orgTree[0].nodes.length > 0) {
          const topOrgID = this.selectedOrgTreeNode(
            orgTree[0],
            this.orgInfo.OrgInfoConfig.OrgFolderId,
          );
          let newUpdatedOrgId;
          if (topOrgID["nodes"].length > 0) {
            topOrgID["nodes"].forEach((t) => {
              if (t?.name == this.subOrgClientData?.Display_Name) {
                newUpdatedOrgId = t.orgId;
                return false;
              }
            });

            if (result.body.Success === true) {
              redirectId = newUpdatedOrgId;
            }
          } else {
            if (result.body.Success === true) {
              redirectId = newCreatedOrgID;
            }
          }
        }

        this.selectionService.openSlideBarPanel(true);
        this.selectionService.selectedOrgTreeRefresh(orgTree);
        if (redirectId > 0) {
          await this.redirectSuccess(
            message,
            topLevelOrg,
            updatedOrg,
            redirectId,
          );
        }
      }),
    );
  }

  selectedOrgTreeNode(orgTreeNodes, parentId) {
    if (orgTreeNodes["id"] === parentId) {
      return orgTreeNodes;
    } else {
      for (
        let i = 0, len = Object.keys(orgTreeNodes.nodes).length;
        i < len;
        i++
      ) {
        if (orgTreeNodes["nodes"].length > 0) {
          const selectedNode = this.selectedOrgTreeNode(
            orgTreeNodes.nodes[i],
            parentId,
          );
          if (selectedNode) {
            return selectedNode;
          }
        }
      }
    }
  }

  industrySelected() {
    return;
  }

  // update queryParams and shows success message
  async redirectSuccess(message, topLevelOrg, updatedOrg, newCreatedOrgID) {
    if (!this.successMessage) {
      this.dialogTrackService.closeDialog(this.ref, updatedOrg);
      await this.navigationService.OpenOrganization(
        topLevelOrg.orgId,
        updatedOrg,
      );
    } else {
      this.dialogTrackService.closeDialog(this.ref, newCreatedOrgID);
      await this.navigationService.OpenOrganization(
        topLevelOrg.orgId,
        newCreatedOrgID,
      );
    }
    this.working = false;

    this.rnToastService.showSuccess(message);
  }

  /**
   * this method return true if the field is not updated otherwise returns false
   * @param currentSavedData current saved data of form
   * @param formChangedData changed or updated data by user
   */
  compareUserInfo(currentSavedData, formChangedData): boolean {
    const userInfoChangedData = Object.values(formChangedData);
    for (let i = 0; i < userInfoChangedData.length; i++) {
      if (!this.isObject(userInfoChangedData[i])) {
        if (!this.isUserInfoChanged(currentSavedData, userInfoChangedData[i])) {
          return false;
        }
      } else {
        const userInfoChangedNestedData = Object.values(userInfoChangedData[i]);
        for (let j = 0; j < userInfoChangedNestedData.length; j++) {
          if (
            !this.isUserInfoChanged(
              currentSavedData,
              userInfoChangedNestedData[j],
            )
          ) {
            return false;
          }
        }
      }
    }
    return true;
  }

  // check the typeof object
  isObject(v): boolean {
    return v && typeof v === "object";
  }

  /**
   * this method check the updated value is exist in the current saved data or not.
   * @param currentSavedData current saved data of form
   * @param formChangedData changed or updated data by user
   */
  isUserInfoChanged(currentSavedData, formChangedData): boolean {
    const currentUserValue = Object.values(currentSavedData);
    for (const userData in currentSavedData) {
      if (this.isObject(currentUserValue[userData])) {
        const userDataExist = Object.values(
          currentUserValue[userData],
        ).includes(formChangedData);
        if (userDataExist === true) return true;
      } else if (currentUserValue[userData] === formChangedData) {
        return true;
      } else continue;
    }
    return false;
  }

  public close(): void {
    this.dialogTrackService.closeDialog(this.ref, null);
  }

  get businessName() {
    return this.frmGroup.get("businessName");
  }

  get country() {
    return this.frmGroup.get("country");
  }

  get address1() {
    return this.frmGroup.get("address1");
  }

  get address2() {
    return this.frmGroup.get("address2");
  }

  get postalCode() {
    return this.frmGroup.get("postalCode");
  }

  get city() {
    return this.frmGroup.get("city");
  }

  get state() {
    return this.frmGroup.get("state");
  }

  get stateText() {
    return this.frmGroup.get("stateText");
  }

  get xid() {
    return this.frmGroup.get("xid");
  }

  get segment() {
    return this.frmGroup.get("segment");
  }

  get industry() {
    return this.frmGroup.get("industry");
  }

  get otherIndustryTypeName() {
    return this.frmGroup.get("otherIndustryTypeName");
  }

  private signalRHandlerID: Guid;
  private subscriptions: Subscription[] = [];
}
