import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GenericDialogConfiguration } from "./generic-dialog-configuration";

@Component({
  selector: "app-generic-dialog",
  templateUrl: "./generic-dialog.component.html",
  styleUrls: ["./generic-dialog.component.scss"],
})
export class GenericDialogComponent implements OnInit {
  public Title: string;
  public Message: string;
  public MessageContainsHTML: boolean;
  public StyleClass: string;
  public CancelButtonText: string;
  public CancelButtonStyleClass: string;
  public ConfirmButtonText: string;
  public ConfirmButtonStyleClass: string;
  public DialogHeaderClass: string;
  public DialogBodyClass: string;
  public DialogFooterClass: string;
  public HeaderClass: string;
  public DialogFooterCancelClass: string;
  public DialogFooterConfirmClass: string;
  public UseButtonForCancel: boolean;
  public ShowCloseButton: boolean;
  public KeepOpenAfterConfirm = false;
  public HideCancel = false;
  public DisableClose = false;
  public showConfirmIcon = false;
  public showConfirmIconImageSource: string;
  public confirmImageIconClass: string;
  public confirmButtonNoContentPadding = false;
  public cancelButtonNoContentPadding = false;
  public IsWorking: boolean;
  public showDontShowAgain: boolean = false;
  public dontShowAgainText: string = "";
  public dontShowAgainVal: boolean = false;

  public Configuration: GenericDialogConfiguration;

  public fName = "";

  @Output() checkboxActionEmitter = new EventEmitter<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GenericDialogConfiguration,
    private mdDialogRef: MatDialogRef<GenericDialogComponent>,
  ) {
    this.Configuration = data;
    this.Title = this.Configuration.Title;
    this.Message = this.Configuration.Message;
    this.MessageContainsHTML = this.Configuration.MessageContainsHTML;
    this.StyleClass = this.Configuration.StyleClass;
    this.CancelButtonText =
      this.Configuration &&
      this.Configuration.CancelButtonText &&
      this.Configuration.CancelButtonText !== ""
        ? this.Configuration.CancelButtonText
        : "Cancel";
    this.CancelButtonStyleClass =
      this.Configuration &&
      this.Configuration.CancelButtonStyleClass &&
      this.Configuration.CancelButtonStyleClass !== ""
        ? this.Configuration.CancelButtonStyleClass
        : "";
    this.ConfirmButtonText =
      this.Configuration &&
      this.Configuration.ConfirmButtonText &&
      this.Configuration.ConfirmButtonText !== ""
        ? this.Configuration.ConfirmButtonText
        : "Confirm";
    this.ConfirmButtonStyleClass =
      this.Configuration &&
      this.Configuration.ConfirmButtonStyleClass &&
      this.Configuration.ConfirmButtonStyleClass !== ""
        ? this.Configuration.ConfirmButtonStyleClass
        : "destroy";
    this.DialogHeaderClass =
      this.Configuration &&
      this.Configuration.DialogHeaderClass &&
      this.Configuration.DialogHeaderClass !== ""
        ? this.Configuration.DialogHeaderClass
        : "modal-header";
    this.DialogBodyClass =
      this.Configuration &&
      this.Configuration.DialogBodyClass &&
      this.Configuration.DialogBodyClass !== ""
        ? this.Configuration.DialogBodyClass
        : "modal-content";
    this.DialogFooterClass =
      this.Configuration &&
      this.Configuration.DialogFooterClass &&
      this.Configuration.DialogFooterClass !== ""
        ? this.Configuration.DialogFooterClass
        : "modal-footer";
    this.DialogFooterCancelClass =
      this.Configuration &&
      this.Configuration.DialogFooterConfirmClass &&
      this.Configuration.DialogFooterCancelClass !== ""
        ? this.Configuration.DialogFooterCancelClass
        : "";
    this.DialogFooterConfirmClass =
      this.Configuration &&
      this.Configuration.DialogFooterConfirmClass &&
      this.Configuration.DialogFooterConfirmClass !== ""
        ? this.Configuration.DialogFooterConfirmClass
        : "";
    this.HeaderClass =
      this.Configuration &&
      this.Configuration.HeaderClass &&
      this.Configuration.HeaderClass !== ""
        ? this.Configuration.HeaderClass
        : "modal-header-text";
    this.UseButtonForCancel = this.Configuration.UseButtonForCancel;
    this.ShowCloseButton = this.Configuration.ShowCloseButton;
    this.KeepOpenAfterConfirm = this.Configuration.KeepOpenAfterConfirm;
    this.HideCancel = this.Configuration.HideCancel;
    this.DisableClose = this.Configuration.DisableClose;
    this.showConfirmIcon =
      this.Configuration && this.Configuration.showConfirmIcon
        ? this.Configuration.showConfirmIcon
        : false;
    this.showConfirmIconImageSource =
      this.Configuration &&
      this.Configuration.showConfirmIconImageSource &&
      this.Configuration.showConfirmIconImageSource !== ""
        ? this.Configuration.showConfirmIconImageSource
        : "";
    this.confirmImageIconClass =
      this.Configuration &&
      this.Configuration.confirmImageIconClass &&
      this.Configuration.confirmImageIconClass !== ""
        ? this.Configuration.confirmImageIconClass
        : "";
    this.showDontShowAgain = this.Configuration.showDontShowAgain;
    this.dontShowAgainText = this.Configuration.dontShowAgainText || "Don't show this message again";
  }

  ngOnInit(): void {
    if (!this.Configuration.DisableClose)
      window.document
        .querySelector(".cdk-overlay-backdrop")
        ?.addEventListener("click", this.CloseFromBody.bind(this));
  }

  public CancelClick(): void {
    this.mdDialogRef.close();
    if (this.Configuration && this.Configuration.Canceled) {
      this.Configuration.Canceled();
    }
  }

  public CloseClick(): void {
    if (this.Configuration && this.Configuration.OnClose) {
      this.mdDialogRef.close();
      this.Configuration.OnClose();
    } else {
      // If we don't have a set delegate for closing, try the Cancel button
      // event (for dialogs that pre-date the separate events)
      this.CancelClick();
    }
  }

  public ConfirmClick(): void {
    if (this.KeepOpenAfterConfirm) {
      this.IsWorking = true;
    } else {
      this.mdDialogRef.close();
    }
    if (this.Configuration.showDontShowAgain && this.dontShowAgainVal) {
      this.Configuration.checkboxActionHandler(this.dontShowAgainVal);
      this.Configuration.Confirmed(this);
    }
    else if (this.Configuration && this.Configuration.Confirmed) {
      this.Configuration.Confirmed(this);
    }
  }

  public dontShowAgainClicked(event) {
    this.dontShowAgainVal = event;
  }

  public CloseFromBody(): void {
    const evt = event.target as Element;
    if (
      window.document.querySelector(".close-clicker") &&
      evt &&
      evt.classList.contains("cdk-overlay-backdrop")
    ) {
      window.document
        .querySelector(".close-clicker")
        .dispatchEvent(new Event("click"));
    }
  }

  public CloseFromBodyClick(): void {
    if (this.Configuration && this.Configuration.OnCloseFromBody) {
      this.mdDialogRef.close();
      this.Configuration.OnCloseFromBody();
    } else {
      // If we don't have a specific delegate when the close from body fires, do the regular close event
      this.CloseClick();
    }
  }

  public CloseDialog(): void {
    this.mdDialogRef.close();
  }
}
