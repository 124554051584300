<div class="modal-content">
  <div class="modal-header">
    <p [innerHTML]="pageTitle"></p>
    <div>
      <button
        type="button"
        class="close pullUpperRight"
        (click)="close()"
        aria-hidden="true"
      >
        <span uib-tooltip="Close" tooltipPosition="top" pTooltip="Close"
          >×</span
        >
      </button>
    </div>
  </div>
  <hr class="headerSeperator" />
  <form [formGroup]="frmGroup">
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div
            class="p-field"
            [ngClass]="{
              'has-error':
                !businessName?.valid && (submitted || businessName?.touched)
            }"
          >
            <label for="businessName">Business Name</label><br />
            <input
              #businessNamelInput
              appTrimInput
              data-testid="businessName"
              id="businessName"
              name="businessName"
              [ngClass]="{ 'ui-inputtext-notallowed': orgNameDisabled }"
              formControlName="businessName"
              class="ui-inputtext form-control"
              placeholder="Business Name"
              pInputText
              [readonly]="orgNameDisabled"
              maxlength="64"
            />
            <div *ngIf="submitted || businessName?.touched">
              <div
                class="help-block"
                *ngIf="
                  businessName.errors?.required ||
                  businessName.errors?.whitespace
                "
              >
                Required field
              </div>
            </div>
            <div *ngIf="orgNameDisabled">{{ canNotEditOrgNameMessage }}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-business-address
            [isReadOnly]="readOnly"
            [address]="address"
            [parentForm]="frmGroup"
            [submitted]="submitted"
          >
          </app-business-address>
        </div>
      </div>
      <div class="row pt-1">
        <div class="col-12">
          <div
            class="p-field"
            [ngClass]="{
              'has-error': !xid?.valid && (submitted || xid?.touched)
            }"
          >
            <label for="xid">XID (optional) </label>&nbsp;<app-icon
              source="rn-help"
              tooltipStyleClass="swTooltipBottom"
              tooltip-placement="right"
              tooltipZIndex="20000"
              [pTooltip]="xidHelp"
              [escape]="false"
            ></app-icon
            ><br />
            <input
              appTrimInput
              id="xid"
              name="xid"
              formControlName="xid"
              type="text"
              placeholder="XID"
              pInputText
              class="form-control"
            />
            <div *ngIf="submitted || xid?.touched">
              <div class="help-block" *ngIf="xid?.errors?.maxlength">
                XID is too long (must be 50 characters or less)
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="canShowBusinessIndustry">
        <div class="row">
          <div class="col-12">
            <div
              class="p-field"
              [ngClass]="{
                'has-error': !segment?.valid && (submitted || segment?.touched)
              }"
            >
              <span class="text_14">Business Industry</span>
              <div *ngFor="let seg of segments">
                <input
                  data-testid="{{ seg }}Option"
                  type="radio"
                  formControlName="segment"
                  [value]="seg"
                />
                <label for="{{ seg }}">{{
                  seg === "Accounting Firm" ? "Accounting/Tax" : "Other"
                }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="segment.value === 'SMB' && !isLoadingIndustry">
          <div class="col-12">
            <div
                 class="p-field"
                 [ngClass]="{
                'has-error':
                  !industry?.valid && (submitted || industry?.touched)
              }"
            >
              <span class="text_14">Business Industry</span>
              <rw-single-select [id]="'industry'"
                                [items]="industries"
                                [bindLabel]="'IndustryName'"
                                [bindValue]="'IndustryName'"
                                [placeholder]="'Select Industry'"
                                [arialabel]="'Select Industry'"
                                [datapendoid]="'selectIndustry'"
                                [datatestid]="'selectIndustry'"
                                searchable="false"
                                formControlName="industry"></rw-single-select>
              <div *ngIf="industry.value === 'Other'">
                <input
                  class="form-control other-industry-input"
                  type="text"
                  formControlName="otherIndustryTypeName"
                  placeholder="Other Industry..."
                  maxlength="50"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="smallLoading" *ngIf="isLoadingIndustry">
          <app-loader
            *ngIf="isLoadingIndustry"
            text="Loading..."
            inline="true"
          ></app-loader>
        </div>
      </div>
    </div>
    <div class="modal-footer right">
      <app-button
        id="btnCancel"
        label="CANCEL"
        noContentPadding="true"
        buttonStyle="addUserTeritary"
        (clickActionEmitter)="close()"
      >
      </app-button>
      <app-button
        id="btnSubmit"
        [label]="normalButtonText"
        [disabled]="submitted || !frmGroup.valid || this.frmGroup.pristine"
        showProcessing="true"
        noContentPadding="true"
        [processing]="working"
        buttonStyle="primary"
        (clickActionEmitter)="onSubmit()"
      >
      </app-button>
    </div>
  </form>
</div>
