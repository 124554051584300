/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnPackagesVM } from "../models/./packagesVM-d";

export class RnUserPackagePoolsVM {
  UserPackagePoolID?: number;
  UserID?: number;
  PackageID?: number;
  OrganizationPackagePoolID?: number;
  EffStartDate?: string;
  EffEndDate?: string;
  CreatedDate?: string;
  CreatedBy?: string | null;
  PackageName?: string | null;
  PackageVM?: RnPackagesVM;
}
