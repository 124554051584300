<div [ngClass]="{ 'has-error': !range.valid }" class="rn-date-range">
  <mat-form-field>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input
        data-testid="fromDate"
        matStartDate
        class="start-date-input"
        formControlName="start"
        placeholder="Start date"
        required
      />
      <input
        data-testid="toDate"
        matEndDate
        class="end-date-input"
        formControlName="end"
        placeholder="End date"
        required
      />
    </mat-date-range-input>
    <mat-datepicker-toggle
      data-testid="datePicker"
      matSuffix
      [for]="picker"
    ></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
  <div data-testid="dateValidation" class="help-block" *ngIf="!range.valid">
    A valid date range is required.
  </div>
</div>
