import { UserToken } from "./user-token";

export class UserLoginState extends UserToken {
  public FirstName: string;
  public LastName: string;
  public Email: string;
  public OrganizationName: string;
  public OrganizationID: number;
  public OrganizationWLPDescription: string;
  public OrganizationWLPID: number;
  public UserType: string;
  public UserID: number;
  public ShowSetupWizardDownload: boolean;
  public AssignedPackage: string;
  public ProfileCreated: boolean;
  public AssignedAddOns: Array<string> | null;
  public OrgDataCenterTypeName: string;
  public RemoteAppEnabled?: boolean;
  public HideSetupWizardLinks?: boolean;
  constructor(userToken: UserToken) {
    super();
    if (userToken !== null) {
      this.aud = userToken.aud;
      this.authlevel = userToken.authlevel;
      this.baseApiUrl = userToken.baseApiUrl;
      this.exp = userToken.exp;
      this.iat = userToken.iat;
      this.iss = userToken.iss;
      this.jti = userToken.jti;
      this.loginid = userToken.loginid;
      this.organizationid = userToken.organizationid;
      this.role = userToken.role;
      this.sub = userToken.sub;
      this.userid = userToken.userid;
      this.UserID = parseInt(userToken.userid);
      this.UPN = userToken.UPN;
    }
  }

  public CopyFromJson(jsonData: string) {
    const parsedObject: UserLoginState = JSON.parse(jsonData);
    Object.assign(this, parsedObject);
  }

  IsInternal(): boolean {
    const orgId = Number(this.organizationid);
    return orgId <= 0;
  }
}
