<div
  style="display: inline"
  [pTooltip]="tooltip"
  [tooltipDisabled]="tooltipDisabled"
  [tooltipPosition]="tooltipsPosition"
  [tooltipStyleClass]="tooltipStyleClass"
  [appendTo]="tooltipAppendTo"
  [escape]="false"
>
  <button
    [type]="buttonType"
    [attr.data-testid]="id"
    [disabled]="disabled"
    [attr.mat-menu-item]="buttonStyle === 'mat-menu-item' ? '' : null"
    [ngStyle]="{ width: width }"
    [ngClass]="{
      disabled: processing || disabled,
      primary: buttonStyle === 'primary',
      secondary: buttonStyle === 'secondary',
      teritary: buttonStyle === 'teritary',
      completed: buttonStyle === 'completed',
      destroy: buttonStyle === 'destroy',
      link: buttonStyle === 'link',
      icon: buttonStyle === 'icon',
      'no-border-button-right': buttonStyle === 'no-border-button-right',
      'no-border-button-left': buttonStyle === 'no-border-button-left',
      'side-nav-button': buttonStyle === 'side-nav-button',
      'mat-mdc-menu-item': buttonStyle === 'mat-menu-item',
      'mat-mdc-focus-indicator': buttonStyle === 'mat-menu-item',
      'mdc-list-item': buttonStyle === 'mat-menu-item',
      'add-license-button-welcomeWizard':
        buttonStyle === 'add-license-welcomeWizard',
      primaryUserModule: buttonStyle === 'primaryUserModule',
      dontCreateUserButton: buttonStyle === 'dontCreateUserButton',
      cancelButton: buttonStyle === 'cancelButton',
      addUserTeritary: buttonStyle === 'addUserTeritary',
      packageCardPrimary: buttonStyle === 'packageCardPrimary',
      addUserBack: buttonStyle === 'addUserBack',
      teritaryEdit: buttonStyle === 'teritaryEdit',
      changePackage: buttonStyle === 'changePackage',
      signInPrimary: buttonStyle === 'signInPrimary',
      returnToInventory: buttonStyle === 'returnToInventory',
      changeSeatCount: buttonStyle === 'changeSeatCount',
      primaryAssign: buttonStyle === 'primaryAssign',
      deleteButton: buttonStyle === 'deleteButton'
    }"
    (click)="handleClick($event)"
    (blur)="onBlur($event)"
  >
    <div [ngClass]="{ 'inside-button-padding': !noContentPadding }">
      <div class="dspy-iblock" *ngIf="!!imgSource && !isImageBackward">
        <app-icon [source]="imgSource" [imgClass]="imgClass"></app-icon>
      </div>
      <div class="dspy-iblock" *ngIf="showIcon">
        <i [class]="iconClass"></i>
      </div>
      <div class="dspy-iblock width-20" *ngIf="showProcessing && processing">
        <app-icon
          data-testid="whiteLoadingSpinner"
          source="rn-circle-notch"
          imgClass="loading-spinner white-icon "
        ></app-icon>
      </div>
      <div class="dspy-iblock" *ngIf="!!label">
        <span [class]="imgSource ? iconClass : ''"
          ><span *ngIf="backwardArrow" class="arrow">&#8592;</span
          ><span *ngIf="addSymbol" class="plus">&nbsp;&#43;</span>&nbsp;&nbsp;{{
            label
          }}&nbsp;&nbsp;<span *ngIf="forwardArrowShow" class="arrow"
            >&#8594;</span
          ><span [class]="additionalContentClass">{{ additionalContent }}</span
          >&nbsp;</span
        >
      </div>
      <div class="dspy-iblock" *ngIf="!!imgSource && isImageBackward">
        <app-icon [source]="imgSource" [imgClass]="imgClass"></app-icon>
      </div>
      <div class="dspy-iblock" *ngIf="additionalIconSource">
        <app-icon [source]="additionalIconSource"></app-icon>
      </div>
    </div>
  </button>
</div>
