/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnOrganizationsVM } from "../models/./organizationsVM-d";
import { RnDataNodesVM } from "../models/./dataNodesVM-d";

export class RnConnectPointsVM {
  ID?: number;
  Name?: string | null;
  OrganizationFolderID?: number;
  DataNodeID?: number;
  FolderName?: string | null;
  FullPath?: string | null;
  ParentID?: number;
  TypeName?: string | null;
  OrganizationID?: number;
  OrganizationName?: string | null;
  CreatedDate?: string;
  CreatedBy?: string | null;
  EffStartDate?: string;
  EffEndDate?: string;
  DataNode?: RnDataNodesVM;
  Organization?: RnOrganizationsVM;
}
