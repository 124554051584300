import { GridColumnBaseConfiguration } from "./grid-column-base-configuration";

export class GridHtmlColumnConfiguration extends GridColumnBaseConfiguration {
  constructor(
    fieldName: string,
    title: string,
    classname: string,
    sortOrder: string,
    trueValue = "",
    falseValue = "",
    falseyButtonText = "",
  ) {
    super("html", fieldName, title, classname, sortOrder);
    this.trueValue = trueValue;
    this.falseValue = falseValue;
    this.falseyButtonText = falseyButtonText;
  }
  public LinkText: string;
  public GetCustomLinkText: (dataRow: any) => string;
  public GetHref: (dataRow: any) => string;
  public Target: string;
  public trueValue: string;
  public falseValue: string;
  public falseyButtonText: string;

  GetHtmlDataForColumn(dataRow: any) {
    return "<h3>HI!!</h3><b>MO!M!!</b>";
  }
}
