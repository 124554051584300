<div
  class="modal-header no-border dspy-block no-padding arial-font purchase-license-header"
>
  <div *ngIf="!processed">
    <div class="row header">
      <div class="col-6 no-padding">
        <span class="purchase-license-header-text">Buy Licenses</span>
      </div>
      <div class="col-5 shoppingcartdiv">
        <div #cartIcon class="pull-right">
          <app-shopping-cart-icon
            [cartClass]="'userCart'"
            (ToggleShoppingCart)="toggleShoppingCart()"
          ></app-shopping-cart-icon>
        </div>
        <div #cart>
          <app-shopping-cart
            [PaymentInfo]="data.paymentInfo"
            [IsInline]="false"
            [UseTypeColumn]="true"
            [UseWiderCart]="true"
            [LicenseForName]="true"
            [UseAdjustQuantityColumn]="true"
            [UseRemoveWithQuantityColumn]="true"
            [UsePurchaseButton]="true"
            [isPlaceOrderDisabled]="submitting"
            (CloseCart)="toggleShoppingCart()"
            (QuantityChanged)="shoppingCartQtyChange($event)"
            (RemoveItem)="shoppingCartRemoveItem($event)"
            (PlaceOrderClicked)="shoppingCartPlaceOrder()"
            *ngIf="isShoppingCartVisible"
          ></app-shopping-cart>
        </div>
        <div><i class="fa-solid fa-help"></i></div>
      </div>
    </div>
    <hr class="headerSeperator" />
    <div class="row purch-license-header-info">
      <div class="col-9 purch-license-info-container">
        <span data-testid="addLicenseMsg" class="purch-license-info-text"
          >You can add as many license types as you like. Get started by
          selecting a license type, fill out the details, and add it to your
          shopping cart.</span
        >
      </div>
    </div>
  </div>
</div>
<div class="modal-body no-padding-top arial-font modal-content height-auto">
  <div *ngIf="!processed" class="row main-content">
    <div class="col-12 no-padding">
      <app-license-type-select
        *ngIf="mode === 'typeSelect'"
        [licenseTypes]="licenseTypes"
        (typeSelected)="typeSelected($event)"
      ></app-license-type-select>
      <app-license-type-detail
        *ngIf="mode === 'enterDetails'"
        [licensePurchConfig]="data"
        [licenseInfoByType]="licenseInfo"
        [selectedLicenseType]="selectedLicenseType"
        [initiateAddItem]="initiateAddItem"
        [purchases]="purchasedItems"
        (formValidChange)="validSelectionChange($event)"
        (licensePurchaseAdded)="itemAddedToCart($event)"
        [purchasingChange]="purchaseChange"
      ></app-license-type-detail>
    </div>
  </div>
  <div *ngIf="processed">
    <app-purchase-summary
      *ngIf="processed"
      [purchases]="purchasedItems"
      [licenseConfig]="data"
    ></app-purchase-summary>
  </div>
</div>
<div class="modal-footer arial-font" style="padding-top: 65px">
  <div *ngIf="mode === 'typeSelect' || processed" class="pull-right">
    <app-button
      data-testid="closeBtn"
      id="cancelLink"
      label="CLOSE"
      buttonStyle="addUserTeritary"
      (clickActionEmitter)="cancel()"
    >
    </app-button>
  </div>
  <div *ngIf="mode === 'enterDetails' && !processed" class="btn-container">
    <span class="pull-left">
      <app-button
        id="btBack"
        (clickActionEmitter)="back()"
        label="BACK"
        [processing]="submitting"
        showProcessing="true"
        buttonStyle="addUserTeritary"
      >
      </app-button>
    </span>
    <span class="pull-right">
      <app-button
        id="btnAddToCart"
        [disabled]="submitting || !addInfoIsValid"
        (clickActionEmitter)="addToCart()"
        label="ADD TO CART"
        buttonStyle="addUserBack"
      >
      </app-button>
      <app-button
        id="btPlaceOrder"
        [disabled]="submitting || purchasedItems.length === 0"
        (clickActionEmitter)="placeOrder()"
        label="PLACE ORDER"
        [processing]="submitting"
        showProcessing="true"
        buttonStyle="primary"
      >
      </app-button>
    </span>
    <br />
  </div>
</div>
