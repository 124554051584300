/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnResponseMessages } from '../models/./responseMessages-d';
import { RnUserLoginStateVM } from '../models/./userLoginStateVM-d';


export class RnUserLoginStateVMRNResponseRelay {
  data?: RnUserLoginStateVM;
  Success: boolean;
  Messages: Array<RnResponseMessages>;
}

