import { HttpResponse } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { OrganizationCatalogInfoVM } from "src/app/shared/services/rnapi2-service/models/OrganizationCatalogInfoVM";
import { UserLoginState } from "../../../../core/models/user.login.state";
import { QuantityCounts } from "../../../../feature-modules/welcomewizard/quantity-counts";
import { CartItem } from "../../../services/cart/cart-item";
import { CartService } from "../../../services/cart/cart.service";
import { ConstantsService } from "../../../services/constants/constants.service";
import { RnDialogService } from "../../../services/dialog/rndialog.service";
import { LoggedInInfoService } from "../../../services/loggedInInfo/logged-in-info.service";
import { NotificationDialogService } from "../../../services/notificationDialog/notification-dialog.service";
import { OrganizationLogicService } from "../../../services/organization-logic/organization-logic.service";
import { OrganizationService } from "../../../services/rnapi2-service/apis/api";
import {
  RnCommonId,
  RnCommonSearch,
  RnOrganizationConfigurationVM,
  RnOrganizationsProfileVM,
  RnOrganizationsVM,
  RnOrganizationWLPContactInformationVM,
  RnUsersProfileVM,
  RnUsersVM,
} from "../../../services/rnapi2-service/models/models";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { BillingFormData } from "../billing-form/billing-form.data";
import { PackageCardData } from "../package-card/package-card-data";

@Component({
  selector: "app-change-package",
  templateUrl: "./change-package.component.html",
  styleUrls: ["./change-package.component.scss"],
})
export class ChangePackageComponent implements OnDestroy, OnChanges {
  @Input() packageSelectForm: FormGroup;
  @Input() IsAccountOwner = false;
  @Input() accountOwner: RnUsersVM;
  @Input() set CheckForInitialActiveMessage(
    checkForInitialActiveMessage: boolean,
  ) {
    this.checkForInitialActiveMessage = checkForInitialActiveMessage;
    if (this.checkForInitialActiveMessage && !this.initialActiveMessageShown) {
      this.checkShowInitialActiveMessage();
      this.initialActiveMessageShown = true;
    }
  }
  @Output() PackagesLoaded = new EventEmitter<PackageCardData[]>();
  @Output() AddonsLoaded = new EventEmitter<PackageCardData[]>();
  @Output() PackageSelected = new EventEmitter<PackageCardData>();
  @Output() OwnerPackageAction = new EventEmitter<QuantityCounts[]>();
  packages: any[];
  @Input() userData: RnUsersProfileVM;
  @Input() OrganizationID: number;
  @Input() public orgProfile: RnOrganizationsProfileVM;
  @Input() public orgConfig: RnOrganizationConfigurationVM;
  @Input() public orgWlpContactInfo: RnOrganizationWLPContactInformationVM;
  @Input() public AssignedPackage: string;
  @Input() AllActions: QuantityCounts[];
  @Input() hideTitles = false;
  @Output() PackageChanged = new EventEmitter<boolean>();

  packageData: PackageCardData[];
  addonData: PackageCardData[];
  selectedPackage: PackageCardData;
  loading = false;
  ownerPackageActions: QuantityCounts[];
  partnerName = "Drake"; //TODO: get proper name if needed
  infoText = ""; //TODO: get proper name if needed
  private passedOnChanges = false;
  user: RnUsersProfileVM;

  public purchasablePackages: PackageCardData[];
  public nonPurchasablePackages: PackageCardData[];

  constructor(
    private organizationService: OrganizationService,
    private dialogService: NotificationDialogService,
    private loggedinInfoService: LoggedInInfoService,
    private orgLogicService: OrganizationLogicService,
    private cart: CartService,
    private rnDialogService: RnDialogService,
    private toastService: RnToastService,
    private constantsService: ConstantsService,
    private ngZone: NgZone,
    private cd: ChangeDetectorRef,
  ) {
    this.subscriptions.push(
      this.loggedinInfoService.LoggedInUserOrg.subscribe((o) => {
        this.loggedInUserOrg = o;
        if (this.orgProfile) {
          this.setupPurchaseFlags();
        }
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    let orgChanged = false;
    for (const propName in changes) {
      if (propName === "orgProfile") {
        this.orgProfile = changes[propName]
          .currentValue as RnOrganizationsProfileVM;
        orgChanged = true;
      }
    }

    if (
      !this.passedOnChanges &&
      this.packageSelectForm &&
      this.OrganizationID &&
      this.orgProfile &&
      this.orgConfig &&
      this.orgWlpContactInfo
    ) {
      this.loggedInUser = this.loggedinInfoService.GetLoggedInUser();
      if (!this.loggedInUser) {
        return;
      }

      this.loading = true;
      this.PRCHDROPPK = this.loggedinInfoService.loggedInUserHasRights([
        "PRCHDROPPK",
      ]);
      this.UPDTBILING = this.loggedinInfoService.loggedInUserHasRights([
        "UPDTBILING",
      ]);
      this.PMTMTDCREATE = this.loggedinInfoService.loggedInUserHasRights([
        "PMTMTDCREATE",
      ]);

      this.setupPurchaseFlags();
      if (this.orgProfile.CustomerType !== "Rightworks") {
        this.partnerName = this.orgProfile.PartnerName;
        this.getInfoText();
      }
      this.setupPurchaseFlags();

      this.f.selectedPackage.setValue({ PackagePoolID: -2, PackageID: -1 });

      const payload = new RnCommonId();
      payload.Id = this.OrganizationID.toString();
      payload.AffectedOrganizationId = this.OrganizationID;
      this.subscriptions.push(
        this.loadOrganizationPackageData().subscribe((r) => {
          if (r.body.Success) {
            this.packageData = [];
            this.addonData = [];
            r.body.data.CatalogItems.forEach((p) => {
              if (
                ((p.CatalogVisibility === "Internal" ||
                  p.CatalogVisibility === "InternalExclusive") &&
                  p.CurrentQuantity !== 0) ||
                (p.CatalogVisibility !== "Internal" &&
                  p.CatalogVisibility !== "InternalExclusive")
              ) {
                const pkg = new PackageCardData();
                pkg.AssignFromRnCatalogItemVM(p);
                if (this.AllActions) {
                  const foundPackage = this.AllActions.find(
                    (p) => p.PackageID == pkg.PackageID,
                  );
                  if (foundPackage) {
                    pkg.CurrentUsed += foundPackage.Quantity;
                    if (pkg.CurrentUsed > pkg.CurrentQuantity) {
                      pkg.CurrentQuantity = pkg.CurrentUsed;
                    }
                  }
                }
                if (p.PackageUIType === "package") {
                  this.packageData.push(pkg);
                }
                if (p.PackageUIType === "addon" && p.PackageTypeID !== 6) {
                  if (pkg.CurrentUsed < 0) {
                    pkg.CurrentUsed = 0;
                  }
                  this.addonData.push(pkg);
                }
              }
            });
            this.PackagesLoaded.emit(this.packageData);
            this.AddonsLoaded.emit(this.addonData);

            this.purchasablePackages = this.packageData
              .filter((p) => {
                return (
                  p.PackageType === "Entitlement" &&
                  p.HasTerminalServices &&
                  p.CatalogVisibility !== "Unpurchasable"
                );
              })
              .sort((a, b) => {
                return a.ListPrice > b.ListPrice ? 1 : -1;
              });

            this.nonPurchasablePackages = this.packageData
              .filter((p) => {
                return (
                  p.PackageType === "Entitlement" &&
                  p.HasTerminalServices &&
                  p.CatalogVisibility === "Unpurchasable" &&
                  p.CurrentQuantity > 0
                );
              })
              .sort((a, b) => {
                return a.PackageID > b.PackageID ? 1 : -1;
              });

            const noPackageItem = new PackageCardData();
            noPackageItem.PackageID = -1;
            noPackageItem.PackagePoolID = -2;
            noPackageItem.PackageName = "No Package";
            noPackageItem.ExternallyPurchasable = true;
            noPackageItem.PackageDescHtml =
              "This user will be able to access AppHub, but will not have a hosted desktop or the ability to install and use applications.";

            if (
              this.orgConfig &&
              this.orgConfig.DistributedOrgDefaultPackageID &&
              this.orgConfig.DistributedOrgDefaultPackageID > 0
            ) {
              this.packageData.forEach((p) => {
                if (
                  p.PackageID ===
                    this.orgConfig.DistributedOrgDefaultPackageID &&
                  !this.AssignedPackage &&
                  p.CurrentQuantity > p.CurrentUsed
                ) {
                  this.packageSelected(p);
                }
              });
            }

            if (this.AssignedPackage) {
              const selectedpackageList = this.packageData.filter(
                (x) => x.PackageName === this.AssignedPackage,
              );
              if (selectedpackageList.length > 0) {
                const pkg = selectedpackageList[0];
                pkg.Selected = true;
                this.selectedPackage = pkg;
                if (this.packageSelectForm) {
                  this.f.selectedPackage.setValue(pkg);
                }
                this.PackageSelected.emit(this.selectedPackage);
              }
            }

            if (!this.selectedPackage) {
              this.packageSelected(noPackageItem);
            }

            this.purchasablePackages.push(noPackageItem);
            this.loading = false;
          }
        }),
      );
      this.passedOnChanges = true;
    } else if (
      orgChanged &&
      this.packageSelectForm &&
      this.OrganizationID &&
      this.orgProfile &&
      this.orgConfig &&
      this.orgWlpContactInfo
    ) {
      this.setupPurchaseFlags();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  showWLPPackages(): boolean {
    return !this.loading && this.nonPurchasablePackages?.length > 0;
  }

  packageSelected(pkg: PackageCardData): void {
    if (
      (pkg.CatalogVisibility === "Unpurchasable" &&
        pkg.CurrentQuantity === pkg.CurrentUsed) ||
      pkg === this.selectedPackage
    ) {
      return;
    }

    if (this.getPkgIsDisabled(pkg)) {
      return;
    }

    if (
      pkg.PackageID !== -1 &&
      pkg.CatalogVisibility !== "Unpurchasable" &&
      pkg.CurrentQuantity === pkg.CurrentUsed &&
      !this.loggedInUserOrg.HasDirectPaymentOnFile
    ) {
      const data = new BillingFormData();
      data.organization = this.loggedInUserOrg;
      const billingFormDialog = this.rnDialogService.BillingFormDialog(data);
      billingFormDialog.beforeClosed().subscribe((s) => {
        if (s) {
          this.completeSelectPackage(pkg);
        } else {
          this.toastService.showError(
            this.constantsService.cancelCreateBillingInfoPackagesToast,
          );
        }
      });
      return;
    }

    this.completeSelectPackage(pkg);
  }

  completeSelectPackage(pkg: PackageCardData) {
    if (this.IsAccountOwner) {
      this.ownerPackageActions = [];
      if (this.AssignedPackage) {
        if (this.AssignedPackage != pkg.PackageName) {
          const initialPackage = this.packageData.find(
            (p) => p.PackageName === this.AssignedPackage,
          );
          this.ownerPackageActions.push({
            PackageID: initialPackage.PackageID,
            Quantity: -1,
          });
        }
      }
      this.ownerPackageActions.push({
        PackageID: pkg.PackageID,
        Quantity: 1,
      });
      this.OwnerPackageAction.emit(this.ownerPackageActions);
    }

    if (this.selectedPackage) {
      if (this.selectedPackage.BuyNew) {
        this.selectedPackage.CurrentQuantity--;
        this.selectedPackage.BuyNew = false;
        this.cart.removeFromCart(this.selectedPackage);
      }
      this.selectedPackage.CurrentUsed--;
      this.selectedPackage.Selected = false;
    }

    if (pkg.PackagePoolID !== -2) {
      if (pkg.CurrentUsed == pkg.CurrentQuantity) {
        pkg.CurrentQuantity++;
        pkg.BuyNew = true;
        const cartItem = new CartItem();
        cartItem.Name = pkg.PackageName;
        cartItem.Quantity = 1;
        cartItem.DiscountPercent = pkg.DiscountPercent;
        cartItem.Price = pkg.ListPrice;
        cartItem.NextBillPrice = pkg.NextBillPrice;
        cartItem.PackageID = pkg.PackageID;
        cartItem.ItemType = "Package";
        this.cart.addToCart(cartItem);
      } else {
        pkg.BuyNew = false;
      }
      pkg.CurrentUsed++;
    }
    pkg.Selected = true;
    this.selectedPackage = pkg;
    if (this.packageSelectForm) {
      this.f.selectedPackage.setValue(pkg);
    }
    this.PackageSelected.emit(this.selectedPackage);
    this.PackageChanged.emit(true);
  }

  getPkgIsDisabled(pkg: PackageCardData): boolean {
    let result = false;
    if (pkg === this.selectedPackage || pkg.PackageID < 0) {
      return false;
    }
    if (this.userData) {
      this.userData.AssignedAddOns.forEach((a) => {
        if (
          pkg.ExcludingPackageNames.filter((p) => {
            return p === a;
          }).length > 0
        ) {
          this.hasTaxOrAccountingAddOn = pkg.ExcludingPackageNames.filter(
            (p) => {
              return p === a;
            },
          );
          result = true;
        }
      });
    }
    if (!pkg.ExternallyPurchasable) {
      result = true;
    }

    if (
      pkg.CatalogVisibility === "Unpurchasable" &&
      pkg.CurrentQuantity === pkg.CurrentUsed
    ) {
      result = true;
    }

    if (
      pkg.CatalogVisibility !== "Unpurchasable" &&
      pkg.CurrentQuantity === pkg.CurrentUsed &&
      !this.canPurchase
    ) {
      this.canPurchaseNeedsPOF = false;
      result = true;
    }

    return result;
  }

  getPkgTooltip(pkg: PackageCardData): string {
    if (
      pkg.PackageID > 0 &&
      pkg.CatalogVisibility !== "Unpurchasable" &&
      pkg.CurrentQuantity === pkg.CurrentUsed &&
      !this.canPurchase &&
      !this.canPurchaseNeedsPOF &&
      pkg.PackageID !== this.selectedPackage.PackageID
    ) {
      return "There are no remaining packages to assign. <br/> Contact the Account Owner to have them add more to their account.";
    }
    if (
      this.getPkgIsDisabled(pkg) &&
      this.hasTaxOrAccountingAddOn &&
      this.hasTaxOrAccountingAddOn.length > 0
    ) {
      return (
        "This package is not available when the " +
        this.hasTaxOrAccountingAddOn[0] +
        " is assigned"
      );
    }
    return "";
  }

  get f() {
    return this.packageSelectForm.controls;
  }

  private subscriptions = [];
  private checkForInitialActiveMessage = false;
  private initialActiveMessageShown = false;
  private PRCHDROPPK: boolean;
  private PMTMTDCREATE: boolean;
  private UPDTBILING: boolean;
  private loggedInUser: UserLoginState;
  private loggedInUserOrg: RnOrganizationsVM;
  public canPurchase: boolean;
  public canPurchaseNeedsPOF: boolean;
  private hasTaxOrAccountingAddOn: string[];

  private loadOrganizationPackageData(): Observable<
    HttpResponse<OrganizationCatalogInfoVM>
  > {
    if (this?.OrganizationID) {
      const payload = new RnCommonId();
      payload.Id = this.OrganizationID.toString();
      payload.AffectedOrganizationId = this.OrganizationID;
      return this.organizationService.apiV2OrganizationsGetcatalogitemsfororgPost(
        payload,
        "response",
      );
    }
  }

  private checkShowInitialActiveMessage() {
    if (
      this.orgConfig &&
      this.orgConfig.DistributedOrgDefaultPackageID &&
      this.orgConfig.DistributedOrgDefaultPackageID > 0 &&
      this.selectedPackage.PackageID !==
        this.orgConfig.DistributedOrgDefaultPackageID
    ) {
      if (
        this.orgWlpContactInfo.MaxUsagePurchaseMsg &&
        this.orgWlpContactInfo.MaxUsagePurchaseMsg !== ""
      ) {
        this.dialogService.ShowNotification(
          "Maximum Usage Reached",
          this.orgWlpContactInfo.MaxUsagePurchaseMsg,
        );
      }
    }
  }

  private getInfoText(): void {
    if (this.partnerName === "Intuit_PCG" || this.partnerName === "Intuit") {
      if (this.loggedinInfoService.GetWLPConfig) {
        const wlpConfigData = this.loggedinInfoService?.GetWLPConfig()?.data;
        if (wlpConfigData) {
          this.infoText =
            Object.values(wlpConfigData)[
              Object.keys(wlpConfigData).indexOf("packages_addon_tab_message")
            ];
        }
      }
    } else {
      const wlpConfigData = this.loggedinInfoService?.GetWLPConfig()?.data;
      if (wlpConfigData) {
        this.infoText =
          "To purchase additional seats, please contact " +
          Object.values(wlpConfigData)[
            Object.keys(wlpConfigData).indexOf("sales_phone_number")
          ];
      }
    }
  }

  private setupPurchaseFlags(): void {
    if (this.orgProfile && this.orgConfig) {
      this.ngZone.run(() => {
        this.canPurchase = this.orgLogicService.CanPurchaseDropPackages(
          this.PRCHDROPPK,
          this.loggedInUser.IsInternal(),
          this.orgProfile,
          this.loggedInUserOrg,
        );
        this.canPurchaseNeedsPOF =
          this.orgLogicService.CanPurchasePackagesNeedPOF(
            this.PRCHDROPPK,
            this.UPDTBILING,
            this.loggedInUser.IsInternal(),
            this.orgProfile,
            this.orgConfig,
            this.loggedInUserOrg,
            this.loggedInUser.UserType,
          );
        this.cd.detectChanges();
      });
    }
  }
}
