import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subscription } from "rxjs";
import { CartService } from "../../../services/cart/cart.service";
import { ShoppingCart } from "../../../services/cart/shopping-cart";

@Component({
  selector: "app-shopping-cart-icon",
  templateUrl: "./shopping-cart-icon.component.html",
  styleUrls: ["./shopping-cart-icon.component.scss"],
})
export class ShoppingCartIconComponent implements OnInit, OnDestroy {
  @Output() ToggleShoppingCart: EventEmitter<void> = new EventEmitter<void>();
  @Input() cartClass: string;
  shoppingCartData: ShoppingCart = new ShoppingCart();

  constructor(private cartService: CartService) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.cartService.getCart().subscribe((r) => {
        this.shoppingCartData = r;
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  getTotalShoppingCartItems(): number {
    return this.shoppingCartData.items.reduce((a, b) => a + b.Quantity, 0);
  }

  toggleShoppingCartHandler() {
    this.ToggleShoppingCart.emit();
  }

  private subscriptions: Subscription[] = [];
}
