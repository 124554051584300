import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import {
  RnCommonId,
  RnCommonSearch,
  RnOrganizationConfigurationVMRNResponseRelay,
  RnOrganizationsProfileVM,
} from "../../../../shared/services/rnapi2-service/models/models";
import { SalesforceChatConfigInfo } from "./salesforce-chat-config-info";
import * as SalesforceChatWrapper from "./salesforce-chat-wrapper";
import { OrganizationService } from "../../../../shared/services/rnapi2-service/apis/api";
import { LoggedInInfoService } from "src/app/shared/services/loggedInInfo/logged-in-info.service";
import { UserLoginState } from "src/app/core/models/user.login.state";
import { SelectionService } from "src/app/shared/services/selection/selection.service";
import { HttpResponse } from "@angular/common/http";

declare global {
  interface Window {
    embedded_svc: any;
  }
}

@Component({
  selector: "app-salesforce-chat",
  templateUrl: "./salesforce-chat.component.html",
  styleUrls: ["./salesforce-chat.component.scss"],
})
export class SalesforceChatComponent implements OnInit, OnDestroy {
  loggedInUser: UserLoginState;
  selectedOrg: RnOrganizationsProfileVM;
  selectedOrgConfig: HttpResponse<RnOrganizationConfigurationVMRNResponseRelay>;
  orgProfile: RnOrganizationsProfileVM;
  showRNSupportItems: boolean;
  confPayload = new RnCommonId();
  @Input() WelcomeWizardMode: boolean;
  settings = new SalesforceChatConfigInfo();
  payload = new RnCommonSearch();
  sfChatLoaded = false;
  constructor(
    private orgService: OrganizationService,
    private loggedInInfoService: LoggedInInfoService,
    private selectionService: SelectionService,
  ) {}
  ngOnInit(): void {
    if (this.selectionService.SelectedOrgProfile) {
      const OrgProfile = this.selectionService.SelectedOrgProfile.subscribe(
        (o) => {
          this.orgProfile = o;
        },
      );
      this.subscriptions.push(OrgProfile);
    }

    this.payload.search =
      "apphub_salesforce_chat_enabled, apphub_salesforce_chat_button_id, apphub_salesforce_chat_deployment_id, apphub_salesforce_chat_base_live_agent_url, " +
      "apphub_salesforce_chat_base_url, apphub_salesforce_chat_support_url, apphub_salesforce_chat_esw_live_agent_dev_name, apphub_salesforce_chat_js_source, apphub_salesforce_chat_init_id, " +
      "apphub_salesforce_chat_base_live_agent_content_url,apphub_salesforce_chat_deployment_name";

    const subSettings = this.orgService
      .apiV2OrganizationsGetmultipleconfigurablevaluesPost(this.payload)
      .subscribe((response) => {
        if (this.loggedInInfoService.LoggedInUser) {
          const subLoggedinUser =
            this.loggedInInfoService.LoggedInUser.subscribe((u) => {
              this.loggedInUser = u;
            });
          this.subscriptions.push(subLoggedinUser);
        }
        if (this.loggedInUser) {
          this.confPayload.Id = this.loggedInUser.OrganizationID.toString();
          const OrgConfig = this.orgService
            .apiV2OrganizationsGetorganizationconfigurationPost(
              this.confPayload,
              "response",
            )
            .subscribe((c) => {
              this.selectedOrgConfig = c;
            });
          this.subscriptions.push(OrgConfig);
        }
        if (this.selectionService.SelectedOrgProfile) {
          const selectedOrgProfileLoad =
            this.selectionService.SelectedOrgProfile.subscribe((p) => {
              this.selectedOrg = p;
              if (this.loggedInUser) {
                if (this.selectedOrg) {
                  this.settings.showHelpBtn = false;
                }
              } else {
                this.settings.showHelpBtn = false;
                const wrp = new SalesforceChatWrapper();
                wrp.loadChat(this.settings);
              }
            });
          this.subscriptions.push(selectedOrgProfileLoad);
        }
      });
    this.subscriptions.push(subSettings);
  }

  showHelpButton(): boolean {
    const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    if (this.selectedOrg) {
      const hasRights =
        this.selectedOrg.CustomerType === "Rightworks" ||
        this.selectedOrgConfig?.body?.data?.AllowSalesforceChat;
      if (
        !loggedInUser ||
        !hasRights ||
        this.WelcomeWizardMode ||
        !this.settings?.SalesforceChatEnabled
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private subscriptions = [];
}
