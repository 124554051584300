import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import {
  OrderService,
  OrganizationService,
  RnCommonSearch,
  RnOrganizationConfigurationVM,
  RnOrganizationsProfileVM,
  RnRNAddOnPayload,
  RnRNLicenseItem,
  RnRNOrderHeader,
  RnRNOrderItem,
} from "src/app/shared/services/rnapi2-service";
import { ManageQboConfig } from "./manage-qbo-config";
import { DialogTrackingService } from "src/app/shared/services/dialog/dialog-tracking.service";
import { Subscription } from "rxjs";
import { SelectionService } from "src/app/shared/services/selection/selection.service";
import { LoggedInInfoService } from "src/app/shared/services/loggedInInfo/logged-in-info.service";
import { GenericDialogConfiguration } from "../../generic-dialog/generic-dialog-configuration";
import { IdService } from "src/app/shared/services/id/id.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationDialogService } from "src/app/shared/services/notificationDialog/notification-dialog.service";

@Component({
  selector: "app-qbo-subscription-management",
  templateUrl: "./qbo-subscription-management.component.html",
  styleUrls: ["./qbo-subscription-management.component.scss"],
})
export class QboSubscriptionManagementComponent implements OnInit {
  orgProfile: RnOrganizationsProfileVM;
  orgConfig: RnOrganizationConfigurationVM;
  disableQtyChange: boolean;
  // qbo table configuration
  qboPageSize = 10;
  qboRowsPerPageOptions: Array<number> = [10];
  qboCurrentPageNum = 0;
  //private rnCommonSearch: RnCommonSearch = new RnCommonSearch();
  private manageQboConfig: ManageQboConfig;
  isProcessing = false;
  qboSubsData: any[];
  qboSubsCount = 0;
  showQboSubscriptions = false;
  private subscriptions: Subscription[] = [];
  constructor(
    private orgService: OrganizationService,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private dialogTrackService: DialogTrackingService,
    private loggedInInfoService: LoggedInInfoService,
    private router: Router,
    private notificationService: NotificationDialogService,
    private activatedRoute: ActivatedRoute,
    private orderService: OrderService,
    private selectionService: SelectionService,
  ) {
    this.subscriptions.push(
      this.selectionService.SelectedOrgProfile.subscribe((p) => {
        if (p?.ID !== undefined) {
          this.orgProfile = p;
          this.disableQtyChange =
            this.orgProfile && this.orgProfile.Status !== "Active";
        }
      }),
    );
    this.subscriptions.push(
      this.selectionService.SelectedOrgConfig.subscribe((c) => {
        this.orgConfig = c;
      }),
    );
  }

  ngOnInit(): void {
    this.loadQboSubscriptionData();
  }

  qboPageSizeChanged() {
    // NYI feature
  }

  qboNavToPage(pageNumber: number) {
    // NYI feature
  }

  loadQboSubscriptionData() {
    // QBO subscription configuration
    this.manageQboConfig = this.config.data as ManageQboConfig;
    const payload = new RnCommonSearch();
    payload.AffectedOrganizationId = this.manageQboConfig.OrganizationID;
    payload.Id = this.manageQboConfig.OrganizationID.toString();
    payload.pageNumber = this.manageQboConfig.pageNumber;
    this.orgService
      .apiV2OrganizationsGetqboinfofororgPost(payload, "body")
      .subscribe((r: any) => {
        this.qboSubsData = r.data;
        this.showQboSubscriptions = this.qboSubsData.length > 0;
        this.qboSubsCount = this.qboSubsData.length;
        //this.doRerender()
      });
  }

  // private canPurchaseNeedsPOF(): boolean {
  //   if (!this.orgProfile) {
  //     return false;
  //   }
  //   const canPurchaseRight = this.loggedInInfoService.loggedInUserHasRights(['PRCHDROPPK']);
  //   const canUpdateBilling = this.loggedInInfoService.loggedInUserHasRights(['UPDTBILING']);
  //   const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
  //   if (!loggedInUser) {
  //     return false;
  //   }

  //   const isInternal = loggedInUser.IsInternal();
  //   const loggedInUserOrg = this.loggedInInfoService.GetLoggedInUserOrg();
  //   return this.orgLogicService.CanPurchasePackagesNeedPOF(canPurchaseRight, canUpdateBilling, isInternal, this.orgProfile, this.orgConfig, loggedInUserOrg, loggedInUser.UserType);
  // }

  change(selectedItem: any) {
    this.isProcessing = true;
    const confirmConfig = this.getConfirmSaveChangesDialogConfig(selectedItem);
    this.notificationService.ShowConfirmation(confirmConfig);
  }

  getConfirmSaveChangesDialogConfig(
    selectedItem: any,
  ): GenericDialogConfiguration {
    const cc = new GenericDialogConfiguration();
    if (selectedItem.Name === "QuickBooks Online") {
      cc.Title = "Cancel QuickBooks Online Subscription?";
    }
    if (selectedItem.Name === "QuickBooks Payroll") {
      cc.Title = "Cancel QuickBooks Payroll Subscription?";
    }
    if (selectedItem.Name === "QuickBooks Time") {
      cc.Title = "Cancel QuickBooks Time Subscription?";
    }
    cc.StyleClass = "confirmation";
    cc.ConfirmButtonText = "CANCEL SUBSCRIPTION";
    cc.CancelButtonText = "BACK";
    cc.MessageContainsHTML = true;
    cc.DialogFooterClass = "rightAlignButtons";
    cc.CancelButtonStyleClass = "link";
    cc.ConfirmButtonStyleClass = "dontCreateUserButton";
    cc.DialogHeaderClass = "modal-header no-border";
    cc.DialogFooterCancelClass = "right-spacing";
    if (selectedItem.Name === "QuickBooks Online") {
      cc.Message =
        "This will also cancel your QuickBooks Time and Payroll subscriptions. Are you sure you want to continue?";
    } else {
      cc.Message = "Are you sure you want to continue?";
    }
    cc.Confirmed = () => {
      this.confirmSaveChanges(selectedItem);
      if (selectedItem.Type === "Simple Start") {
        for (let i = 0; i < this.qboSubsData[0].Items.length; i++) {
          this.confirmSaveChanges(this.qboSubsData[0].Items[i]);
        }
      }
    };
    this.isProcessing = false;
    return cc;
  }

  getOrderItems(selectedItem: any) {
    const orderItems = [];

    const o: RnRNOrderItem = {
      PackageID: selectedItem.PackageID,
      Quantity: -1,
      PromoCode: "-1",
    };
    orderItems.push(o);

    return orderItems;
  }

  goBackAndReload(): void {
    setTimeout(async () => {
      if (!this.loggedInInfoService.GetLoggedInUser()) {
        return;
      }
      const queryParams = {
        packageState: "inventory",
      };
      await this.router
        .navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
          queryParamsHandling: "merge", // remove to replace all query params by provided
        })
        .then(() => {
          window.location.reload();
        });
    }, 3000);
  }

  confirmSaveChanges(selectedItem: any) {
    this.isProcessing = true;

    const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    if (!loggedInUser) {
      return;
    }
    //to used for add
    const payload: RnRNAddOnPayload = new RnRNAddOnPayload();
    payload.PaymentOnFileID =
      this.loggedInInfoService.GetLoggedInUserOrg().PaymentOnFileID;
    payload.OrganizationID = this.orgProfile.ID;
    payload.OrderItems = this.getOrderItems(selectedItem);
    payload.QbesLicenseItems = new Array<RnRNLicenseItem>();
    payload.PromotionCodeID = null;
    payload.ForceSkipEmail = false;
    const header: RnRNOrderHeader = {
      Order_XID: IdService.makeID(),
      Order_Source: "AppHub",
      Order_Date: new Date().toISOString().slice(0, 10).replace(/-/g, "/"),
      SalesAgent_TAG: loggedInUser.UserID.toString(),
    };
    payload.Header = header;

    const errorMessage =
      "The order was not processed correctly. Try again in a few moments. If this continues to happen, please contact Customer Support.";
    this.orderService
      .apiV2OrdersCreateaddonPost(payload, "response")
      .subscribe((response) => {
        if (response.body && response.body.Success) {
          //this.rnToastService.showSuccess('You have successfully made changes to your Packages and Add-ons Inventory.');
          this.goBackAndReload();
        }
      });
    // let curQTY = this.getOrderItems(selectedItem);
    // if (curQTY[0].Quantity === 0) {
    //   let payload: RnRNAddOnPayload = new RnRNAddOnPayload();
    //   payload.PaymentOnFileID = this.loggedInInfoService.GetLoggedInUserOrg().PaymentOnFileID;
    //   payload.OrganizationID = this.orgProfile.ID;
    //   payload.OrderItems = this.getOrderItems(selectedItem);
    //   payload.QbesLicenseItems = new Array<RnRNLicenseItem>();
    //   payload.PromotionCodeID = null;
    //   payload.ForceSkipEmail = false;
    //   let header: RnRNOrderHeader = {
    //     Order_XID: '',
    //     Order_Source: 'AppHub',
    //     Order_Date: new Date().toISOString().slice(0, 10).replace(/-/g, "/"),
    //     SalesAgent_TAG: ''
    //   };
    //   payload.Header = header;
    //   this.orderService.apiV2OrdersPreflightPost(payload).subscribe((response) => {
    //     if (response.Success) {
    //       //this.rnToastService.showSuccess('You have successfully made changes to your Packages and Add-ons Inventory.');
    //       this.goBackAndReload();
    //     }
    //   });
    // ///}
  }

  cancel() {
    this.dialogTrackService.closeDialog(this.ref, false);
  }
}
