import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable({
  providedIn: "root",
})
export class RnToastService {
  constructor(public messageService: MessageService) {}

  showSuccess(messageDetail) {
    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: messageDetail,
      life: 6000,
    });
  }

  showInfo(messageDetail) {
    this.messageService.add({
      severity: "info",
      summary: "Info",
      detail: messageDetail,
      life: 6000,
    });
  }

  showWarn(messageDetail) {
    this.messageService.add({
      severity: "warn",
      summary: "Warn",
      detail: messageDetail,
      life: 6000,
    });
  }

  showError(messageDetail) {
    this.clear();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: messageDetail,
      life: 6000,
    });
  }

  showErrorSticky(messageDetail: string) {
    this.clear();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: messageDetail,
      sticky: true,
    });
  }

  showTopLeft(messageDetail) {
    this.messageService.add({
      key: "tl",
      severity: "info",
      summary: "Info",
      detail: messageDetail,
      life: 6000,
    });
  }

  showTopCenter(messageDetail) {
    this.messageService.add({
      key: "tc",
      severity: "info",
      summary: "Info",
      detail: messageDetail,
      life: 6000,
    });
  }

  showBottomCenter(messageDetail) {
    this.messageService.add({
      key: "bc",
      severity: "info",
      summary: "Info",
      detail: messageDetail,
      life: 6000,
    });
  }

  showConfirm(messageDetail) {
    this.messageService.clear();
    this.messageService.add({
      key: "c",
      sticky: true,
      severity: "warn",
      summary: "Are you sure?",
      detail: messageDetail,
    });
  }

  showMultiple(messageDetail) {
    this.messageService.addAll([
      { severity: "info", summary: "Message 1", detail: messageDetail },
      { severity: "info", summary: "Message 2", detail: messageDetail },
      { severity: "info", summary: "Message 3", detail: messageDetail },
    ]);
  }

  showSticky(messageDetail, severity?) {
    this.messageService.add({
      severity: severity ? severity : "info",
      summary: "Sticky",
      detail: messageDetail,
      sticky: true,
    });
  }

  onConfirm() {
    this.messageService.clear("c");
  }

  onReject() {
    this.messageService.clear("c");
  }

  clear() {
    this.messageService.clear();
  }
}
