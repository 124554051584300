import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { CartService } from "../../../services/cart/cart.service";
import { ShoppingCart } from "../../../services/cart/shopping-cart";
import {
  LookupService,
  OrganizationService,
} from "../../../services/rnapi2-service/apis/api";
import {
  RnOrganizationPaymentOnFileVM,
  RnOrganizationsProfileVM,
} from "../../../services/rnapi2-service/models/models";
import { SelectionService } from "../../../services/selection/selection.service";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { Subscription } from "rxjs";
import { OrganizationLogicService } from "../../../services/organization-logic/organization-logic.service";

@Component({
  selector: "app-review-user",
  templateUrl: "./review-user.component.html",
  styleUrls: ["./review-user.component.scss"],
})
export class ReviewUserComponent implements OnInit {
  public shoppingCart: ShoppingCart;
  public selectedOrg: RnOrganizationsProfileVM = new RnOrganizationsProfileVM();
  public inputPromoCode = "";
  public promoCodeIsValid: boolean;
  //  public canShowPromoCodeEntry: boolean;
  public wait: boolean;
  public error: boolean;
  public nextBillTotal = 0;
  public nextBillAmount = 0;
  public recalculatingTotal: boolean;
  //public showFutureMonthlyBill: boolean = false;
  public futureMonthlyBill: number;

  public hasDiscounts: boolean;
  public canShowPromoCodeEntry = false;

  private subscriptions: Subscription[] = [];
  private beforePromoApplyShoppingCart = {};
  private initialAmountDue;
  private validatedPromo: any = {};
  private truncateSize = 60;

  @Input() reviewForm: FormGroup;
  @Input() paymentInfo: RnOrganizationPaymentOnFileVM;
  @Output() promoEvent = new EventEmitter<any>();

  constructor(
    private cartService: CartService,
    private selectionService: SelectionService,
    private toastService: RnToastService,
    private lookupService: LookupService,
    private organizationService: OrganizationService,
    private orgLogicService: OrganizationLogicService,
  ) {}

  ngOnInit(): void {
    this.initialAmountDue = this.paymentInfo?.NextBillAmount;
    //this.showFutureMonthlyBill = this.cartService.getItems().length > 0 || parseFloat(this.paymentInfo?.NextBillAmount) >= 0;

    this.subscriptions.push(
      this.cartService.getCart().subscribe((response) => {
        this.shoppingCart = response;
        this.futureMonthlyBill =
          parseFloat(this.paymentInfo?.NextBillAmount) >= 0
            ? parseFloat(this.paymentInfo?.NextBillAmount) +
              this.shoppingCart.CartTotal
            : this.nextBillAmount + this.nextBillTotal;
      }),
    );

    this.subscriptions.push(
      this.selectionService.SelectedOrgProfile.subscribe((response) => {
        this.selectedOrg = response;
        //  this.canShowPromoCodeEntry = this.selectedOrg.CustomerType == 'Rightworks'
        //      && this.selectedOrg.ParentID > 0
        //      && this.selectedOrg.CreatedTodayInZuoraTimeZone == true
        //      && this.selectedOrg.AccountOwnerID > 0
        //      && !this.selectedOrg.HasAPromotion;
      }),
    );

    if (
      this.cartService.hasDiscounts() &&
      this.validatedPromo &&
      this.validatedPromo.promoCodeIsValid
    ) {
      this.nextBillTotal = this.cartService.updateTotal(this.validatedPromo);
    }
    this.canShowPromoCodeEntry = this.orgLogicService.CanShowPromoCodeEntry();
    this.hasDiscounts = this.cartService.hasDiscounts();
  }

  getTooltip(): string {
    return this.truncateSize && this.email?.value.length >= this.truncateSize
      ? this.email?.value
      : "";
  }

  getTruncated(): string {
    const slicedEmail: string = this.email?.value.slice(
      0,
      this.truncateSize - 1,
    );
    return this.truncateSize && this.email?.value.length > this.truncateSize
      ? slicedEmail + "..."
      : this.email?.value;
  }

  public validatePromoCode(code) {
    if (this.wait == true || this.promoCodeIsValid == true) {
      return;
    }

    this.wait = true;
    this.promoCodeIsValid = false;
    this.error = false;
    this.validatedPromo = {};
    this.inputPromoCode = this.inputPromoCode.toUpperCase();

    //get package ids to validate
    const packageIDs = this.cartService.getAllIds();

    this.subscriptions.push(
      this.lookupService
        .apiV2LookupValidatepromocodeGet(code, packageIDs)
        .subscribe(
          (response) => {
            if (response && response.data && response.Success) {
              this.validatedPromo = response.data;
              this.promoEvent.emit(this.validatedPromo);
              this.wait = false;

              //good code
              if (this.validatedPromo && this.validatedPromo.promoCodeIsValid) {
                this.promoCodeIsValid = true;

                this.beforePromoApplyShoppingCart = this.cartService.cart;

                this.nextBillTotal = this.cartService.updateTotal(
                  this.validatedPromo,
                );
                this.getNextBalanceForShoppingCart();
              }
              //code not allowed
              else {
                this.error = true;
                this.wait = false;
                this.promoCodeIsValid = false;
              }
            }
            //not successful - something like timeout or 500
            else {
              this.wait = false;
              this.promoCodeIsValid = false;
              this.validatedPromo = {};
              this.promoEvent.emit(this.validatedPromo);
              const content =
                "<strong>There was a problem checking the promo code. Please try again.<strong>";
              console.log("errorCallback", JSON.stringify(response));
              this.toastService.showError(content);
            }
          },
          //just in case
          (error) => {
            this.wait = false;
            this.promoCodeIsValid = false;
            this.validatedPromo = {};
            this.promoEvent.emit(this.validatedPromo);
            const content =
              "<strong>There was a problem checking the promo code. Please try again.<strong>";
            console.log("errorCallback", JSON.stringify(error));
            this.toastService.showError(content);
          },
        ),
    );
  }

  private getNextBalanceForShoppingCart() {
    this.recalculatingTotal = true;
    this.subscriptions.push(
      this.organizationService
        .apiV2OrganizationsCalculateOrgBalanceNextDuePost(
          this.selectedOrg.ID,
          this.validatedPromo.promoCode?.ID,
        )
        .subscribe(
          (response) => {
            if (response && response.data && response.Success) {
              this.nextBillAmount =
                Math.round(parseFloat(response.data) * 100) / 100;
              this.cartService.calculateTotal();
            } else {
              let errMsg =
                "There was an error calculating the next bill amount";
              this.toastService.showError(errMsg);

              if (
                response &&
                response.data &&
                response.Messages &&
                response.Messages.length > 0
              ) {
                for (let i = 0; i < response.Messages.length; i++) {
                  const error = response.Messages[i];
                  errMsg +=
                    "\r\nCode: " +
                    error.Code +
                    "\r\nName: " +
                    error.Name +
                    "\r\nDescription: " +
                    error.Description;
                }
              }
              console.log(errMsg);
            }
            this.recalculatingTotal = false;
          },
          (error) => {
            //TODO: where do we show the error
            const errMsg =
              "Calculating the next bill amount resulted in an error";
            this.toastService.showError(errMsg);
            console.log(errMsg);
            this.recalculatingTotal = false;
          },
        ),
    );
  }

  public onPromoChange() {
    //if promocode was validated then got updated, then promo needs revalidation
    if (
      this.validatedPromo.promoCode != undefined &&
      this.validatedPromo.promoCode?.PromoCode != this.inputPromoCode
    ) {
      this.clearPromo();
    }
  }

  private clearPromo() {
    this.promoCodeIsValid = false;
    this.validatedPromo = {};
    this.nextBillTotal = this.cartService.updateTotal(this.validatedPromo);
  }

  public showFutureMonthlyBill() {
    return (
      this.cartService.getItems().length > 0 ||
      parseFloat(this.paymentInfo?.NextBillAmount) >= 0
    );
  }

  public getFutureMonthlyBill() {
    return parseFloat(this.paymentInfo?.NextBillAmount) >= 0
      ? parseFloat(this.paymentInfo?.NextBillAmount) +
          this.shoppingCart.CartMonthlyTotal
      : this.nextBillAmount + this.nextBillTotal;
  }

  get userDetailsForm() {
    return this.reviewForm?.get("UserCreate") as FormGroup;
  }
  get firstName() {
    return this.userDetailsForm.get("firstName");
  }
  get lastName() {
    return this.userDetailsForm.get("lastName");
  }
  get email() {
    return this.userDetailsForm.get("email");
  }
  get role() {
    return this.userDetailsForm.get("role");
  }
  get packageFormData() {
    return this.reviewForm.get("UserPackage") as FormGroup;
  }
  get selectedPackage() {
    return this.packageFormData?.get("selectedPackage");
  }
  get UserAddons() {
    return this.reviewForm.get("UserAddons") as FormGroup;
  }
  get selectedAddons() {
    return this.UserAddons?.get("selectedAddons").value;
  }
}
