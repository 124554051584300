<div
  *ngIf="!Package.InfoOnly"
  class="flex"
  [ngClass]="{
    'license-card': CardType === 'license',
    'disabled-view': CardType !== 'license' && !Package.ExternallyPurchasable
  }"
>
  <mat-card-title
    [attr.data-testid]="
      'cardUpperSection_' + Package.PackageCategoryDescription
    "
    class="package-card-header"
    [ngClass]="{
      'card-standard':
        !Package.Selected && Package.CatalogVisibility !== 'Unpurchasable',
      'card-selected-not-purchasable':
        !Package.Selected && Package.CatalogVisibility === 'Unpurchasable',
      'card-selected': Package.Selected
    }"
  >
    <mat-card-title-text *ngIf="!Package.InfoOnly">
      <div
        [attr.data-testid]="'licenseCardHeadline_' + Package.PackageType"
        *ngIf="CardType === 'license'"
        class="dspy-inline"
      >
        <span class="license-card-headline">{{ Package.PackageName }}</span>
        <br />
        <div
          class="license-card-desc"
          [innerHtml]="Package.PackageDescHtml | safeHtml"
        ></div>
      </div>
      <span
        [attr.data-testid]="'packageTitle_' + Package.PackageName"
        *ngIf="CardType !== 'license'"
        class="package-card-headline"
        >{{ Package.PackageName }}</span
      >
    </mat-card-title-text>
    <p *ngIf="ShowPriceHeading && !Package.InfoOnly">
      <span
        [attr.data-testid]="Package.PackageName + '_Price'"
        class="packagePriceHeading"
        [ngClass]="{ preDiscountedPrice: Package.DiscountPercent > 0 }"
        >{{ Package.ListPrice | currency }}</span
      >
      <span
        data-testid="packageAddonDiscountedPrice"
        class="packagePriceHeading"
        *ngIf="Package.DiscountPercent > 0"
        >&nbsp;{{
          (Package.ListPrice * (100 - Package.DiscountPercent)) / 100 | currency
        }}</span
      >
      <span class="packagePricePer">{{ Package.UnitTypeDesc }}</span>
      <span class="discountBadge" *ngIf="Package.DiscountPercent > 0">
        {{ Package.DiscountPercent }}% OFF
      </span>
    </p>
    <hr class="borderLess" *ngIf="ShowPriceHeading && !Package.InfoOnly" />
  </mat-card-title>
  <mat-card-content
    *ngIf="ShowDescription"
    class="card-content-standard packageCardContent"
  >
    <div
      [attr.data-testid]="Package.PackageName + '_Content'"
      *ngIf="CardType !== 'license'"
      class="rn-blackText"
      [innerHtml]="Package.PackageDescHtml | safeHtml"
    ></div>
  </mat-card-content>
</div>
<mat-card-actions
  *ngIf="!InfoOnly && ShowQuantityChanged"
  class="card-product-footer"
>
  <div class="col-4 quantityControl">
    <app-quantity-control
      (quantityChangeActionEmitter)="quantityChanged(Package, $event)"
      (quantityEnteredActionEmitter)="newQuantityEntered(Package, $event)"
      [QtyControlsDisabled]="this.QtyControlsDisabled"
      [AddQtyControlsDisabled]="this.AddQtyControlsDisabled"
      [MinValue]="Package.CurrentUsed"
      [DefaultValue]="Package.CurrentQuantity"
      [DisallowInput]="false"
      [MaxUsedMessage]="MaxUsedMessage"
      [MaxAllowed]="Package.MaxItemsAllowed"
      [InternalExclusiveTooltip]="this.InternalExclusiveTooltip"
    ></app-quantity-control>
  </div>
  <div style="margin-top: 10px">
    <span
      [attr.data-testid]="Package.PackageName + '_assignedSeats'"
      class="assignmentText appControlAssignmentText"
      >Assigned: {{ Package.CurrentUsed }} of
      {{ Package.CurrentQuantity }}</span
    >
  </div>
</mat-card-actions>
<mat-card-actions *ngIf="!InfoOnly && ShowStaticCounts">
  <div class="row">
    <div class="col-12 text-right pb-2 footerStyle">
      <app-button
        id="btnAdd"
        buttonStyle="packageCardPrimary"
        label="SELECT"
        *ngIf="!Package.AddOnAdded"
        [disabled]="
          Package
            | rnPackage: 'addonisdisabled' : AddOnDisabled : PurchaseRestricted
        "
        (clickActionEmitter)="AddClick()"
        width="120px"
        [escape]="false"
        noContentPadding="true"
        pTooltip="{{ AddOnTooltip }}"
      >
      </app-button>

      <app-button
        id="btnRemove"
        buttonStyle="dontCreateUserButton"
        label="REMOVE"
        *ngIf="Package.AddOnAdded"
        (clickActionEmitter)="RemoveClick()"
        noContentPadding="true"
        width="120px"
      >
      </app-button>
    </div>
    <div class="col-12 text-center pb-3 assigned-values">
      <span class="assignmentText"
        >Assigned: {{ Package.CurrentUsed }} of
        {{ Package.CurrentQuantity }}</span
      >
    </div>
  </div>
</mat-card-actions>
<mat-card-actions *ngIf="!InfoOnly && ShowAddOnRemove">
  <div class="row">
    <div class="col-12 text-right pb-2 footerStyle">
      <app-button
        id="btnAdd"
        buttonStyle="packageCardPrimary"
        label="SELECT"
        *ngIf="!Package.AddOnAdded"
        [disabled]="
          Package
            | rnPackage: 'addonisdisabled' : AddOnDisabled : PurchaseRestricted
        "
        (clickActionEmitter)="AddClick()"
        width="120px"
        [escape]="false"
        noContentPadding="true"
        pTooltip="{{ AddOnTooltip }}"
      >
      </app-button>

      <app-button
        id="btnRemove"
        buttonStyle="dontCreateUserButton"
        label="REMOVE"
        *ngIf="Package.AddOnAdded"
        (clickActionEmitter)="RemoveClick()"
        noContentPadding="true"
        width="120px"
      >
      </app-button>
    </div>
    <div class="col-12 text-center pb-3 assigned-values">
      <span class="assignmentText"
        >Assigned: {{ Package.CurrentUsed }} of
        {{ Package.CurrentQuantity }}</span
      >
    </div>
  </div>
</mat-card-actions>
<div
  *ngIf="Package.InfoOnly"
  [innerHtml]="Package.PackageDescHtml | safeHtml"
  class="card-content-infoOnly"
></div>
