import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SearchControlConfiguration } from "../search-control/configuration/search-control-configuration";

@Component({
  selector: "app-primary-frame",
  templateUrl: "./primary-frame.component.html",
  styleUrls: ["./primary-frame.component.scss"],
})
export class PrimaryFrameComponent implements OnInit {
  currentSearchText: string; //debounced input
  searchControlConfiguration: SearchControlConfiguration;
  @Input() frameLabel: string; //label on the page
  @Input() searchLabel: string; //placeholder text.  if empty, search won't be displayed
  @Output() searchEvent; //emitted event when search text

  public showSearch = false;

  constructor() {
    this.searchEvent = new EventEmitter<string>();
  }

  ngOnInit(): void {
    // search bar configuration
    this.searchControlConfiguration = new SearchControlConfiguration();
    this.searchControlConfiguration.EditFieldPlaceholderText = "Search apps";
    this.searchControlConfiguration.EditClass = "org-user-search-edit";
    this.searchControlConfiguration.ContainerClass =
      "col-xs-12 col-sm-12 col-md-12 col-lg-12 noLeftPadding";
    this.searchControlConfiguration.ShowMagnifyingGlassIcon = true;
    this.searchControlConfiguration.MagnifyingGlassClass = "searchApps";
    this.searchControlConfiguration.EditFieldPlaceholderText = this.searchLabel;
    this.showSearch =
      this.searchLabel && this.searchLabel.length > 0 ? true : false;
  }

  searchOccurred(searchText: string) {
    this.currentSearchText = searchText;
    this.searchEvent.emit(searchText);
  }
}
