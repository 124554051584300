<div class="modal-header">
  <h3 class="modal-title">Delete Folder</h3>
</div>
<div class="modal-body">
  This will delete <b>{{ folderConfig.Foldername }}</b> and any data that is
  still in the folder. <br />
  Are you sure you want to do this?
</div>

<div class="modal-footer">
  <a
    tabindex="0"
    (keyup.enter)="!working && close()"
    data-testid="cancelButton"
    [ngClass]="{ disabled: working }"
    class="cancel"
    (click)="!working && close()"
    >Cancel</a
  >
  <app-button
    id="btnDelete"
    label="Delete Folder"
    (clickActionEmitter)="actualDeleteFolder()"
    [processing]="working"
    showProcessing="true"
    buttonStyle="destroy"
  >
  </app-button>
</div>
