import { GridColumnBaseConfiguration } from "./grid-column-base-configuration";

export class GridAffectedColumnConfiguration extends GridColumnBaseConfiguration {
  getHtml(dataRow: any): string {
    let html = "";
    const hrefClass =
      this.linkClassName && this.linkClassName !== ""
        ? this.linkClassName
        : "rn-text-link";
    this.fieldNames.forEach((value, index) => {
      let preText = this.pretext[index];
      const link = this.rootHref[index];
      const id: string = dataRow[this.ids[index]];
      let linkText: string = dataRow[value];
      if (
        this.showAsLink[index] !== undefined &&
        this.showAsLink[index](dataRow, this.currentIdentifier)
      ) {
        html +=
          "<div>" +
          preText +
          "<a class='" +
          hrefClass +
          "' href= '" +
          link +
          id +
          "'>" +
          linkText +
          "</a></div>";
      } else {
        if (
          (!linkText || linkText === "") &&
          this.noLinkText &&
          this.noLinkText.length > index
        ) {
          linkText = this.noLinkText[index];
          if (!linkText || linkText === "") {
            preText = "";
          }
        }
        html += "<div>" + preText + linkText + "</div>";
      }
    });
    return html;
  }
  public ids: string[];
  public pretext: string[];
  public rootHref: string[];
  public fieldNames: string[];
  public classNames: string[];
  public showAsLink: any;
  public currentIdentifier: string;
  public linkClassName: string;
  public noLinkText: string[];

  constructor(
    pretext: string[],
    fieldNames: string[],
    ids: string[],
    rootHref: string[],
    title: string,
    classnames: string[],
    sortOrder: string,
    hyperlinkAsText: any,
    currentIdentifier: string,
    linkClassName: string,
    noLinkText: string[],
  ) {
    super("affected", fieldNames[0], title, classnames[0], sortOrder);
    this.fieldNames = fieldNames;
    this.ids = ids;
    this.pretext = pretext;
    this.rootHref = rootHref;
    this.classNames = classnames;
    this.showAsLink = hyperlinkAsText;
    this.currentIdentifier = currentIdentifier;
    this.linkClassName = linkClassName;
    this.noLinkText = noLinkText;
  }

  public TypeName(): string {
    return "affected";
  }
}
