import { Injectable } from "@angular/core";
import { RnRNResponseMessages } from "../rnapi2-service/models/models";

@Injectable({
  providedIn: "root",
})
export class RnErrorHandlerService {
  public handleError(
    logMsg: string,
    errMsgs: Array<RnRNResponseMessages>,
  ): void {
    let errMsg = logMsg;
    if (errMsgs && errMsgs.length > 0) {
      errMsgs.forEach((err) => {
        errMsg += `\r\nCode: ${err.Code}\r\nName: ${err.Name}\r\nMsg: ${err.Description};`;
      });
    }
    console.log(errMsg);
  }
}
