import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DialogTrackingService } from "../../../services/dialog/dialog-tracking.service";
import { LoggedInInfoService } from "../../../services/loggedInInfo/logged-in-info.service";
import {
  LookupService,
  UserService,
} from "../../../services/rnapi2-service/apis/api";
import {
  RnCategoriesVM,
  RnUserFeedback,
} from "../../../services/rnapi2-service/models/models";
import { RnToastService } from "../../../services/toast/rntoast.service";

@Component({
  selector: "app-user-feedback-dialog",
  templateUrl: "./user-feedback-dialog.component.html",
  styleUrls: ["./user-feedback-dialog.component.scss"],
})
export class UserFeedbackDialogComponent implements OnInit {
  feedbackForm: FormGroup;
  categories: RnCategoriesVM[] = [];
  isWorking = false;
  loadingCategories = true;

  constructor(
    private lookupService: LookupService,
    private userService: UserService,
    private loggedInInfoService: LoggedInInfoService,
    private formBuilder: FormBuilder,
    private ref: DynamicDialogRef,
    private toastService: RnToastService,
    private dialogTrackService: DialogTrackingService,
  ) {}

  ngOnInit(): void {
    this.lookupService.apiV2LookupGetCategoriesGet().subscribe((r) => {
      this.categories = r.data;
      this.loadingCategories = false;
    });

    this.feedbackForm = this.formBuilder.group({
      category: ["", Validators.required.bind(Validators)],
      comments: ["", Validators.required.bind(Validators)],
    });
  }

  sendFeedback() {
    this.isWorking = true;
    const payload: RnUserFeedback = {
      User_ID: this.loggedInInfoService.GetLoggedInUser().UserID,
      Category_ID: this.feedbackForm.value["category"],
      UserFeedback: this.feedbackForm.value["comments"],
    };
    this.userService.apiV2UsersSenduserfeedbackPost(payload).subscribe((r) => {
      this.isWorking = false;
      this.toastService.showSuccess(
        "Feedback sent successfully, thank you for your input.",
      );
      this.close();
    });
  }

  get category() {
    return this.feedbackForm.get("category");
  }

  close() {
    this.dialogTrackService.closeDialog(this.ref);
  }

  get f() {
    return this.feedbackForm.controls;
  }
}
