<div class="medium" *ngFor="let group of nonPurchasableAddOnGroups">
  <div class="panel-heading" *ngIf="!hideTitles">
    <h3 class="select-package-addon-panel-title">{{ group.GroupName }}</h3>
    <div class="text_14_margin_top_10">{{ infoText }}</div>
  </div>
  <div class="category-desc" *ngIf="group.GroupDescription">
    {{ group.GroupDescription }}
  </div>
  <div class="package-cards m-l-neg15">
    <app-package-card
      data-testid="packageCard"
      *ngFor="let addOn of group.Addons"
      [AddOnDisabled]="addonIsDisabled(addOn)"
      [AddOnTooltip]="addonTooltip(addOn)"
      [ngClass]="{
        'card-selected': addOn.Selected,
        'package-card-info-only': addOn.InfoOnly,
        'package-card': !addOn.InfoOnly
      }"
      [Package]="addOn"
      CardType="addRemove"
      (AddItem)="packageCardAdd($event)"
      (RemoveItem)="packageCardRemove($event)"
    ></app-package-card>
  </div>
</div>

<div class="medium" *ngFor="let group of purchasableAddOnGroups">
  <div class="panel-heading" *ngIf="!hideTitles"></div>
  <div class="category-desc" *ngIf="group.GroupDescription">
    {{ group.GroupDescription }}
  </div>
  <div class="package-cards m-l-neg15">
    <app-package-card
      data-testid="addonCard"
      *ngFor="let addOn of group.Addons"
      [AddOnDisabled]="addonIsDisabled(addOn)"
      [AddOnTooltip]="addonTooltip(addOn)"
      [ngClass]="{
        'card-selected': addOn.Selected,
        'package-card-info-only': addOn.InfoOnly,
        'package-card': !addOn.InfoOnly
      }"
      class="package-card"
      [Package]="addOn"
      CardType="addRemove"
      (AddItem)="packageCardAdd($event)"
      (RemoveItem)="packageCardRemove($event)"
    ></app-package-card>
  </div>
</div>
