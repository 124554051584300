import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  RnEventsVMSearchResultsVM,
  RnUsersProfileVM,
} from "../../../../shared/services/rnapi2-service/models/models";
import { UserService } from "../../../../shared/services/rnapi2-service/apis/api";
import { EventModel } from "../../../../core/models/event-model.model";
import { UserEventsData } from "./user-events-data";
import { RnsidebarService } from "../../../services/sidebar/rnsidebar.service";

@Component({
  selector: "app-user-events",
  templateUrl: "./user-events.component.html",
  styleUrls: ["./user-events.component.scss"],
})
export class UserEventsComponent implements OnInit, OnDestroy {
  @Input() set data(eventsData: UserEventsData) {
    if (eventsData.userProfile) {
      this.userProfile = eventsData.userProfile;

      this.clearSearch = [];
    }
  }

  @Output() componentLoaded = new EventEmitter<boolean>();

  public UserEvents: RnEventsVMSearchResultsVM = {
    Results: [],
    TotalNumberRecords: 0,
    TotalNumberPages: 0,
  };
  public LoadingEvents = false;
  public clearSearch = [];

  constructor(
    private userService: UserService,
    private rnsidebarService: RnsidebarService,
  ) {}
  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }
  ngOnInit(): void {
    this.rnsidebarService.getObservable().subscribe((payload) => {
      this.clearSearch = [];
    });
    this.componentLoaded.emit(true);
  }

  GetEvents($event) {
    const eventModel = $event as EventModel;
    this.retrieveEvents(eventModel);
  }

  retrieveEvents(eventModel: EventModel) {
    if (!this.userProfile) {
      return;
    }

    const eventsApiSearchParams = eventModel.toRequestPayload();
    if (eventsApiSearchParams !== null) {
      eventsApiSearchParams.Id = this.userProfile.UserID.toString();
      eventsApiSearchParams.AffectedOrganizationId =
        this.userProfile.OrganizationID;

      this.LoadingEvents = true;
      eventsApiSearchParams.pageNumber++; //The api starts @ 1 for User Events

      this.subscriptions.push(
        this.userService
          .apiV2UsersEventsPost(eventsApiSearchParams, "response", false)
          .subscribe((data) => {
            this.UserEvents = data.body.data;
            this.LoadingEvents = false;
          }),
      );
    }
  }

  private userProfile: RnUsersProfileVM;
  private subscriptions = [];
}
