/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export class RnOrganizationPromoCodeVM {
  PromoCodeID?: number;
  PromoCode?: string | null;
  PromoCodeDesc?: string | null;
  DiscountPercent?: number;
  PromoCodeTypeID?: number;
  PromoCodeTypeName?: string | null;
  AppliedBy?: string | null;
  EffectiveDate?: string;
  PackageIDs?: Array<number> | null;
  IsPromoCodeActive?: boolean;
  IsPromoCodeActiveNextBillCycle?: boolean;
  PromoEffEndDate?: string;
  PromoCodeCategoryName?: string | null;
}
