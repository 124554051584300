import { Injectable, Inject } from "@angular/core";
import * as ld from "launchdarkly-js-client-sdk";
import { Observable, of, switchMap, filter, BehaviorSubject } from "rxjs";
import { LD_ENV_KEY } from "./ld-tokens";
import { ldWrapper } from "./ld-wrapper";

/**
 * Feature flag service to get flags from LaunchDarkly
 */
@Injectable({
  providedIn: "root",
})
export class FeatureFlagService {
  launchDarkly: ld.LDClient;
  launchDarklyReady = new BehaviorSubject<boolean>(false);

  constructor(@Inject(LD_ENV_KEY) private environmentKey: string) {}

  /**
   * Initialize the LaunchDarkly client with the current user context
   * @param userId
   * @param email
   * @param organizationId
   */
  public initialize(context: ld.LDContext): void {
    if (!this.environmentKey) {
      console.error(
        "No LaunchDarkly environment key provided, cannot intialize, default values will be supplied for keys",
      );
    }

    this.launchDarkly = ldWrapper.initializeLaunchDarkly(
      this.environmentKey,
      context,
    );
    this.launchDarkly.on("ready", () => {
      this.launchDarklyReady.next(true);
    });
  }

  private initialized(): Observable<void> {
    return this.launchDarklyReady.pipe(
      filter((isReady) => isReady),
      switchMap(() => of(undefined)),
    );
  }

  /**
   * Get a single feature flag by its key, with a default value in case not available
   * @param key flag name
   * @param defaultValue default value in case flag no longer available
   * @returns boolean
   */
  public getFlag(key: string, defaultValue: boolean): Observable<boolean> {
    return this.initialized().pipe(
      switchMap(() => {
        return of(this.launchDarkly.variation(key, defaultValue));
      }),
    );
  }
}
