import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { Subscription } from "rxjs";
import { RnOnSidebarClose } from "../../../../core/interfaces/RnOnSidebarClose";
import { AppInsightsService } from "../../../appInsights/app-insights.service";
import { CartItem } from "../../../services/cart/cart-item";
import { CartService } from "../../../services/cart/cart.service";
import { LoggedInInfoService } from "../../../services/loggedInInfo/logged-in-info.service";
import { RnErrorHandlerService } from "../../../services/rn-error-handler/rn-error-handler.service";
import {
  LicenseService,
  OrderService,
} from "../../../services/rnapi2-service/apis/api";
import {
  RnInt64RNResponseRelay,
  RnLicenseBaseVM,
  RnLicenseGetCatalog,
  RnRNAddOnPayload,
  RnRNLicenseItem,
  RnRNOrderHeader,
  RnRNOrderItem,
} from "../../../services/rnapi2-service/models/models";
import { RnsidebarService } from "../../../services/sidebar/rnsidebar.service";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { OrgInfo } from "../org-lookup/org-lookup-data";
import { PackageCardData } from "../package-card/package-card-data";
import { LicenseInfoByType } from "./license-info-by-type";
import { LicensePurchaseConfig } from "./license-purchase-config";
import {
  LicensePurchaseInfo,
  LicensePurchaseInfoResponse,
  LicensePurchaseResponse,
} from "./license-purchase-info";

@Component({
  selector: "app-license-purchase",
  templateUrl: "./license-purchase.component.html",
  styleUrls: ["./license-purchase.component.scss"],
})
export class LicensePurchaseComponent
  implements OnInit, OnDestroy, RnOnSidebarClose
{
  @Input() data: LicensePurchaseConfig;
  @Output() componentLoaded = new EventEmitter<boolean>();

  constructor(
    private rnsidebarService: RnsidebarService,
    private licenseService: LicenseService,
    private cartService: CartService,
    private appInsights: AppInsightsService,
    private toastService: RnToastService,
    private renderer: Renderer2,
    private errorHandler: RnErrorHandlerService,
    private loggedInInfoService: LoggedInInfoService,
    private orderService: OrderService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {}

  @ViewChild("cartIcon") cartIcon: ElementRef;
  @ViewChild("cart") cart: ElementRef;

  public licenseConfig: LicensePurchaseConfig;

  public formData: { [key: string]: LicensePurchaseInfo } = {};

  public licenseInfo: LicenseInfoByType;
  public versionInfo: { [key: string]: string[] } = {};
  public editionInfo: { [key: string]: string[] } = {};
  public quantityInfo: { [key: string]: string[] } = {};

  public subscriptionQbVer: string[] = [];
  public subscriptionQbEditions: string[] = [];
  public subscriptionNumberOfUsersOptions: string[] = [];

  public purchaseQbVer: string[] = [];
  public purchaseQbEditions: string[] = [];
  public purchaseNumberOfUsersOptions: string[] = [];

  public leasedQbVer: string[] = [];
  public leasedQbEditions: string[] = [];
  public leasedNumberOfUsersOptions: string[] = [];

  public formValid = false;
  public submitting = false;
  public loading = false;

  public initiateAddItem = [];
  public purchaseChange = [];
  public purchasedItems: LicensePurchaseResponse[] = [];
  public processed = false;

  public mode = "typeSelect";
  public addInfoIsValid = false;

  public editionTooltip =
    "<p data-testid='tooltipMsg'><b>Pro</b> is the standard version of QuickBooks.<br><br></p><b>Premier</b> adds industry-specific tools and reporting capabilities.<br><br><b>Premier Accountant</b> includes all <b>Premier</b> features, plus extra accountant-oriented features like bulk transaction management to help manage your clients more effectively.</p>";
  public editionSubscriptionTooltip =
    "<p data-testid='tooltipMsg'><b>Pro Plus</b> is the standard version of QuickBooks.<br><br><b>Premier Plus</b> adds industry-specific tools and reporting capabilities.</p>";

  licenseTypes: PackageCardData[] = [];
  selectedLicenseType: PackageCardData;

  isShoppingCartVisible = false;

  ngOnInit(): void {
    this.rnsidebarService.setOnClose(this);
    this.licenseInfo = new LicenseInfoByType();
    this.componentLoaded.emit(false);

    this.rendererListenEvent = this.renderer.listen(
      "window",
      "click",
      (e: Event) => {
        if (
          !this.cartIcon.nativeElement.contains(e.target) &&
          !this.cart.nativeElement.contains(e.target)
        ) {
          this.forceCloseShoppingCart();
        }
      },
    );

    this.backupShoppingCart = this.cartService.backupCartItems();
    this.loading = true;

    this.formData["leased"] = this.setupNewPurchaseInfoForForm();
    this.formData["leased"].quantity = 1;
    this.formData["purchase"] = this.setupNewPurchaseInfoForForm();
    this.formData["subscription"] = this.setupNewPurchaseInfoForForm();

    const payload = new RnLicenseGetCatalog();
    payload.IncludeByol = true;
    payload.OrganizationId = this.data.selectedOrg.ID;
    this.subscriptions.push(
      this.licenseService
        .apiV2LicensesGetlicenseitemsPost(payload, "response")
        .subscribe((r) => {
          this.licenseCatalog = r.body.data;

          this.licenseInfo.leasedInfo = this.licenseCatalog.filter((l) => {
            return l.Type === "LeasedLicense";
          });

          this.licenseInfo.purchaseInfo = this.licenseCatalog.filter((l) => {
            return l.Type === "PurchasedLicense";
          });

          this.licenseInfo.subscriptionInfo = this.licenseCatalog.filter(
            (l) => {
              return l.Type === "AnnualSubscriptionLicense";
            },
          );

          if (this.licenseInfo.subscriptionInfo.length > 0) {
            const subtype = new PackageCardData();
            subtype.PackageID = 1;
            subtype.PackageName = "Annual QuickBooks License";
            subtype.PackageType = "subscription";
            subtype.PackageDescHtml =
              "Pay one time annual fee for the latest version of QuickBooks.";
            this.licenseTypes.push(subtype);
          }

          if (this.licenseInfo.purchaseInfo.length > 0) {
            const purchType = new PackageCardData();
            purchType.PackageID = 2;
            purchType.PackageName = "Buy a QuickBooks License";
            purchType.PackageType = "purchase";
            purchType.PackageDescHtml =
              "Pay once and use QuickBooks for as long as it is supported.";
            this.licenseTypes.push(purchType);
          }

          if (this.licenseInfo.leasedInfo.length > 0) {
            const leaseType = new PackageCardData();
            leaseType.PackageID = 3;
            leaseType.PackageName = "Lease a QuickBooks License";
            leaseType.PackageType = "leased";
            leaseType.PackageDescHtml =
              "Don't use QuickBooks all the time? Lease a license and pay monthly.";
            this.licenseTypes.push(leaseType);
          }

          this.loading = false;
          this.componentLoaded.emit(true);
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    this.rendererListenEvent();
  }

  typeSelected($event): void {
    this.selectedLicenseType = $event as PackageCardData;
    if (this.selectedLicenseType) {
      this.mode = "enterDetails";
      this.rnsidebarService.setPendingChanges(true);
    }
  }

  validSelectionChange($event): void {
    this.ngZone.run(() => {
      this.addInfoIsValid = $event as boolean;
      this.cd.detectChanges();
    });
  }

  itemAddedToCart(purchaseItem: LicensePurchaseResponse) {
    const item = new CartItem();
    item.Name = purchaseItem.license.PackageName;
    item.Quantity = purchaseItem.quantity;
    item.Price = purchaseItem.license.Price;
    item.PackageID = purchaseItem.license.PackageId;
    item.ItemType = "License";
    item.OrgID = purchaseItem.orgID;
    item.OrgName = purchaseItem.orgName;
    item.TypeName = purchaseItem.typeName;
    item.IsMonthly = purchaseItem.typeName === "Leased";

    this.cartService.addToCart(item);

    this.appInsights.logEvent(
      `Added To Cart - ${item.Quantity} ${purchaseItem.license.PackageName}`,
    );
    const foundItem = this.purchasedItems.filter((i) => {
      return (
        i.license.PackageId === purchaseItem.license.PackageId &&
        i.orgID === item.OrgID
      );
    });
    if (foundItem.length > 0) {
      foundItem[0].quantity += purchaseItem.quantity;
    } else {
      this.purchasedItems.push(purchaseItem);
    }
    this.rnsidebarService.setPendingChanges(true);
    this.purchaseChange = [];
    this.addInfoIsValid = false;
  }

  addToCart() {
    this.initiateAddItem = [];
  }

  shoppingCartQtyChange($event) {
    const item = $event as CartItem;
    if (item) {
      const purchaseItem = this.purchasedItems.filter((i) => {
        return i.license.PackageId === item.PackageID && i.orgID === item.OrgID;
      });
      if (purchaseItem.length > 0) {
        purchaseItem[0].quantity = item.Quantity;
        this.purchaseChange = [];
      }
    }
  }

  shoppingCartRemoveItem($event) {
    const item = $event as CartItem;
    if (item) {
      const purchaseItem = this.purchasedItems.filter((i) => {
        return i.license.PackageId === item.PackageID && i.orgID === item.OrgID;
      });
      if (purchaseItem.length > 0) {
        const deleteIdx = this.purchasedItems.indexOf(purchaseItem[0]);
        this.purchasedItems.splice(deleteIdx, 1);
        this.purchaseChange = [];
      }
    }
  }

  shoppingCartPlaceOrder() {
    this.placeOrder();
  }

  cancel() {
    if (this.processed) {
      this.rnsidebarService.refreshUser();
      this.purchasedItems = [];
      this.cartService.clearCart();
    } else if (this.purchasedItems.length > 0) {
      this.rnsidebarService.showConfirmation();
    } else {
      this.rnsidebarService.hide();
    }
  }

  placeOrder() {
    this.submitting = true;
    const response = new LicensePurchaseInfoResponse();
    response.isPurchase = true;
    response.purchasedItems = this.purchasedItems;

    const payload = new RnRNAddOnPayload();
    payload.OrganizationID = this.data.selectedOrg.ID;
    payload.PaymentOnFileID = this.data.selectedOrg.PaymentOnFileID;

    payload.Header = new RnRNOrderHeader();
    payload.Header.Order_XID = "-1";
    payload.Header.Order_Source = "AppHub";
    payload.Header.Order_Date = new Date().toUTCString();
    payload.Header.SalesAgent_TAG =
      this.loggedInInfoService.GetLoggedInUser().userid;

    payload.OrderItems = [];
    this.purchasedItems.forEach((i) => {
      const orderItem = new RnRNOrderItem();
      orderItem.PackageID = i.license.PackageId;
      orderItem.Quantity = i.quantity;
      orderItem.ItemOrgAssignmentID = i.orgID;
      orderItem.PromoCode = "-1";
      orderItem.ItemFlavorID = 0;
      payload.OrderItems.push(orderItem);
    });

    payload.QbesLicenseItems = new Array<RnRNLicenseItem>();
    payload.PromotionCodeID = null;
    payload.ForceSkipEmail = false;

    this.subscriptions.push(
      this.orderService.apiV2OrdersCreateaddonPost(payload).subscribe(
        (r) => {
          if (r && r.Success) {
            this.processed = true;
            this.rnsidebarService.setPendingChanges(false);
            this.appInsights.logEvent("Buy/Lease License - Order Placed");
          } else {
            this.submitting = false;
          }
        },
        (error) => {
          const errResp = error as HttpErrorResponse;
          if (errResp) {
            if (errResp.error) {
              const rnResp = errResp.error as RnInt64RNResponseRelay;
              if (rnResp) {
                this.errorHandler.handleError(
                  "An error occurred attempting to purchase the license(s).",
                  rnResp.Messages,
                );
              }
            }
          }

          this.toastService.showError(
            "There was an error purchasing the license(s).",
          );
          this.submitting = false;
        },
      ),
    );
  }

  toggleShoppingCart() {
    this.isShoppingCartVisible = !this.isShoppingCartVisible;
  }

  forceCloseShoppingCart(): void {
    this.isShoppingCartVisible = false;
  }

  showOrgSelection(type: string): boolean {
    return (
      this.data.selectedOrg.HasClientOrgs &&
      (this.formData[type].dest === null ||
        this.formData[type].dest === undefined)
    );
  }

  showRemoveOrgSelection(type: string): boolean {
    return (
      this.data.selectedOrg.HasClientOrgs &&
      this.formData[type].dest !== null &&
      this.formData[type].dest !== undefined
    );
  }

  removeSelectedOrg(type: string) {
    this.formData[type].dest = null;
  }

  clearForm(type: string, form: NgForm) {
    form.resetForm();
    if (type === "leased") {
      this.formData[type].quantity = 1;
    }
  }

  selectThisOrg(type: string) {
    this.formData[type].dest = this.getOrgInfo(
      this.data.selectedOrg.ID,
      this.data.selectedOrg.Name,
    );
  }

  selectSubOrg(type: string) {
    this.formData[type].dest = undefined;
  }

  back(): void {
    this.mode = "typeSelect";
    this.selectedLicenseType = undefined;
    if (this.purchasedItems.length === 0) {
      this.rnsidebarService.setPendingChanges(false);
    }
  }

  private subscriptions: Subscription[] = [];
  private licenseCatalog: RnLicenseBaseVM[] = [];
  private leasedQbFullList: RnLicenseBaseVM[] = [];
  private purchasedQbFullList: RnLicenseBaseVM[] = [];
  private subscriptionQbFullList: RnLicenseBaseVM[] = [];
  private rendererListenEvent: () => void;

  private backupShoppingCart: CartItem[];

  private setupNewPurchaseInfoForForm(): LicensePurchaseInfo {
    const result = new LicensePurchaseInfo();
    //    result.dest = this.getOrgInfo(this.data.selectedOrg.ID, this.data.selectedOrg.Name);
    return result;
  }

  private getOrgInfo(ID: number, orgName: string): OrgInfo {
    const result = new OrgInfo();
    result.ID = ID;
    result.Name = orgName;
    return result;
  }

  onSidebarClose(): void {
    if (this.processed && this.purchasedItems.length > 0) {
      this.rnsidebarService.refreshUser();
      this.purchasedItems = [];
      this.cartService.clearCart();
    }
  }
}
