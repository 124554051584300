import { Pipe, PipeTransform } from "@angular/core";
import { OrgFlatNode } from "../../../feature-modules/organization/organization-details/organization-tree/organization-tree.component";

@Pipe({
  name: "rnOrganizationTreeNode",
})
export class RnOrganizationTreeNodePipe implements PipeTransform {
  transform(node: OrgFlatNode, ...args: unknown[]): unknown {
    if (node && args && args.length > 0) {
      const prop = args[0].toString().toLowerCase();
      switch (prop) {
        case "deletetooltipclass": {
          return node?.treeItem?.isFolder && node?.treeItem?.children.length > 0
            ? "wideTooltipMed"
            : "";
        }
        case "deletetooltip": {
          if (node?.treeItem?.isFolder) {
            const reasons = [];
            if (node?.treeItem?.children.length > 0) {
              reasons.push("Sub Organizations/Folders");
            }

            if (node?.treeItem?.rnTreeNode.hasUsersMapped) {
              reasons.push("Users mapped to organization");
            }

            if (reasons.length > 0) {
              let result =
                '<b data-testid="tooltipMsg">This folder cannot be deleted because the following area(s) contain data:</b><br \\>';
              reasons.forEach((r: string) => {
                result += `<ul><li data-testid='reason/s'>${r}</li></ul>`;
              });
              return result;
            }

            return '<span data-testid="tooltipMsg">Delete folder</span>';
          }
          return "";
        }
      }
    }
    return null;
  }
}
