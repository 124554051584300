/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnLicenseFullVM } from "../models/./licenseFullVM-d";
import { RnEventLogInfo } from "../models/./eventLogInfo-d";

export class RnLicensesCreate {
  OrganizationID?: number;
  LicenseTypeName?: string | null;
  isFSP?: boolean;
  FlavorID?: number;
  ItemID?: number;
  FlavorCountry?: string | null;
  LicenseData?: RnLicenseFullVM;
  EventLogInfo?: RnEventLogInfo;
  AffectedOrganizationId?: number;
  AffectedUserId?: number;
}
