import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { MaintenanceModeConfiguration } from "../../../../core/models/maintenance-mode-configuration";
import { AuthenticationService } from "../../../security/authentication.service";
import { DialogTrackingService } from "../../../services/dialog/dialog-tracking.service";
import { NavigationService } from "../../../services/navigation/navigation.service";
import { SelectionService } from "../../../services/selection/selection.service";

@Component({
  selector: "app-maintenance-mode-notification",
  templateUrl: "./maintenance-mode-notification.component.html",
  styleUrls: ["./maintenance-mode-notification.component.scss"],
})
export class MaintenanceModeNotificationComponent implements OnInit {
  public Configuration: MaintenanceModeConfiguration;
  OrganizationName: string;
  IsInternal: boolean;
  FirstName: string;
  StartDate: string;
  Reason: string;
  technical_support_phone: string;
  State: string;
  ConvertToFolderProcess = false;
  showOrgName = false;

  constructor(
    public config: DynamicDialogConfig,
    public authService: AuthenticationService,
    public navigationService: NavigationService,
    public dialogRef: DynamicDialogRef,
    private dialogTrackService: DialogTrackingService,
    private selectionService: SelectionService,
  ) {
    this.Configuration = config.data;
    this.OrganizationName = this.Configuration.OrganizationName;
    this.IsInternal = this.Configuration.IsInternal;
    this.FirstName = this.Configuration.FirstName;
    this.StartDate = this.Configuration.StartDate;
    this.Reason = this.Configuration.MaintenanceModeText;
    this.technical_support_phone = this.Configuration.MaintenanceModePhone;
    this.State = this.Configuration.State.toLowerCase();
  }

  ngOnInit(): void {
    this.topLevelOrgID = this.selectionService.getSelectedTopLevelOrg().ID;
    this.showOrgName = this.State === "returntotoplevel";
  }

  async returnToSearch(): Promise<void> {
    this.close();
    await this.navigationService.GoHome();
  }

  removeFromMaintenanceMode(): void {
    this.close();
  }

  async goToTopLevelOrg(): Promise<void> {
    await this.navigationService.OpenOrganization(this.topLevelOrgID);
    this.close();
  }

  async logout(): Promise<void> {
    this.close();
    await this.navigationService.GoToLoginPage();
    await this.authService.logout();
  }

  close(): void {
    this.dialogTrackService.closeDialog(this.dialogRef);
  }

  private topLevelOrgID: number;
}
