/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnPackPoolMV } from "../models/./packPoolMV-d";
import { RnCatalogCategoryVM } from "../models/./catalogCategoryVM-d";
import { RnCategoriesVM } from "../models/./categoriesVM-d";

export class RnOrganizationAddonOrderCatalogVM {
  PackagePools?: Array<RnPackPoolMV> | null;
  UserAllocations?: Array<number> | null;
  PackageCategories?: Array<RnCategoriesVM> | null;
  CatalogCategories?: Array<RnCatalogCategoryVM> | null;
}
