<img
  alt="{{ source }} icon"
  [pTooltip]="tooltip"
  [escape]="false"
  [tooltipPosition]="tooltipsPosition"
  [tooltipStyleClass]="tooltipStyleClass"
  class="{{ imgClass }}"
  [ngClass]="{ 'max-height-unset': useOriginalHeight }"
  src="{{ assetUrl }}/icons/{{ source }}.svg"
/>
