import { Pipe, PipeTransform } from "@angular/core";
import { PackageCardData } from "../../components/rn-business-components/package-card/package-card-data";

@Pipe({
  name: "rnManageAddons",
})
export class RnManageAddonsPipe implements PipeTransform {
  transform(addon: PackageCardData, ...args: unknown[]): unknown {
    if (addon && args && args.length > 0) {
      const prop = args[0].toString().toLowerCase();
      switch (prop) {
        case "addonIsDisabled": {
          return "";
        }
      }
    }
  }
}
