import { CurrencyPipe } from "@angular/common";
import { ViewChild } from "@angular/core";
import { ElementRef } from "@angular/core";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subscription } from "rxjs";
import { CartItem } from "../../../services/cart/cart-item";
import { CartService } from "../../../services/cart/cart.service";
import { ShoppingCart } from "../../../services/cart/shopping-cart";
import { OrganizationLogicService } from "../../../services/organization-logic/organization-logic.service";
import { OrganizationService } from "../../../services/rnapi2-service/apis/organization.service";
import { RnOrganizationPaymentOnFileVM } from "../../../services/rnapi2-service/models/models";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { GridColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-column-configuration";
import { GridConfiguration } from "../../third-party-wrappers/grid/configuration/grid-configuration";
import { GridHtmlColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-html-column-configuration";
import { GridLinkButtonColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-linkbutton-column-configuration";
import { GridQuantityColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-quantity-column-configuration";
import { ValidatePromoCodeVM } from "../change-package-inventory/validate-promo-code-vm";

@Component({
  selector: "app-shopping-cart",
  templateUrl: "./shopping-cart.component.html",
  styleUrls: ["./shopping-cart.component.scss"],
})
export class ShoppingCartComponent implements OnInit, OnDestroy {
  @ViewChild("promo") promo: ElementRef;

  @Input() PaymentInfo: RnOrganizationPaymentOnFileVM;
  @Input() IsInline = false;
  @Input() UseTypeColumn = false;
  @Input() LicenseForName = false;
  @Input() UseAdjustQuantityColumn = false;
  @Input() UseRemoveWithQuantityColumn = false;
  @Input() UsePurchaseButton = false;
  @Input() isPlaceOrderDisabled: boolean;
  @Input() UseWiderCart = false;
  @Input() HidePromoIfEmpty = false;
  @Output() CloseCart: EventEmitter<void> = new EventEmitter<void>();
  @Output() QuantityChanged: EventEmitter<CartItem> =
    new EventEmitter<CartItem>();
  @Output() RemoveItem: EventEmitter<CartItem> = new EventEmitter<CartItem>();
  @Output() PlaceOrderClicked = new EventEmitter();

  nextBillAmount: any;
  nextBillTotal: number;
  beforePromoApplyShoppingCart: any;
  inputPromoCode = "";
  promoCodeIsValid = false;
  wait = false;
  error = false;
  validatedPromo: ValidatePromoCodeVM = new ValidatePromoCodeVM();

  recalculatingTotal = false;
  CartTableConfiguration: GridConfiguration;
  shoppingCart: ShoppingCart;
  submitting = false;
  canShowPromoCodeEntry: boolean;

  showGridArea: boolean;
  showPaymentArea: boolean;
  showACHInfo: boolean;
  showCCInfo: boolean;
  showTotalDue: boolean;
  showFutureMonthlyBill: boolean;
  purchaseDisabled: boolean;

  constructor(
    private cartService: CartService,
    private currencyPipe: CurrencyPipe,
    private orgLogicService: OrganizationLogicService,
    private toastService: RnToastService,
    private organizationService: OrganizationService,
  ) {}

  ngOnInit(): void {
    this.nextBillAmount = this.PaymentInfo?.NextBillAmount;
    this.setupColumnsForTable();
    this.subscriptions.push(
      this.cartService.getCart().subscribe((r) => {
        this.shoppingCart = r;
        this.CartTableConfiguration.GridData = this.shoppingCart.items;
        if (this.HidePromoIfEmpty) {
          if (this.shoppingCart.items.length > 0) {
            this.HidePromoIfEmpty = false;
          }
          // else, keep it true
        }
      }),
    );
    this.canShowPromoCodeEntry = this.orgLogicService.CanShowPromoCodeEntry();
    this.validatedPromo = this.cartService.getPromo();

    this.showPaymentArea = this.PaymentInfo && !this.IsInline;
    this.showACHInfo =
      this.PaymentInfo?.PaymentMethods[0]?.PaymentMethodType === "ACH";
    this.showCCInfo =
      this.PaymentInfo?.PaymentMethods[0]?.PaymentMethodType !== "ACH";
    this.updateVisualProperties();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  hideShoppingCart(): void {
    this.CloseCart.emit();
  }

  updateVisualProperties() {
    this.showGridArea =
      !this.IsInline || (this.IsInline && this.shoppingCart.items.length > 0);
    this.showTotalDue = this.shoppingCart.items.length > 0;
    this.showFutureMonthlyBill = this.shoppingCart.items.length > 0;
    this.purchaseDisabled = this.shoppingCart.items.length === 0;
  }

  setupColumnsForTable() {
    this.CartTableConfiguration = new GridConfiguration();
    this.CartTableConfiguration.FirstRow = 0;
    this.CartTableConfiguration.UsePaginator = false;
    this.CartTableConfiguration.IsLazyLoading = false;
    this.CartTableConfiguration.ShowCountHeader = false;
    this.CartTableConfiguration.GridClass = "shoppingCart";
    this.CartTableConfiguration.ColumnConfiguration = [];
    if (this.UseTypeColumn) {
      const typeColClass =
        (this.LicenseForName ? "col-15-width" : "col-55-width") +
        " direct-inventory-table-header";

      this.CartTableConfiguration.ColumnConfiguration.push(
        new GridColumnConfiguration("TypeName", "Type", typeColClass, ""),
      );
    }

    if (this.LicenseForName) {
      const licenseDetCol = new GridHtmlColumnConfiguration(
        "",
        "License Details",
        "col-25-width direct-inventory-table-header",
        "",
      );
      licenseDetCol.GetHtmlDataForColumn = (dataRow: any) => {
        let result: string;
        const item = dataRow as CartItem;
        result = `<span>${item.Name}</span>`;
        result += `<br /><span><b>Organization:</b> ${item.OrgName}</span>`;
        result += `<br /><span><b>Quantity:</b> ${item.Quantity}</span>`;
        return result;
      };
      this.CartTableConfiguration.ColumnConfiguration.push(licenseDetCol);
    } else {
      this.CartTableConfiguration.ColumnConfiguration.push(
        new GridColumnConfiguration(
          "Name",
          "Product",
          "col-14-width direct-inventory-table-header",
          "",
        ),
      );
    }

    if (this.UseAdjustQuantityColumn) {
      const qtyColClass =
        (this.LicenseForName ? "col-15-width" : "col-25-width") +
        " direct-inventory-table-header c-align";
      const quantityColConfig = new GridQuantityColumnConfiguration(
        "Quantity",
        "Quantity",
        qtyColClass,
        "",
      );
      quantityColConfig.onEdit = (dataRow, $event) => {
        const item = dataRow as CartItem;
        const quantity = $event as number;
        if (item && quantity) {
          item.Quantity = quantity;
          this.cartService.calculateTotal();
          this.cartService.calculateMonthlyTotal();
          this.QuantityChanged.emit(item);
        }
      };

      quantityColConfig.onQuantityChanged = (dataRow, $event) => {
        const item = dataRow as CartItem;
        const quantity = $event as number;
        if (item && quantity) {
          const newQty = item.Quantity + quantity;
          if (newQty > 0) {
            item.Quantity += quantity;
            this.cartService.calculateTotal();
            this.cartService.calculateMonthlyTotal();
            this.QuantityChanged.emit(item);
          }
        }
      };

      this.CartTableConfiguration.ColumnConfiguration.push(quantityColConfig);

      if (this.UseRemoveWithQuantityColumn) {
        const removeColConfig = new GridLinkButtonColumnConfiguration(
          "Remove",
          "",
          "col-icon-width",
          "",
        );
        removeColConfig.removeClass = "removeClass";
        removeColConfig.LinkText = "Remove";
        removeColConfig.GetAction = (rowData) => {
          this.RemoveItem.emit(rowData);
          this.cartService.removeFullRow(rowData);
          if (this.shoppingCart.TotalItems < 1) {
            this.purchaseDisabled = true;
          }
        };

        this.CartTableConfiguration.ColumnConfiguration.push(removeColConfig);
      }
    } else {
      this.CartTableConfiguration.ColumnConfiguration.push(
        new GridColumnConfiguration(
          "Quantity",
          "Quantity",
          "col-25-width direct-inventory-table-header c-align",
          "",
        ),
      );
    }

    const priceColClass =
      (this.LicenseForName ? "col-15-width" : "col-25-width") +
      " direct-inventory-table-header text-align-right";
    const priceCol = new GridHtmlColumnConfiguration(
      "Price",
      "Item Price",
      priceColClass,
      "",
    );
    priceCol.GetHtmlDataForColumn = (dataRow: {
      DiscountPercent: number;
      Price: any;
      IsMonthly: boolean;
    }) => {
      if (dataRow.DiscountPercent > 0) {
        return `<p>
        <div class="preDiscountedPrice">${this.currencyPipe.transform(dataRow.Price, "USD")}${dataRow.IsMonthly ? "/month" : ""}</div>
        <div>${this.currencyPipe.transform(dataRow.Price * ((100 - dataRow.DiscountPercent) / 100), "USD")}&nbsp;(${dataRow.DiscountPercent}% off)</span>
        </div>
        </p>`;
      } else {
        return `<span>${this.currencyPipe.transform(dataRow.Price, "USD")}${dataRow.IsMonthly ? "/month" : ""}</span>`;
      }
    };
    this.CartTableConfiguration.ColumnConfiguration.push(priceCol);

    const totalColClass =
      (this.LicenseForName ? "col-15-width" : "col-25-width") +
      " direct-inventory-table-header text-align-right";
    const totalCol = new GridHtmlColumnConfiguration(
      "Total",
      "Total Price",
      totalColClass,
      "",
    );
    totalCol.GetHtmlDataForColumn = (dataRow: any) => {
      if (dataRow.DiscountPercent > 0) {
        return `<p>
        <div class="preDiscountedPrice">
          ${this.currencyPipe.transform(dataRow.Price, "USD")}${dataRow.IsMonthly ? "/month" : ""}
        </div>
        <div>
          ${this.currencyPipe.transform(dataRow.Price * ((100 - dataRow.DiscountPercent) / 100) * dataRow.Quantity, "USD")}${dataRow.IsMonthly ? "/month" : ""}
          </div>
        </p>`;
      } else {
        return `<span>${this.currencyPipe.transform(dataRow.Price * dataRow.Quantity, "USD")}${dataRow.IsMonthly ? "/month" : ""}</span>`;
      }
    };
    this.CartTableConfiguration.ColumnConfiguration.push(totalCol);
    this.CartTableConfiguration.RetrieveClassForCell = (col, rowData) => {
      let response = "";
      const rightAlignClassText = "text-align-right";
      const centerAlignClassText = "c-align";
      switch (col.FieldName) {
        case "Quantity":
          response = centerAlignClassText;
          break;
        case "Price":
        case "Total":
          response = rightAlignClassText;
          break;
      }

      if (this.UseWiderCart && this.LicenseForName) {
        response += " v-align-top shopping-cart-cell";
      }
      return response;
    };

    this.CartTableConfiguration.TableClass = "top-border-not-first-row";
  }

  placeOrder() {
    this.submitting = true;
    this.isPlaceOrderDisabled = true;
    this.PlaceOrderClicked.emit();
  }

  private getNextBalanceForShoppingCart() {
    this.recalculatingTotal = true;
    this.subscriptions.push(
      this.organizationService
        .apiV2OrganizationsCalculateOrgBalanceNextDuePost(
          this.PaymentInfo.OrganizationID,
          this.validatedPromo.promoCode.PromoCodeID,
        )
        .subscribe(
          (response) => {
            if (response && response.data && response.Success) {
              this.nextBillAmount =
                Math.round(parseFloat(response.data) * 100) / 100;
            } else {
              let errMsg =
                "There was an error calculating the next bill amount";
              this.toastService.showError(errMsg);

              if (
                response &&
                response.data &&
                response.Messages &&
                response.Messages.length > 0
              ) {
                for (let i = 0; i < response.Messages.length; i++) {
                  const error = response.Messages[i];
                  errMsg +=
                    "\r\nCode: " +
                    error.Code +
                    "\r\nName: " +
                    error.Name +
                    "\r\nDescription: " +
                    error.Description;
                }
              }
              console.log(errMsg);
            }
            this.recalculatingTotal = false;
          },
          (error) => {
            //TODO: where do we show the error
            const errMsg =
              "Calculating the next bill amount resulted in an error";
            this.toastService.showError(errMsg);
            console.log(errMsg);
            this.recalculatingTotal = false;
          },
        ),
    );
  }

  public onPromoChange() {
    //if promocode was validated then got updated, then promo needs revalidation
    if (
      this.validatedPromo.promoCode != undefined &&
      this.validatedPromo.promoCode.PromoCode != this.inputPromoCode
    ) {
      //this.clearPromo();
    }
  }

  applyPromo(promo: any) {
    this.promoCodeIsValid = !!promo;
    this.validatedPromo = promo;
    this.cartService.updateTotal(this.validatedPromo);
    if (this.promoCodeIsValid) {
      this.beforePromoApplyShoppingCart = this.cartService.cart;
      this.nextBillTotal = this.cartService.updateTotal(this.validatedPromo);
      //this.getNextBalanceForShoppingCart();
    }
  }

  changePackagePricesPerPromo(pkg: CartItem): void {
    if (pkg) {
      const discount =
        (this.validatedPromo.promoCode.DiscountPercent / 100.0) * pkg.Price;
      //pkg.DiscountPrice = pkg.Price - discount;
      pkg.DiscountPercent = this.validatedPromo.promoCode.DiscountPercent;

      if (this.validatedPromo.promoCode.IsPromoCodeActiveNextBillCycle) {
        //pkg.NextBillPrice = pkg.DiscountPrice;
      }
    }
  }

  private subscriptions: Subscription[] = [];
}
