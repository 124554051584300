import { IdleStateTypes } from "../../shared/idle/idle-state-types.enum";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class IdleService {
  public idleState = IdleStateTypes.none;
  public countdown: number;
}
