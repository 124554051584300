import { Pipe, PipeTransform } from "@angular/core";
import { RnUsersProfileVM } from "../../services/rnapi2-service/models/usersProfileVM-d";

@Pipe({
  name: "rnEditMfaDialog",
})
export class RnEditMfaDialogPipe implements PipeTransform {
  transform(user: RnUsersProfileVM, ...args: unknown[]): unknown {
    if (args && args.length > 0) {
      const param = args[0];
      switch (param) {
        case "loading": {
          return !user;
        }
        case "userIsMfaEnabled": {
          return user && !!user.MfaEnabled;
        }
      }
    }

    return null;
  }
}
