import { Pipe, PipeTransform } from "@angular/core";
import { ConstantsService } from "../../services/constants/constants.service";
import { RnOrganizationConfigurationVM } from "../../services/rnapi2-service/models/models";

@Pipe({
  name: "rnOrganizationConfig",
})
export class RnOrganizationConfigPipe implements PipeTransform {
  constructor(private constantsService: ConstantsService) {}

  transform(
    orgConfig: RnOrganizationConfigurationVM,
    ...args: unknown[]
  ): unknown {
    if (orgConfig && args && args.length > 0) {
      const params = args[0].toString().toLowerCase();
      switch (params) {
        case "initiatedbillingseparation": {
          const isWorkInProgress = args[1];
          return isWorkInProgress || orgConfig.InitiatedBillingSeparation;
        }
        case "billingseparationtooltip": {
          return orgConfig.InitiatedBillingSeparation
            ? this.constantsService.billingSeparationTooltip
            : "";
        }
        case "formatindustry": {
          return orgConfig.CustomerSegment === "Accounting Firm"
            ? "Accounting/Tax"
            : orgConfig.IndustryType === "Other"
              ? "Other - " + orgConfig.OtherIndustryTypeName
              : orgConfig.IndustryType;
        }
      }
    }

    return null;
  }
}
