/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export class RnUserProfileConnectPointsVM {
  FolderName?: string | null;
  FolderPath?: string | null;
  MappedDrive?: string | null;
  ConnectPointID?: number;
  ChildConnectPointIDs?: Array<number> | null;
  ProcessTypeName?: string | null;
  MappedDriveId?: number;
  OrganizationName?: string | null;
  IsConnectPointOwnedByThisOrganization?: boolean;
}
