import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {
  @Input() text = "";
  @Input() inline = false;
  @Input() opaque = false;
  @Input() scrollCenter = false;

  overlay = "overlay";

  ngOnInit(): void {
    if (this.inline) this.overlay = "";
    if (this.opaque) this.overlay = "overlay opacity-1";

    this.overlay += " c-align";
  }
}
