import { GridColumnBaseConfiguration } from "./grid-column-base-configuration";

export class GridColumnConfiguration extends GridColumnBaseConfiguration {
  constructor(
    fieldName: string,
    title: string,
    classname: string,
    sortOrder: string,
  ) {
    super("generic", fieldName, title, classname, sortOrder);
  }
}
