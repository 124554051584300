import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoggedInInfoService } from "../../../services/loggedInInfo/logged-in-info.service";
import { OrganizationLogicService } from "../../../services/organization-logic/organization-logic.service";
import {
  RnOrganizationConfigurationVM,
  RnOrganizationPackagePoolsForListVM,
  RnOrganizationsProfileVM,
  RnOrganizationWLPContactInformationVM,
} from "../../../services/rnapi2-service/models/models";
import { PackageConfigInfo } from "./package-config-info";

@Component({
  selector: "app-package-inventory",
  templateUrl: "./package-inventory.component.html",
  styleUrls: ["./package-inventory.component.scss"],
})
export class PackageInventoryComponent {
  @Input() set PackageList(
    packageList: Array<RnOrganizationPackagePoolsForListVM>,
  ) {
    this.packageList = packageList;
    this.categorizePackagesForUI();
  }
  @Input() set OrgProfile(orgProfile: RnOrganizationsProfileVM) {
    this.orgProfile = orgProfile;
  }
  get OrgProfile(): RnOrganizationsProfileVM {
    return this.orgProfile;
  }
  @Input() OrgConfig: RnOrganizationConfigurationVM;
  @Input() Loading = false;
  @Input() set PkgConfigInfo(pkgInfo: PackageConfigInfo) {
    this.pkgConfigInfo = pkgInfo;
    this.directPackagesSubtext = `This is a view of the current Packages inventory. If you need further assistance, contact us at ${pkgInfo.RNDirectSalesPhoneNumber}.`;
    this.directAddonsSubtext = `This is a view of the current Add-ons inventory. If you need further assistance, contact us at ${pkgInfo.RNDirectSalesPhoneNumber}.`;
  }

  get PkgConfigInfo(): PackageConfigInfo {
    return this.pkgConfigInfo;
  }

  @Input() set OrgWlpContactInfo(info: RnOrganizationWLPContactInformationVM) {
    this.orgWlpContactInfo = info;
    if (this.orgProfile?.CustomerType === "Drake") {
      this.WLPPackageText = `To purchase additional seats, please contact ${this.orgWlpContactInfo?.SalesPhone}.`;
    }
  }

  get OrgWlpContactInfo(): RnOrganizationWLPContactInformationVM {
    return this.orgWlpContactInfo;
  }

  @Output() PackagePoolSelected =
    new EventEmitter<RnOrganizationPackagePoolsForListVM>();

  public WlpPackages: Array<RnOrganizationPackagePoolsForListVM> = [];
  public WlpAddOns: Array<RnOrganizationPackagePoolsForListVM> = [];
  public RnPackages: Array<RnOrganizationPackagePoolsForListVM> = [];
  public RnAddOns: Array<RnOrganizationPackagePoolsForListVM> = [];

  public WLPPackageText: string;
  public isAddonsFlag = true;

  directPackagesHeading = "Packages";
  directPackagesSubtext: string;
  directAddonsHeading = "Add-ons";
  directAddonsSubtext: string;
  constructor(
    private orgLogicService: OrganizationLogicService,
    private loggedInInfoService: LoggedInInfoService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  showWLPPackages(): boolean {
    const result =
      this.OrgProfile?.CustomerType !== "Rightworks" &&
      !(
        this.OrgProfile?.HasDirectPaymentOnFile &&
        this.OrgProfile?.ParentID !== -1
      );
    return result;
  }

  showDirectPackages(): boolean {
    return this.RnPackages.length > 0;
  }

  showDirectAddons(): boolean {
    return true;
  }

  canShowChangeInventory(): boolean {
    if (!this.OrgProfile) {
      return false;
    }

    const canPurchaseRight = this.loggedInInfoService.loggedInUserHasRights([
      "PRCHDROPPK",
    ]);

    const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    if (!loggedInUser) {
      return false;
    }

    const isInternal = loggedInUser.IsInternal();
    const loggedInUserOrg = this.loggedInInfoService.GetLoggedInUserOrg();

    let result = this.orgLogicService.CanPurchaseDropPackages(
      canPurchaseRight,
      isInternal,
      this.OrgProfile,
      loggedInUserOrg,
    );

    if (!result) {
      result = this.canPurchaseNeedsPOF();
    }

    return result;
  }

  async changeInventory(): Promise<void> {
    await this.navigateToPurchase();
  }

  packageSelected(selectedPackage: RnOrganizationPackagePoolsForListVM) {
    if (this.PackagePoolSelected) {
      this.PackagePoolSelected.emit(selectedPackage);
    }
  }

  private async navigateToPurchase(): Promise<void> {
    const queryParams = {
      packageState: "purchase",
    };
    await this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: "merge",
    });
  }

  private canPurchaseNeedsPOF(): boolean {
    if (!this.OrgProfile) {
      return false;
    }
    const canPurchaseRight = this.loggedInInfoService.loggedInUserHasRights([
      "PRCHDROPPK",
    ]);
    const canUpdateBilling = this.loggedInInfoService.loggedInUserHasRights([
      "UPDTBILING",
    ]);
    const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    if (!loggedInUser) {
      return false;
    }

    const isInternal = loggedInUser.IsInternal();
    const loggedInUserOrg = this.loggedInInfoService.GetLoggedInUserOrg();
    return this.orgLogicService.CanPurchasePackagesNeedPOF(
      canPurchaseRight,
      canUpdateBilling,
      isInternal,
      this.OrgProfile,
      this.OrgConfig,
      loggedInUserOrg,
      loggedInUser.UserType,
    );
  }

  private packageList: Array<RnOrganizationPackagePoolsForListVM>;
  private orgProfile: RnOrganizationsProfileVM;
  private pkgConfigInfo: PackageConfigInfo;
  private orgWlpContactInfo: RnOrganizationWLPContactInformationVM;

  private categorizePackagesForUI(): void {
    if (this.packageList) {
      this.WlpPackages = this.packageList
        .filter(
          (p) =>
            p.PackageCategory !== "Add-Ons" && !p.IsAddOn && !p.IsPurchasable,
        )
        .sort((a, b) => {
          return a.PackageName.localeCompare(b.PackageName);
        });
      this.WlpAddOns = this.packageList
        .filter((p) => p.IsAddOn && !p.IsPurchasable)
        .sort((a, b) => {
          return a.PackageName.localeCompare(b.PackageName);
        });
      this.RnPackages = this.packageList
        .filter(
          (p) =>
            p.PackageCategory !== "Add-Ons" && !p.IsAddOn && p.IsPurchasable,
        )
        .sort((a, b) => {
          return a.PackageName.localeCompare(b.PackageName);
        });
      this.RnAddOns = this.packageList
        .filter(
          (p) =>
            (p.PackageCategory === "Add-Ons" || p.IsAddOn) && p.IsPurchasable,
        )
        .sort((a, b) => {
          return a.PackageName.localeCompare(b.PackageName);
        });
      this.Loading = false;
    }
  }
}
