<div class="full-container-padding-side-15">
  <div class="flex-row m-t-md" *ngIf="IsUsingWelcomeWizard">
    <div class="col-sm-12 addUsersHeadingDivision">
      <h2 class="addUsersHeading">Add Users & Assign Packages</h2>
    </div>
    <span class="addUserText">
      It's time to add <b>Users</b> to your account. You can add as many users
      as you'd like and assign them to the appropriate roles within your
      organization, but only the users you assign a <b>Package</b> to will be
      able to access the remote desktop environment. Additional user changes,
      including adding or deleting users, can be made within the Admin Console.
    </span>
  </div>
  <div class="flex buttonRow">
    @if (!appHubProfileRemoveFeatureEnabled) {
      <span class="usersHeading">Users</span>
      <span class="pull-right addNewUserButton">
        <app-button
          id="btnCreateNewUser"
          label="ADD USER"
          showIcon="true"
          imgSource="rn-user-plus"
          *ngIf="isAddUserVisible"
          [disabled]="
            orgConfig
              | rnOrganizationConfig
                : 'initiatedbillingseparation'
                : workInProgress
          "
          [pTooltip]="
            orgConfig | rnOrganizationConfig: 'billingseparationtooltip'
          "
          [escape]="false"
          buttonStyle="primaryUserModule"
          noContentPadding="true"
          tooltipStyleClass="wideTooltipSm"
          (clickActionEmitter)="createNewUser()"
        >
        </app-button>
      </span>
      <span class="pull-right">
        <app-button
          id="btnGenerateReport"
          class="lmargin-auto"
          label="GENERATE REPORT"
          showIcon="true"
          imgSource="rn-file-text"
          *ngIf="orgProfile | rnUserMgmt: 'cangeneratereport'"
          buttonStyle="primaryUserModule"
          (clickActionEmitter)="generateReport()"
          showProcessing="true"
          noContentPadding="true"
          [processing]="generatingReport"
        >
        </app-button>
      </span>
      <span class="pull-right">
        <app-button
          id="btnSendPasswordReset"
          label="Reset Password(s)"
          imgSource="rn-lock-outline"
          *ngIf="IsUsingWelcomeWizard | rnUserMgmt: 'isresetpasswordvisible'"
          [disabled]="
            !(selectedUsers?.length | rnQuantityChange: 'hasquantity')
          "
          buttonStyle="primaryUserModule"
          noContentPadding="true"
          showIcon="true"
          (clickActionEmitter)="sendPasswordResetForSelectedUsers()"
          showProcessing="true"
          [processing]="sendingResetEmails"
        >
        </app-button>
      </span>
      <span
        pTooltip="{{
          selectedUsers?.length
            | rnUserMgmt: 'resetactivationtooltip' : selectedUsers
        }}"
        class="pull-right"
        tooltipStyleClass="wideTooltipMed"
        [escape]="false"
      >
        <app-button
          id="ResendActivationBtn"
          label="Resend Activation Email(s)"
          imgSource="rn-mail"
          *ngIf="
            IsUsingWelcomeWizard | rnUserMgmt: 'isresendwelcomeemailvisible'
          "
          [disabled]="
            selectedUsers?.length
              | rnUserMgmt: 'isresendwelcomeemaildisabled' : selectedUsers
          "
          buttonStyle="primaryUserModule"
          (clickActionEmitter)="sendWelcomeEmailToSelectedUsers()"
          showProcessing="true"
          showIcon="true"
          noContentPadding="true"
          [processing]="resendingActivationEmails"
        >
        </app-button>
      </span>
      <span class="pull-right">
        <app-button
          id="btnUnlockUsers"
          label="Unlock Account(s)"
          *ngIf="IsUsingWelcomeWizard | rnUserMgmt: 'isunlockaccountvisible'"
          [disabled]="
            !(selectedUsers?.length | rnQuantityChange: 'hasquantity')
          "
          buttonStyle="primaryUserModule"
          noContentPadding="true"
          (clickActionEmitter)="unlockUsers()"
        >
        </app-button>
      </span>
      <span class="pull-right">
        <button
          id="deleteUser"
          *ngIf="IsUsingWelcomeWizard | rnUserMgmt: 'isdeleteuservisible'"
          data-testid="btnRemoveLicenses"
          [disabled]="
            selectedUsers?.length
              | rnUserMgmt: 'isdeleteuserdisabled' : selectedUsers
          "
          class="button-spaced btn-icon"
          (click)="deleteSelectedUsers()"
        >
          <app-icon source="rn-trash-2" [imgClass]="'deleteIcon'"></app-icon>
          &nbsp;DELETE
        </button>
      </span>
    } @else {
      <div class="pull-right">
        @if (isAddUserVisible) {
          <div class="pull-right user-management-button">
            <div
              [pTooltip]="addUserToolTip()"
              tooltipPosition="left"
              tooltipStyleClass="wideTooltipSm"
            >
              <rw-button
                text="ADD USER"
                arialabel="add user"
                datapendoid="manage-users-add-user"
                datatestid="manage-users-add-user"
                buttonclass="rw-btn-secondary"
                extraclass="user-management-button"
                iconclass="fa-solid fa-plus"
                [iconrightside]="true"
                [disable]="orgConfig?.InitiatedBillingSeparation"
                (clickButton)="createNewUser()"
              ></rw-button>
            </div>
          </div>
        }

        @if (canGenerateReport()) {
          <div class="pull-right user-management-button">
            <rw-button
              text="GENERATE REPORT"
              arialabel="generate report"
              datapendoid="manage-users-generate-report"
              datatestid="manage-users-generate-report"
              buttonclass="rw-btn-tertiary"
              extraclass="user-management-button"
              (clickButton)="generateReport()"
            ></rw-button>
          </div>
        }
        <div class="pull-right user-management-button">
          <div>
            <mat-menu
              #bulkMenu="matMenu"
              yPosition="below"
              xPosition="before"
              class="custom-menu"
            >
              <div>
                <app-button
                  id="manage-users-bulk-send-password-reset-emails"
                  data-testid="manage-users-bulk-send-password-reset-emails"
                  data-pendo-id="manage-users-bulk-send-password-reset-emails"
                  buttonStyle="mat-menu-item"
                  (clickActionEmitter)="bulkSendPasswordResetEmails()"
                  label="Send Password Reset Emails"
                  [disabled]="!isBulkSendPasswordResetEnabled()"
                ></app-button>
              </div>
              <div>
                <app-button
                  id="manage-users-bulk-send-activation-emails"
                  data-testid="manage-users-bulk-send-activation-emails"
                  data-pendo-id="manage-users-bulk-send-activation-emails"
                  buttonStyle="mat-menu-item"
                  (clickActionEmitter)="bulkReSendActivationEmails()"
                  label="Resend Activation Emails"
                  [disabled]="!isBulkResendActivationEmailEnabled()"
                ></app-button>
              </div>
              <div>
                <app-button
                  id="manage-users-bulk-delete-users"
                  data-testid="manage-users-bulk-delete-users"
                  data-pendo-id="manage-users-bulk-delete-users"
                  buttonStyle="mat-menu-item"
                  (clickActionEmitter)="bulkDeleteUsers()"
                  label="Delete Users"
                  [disabled]="!isBulkDeleteUsersEnabled()"
                ></app-button>
              </div>
            </mat-menu>
            <rw-button
              text="BULK ACTIONS"
              arialabel="bulk user actions"
              datapendoid="manage-users-bulk-actions-menu-button"
              datatestid="manage-users-bulk-actions-menu-button"
              buttonclass="rw-btn-tertiary"
              extraclass="manage-users-bulk-actions-menu-button"
              iconclass="fa-solid fa-caret-down"
              iconrightside="true"
              [matMenuTriggerFor]="
                isBulkActionsButtonEnabled() ? bulkMenu : null
              "
              [disable]="!isBulkActionsButtonEnabled()"
              [spin]="bulkActionSpinner"
            ></rw-button>
          </div>
        </div>
      </div>
    }
  </div>
  <div class="flex searchBox">
    <app-search-control
      #userSearchControl
      id="userSearchControl"
      class="col-xs-12 col-sm-12 col-md-6 col-lg-6"
      [Configuration]="searchControlConfiguration"
      (SearchOccurred)="searchOccurred($event)"
      [clearEvent]="clearSearch.asObservable()"
      [searchText]="searchText"
    ></app-search-control>
  </div>
  <div class="flex usersTable">
    <rn-grid
      class="flex-fill"
      [Configuration]="userListGridConfiguration"
      [UniqueId]="'UserID'"
      (rowSelected)="rowSelected($event)"
      (userRowClicked)="navigateToUser($event)"
      (falseyValueClicked)="copyActivationLinkClicked($event)"
      (actionButtonClicked)="actionClickHandler($event)"
      [clearSelected]="clearSelection.asObservable()"
    ></rn-grid>
  </div>
  <rw-modal
    (initModal)="modalService.add($event)"
    (destroyModal)="modalService.remove($event)"
    id="{{ userProfileModalId }}"
  >
    <div *rwModalHeaderContent class="modal-header justify-content-between">
      <h3 class="modal-title">Edit User Details</h3>
      <rw-button
        buttonclass="btn-close"
        arialabel="close add client dialog"
        datapendoid="{{ userProfileModalId }}-close"
        datatestid="{{ userProfileModalId }}-close"
        (clickButton)="this.closeUserDetailsModal()"
        [disable]="isSavingUserDetails"
      >
      </rw-button>
    </div>
    <div *rwModalBodyContent>
      <app-user-details-modal
        #userDetailsModal
        [modalId]="userProfileModalId"
        class="user-detail-info-panel"
        (savingEvent)="savingUserDetails($event)"
      ></app-user-details-modal>
    </div>
  </rw-modal>
</div>
