<div class="row medium titleDivision">
  <div class="col-md-12">
    <h2 class="pageTitle" data-testid="pageTitle">
      Packages and Add-ons Inventory
    </h2>
    <h6 class="packages-subtext" data-testid="pageTitleSubTxt">
      This is a view of your assigned and remaining packages and add-ons.
    </h6>
  </div>
</div>
<div *ngIf="canShowChangeInventory()" class="medium changePackageButton">
  <div class="float-right">
    <app-button
      id="btnChangePackagesandAddOns"
      buttonStyle="changePackage"
      noContentPadding="true"
      label="CHANGE PACKAGES AND ADD-ONS INVENTORY"
      (clickActionEmitter)="changeInventory()"
    >
    </app-button>
  </div>
</div>
<app-wlp-inventory-table
  *ngIf="showWLPPackages()"
  [WLPName]="OrgProfile?.PartnerName"
  [WlpPackageText]=""
  [PkgConfigInfo]="PkgConfigInfo"
  [WLPPackages]="WlpPackages"
  [WLPAddOns]="WlpAddOns"
  [OrgWlpContactInfo]="OrgWlpContactInfo"
  [OrgProfile]="OrgProfile"
  [Loading]="Loading"
  (PackagePoolSelected)="packageSelected($event)"
>
</app-wlp-inventory-table>

<app-direct-inventory-table
  data-testid="pkgTable"
  *ngIf="showDirectPackages()"
  [Heading]="directPackagesHeading"
  [QuantityColumnHeading]="'Seats'"
  [Subtext]="directPackagesSubtext"
  [PackageList]="RnPackages"
  [Loading]="Loading"
  [OrgProfile]="OrgProfile"
  [PkgConfigInfo]="PkgConfigInfo"
  (PackagePoolSelected)="packageSelected($event)"
>
</app-direct-inventory-table>

<app-direct-inventory-table
  data-testid="addonsTable"
  *ngIf="showDirectAddons()"
  [IsAddOns]="isAddonsFlag"
  [Heading]="directAddonsHeading"
  [QuantityColumnHeading]="'Quantity'"
  [Subtext]="directAddonsSubtext"
  [PackageList]="RnAddOns"
  [Loading]="Loading"
  [OrgProfile]="OrgProfile"
  [PkgConfigInfo]="PkgConfigInfo"
  (PackagePoolSelected)="packageSelected($event)"
>
</app-direct-inventory-table>
