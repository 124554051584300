<ng-container [formGroup]="parentForm">
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="p-field"
          [ngClass]="{
            'has-error': !bankName?.valid && (submitted || bankName?.touched)
          }"
        >
          <label for="bankName">Bank Name</label><br />
          <input
            appTrimInput
            id="bankName"
            name="bankName"
            formControlName="bankName"
            type="text"
            pInputText
            class="form-control"
            (focus)="$event.target.select()"
            data-testid="billinginfobankname"
          />
          <div *ngIf="submitted || bankName?.touched">
            <div
              class="help-block"
              *ngIf="bankName.errors?.required || bankName.errors?.whitespace"
            >
              Required field
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div
          class="p-field"
          [ngClass]="{
            'has-error':
              !bankAccountType?.valid &&
              (submitted || bankAccountType?.touched || bankAccountType?.dirty)
          }"
        >
          <label for="bankAccountType">Bank Account Type</label><br />
          <p-dropdown
            id="bankAccountType"
            name="bankAccountType"
            formControlName="bankAccountType"
            [options]="accountTypes"
            class="width-100-percent"
          ></p-dropdown>
          <div
            *ngIf="
              submitted || bankAccountType?.touched || bankAccountType?.dirty
            "
          >
            <div
              class="help-block"
              *ngIf="
                bankAccountType.errors?.required ||
                bankAccountType.errors?.whitespace
              "
            >
              Required field
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div
          class="p-field"
          [ngClass]="{
            'has-error':
              !bankAccountHolderName?.valid &&
              (submitted || bankAccountHolderName?.touched)
          }"
        >
          <label for="bankAccountHolderName">Bank Account Holder Name</label
          ><br />
          <input
            appTrimInput
            id="bankAccountHolderName"
            name="bankAccountHolderName"
            formControlName="bankAccountHolderName"
            type="text"
            pInputText
            class="form-control"
            (focus)="$event.target.select()"
            maxlength="70"
            data-testid="billinginfoaccountholdername"
          />
          <div *ngIf="submitted || bankAccountHolderName?.touched">
            <div
              class="help-block"
              *ngIf="
                bankAccountHolderName.errors?.required ||
                bankAccountHolderName.errors?.whitespace
              "
            >
              Required field
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div
          class="p-field"
          [ngClass]="{
            'has-error':
              (invalidRoutingNumber || !bankAccountRoutingNumber?.valid) &&
              (submitted || bankAccountRoutingNumber?.touched)
          }"
        >
          <label for="bankAccountRoutingNumberLabel"
            >Bank Account Routing Number</label
          ><br />
          <input
            id="bankAccountRoutingNumber"
            name="bankAccountRoutingNumber"
            formControlName="bankAccountRoutingNumber"
            pInputText
            maxlength="9"
            (blur)="onChangeBankRoutingNumber()"
            (keyup)="onChangeBankRoutingNumber()"
            (focus)="$event.target.select()"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            class="form-control"
            data-testid="billinginforoutingnumber"
          />
          <div *ngIf="submitted || bankAccountRoutingNumber?.touched">
            <div
              class="help-block"
              *ngIf="
                bankAccountRoutingNumber.errors?.required ||
                bankAccountRoutingNumber.errors?.whitespace
              "
            >
              Required field
            </div>
            <div
              class="help-block"
              *ngIf="
                !bankAccountRoutingNumber.errors?.required &&
                invalidRoutingNumber
              "
            >
              Must be 9 digits
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div
          class="p-field"
          [ngClass]="{
            'has-error':
              !bankAccountNumber?.valid &&
              (submitted || bankAccountNumber?.touched)
          }"
        >
          <label for="bankAccountNumberLabel">Bank Account Number</label><br />
          <input
            id="bankAccountNumber"
            name="bankAccountNumber"
            formControlName="bankAccountNumber"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            pInputText
            class="form-control"
            (focus)="$event.target.select()"
            data-testid="billinginfoaccountnumber"
          />
          <div *ngIf="submitted || bankAccountNumber?.touched">
            <div
              class="help-block"
              *ngIf="
                bankAccountNumber.errors?.required ||
                bankAccountNumber.errors?.whitespace
              "
            >
              Required field
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
