import { Injectable } from "@angular/core";
import { DynamicDialogRef } from "primeng/dynamicdialog";

@Injectable({
  providedIn: "root",
})
export class DialogTrackingService {
  public addDialog(dialog: DynamicDialogRef): void {
    this.openDialogs.push(dialog);
  }

  public closeDialog(dialog: DynamicDialogRef, dialogResult?: any): void {
    const deleteIdx = this.openDialogs.indexOf(dialog);
    this.openDialogs.splice(deleteIdx, 1);
    dialog.close(dialogResult);
  }

  public closeAll(): void {
    while (this.openDialogs.length > 0) {
      const dialog = this.openDialogs[this.openDialogs.length - 1];
      this.closeDialog(dialog);
    }
  }

  openDialogs: DynamicDialogRef[] = [];
}
