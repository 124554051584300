import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class IdService {
  static makeID() {
    const p = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    return Array.from(
      { length: 32 },
      (_) => p[Math.floor(Math.random() * p.length)],
    ).join("");
  }
}
