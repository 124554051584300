import { Component, OnDestroy, OnInit } from "@angular/core";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/dynamicdialog";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import {
  RnOrganizationsProfileVM,
  RnUsersVM,
} from "../../../services/rnapi2-service/models/models";
import { DialogTrackingService } from "../../../services/dialog/dialog-tracking.service";
import { LoggedInInfoService } from "../../../services/loggedInInfo/logged-in-info.service";
import { OrganizationLogicService } from "../../../services/organization-logic/organization-logic.service";
import { SelectionService } from "../../../services/selection/selection.service";

@Component({
  selector: "app-user-keep-drop-package-dialog",
  templateUrl: "./user-keep-drop-package-dialog.component.html",
  styleUrls: ["./user-keep-drop-package-dialog.component.scss"],
})
export class UserKeepDropPackageDialogComponent implements OnInit, OnDestroy {
  oldPackageActionForm: FormGroup;
  plural = false;
  multiPackages = false;
  users: RnUsersVM[];
  usersText: string;
  packageToString = "";
  isChangePackage: boolean;
  showDeleteUserText: boolean;
  isPurchasablePackage;
  releaseDisabled: string = null;
  setManually = false;
  isWlp = false;
  public OrgProfile: RnOrganizationsProfileVM;
  private subscriptions = [];

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private dialogTrackService: DialogTrackingService,
    private orgLogicService: OrganizationLogicService,
    private selectionService: SelectionService,
    private loggedinInfoService: LoggedInInfoService,
  ) {
    this.oldPackageActionForm = this.formBuilder.group({
      keepOldPackage: ["", Validators.required.bind(Validators)],
    });

    this.isChangePackage =
      this.config.data?.mode === "changePackage" ? true : false;
    this.showDeleteUserText = this.config.data?.showDeleteUserText;
    this.isPurchasablePackage = this.config.data?.isPurchasable;

    this.users = this.config.data.users;
    this.plural = this.users.length > 1;
    if (this.plural) {
      this.usersText = this.users.length.toString();
    } else {
      this.usersText = this.users[0].FullName;
    }
    const packageTypes = this.config.data.packageTypes;
    Object.keys(packageTypes)
      .sort()
      .forEach((p, i) => {
        this.packageToString = this.packageToString.trim();
        if (this.packageToString != "") {
          this.packageToString += ", ";
        }
        this.packageToString = this.packageToString + p;
        if (packageTypes[p].length > 1) {
          const packageLength: string = packageTypes[p].length.toString();
          this.packageToString += " (" + packageLength + ") ";
        }
      });
    this.multiPackages = this.config.data.totalPackages > 1;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.selectionService.SelectedOrgProfile.subscribe((p) => {
        if (p?.ID !== undefined) {
          this.OrgProfile = p;
          const canPurchaseRight =
            this.loggedinInfoService.loggedInUserHasRights(["PRCHDROPPK"]);
          const loggedInUser = this.loggedinInfoService.GetLoggedInUser();
          const isInternal = loggedInUser.IsInternal();
          const loggedInUserOrg = this.loggedinInfoService.GetLoggedInUserOrg();
          const result = this.orgLogicService.CanPurchaseDropPackages(
            canPurchaseRight,
            isInternal,
            this.OrgProfile,
            loggedInUserOrg,
          );
          if (!result) {
            this.f.keepOldPackage.setValue("true");
            this.releaseDisabled = "disabled";
            this.setManually = true;
          }
        }
      }),
    );
    this.isWlp = this.isPurchasablePackage === "Unpurchasable";
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  cancel(): void {
    this.dialogTrackService.closeDialog(this.ref);
  }

  close(): void {
    this.dialogTrackService.closeDialog(this.ref, this.f.keepOldPackage.value);
  }

  get f() {
    return this.oldPackageActionForm.controls;
  }
}
