import * as ld from "launchdarkly-js-client-sdk";

/**
 * Wrapper function for testabilty as LD's initialize is read-only
 * @param envKey launch darkly environment key
 * @param context user context
 * @returns LDClient
 */

export class ldWrapper {
  static initializeLaunchDarkly(
    envKey: string,
    context: ld.LDContext,
  ): ld.LDClient {
    return ld.initialize(envKey, context);
  }
}
