/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export class RnLicenseBaseVM {
  PackageId?: number;
  PackageName?: string | null;
  FlavorId?: number;
  ItemFlavorId?: number;
  Version?: string | null;
  Country?: string | null;
  Edition?: string | null;
  Flavor?: string | null;
  Type?: string | null;
  TypeId?: number;
  Price?: number;
  LicenseTypeId?: number;
  LicenseTypeName?: string | null;
  LicenseEditTypeID?: number;
  LicenseEditTypeName?: string | null;
  ItemId?: number;
  PackageTypeName?: string | null;
  TypeDesc?: string | null;
}
