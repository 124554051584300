import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "rnCopyActivation",
})
export class RnCopyActivationPipe implements PipeTransform {
  transform(state: string, ...args: unknown[]): unknown {
    if (args && args.length > 0) {
      const param = args[0];
      switch (param) {
        case "isCopiedButtonVisible":
          return state === "copied";
          break;
        case "disableActivationLink":
        case "disableCopyButton":
          return state === "loading";
          break;
      }
    }

    return null;
  }
}
