/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
} from "@angular/common/http";
import { CustomHttpParameterCodec } from "../encoder";
import { Observable } from "rxjs";

import {
  RnBooleanRNResponseRelay,
  RnPackagesAndAddonsInventoryVM,
  RnQBOInfoVM,
} from "../models/models";
import { RnCommonId } from "../models/models";
import { RnCommonSearch } from "../models/models";
import { RnDetailedBillingReportVMListRNResponseRelay } from "../models/models";
import { RnEstimatedBillVMRNResponseRelay } from "../models/models";
import { RnEventsVMSearchResultsVMRNResponseRelay } from "../models/models";
import { RnFolderSearch } from "../models/models";
import { RnHCOrgAndUserID } from "../models/models";
import { RnLicenseFullVMSearchResultsVMRNResponseRelay } from "../models/models";
import { RnLicensesWithUsersVMListRNResponseRelay } from "../models/models";
import { RnObjectRNResponseRelay } from "../models/models";
import { RnOrganizationAddonOrderCatalogVMRNResponseRelay } from "../models/models";
import { RnOrganizationBillingInformationCreate } from "../models/models";
import { RnOrganizationClose } from "../models/models";
import { RnOrganizationConfigurationVMRNResponseRelay } from "../models/models";
import { RnOrganizationContainerCreate } from "../models/models";
import { RnOrganizationContainerDelete } from "../models/models";
import { RnOrganizationConvertToFoldersVMRNResponseRelay } from "../models/models";
import { RnOrganizationCreateUpdateXid } from "../models/models";
import { RnOrganizationFolderRename } from "../models/models";
import { RnOrganizationFoldersVMRNResponseRelay } from "../models/models";
import { RnOrganizationLicensesSearch } from "../models/models";
import { RnOrganizationPackagePoolsForListVMRNResponseRelay } from "../models/models";
import { RnOrganizationPackagePoolsForListVMSearchResultsVMRNResponseRelay } from "../models/models";
import { RnOrganizationPaymentOnFileVMRNResponseRelay } from "../models/models";
import { RnOrganizationPromoCodeVMSearchResultsVMRNResponseRelay } from "../models/models";
import { RnOrganizationRename } from "../models/models";
import { RnOrganizationStatesVMRNResponseRelay } from "../models/models";
import { RnOrganizationTreeNodeListRNResponseRelay } from "../models/models";
import { RnOrganizationUpdateAccountOwner } from "../models/models";
import { RnOrganizationUpdateBusinessAddress } from "../models/models";
import { RnOrganizationUsersForLicenseSearch } from "../models/models";
import { RnOrganizationWLPContactInformationVMRNResponseRelay } from "../models/models";
import { RnOrganizationsProfileVMRNResponseRelay } from "../models/models";
import { RnOrganizationsVMRNResponseRelay } from "../models/models";
import { RnOrganizationsVMSearchResultsVMRNResponseRelay } from "../models/models";
import { RnPackageSelectionInfoVMSearchResultsVMRNResponseRelay } from "../models/models";
import { RnPackagesAndAddonsInventoryVMRNResponseRelay } from "../models/models";
import { RnQbesOrderSearch } from "../models/models";
import { RnStringRNResponseRelay } from "../models/models";
import { RnStringStringDictionaryRNResponseRelay } from "../models/models";
import { RnSubOrganizationCreate } from "../models/models";
import { RnUserPackageForListVMSearchResultsVMRNResponseRelay } from "../models/models";
import { RnUserWithMutualExclusionForAssignPackageVMSearchResultsVMRNResponseRelay } from "../models/models";
import { RnUsersForOrgSubOrgSearchVMSearchResultsVMRNResponseRelay } from "../models/models";
import { RnUsersVMSearchResultsVMRNResponseRelay } from "../models/models";
import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";
import { RnCatalogItemVM } from "../models/catalogItemVM-d";
import { OrganizationCatalogInfoVM } from "../models/OrganizationCatalogInfoVM";
import { RnMappedUserSearchVMSearchResultsVMRNResponseRelay } from "../models/mappedUserSearchVMSearchResultsVMRNResponseRelay-d";

import { RnUnmappedUsersSearchSort } from "../models/unmappedUsersSearchSort-d";
import { RnGrantAccessSearchResultVMSearchResultsVMRNResponseRelay } from "../models/grantAccessSearchResultVMSearchResultsVMRNResponseRelay-d";

@Injectable({
  providedIn: "root",
})
export class OrganizationService {
  protected basePath = "http://localhost";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== "string") {
      if (typeof basePath !== "string") {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string,
  ): HttpParams {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string,
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)),
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            value.toISOString().substr(0, 10),
          );
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k,
            )),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }

  /**
   * ( Gets available licenses for an organization )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; VIEWLICNCS
   * @param rnOrganizationLicensesSearch contains the information necessary to retrive the licenses
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsAvailablelicensesPost(
    rnOrganizationLicensesSearch?: RnOrganizationLicensesSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnLicenseFullVMSearchResultsVMRNResponseRelay>;
  public apiV2OrganizationsAvailablelicensesPost(
    rnOrganizationLicensesSearch?: RnOrganizationLicensesSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnLicenseFullVMSearchResultsVMRNResponseRelay>>;
  public apiV2OrganizationsAvailablelicensesPost(
    rnOrganizationLicensesSearch?: RnOrganizationLicensesSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnLicenseFullVMSearchResultsVMRNResponseRelay>>;
  public apiV2OrganizationsAvailablelicensesPost(
    rnOrganizationLicensesSearch?: RnOrganizationLicensesSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnLicenseFullVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/availablelicenses`,
      rnOrganizationLicensesSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Calculates the next bill balance
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param organizationID
   * @param promoCodeID
   * @param eventLogInfoUserName
   * @param eventLogInfoAppSource
   * @param eventLogInfoUserID
   * @param eventLogInfoEmail
   * @param eventLogInfoCreatedByUserID
   * @param affectedOrganizationId
   * @param affectedUserId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsCalculateOrgBalanceNextDuePost(
    organizationID?: number,
    promoCodeID?: number,
    eventLogInfoUserName?: string,
    eventLogInfoAppSource?: string,
    eventLogInfoUserID?: string,
    eventLogInfoEmail?: string,
    eventLogInfoCreatedByUserID?: number,
    affectedOrganizationId?: number,
    affectedUserId?: number,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnStringRNResponseRelay>;
  public apiV2OrganizationsCalculateOrgBalanceNextDuePost(
    organizationID?: number,
    promoCodeID?: number,
    eventLogInfoUserName?: string,
    eventLogInfoAppSource?: string,
    eventLogInfoUserID?: string,
    eventLogInfoEmail?: string,
    eventLogInfoCreatedByUserID?: number,
    affectedOrganizationId?: number,
    affectedUserId?: number,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnStringRNResponseRelay>>;
  public apiV2OrganizationsCalculateOrgBalanceNextDuePost(
    organizationID?: number,
    promoCodeID?: number,
    eventLogInfoUserName?: string,
    eventLogInfoAppSource?: string,
    eventLogInfoUserID?: string,
    eventLogInfoEmail?: string,
    eventLogInfoCreatedByUserID?: number,
    affectedOrganizationId?: number,
    affectedUserId?: number,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnStringRNResponseRelay>>;
  public apiV2OrganizationsCalculateOrgBalanceNextDuePost(
    organizationID?: number,
    promoCodeID?: number,
    eventLogInfoUserName?: string,
    eventLogInfoAppSource?: string,
    eventLogInfoUserID?: string,
    eventLogInfoEmail?: string,
    eventLogInfoCreatedByUserID?: number,
    affectedOrganizationId?: number,
    affectedUserId?: number,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (organizationID !== undefined && organizationID !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>organizationID,
        "OrganizationID",
      );
    }
    if (promoCodeID !== undefined && promoCodeID !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>promoCodeID,
        "PromoCodeID",
      );
    }
    if (eventLogInfoUserName !== undefined && eventLogInfoUserName !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>eventLogInfoUserName,
        "EventLogInfo.UserName",
      );
    }
    if (eventLogInfoAppSource !== undefined && eventLogInfoAppSource !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>eventLogInfoAppSource,
        "EventLogInfo.AppSource",
      );
    }
    if (eventLogInfoUserID !== undefined && eventLogInfoUserID !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>eventLogInfoUserID,
        "EventLogInfo.UserID",
      );
    }
    if (eventLogInfoEmail !== undefined && eventLogInfoEmail !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>eventLogInfoEmail,
        "EventLogInfo.Email",
      );
    }
    if (
      eventLogInfoCreatedByUserID !== undefined &&
      eventLogInfoCreatedByUserID !== null
    ) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>eventLogInfoCreatedByUserID,
        "EventLogInfo.CreatedByUserID",
      );
    }
    if (
      affectedOrganizationId !== undefined &&
      affectedOrganizationId !== null
    ) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>affectedOrganizationId,
        "AffectedOrganizationId",
      );
    }
    if (affectedUserId !== undefined && affectedUserId !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>affectedUserId,
        "AffectedUserId",
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnStringRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/calculateOrgBalanceNextDue`,
      null,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Convert an organization to a folder
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnOrganizationClose Contains the id of the org that will be converted to a folder.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsConvertorgtofolderPost(
    rnOrganizationClose?: RnOrganizationClose,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2OrganizationsConvertorgtofolderPost(
    rnOrganizationClose?: RnOrganizationClose,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsConvertorgtofolderPost(
    rnOrganizationClose?: RnOrganizationClose,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsConvertorgtofolderPost(
    rnOrganizationClose?: RnOrganizationClose,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/convertorgtofolder`,
      rnOrganizationClose,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Creates a new Folder on an Organization
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnOrganizationContainerCreate Contains information for creating the new folder
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsCreateorgcontainerPost(
    rnOrganizationContainerCreate?: RnOrganizationContainerCreate,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2OrganizationsCreateorgcontainerPost(
    rnOrganizationContainerCreate?: RnOrganizationContainerCreate,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsCreateorgcontainerPost(
    rnOrganizationContainerCreate?: RnOrganizationContainerCreate,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsCreateorgcontainerPost(
    rnOrganizationContainerCreate?: RnOrganizationContainerCreate,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/createorgcontainer`,
      rnOrganizationContainerCreate,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( CreateOrUpdateConfiguration )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param organizationID
   * @param customFieldName
   * @param customFieldValues
   * @param eventLogInfoUserName
   * @param eventLogInfoAppSource
   * @param eventLogInfoUserID
   * @param eventLogInfoEmail
   * @param eventLogInfoCreatedByUserID
   * @param affectedOrganizationId
   * @param affectedUserId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsCreateorupdateorgconfigurationPost(
    organizationID?: number,
    customFieldName?: string,
    customFieldValues?: Array<string>,
    eventLogInfoUserName?: string,
    eventLogInfoAppSource?: string,
    eventLogInfoUserID?: string,
    eventLogInfoEmail?: string,
    eventLogInfoCreatedByUserID?: number,
    affectedOrganizationId?: number,
    affectedUserId?: number,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2OrganizationsCreateorupdateorgconfigurationPost(
    organizationID?: number,
    customFieldName?: string,
    customFieldValues?: Array<string>,
    eventLogInfoUserName?: string,
    eventLogInfoAppSource?: string,
    eventLogInfoUserID?: string,
    eventLogInfoEmail?: string,
    eventLogInfoCreatedByUserID?: number,
    affectedOrganizationId?: number,
    affectedUserId?: number,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsCreateorupdateorgconfigurationPost(
    organizationID?: number,
    customFieldName?: string,
    customFieldValues?: Array<string>,
    eventLogInfoUserName?: string,
    eventLogInfoAppSource?: string,
    eventLogInfoUserID?: string,
    eventLogInfoEmail?: string,
    eventLogInfoCreatedByUserID?: number,
    affectedOrganizationId?: number,
    affectedUserId?: number,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsCreateorupdateorgconfigurationPost(
    organizationID?: number,
    customFieldName?: string,
    customFieldValues?: Array<string>,
    eventLogInfoUserName?: string,
    eventLogInfoAppSource?: string,
    eventLogInfoUserID?: string,
    eventLogInfoEmail?: string,
    eventLogInfoCreatedByUserID?: number,
    affectedOrganizationId?: number,
    affectedUserId?: number,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (organizationID !== undefined && organizationID !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>organizationID,
        "OrganizationID",
      );
    }
    if (customFieldName !== undefined && customFieldName !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>customFieldName,
        "CustomFieldName",
      );
    }
    if (customFieldValues) {
      customFieldValues.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          "CustomFieldValues",
        );
      });
    }
    if (eventLogInfoUserName !== undefined && eventLogInfoUserName !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>eventLogInfoUserName,
        "EventLogInfo.UserName",
      );
    }
    if (eventLogInfoAppSource !== undefined && eventLogInfoAppSource !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>eventLogInfoAppSource,
        "EventLogInfo.AppSource",
      );
    }
    if (eventLogInfoUserID !== undefined && eventLogInfoUserID !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>eventLogInfoUserID,
        "EventLogInfo.UserID",
      );
    }
    if (eventLogInfoEmail !== undefined && eventLogInfoEmail !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>eventLogInfoEmail,
        "EventLogInfo.Email",
      );
    }
    if (
      eventLogInfoCreatedByUserID !== undefined &&
      eventLogInfoCreatedByUserID !== null
    ) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>eventLogInfoCreatedByUserID,
        "EventLogInfo.CreatedByUserID",
      );
    }
    if (
      affectedOrganizationId !== undefined &&
      affectedOrganizationId !== null
    ) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>affectedOrganizationId,
        "AffectedOrganizationId",
      );
    }
    if (affectedUserId !== undefined && affectedUserId !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>affectedUserId,
        "AffectedUserId",
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/createorupdateorgconfiguration`,
      null,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Get Current Payment Info )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsCurrentpaymentinfoPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrganizationPaymentOnFileVMRNResponseRelay>;
  public apiV2OrganizationsCurrentpaymentinfoPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnOrganizationPaymentOnFileVMRNResponseRelay>>;
  public apiV2OrganizationsCurrentpaymentinfoPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnOrganizationPaymentOnFileVMRNResponseRelay>>;
  public apiV2OrganizationsCurrentpaymentinfoPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnOrganizationPaymentOnFileVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/currentpaymentinfo`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Delete a Folder of an Organization
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnOrganizationContainerDelete Contains information to delete the org\&#39;s folder
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsDeleteorgcontainerPost(
    rnOrganizationContainerDelete?: RnOrganizationContainerDelete,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2OrganizationsDeleteorgcontainerPost(
    rnOrganizationContainerDelete?: RnOrganizationContainerDelete,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsDeleteorgcontainerPost(
    rnOrganizationContainerDelete?: RnOrganizationContainerDelete,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsDeleteorgcontainerPost(
    rnOrganizationContainerDelete?: RnOrganizationContainerDelete,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/deleteorgcontainer`,
      rnOrganizationContainerDelete,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets events associated with the organization Id in the payload )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonSearch Contains the id of the organization and other general search parameters (page, orderBy, etc)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsEventsPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnEventsVMSearchResultsVMRNResponseRelay>;
  public apiV2OrganizationsEventsPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnEventsVMSearchResultsVMRNResponseRelay>>;
  public apiV2OrganizationsEventsPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnEventsVMSearchResultsVMRNResponseRelay>>;
  public apiV2OrganizationsEventsPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnEventsVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/events`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets organization folder for the given folder id )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsFolderPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrganizationFoldersVMRNResponseRelay>;
  public apiV2OrganizationsFolderPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnOrganizationFoldersVMRNResponseRelay>>;
  public apiV2OrganizationsFolderPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnOrganizationFoldersVMRNResponseRelay>>;
  public apiV2OrganizationsFolderPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnOrganizationFoldersVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/folder`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Rename a Folder of an Organization
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnOrganizationFolderRename Contains information for renaming the org\&#39;s folder
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsFolderRenamePost(
    rnOrganizationFolderRename?: RnOrganizationFolderRename,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2OrganizationsFolderRenamePost(
    rnOrganizationFolderRename?: RnOrganizationFolderRename,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsFolderRenamePost(
    rnOrganizationFolderRename?: RnOrganizationFolderRename,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsFolderRenamePost(
    rnOrganizationFolderRename?: RnOrganizationFolderRename,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/folderRename`,
      rnOrganizationFolderRename,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( GetCurrentDetailedBillingReportInfoListing )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetCurrentDetailedBillingReportInfoListingPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnDetailedBillingReportVMListRNResponseRelay>;
  public apiV2OrganizationsGetCurrentDetailedBillingReportInfoListingPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnDetailedBillingReportVMListRNResponseRelay>>;
  public apiV2OrganizationsGetCurrentDetailedBillingReportInfoListingPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnDetailedBillingReportVMListRNResponseRelay>>;
  public apiV2OrganizationsGetCurrentDetailedBillingReportInfoListingPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnDetailedBillingReportVMListRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getCurrentDetailedBillingReportInfoListing`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( GetDetailedBillingReportContent )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetDetailedBillingReportContentPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<Blob>;
  public apiV2OrganizationsGetDetailedBillingReportContentPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<Blob>>;
  public apiV2OrganizationsGetDetailedBillingReportContentPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<Blob>>;
  public apiV2OrganizationsGetDetailedBillingReportContentPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post(
      `${this.configuration.basePath}/api/v2/organizations/getDetailedBillingReportContent`,
      rnCommonId,
      {
        responseType: "blob",
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonSearch
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetMappedUsersForAccessManagementPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnMappedUserSearchVMSearchResultsVMRNResponseRelay>;
  public apiV2OrganizationsGetMappedUsersForAccessManagementPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpResponse<RnMappedUserSearchVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2OrganizationsGetMappedUsersForAccessManagementPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnMappedUserSearchVMSearchResultsVMRNResponseRelay>>;
  public apiV2OrganizationsGetMappedUsersForAccessManagementPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnMappedUserSearchVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getMappedUsersForAccessManagement`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnUnmappedUsersSearchSort
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetunmappedusersforaccessmanagementwithsortandpagingPost(
    rnUnmappedUsersSearchSort?: RnUnmappedUsersSearchSort,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnGrantAccessSearchResultVMSearchResultsVMRNResponseRelay>;
  public apiV2OrganizationsGetunmappedusersforaccessmanagementwithsortandpagingPost(
    rnUnmappedUsersSearchSort?: RnUnmappedUsersSearchSort,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpResponse<RnGrantAccessSearchResultVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2OrganizationsGetunmappedusersforaccessmanagementwithsortandpagingPost(
    rnUnmappedUsersSearchSort?: RnUnmappedUsersSearchSort,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpEvent<RnGrantAccessSearchResultVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2OrganizationsGetunmappedusersforaccessmanagementwithsortandpagingPost(
    rnUnmappedUsersSearchSort?: RnUnmappedUsersSearchSort,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnGrantAccessSearchResultVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getunmappedusersforaccessmanagementwithsortandpaging`,
      rnUnmappedUsersSearchSort,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnUnmappedUsersSearchSort
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetunmappedusersandorgsforaccessmanagementwithsortandpagingPost(
    rnUnmappedUsersSearchSort?: RnUnmappedUsersSearchSort,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnGrantAccessSearchResultVMSearchResultsVMRNResponseRelay>;
  public apiV2OrganizationsGetunmappedusersandorgsforaccessmanagementwithsortandpagingPost(
    rnUnmappedUsersSearchSort?: RnUnmappedUsersSearchSort,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpResponse<RnGrantAccessSearchResultVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2OrganizationsGetunmappedusersandorgsforaccessmanagementwithsortandpagingPost(
    rnUnmappedUsersSearchSort?: RnUnmappedUsersSearchSort,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpEvent<RnGrantAccessSearchResultVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2OrganizationsGetunmappedusersandorgsforaccessmanagementwithsortandpagingPost(
    rnUnmappedUsersSearchSort?: RnUnmappedUsersSearchSort,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnGrantAccessSearchResultVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getunmappedusersandorgsforaccessmanagementwithsortandpaging`,
      rnUnmappedUsersSearchSort,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  //      /**
  //       * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
  //       * @param rnUnmappedUsersSearchSort
  //       * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
  //       * @param reportProgress flag to report request and response progress.
  //       */
  //      public apiV2OrganizationsGetunmappedusersforaccessmanagementPost(rnUnmappedUsersSearchSort?: RnUnmappedUsersSearchSort, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain'|'application/json'|'text/json'}): Observable<RnGrantAccessSearchResultUnmappedVMListRNResponseRelay>
  //    ;
  //    public apiV2OrganizationsGetunmappedusersforaccessmanagementPost(rnUnmappedUsersSearchSort?: RnUnmappedUsersSearchSort, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain'|'application/json'|'text/json'}): Observable<HttpResponse<RnGrantAccessSearchResultUnmappedVMListRNResponseRelay>>;
  //      public apiV2OrganizationsGetunmappedusersforaccessmanagementPost(rnUnmappedUsersSearchSort?: RnUnmappedUsersSearchSort, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain'|'application/json'|'text/json'}): Observable<HttpEvent<RnGrantAccessSearchResultUnmappedVMListRNResponseRelay>>;
  //      public apiV2OrganizationsGetunmappedusersforaccessmanagementPost(rnUnmappedUsersSearchSort?: RnUnmappedUsersSearchSort, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain'|'application/json'|'text/json'}): Observable<any> {

  //          let headers = this.defaultHeaders;

  //          // authentication (Bearer) required
  //          if (this.configuration.apiKeys) {
  //              const key: string | undefined = this.configuration.apiKeys["Bearer"] || this.configuration.apiKeys["Authorization"];
  //              if (key) {
  //                  headers = headers.set('Authorization', key);
  //              }
  //          }

  //          let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
  //          if (httpHeaderAcceptSelected === undefined) {
  //              // to determine the Accept header
  //              const httpHeaderAccepts: string[] = [
  //                  'text/plain',
  //                  'application/json',
  //                  'text/json'
  //              ];
  //              httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
  //          }
  //          if (httpHeaderAcceptSelected !== undefined) {
  //              headers = headers.set('Accept', httpHeaderAcceptSelected);
  //          }

  //          // to determine the Content-Type header
  //          const consumes: string[] = [
  //              'application/json-patch+json',
  //              'application/json',
  //              'text/json',
  //              'application/*+json'
  //          ];
  //          const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
  //          if (httpContentTypeSelected !== undefined) {
  //              headers = headers.set('Content-Type', httpContentTypeSelected);
  //          }

  //          let responseType: 'text' | 'json' = 'json';
  //          if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
  //              responseType = 'text';
  //          }

  //          return this.httpClient.post<RnGrantAccessSearchResultUnmappedVMListRNResponseRelay>(`${this.configuration.basePath}/api/v2/organizations/getunmappedusersforaccessmanagement`,
  //              rnUnmappedUsersSearchSort,
  //              {
  //                  responseType: <any>responseType,
  //                  withCredentials: this.configuration.withCredentials,
  //                  headers: headers,
  //                  observe: observe,
  //                  reportProgress: reportProgress
  //              }
  //          );
  //      }

  /**
   * ( GetEstimatedBill )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetEstimatedBillPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnEstimatedBillVMRNResponseRelay>;
  public apiV2OrganizationsGetEstimatedBillPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnEstimatedBillVMRNResponseRelay>>;
  public apiV2OrganizationsGetEstimatedBillPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnEstimatedBillVMRNResponseRelay>>;
  public apiV2OrganizationsGetEstimatedBillPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnEstimatedBillVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getEstimatedBill`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( GetOrgLicenseExportByOrgId )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonSearch
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetOrgLicenseExportByOrgIdPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<Blob>;
  public apiV2OrganizationsGetOrgLicenseExportByOrgIdPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<Blob>>;
  public apiV2OrganizationsGetOrgLicenseExportByOrgIdPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<Blob>>;
  public apiV2OrganizationsGetOrgLicenseExportByOrgIdPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post(
      `${this.configuration.basePath}/api/v2/organizations/getOrgLicenseExportByOrgId`,
      rnCommonSearch,
      {
        responseType: "blob",
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( GetOrgLicenseExportByOrgId )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonSearch
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetqboinfoforreportbyorgidPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<Blob>;
  public apiV2OrganizationsGetqboinfoforreportbyorgidPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<Blob>>;
  public apiV2OrganizationsGetqboinfoforreportbyorgidPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<Blob>>;
  public apiV2OrganizationsGetqboinfoforreportbyorgidPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post(
      `${this.configuration.basePath}/api/v2/organizations/getqboinfoforreportbyorgid`,
      rnCommonId,
      {
        responseType: "blob",
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Returns the RN state information of the org
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonId contains the ID of the org
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetOrganizationRNStateNamePost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrganizationStatesVMRNResponseRelay>;
  public apiV2OrganizationsGetOrganizationRNStateNamePost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnOrganizationStatesVMRNResponseRelay>>;
  public apiV2OrganizationsGetOrganizationRNStateNamePost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnOrganizationStatesVMRNResponseRelay>>;
  public apiV2OrganizationsGetOrganizationRNStateNamePost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnOrganizationStatesVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getOrganizationRNStateName`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Returns information for packages selection
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonId contains the ID of the organization to get package info for
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetPackageSelectionInfoPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnPackageSelectionInfoVMSearchResultsVMRNResponseRelay>;
  public apiV2OrganizationsGetPackageSelectionInfoPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpResponse<RnPackageSelectionInfoVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2OrganizationsGetPackageSelectionInfoPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpEvent<RnPackageSelectionInfoVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2OrganizationsGetPackageSelectionInfoPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnPackageSelectionInfoVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/GetPackageSelectionInfo`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets the Addon Catalog for an Addon Order )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param rnHCOrgAndUserID contains the IDs of the User and Organization to retrieve the catalog for
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetaddoncatalogforaddonordersPost(
    rnHCOrgAndUserID?: RnHCOrgAndUserID,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrganizationAddonOrderCatalogVMRNResponseRelay>;
  public apiV2OrganizationsGetaddoncatalogforaddonordersPost(
    rnHCOrgAndUserID?: RnHCOrgAndUserID,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnOrganizationAddonOrderCatalogVMRNResponseRelay>>;
  public apiV2OrganizationsGetaddoncatalogforaddonordersPost(
    rnHCOrgAndUserID?: RnHCOrgAndUserID,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnOrganizationAddonOrderCatalogVMRNResponseRelay>>;
  public apiV2OrganizationsGetaddoncatalogforaddonordersPost(
    rnHCOrgAndUserID?: RnHCOrgAndUserID,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnOrganizationAddonOrderCatalogVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getaddoncatalogforaddonorders`,
      rnHCOrgAndUserID,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetavailablelicensesfororgPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnLicensesWithUsersVMListRNResponseRelay>;
  public apiV2OrganizationsGetavailablelicensesfororgPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnLicensesWithUsersVMListRNResponseRelay>>;
  public apiV2OrganizationsGetavailablelicensesfororgPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnLicensesWithUsersVMListRNResponseRelay>>;
  public apiV2OrganizationsGetavailablelicensesfororgPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnLicensesWithUsersVMListRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getavailablelicensesfororg`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetcatalogitemsfororgPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<OrganizationCatalogInfoVM>;
  public apiV2OrganizationsGetcatalogitemsfororgPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<OrganizationCatalogInfoVM>>;
  public apiV2OrganizationsGetcatalogitemsfororgPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<OrganizationCatalogInfoVM>>;
  public apiV2OrganizationsGetcatalogitemsfororgPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<Array<RnCatalogItemVM>>(
      `${this.configuration.basePath}/api/v2/organizations/getcatalogitemsfororg`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets a configurable value )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param rnCommonSearch contains configurable value to retrieve
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetconfigurablevaluePost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnStringRNResponseRelay>;
  public apiV2OrganizationsGetconfigurablevaluePost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnStringRNResponseRelay>>;
  public apiV2OrganizationsGetconfigurablevaluePost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnStringRNResponseRelay>>;
  public apiV2OrganizationsGetconfigurablevaluePost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnStringRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getconfigurablevalue`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Get info of what will occur when converting an org to a folder
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonId Contains the id of the org that will be converted to a folder
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetitemsfororgconverttofolderPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrganizationConvertToFoldersVMRNResponseRelay>;
  public apiV2OrganizationsGetitemsfororgconverttofolderPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnOrganizationConvertToFoldersVMRNResponseRelay>>;
  public apiV2OrganizationsGetitemsfororgconverttofolderPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnOrganizationConvertToFoldersVMRNResponseRelay>>;
  public apiV2OrganizationsGetitemsfororgconverttofolderPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnOrganizationConvertToFoldersVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getitemsfororgconverttofolder`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Returns licenses for an organization
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonSearch Contains the ID of the organization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetlicensesfororganizationPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnLicenseFullVMSearchResultsVMRNResponseRelay>;
  public apiV2OrganizationsGetlicensesfororganizationPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnLicenseFullVMSearchResultsVMRNResponseRelay>>;
  public apiV2OrganizationsGetlicensesfororganizationPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnLicenseFullVMSearchResultsVMRNResponseRelay>>;
  public apiV2OrganizationsGetlicensesfororganizationPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnLicenseFullVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getlicensesfororganization`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets multiple configuration values )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param rnCommonSearch search property contains comma separated list of the names of the configuration values to retrieve
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetmultipleconfigurablevaluesPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnStringStringDictionaryRNResponseRelay>;
  public apiV2OrganizationsGetmultipleconfigurablevaluesPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnStringStringDictionaryRNResponseRelay>>;
  public apiV2OrganizationsGetmultipleconfigurablevaluesPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnStringStringDictionaryRNResponseRelay>>;
  public apiV2OrganizationsGetmultipleconfigurablevaluesPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnStringStringDictionaryRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getmultipleconfigurablevalues`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets the organization of the current user )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetmyorganizationPost(
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrganizationsVMRNResponseRelay>;
  public apiV2OrganizationsGetmyorganizationPost(
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnOrganizationsVMRNResponseRelay>>;
  public apiV2OrganizationsGetmyorganizationPost(
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnOrganizationsVMRNResponseRelay>>;
  public apiV2OrganizationsGetmyorganizationPost(
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnOrganizationsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getmyorganization`,
      null,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets organization details for a given organization )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetorganizationPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrganizationsVMRNResponseRelay>;
  public apiV2OrganizationsGetorganizationPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnOrganizationsVMRNResponseRelay>>;
  public apiV2OrganizationsGetorganizationPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnOrganizationsVMRNResponseRelay>>;
  public apiV2OrganizationsGetorganizationPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnOrganizationsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getorganization`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets organization configuration for a given organization )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetorganizationconfigurationPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrganizationConfigurationVMRNResponseRelay>;
  public apiV2OrganizationsGetorganizationconfigurationPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnOrganizationConfigurationVMRNResponseRelay>>;
  public apiV2OrganizationsGetorganizationconfigurationPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnOrganizationConfigurationVMRNResponseRelay>>;
  public apiV2OrganizationsGetorganizationconfigurationPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnOrganizationConfigurationVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getorganizationconfiguration`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Returns information on a specific PackagePool for an organization
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonSearch Contains the ID of the organization packagepool
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetorganizationpackagepoolPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrganizationPackagePoolsForListVMRNResponseRelay>;
  public apiV2OrganizationsGetorganizationpackagepoolPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpResponse<RnOrganizationPackagePoolsForListVMRNResponseRelay>
  >;
  public apiV2OrganizationsGetorganizationpackagepoolPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnOrganizationPackagePoolsForListVMRNResponseRelay>>;
  public apiV2OrganizationsGetorganizationpackagepoolPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnOrganizationPackagePoolsForListVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getorganizationpackagepool`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Returns Packages available for an organization
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonSearch contains search informtion where Id is the org\&#39;s ID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetorganizationpackagepoolsforlistPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrganizationPackagePoolsForListVMSearchResultsVMRNResponseRelay>;
  public apiV2OrganizationsGetorganizationpackagepoolsforlistPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpResponse<RnOrganizationPackagePoolsForListVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2OrganizationsGetorganizationpackagepoolsforlistPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpEvent<RnOrganizationPackagePoolsForListVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2OrganizationsGetorganizationpackagepoolsforlistPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnOrganizationPackagePoolsForListVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getorganizationpackagepoolsforlist`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets organization WLP contact Informa for a given organization )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetorganizationwlpcontactinfoPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrganizationWLPContactInformationVMRNResponseRelay>;
  public apiV2OrganizationsGetorganizationwlpcontactinfoPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpResponse<RnOrganizationWLPContactInformationVMRNResponseRelay>
  >;
  public apiV2OrganizationsGetorganizationwlpcontactinfoPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpEvent<RnOrganizationWLPContactInformationVMRNResponseRelay>
  >;
  public apiV2OrganizationsGetorganizationwlpcontactinfoPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnOrganizationWLPContactInformationVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getorganizationwlpcontactinfo`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Returns users for an organization to be assigned to a license
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnOrganizationUsersForLicenseSearch Contains the ID of the organization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetorgusersandsuborgusersexcludeonlicensePost(
    rnOrganizationUsersForLicenseSearch?: RnOrganizationUsersForLicenseSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnUsersForOrgSubOrgSearchVMSearchResultsVMRNResponseRelay>;
  public apiV2OrganizationsGetorgusersandsuborgusersexcludeonlicensePost(
    rnOrganizationUsersForLicenseSearch?: RnOrganizationUsersForLicenseSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpResponse<RnUsersForOrgSubOrgSearchVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2OrganizationsGetorgusersandsuborgusersexcludeonlicensePost(
    rnOrganizationUsersForLicenseSearch?: RnOrganizationUsersForLicenseSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpEvent<RnUsersForOrgSubOrgSearchVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2OrganizationsGetorgusersandsuborgusersexcludeonlicensePost(
    rnOrganizationUsersForLicenseSearch?: RnOrganizationUsersForLicenseSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnUsersForOrgSubOrgSearchVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getorgusersandsuborgusersexcludeonlicense`,
      rnOrganizationUsersForLicenseSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * gets package catalog for corresponding organization
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonSearch
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetpackagesandaddonscatalogPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnPackagesAndAddonsInventoryVMRNResponseRelay>;
  public apiV2OrganizationsGetpackagesandaddonscatalogPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnPackagesAndAddonsInventoryVMRNResponseRelay>>;
  public apiV2OrganizationsGetpackagesandaddonscatalogPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnPackagesAndAddonsInventoryVMRNResponseRelay>>;
  public apiV2OrganizationsGetpackagesandaddonscatalogPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnPackagesAndAddonsInventoryVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getpackagesandaddonscatalog`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetallcatalogitemsfororgPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnPackagesAndAddonsInventoryVM>;
  public apiV2OrganizationsGetallcatalogitemsfororgPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnPackagesAndAddonsInventoryVM>>;
  public apiV2OrganizationsGetallcatalogitemsfororgPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnPackagesAndAddonsInventoryVM>>;
  public apiV2OrganizationsGetallcatalogitemsfororgPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnPackagesAndAddonsInventoryVM>(
      `${this.configuration.basePath}/api/v2/organizations/getallcatalogitemsfororg`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Returns a list of the organizations QBO info
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonSearch
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetqboinfofororgPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<Array<RnQBOInfoVM>>;
  public apiV2OrganizationsGetqboinfofororgPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<Array<RnQBOInfoVM>>>;
  public apiV2OrganizationsGetqboinfofororgPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<Array<RnQBOInfoVM>>>;
  public apiV2OrganizationsGetqboinfofororgPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<Array<RnQBOInfoVM>>(
      `${this.configuration.basePath}/api/v2/organizations/getqboinfofororg`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Returns Users assigned to the package pool
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonSearch contains search information where Id is the packagePool\&#39;s ID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetusersfororganizationpackagepoolPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnUserPackageForListVMSearchResultsVMRNResponseRelay>;
  public apiV2OrganizationsGetusersfororganizationpackagepoolPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpResponse<RnUserPackageForListVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2OrganizationsGetusersfororganizationpackagepoolPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpEvent<RnUserPackageForListVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2OrganizationsGetusersfororganizationpackagepoolPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnUserPackageForListVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getusersfororganizationpackagepool`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetusersfororgreportPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<Blob>;
  public apiV2OrganizationsGetusersfororgreportPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<Blob>>;
  public apiV2OrganizationsGetusersfororgreportPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<Blob>>;
  public apiV2OrganizationsGetusersfororgreportPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post(
      `${this.configuration.basePath}/api/v2/organizations/getusersfororgreport`,
      rnCommonId,
      {
        responseType: "blob",
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Returns the Users available to assign to the Package
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonSearch contains search information where the Id is the packagePool\&#39;s ID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetuserswithmutualexclusionforpackageassignPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnUserWithMutualExclusionForAssignPackageVMSearchResultsVMRNResponseRelay>;
  public apiV2OrganizationsGetuserswithmutualexclusionforpackageassignPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpResponse<RnUserWithMutualExclusionForAssignPackageVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2OrganizationsGetuserswithmutualexclusionforpackageassignPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpEvent<RnUserWithMutualExclusionForAssignPackageVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2OrganizationsGetuserswithmutualexclusionforpackageassignPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnUserWithMutualExclusionForAssignPackageVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getuserswithmutualexclusionforpackageassign`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets all order history for welcome wizard )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsGetwworderhistoryPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2OrganizationsGetwworderhistoryPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsGetwworderhistoryPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsGetwworderhistoryPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/getwworderhistory`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets whether a feature management key is enabled- if the segment data needs to be analyzed this endpoint does not do that )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonSearch contains key of the feature management to see if it is enabled
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsIsfeaturemanagementkeyenabledPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnBooleanRNResponseRelay>;
  public apiV2OrganizationsIsfeaturemanagementkeyenabledPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnBooleanRNResponseRelay>>;
  public apiV2OrganizationsIsfeaturemanagementkeyenabledPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnBooleanRNResponseRelay>>;
  public apiV2OrganizationsIsfeaturemanagementkeyenabledPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnBooleanRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/isfeaturemanagementkeyenabled`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * See if a Folder\&#39;s name is unique on an Organization
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnFolderSearch Contains information to perform the unique check of folder\&#39;s name
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsIsfoldernameuniquePost(
    rnFolderSearch?: RnFolderSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2OrganizationsIsfoldernameuniquePost(
    rnFolderSearch?: RnFolderSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsIsfoldernameuniquePost(
    rnFolderSearch?: RnFolderSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsIsfoldernameuniquePost(
    rnFolderSearch?: RnFolderSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/isfoldernameunique`,
      rnFolderSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Returns subOrgs that don\&#39;t pay for themselves
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param rnCommonSearch Contains the ID of the top level org
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsNonselfpaidsuborgsearchPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrganizationsVMSearchResultsVMRNResponseRelay>;
  public apiV2OrganizationsNonselfpaidsuborgsearchPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnOrganizationsVMSearchResultsVMRNResponseRelay>>;
  public apiV2OrganizationsNonselfpaidsuborgsearchPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnOrganizationsVMSearchResultsVMRNResponseRelay>>;
  public apiV2OrganizationsNonselfpaidsuborgsearchPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnOrganizationsVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/nonselfpaidsuborgsearch`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Retrieves the Custome Field Value for a given organization and customfield name.
   * This API endpoint checks for authorization before proceeding.If authorized, it will then validate the customfield.  If the customfield matches the expected value, it will proceed to fetch and return the customfield value for the given organization.  Note: The customfield comparison is case-insensitive.&lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param organizationId The unique identifier of the organization.
   * @param customfieldName The customfield name for the specific package.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsOrganizationIdCustomfieldCustomfieldNameGet(
    organizationId: number,
    customfieldName: string,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnStringRNResponseRelay>;
  public apiV2OrganizationsOrganizationIdCustomfieldCustomfieldNameGet(
    organizationId: number,
    customfieldName: string,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnStringRNResponseRelay>>;
  public apiV2OrganizationsOrganizationIdCustomfieldCustomfieldNameGet(
    organizationId: number,
    customfieldName: string,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnStringRNResponseRelay>>;
  public apiV2OrganizationsOrganizationIdCustomfieldCustomfieldNameGet(
    organizationId: number,
    customfieldName: string,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    if (organizationId === null || organizationId === undefined) {
      throw new Error(
        "Required parameter organizationId was null or undefined when calling apiV2OrganizationsOrganizationIdCustomfieldCustomfieldNameGet.",
      );
    }
    if (customfieldName === null || customfieldName === undefined) {
      throw new Error(
        "Required parameter customfieldName was null or undefined when calling apiV2OrganizationsOrganizationIdCustomfieldCustomfieldNameGet.",
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.get<RnStringRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/${encodeURIComponent(String(organizationId))}/customfield/${encodeURIComponent(String(customfieldName))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets organization profile for a given organization )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsProfilePost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrganizationsProfileVMRNResponseRelay>;
  public apiV2OrganizationsProfilePost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnOrganizationsProfileVMRNResponseRelay>>;
  public apiV2OrganizationsProfilePost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnOrganizationsProfileVMRNResponseRelay>>;
  public apiV2OrganizationsProfilePost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnOrganizationsProfileVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/profile`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( GetOrganizationPromoCodes )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonSearch
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsPromocodesCurrentPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrganizationPromoCodeVMSearchResultsVMRNResponseRelay>;
  public apiV2OrganizationsPromocodesCurrentPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpResponse<RnOrganizationPromoCodeVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2OrganizationsPromocodesCurrentPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpEvent<RnOrganizationPromoCodeVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2OrganizationsPromocodesCurrentPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnOrganizationPromoCodeVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/promocodes/current`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Updates organization name )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; RENAMEORG,RNMSUBORG
   * @param rnOrganizationRename
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsRenamePost(
    rnOrganizationRename?: RnOrganizationRename,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrganizationsVMRNResponseRelay>;
  public apiV2OrganizationsRenamePost(
    rnOrganizationRename?: RnOrganizationRename,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnOrganizationsVMRNResponseRelay>>;
  public apiV2OrganizationsRenamePost(
    rnOrganizationRename?: RnOrganizationRename,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnOrganizationsVMRNResponseRelay>>;
  public apiV2OrganizationsRenamePost(
    rnOrganizationRename?: RnOrganizationRename,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnOrganizationsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/rename`,
      rnOrganizationRename,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Search for ogranization by providing search criteria )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Internal&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; SEARCHORGS
   * @param rnCommonSearch
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsSearchPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrganizationsVMSearchResultsVMRNResponseRelay>;
  public apiV2OrganizationsSearchPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnOrganizationsVMSearchResultsVMRNResponseRelay>>;
  public apiV2OrganizationsSearchPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnOrganizationsVMSearchResultsVMRNResponseRelay>>;
  public apiV2OrganizationsSearchPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnOrganizationsVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/search`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Creates sub organization )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; CREATESUBS
   * @param rnSubOrganizationCreate
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsSuborganizationPost(
    rnSubOrganizationCreate?: RnSubOrganizationCreate,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2OrganizationsSuborganizationPost(
    rnSubOrganizationCreate?: RnSubOrganizationCreate,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsSuborganizationPost(
    rnSubOrganizationCreate?: RnSubOrganizationCreate,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsSuborganizationPost(
    rnSubOrganizationCreate?: RnSubOrganizationCreate,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/suborganization`,
      rnSubOrganizationCreate,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets sub organization tree for a given organisation )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; VWORGTREE
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsSuborgtreePost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrganizationTreeNodeListRNResponseRelay>;
  public apiV2OrganizationsSuborgtreePost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnOrganizationTreeNodeListRNResponseRelay>>;
  public apiV2OrganizationsSuborgtreePost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnOrganizationTreeNodeListRNResponseRelay>>;
  public apiV2OrganizationsSuborgtreePost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnOrganizationTreeNodeListRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/suborgtree`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; VWORGTREE
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsSuborgtreewithstatusPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrganizationTreeNodeListRNResponseRelay>;
  public apiV2OrganizationsSuborgtreewithstatusPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnOrganizationTreeNodeListRNResponseRelay>>;
  public apiV2OrganizationsSuborgtreewithstatusPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnOrganizationTreeNodeListRNResponseRelay>>;
  public apiV2OrganizationsSuborgtreewithstatusPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnOrganizationTreeNodeListRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/suborgtreewithstatus`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Updates the name of the folder for a Top Level Org )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; RNMFOLDER
   * @param rnOrganizationFolderRename
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsToplevelfolderrenamePost(
    rnOrganizationFolderRename?: RnOrganizationFolderRename,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2OrganizationsToplevelfolderrenamePost(
    rnOrganizationFolderRename?: RnOrganizationFolderRename,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsToplevelfolderrenamePost(
    rnOrganizationFolderRename?: RnOrganizationFolderRename,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsToplevelfolderrenamePost(
    rnOrganizationFolderRename?: RnOrganizationFolderRename,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/toplevelfolderrename`,
      rnOrganizationFolderRename,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; CHNGUSRROL
   * @param rnOrganizationUpdateAccountOwner
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsUpdateAccountOwnerPost(
    rnOrganizationUpdateAccountOwner?: RnOrganizationUpdateAccountOwner,
    observe?: "body",
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined },
  ): Observable<any>;
  public apiV2OrganizationsUpdateAccountOwnerPost(
    rnOrganizationUpdateAccountOwner?: RnOrganizationUpdateAccountOwner,
    observe?: "response",
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined },
  ): Observable<HttpResponse<any>>;
  public apiV2OrganizationsUpdateAccountOwnerPost(
    rnOrganizationUpdateAccountOwner?: RnOrganizationUpdateAccountOwner,
    observe?: "events",
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined },
  ): Observable<HttpEvent<any>>;
  public apiV2OrganizationsUpdateAccountOwnerPost(
    rnOrganizationUpdateAccountOwner?: RnOrganizationUpdateAccountOwner,
    observe: any = "body",
    reportProgress = false,
    options?: { httpHeaderAccept?: undefined },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/api/v2/organizations/updateAccountOwner`,
      rnOrganizationUpdateAccountOwner,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Updates XID of an organization )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; ADDUPDTXID
   * @param rnOrganizationCreateUpdateXid
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsUpdateXidPost(
    rnOrganizationCreateUpdateXid?: RnOrganizationCreateUpdateXid,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnBooleanRNResponseRelay>;
  public apiV2OrganizationsUpdateXidPost(
    rnOrganizationCreateUpdateXid?: RnOrganizationCreateUpdateXid,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnBooleanRNResponseRelay>>;
  public apiV2OrganizationsUpdateXidPost(
    rnOrganizationCreateUpdateXid?: RnOrganizationCreateUpdateXid,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnBooleanRNResponseRelay>>;
  public apiV2OrganizationsUpdateXidPost(
    rnOrganizationCreateUpdateXid?: RnOrganizationCreateUpdateXid,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnBooleanRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/updateXid`,
      rnOrganizationCreateUpdateXid,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Updates billing information )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnOrganizationBillingInformationCreate
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsUpdatebillinginfoPost(
    rnOrganizationBillingInformationCreate?: RnOrganizationBillingInformationCreate,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2OrganizationsUpdatebillinginfoPost(
    rnOrganizationBillingInformationCreate?: RnOrganizationBillingInformationCreate,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsUpdatebillinginfoPost(
    rnOrganizationBillingInformationCreate?: RnOrganizationBillingInformationCreate,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2OrganizationsUpdatebillinginfoPost(
    rnOrganizationBillingInformationCreate?: RnOrganizationBillingInformationCreate,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/updatebillinginfo`,
      rnOrganizationBillingInformationCreate,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Updates organization address )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; RENAMEORG
   * @param rnOrganizationUpdateBusinessAddress
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsUpdatebusinessaddressPost(
    rnOrganizationUpdateBusinessAddress?: RnOrganizationUpdateBusinessAddress,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnBooleanRNResponseRelay>;
  public apiV2OrganizationsUpdatebusinessaddressPost(
    rnOrganizationUpdateBusinessAddress?: RnOrganizationUpdateBusinessAddress,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnBooleanRNResponseRelay>>;
  public apiV2OrganizationsUpdatebusinessaddressPost(
    rnOrganizationUpdateBusinessAddress?: RnOrganizationUpdateBusinessAddress,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnBooleanRNResponseRelay>>;
  public apiV2OrganizationsUpdatebusinessaddressPost(
    rnOrganizationUpdateBusinessAddress?: RnOrganizationUpdateBusinessAddress,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnBooleanRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/updatebusinessaddress`,
      rnOrganizationUpdateBusinessAddress,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Returns a list of users based on the search payload )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; VIEWUSRDET
   * @param rnCommonSearch Contains the parameters for the search
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsUsersPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnUsersVMSearchResultsVMRNResponseRelay>;
  public apiV2OrganizationsUsersPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnUsersVMSearchResultsVMRNResponseRelay>>;
  public apiV2OrganizationsUsersPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnUsersVMSearchResultsVMRNResponseRelay>>;
  public apiV2OrganizationsUsersPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnUsersVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/organizations/users`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param rnQbesOrderSearch
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OrganizationsVerifyQbesSetupPost(
    rnQbesOrderSearch?: RnQbesOrderSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined },
  ): Observable<any>;
  public apiV2OrganizationsVerifyQbesSetupPost(
    rnQbesOrderSearch?: RnQbesOrderSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined },
  ): Observable<HttpResponse<any>>;
  public apiV2OrganizationsVerifyQbesSetupPost(
    rnQbesOrderSearch?: RnQbesOrderSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined },
  ): Observable<HttpEvent<any>>;
  public apiV2OrganizationsVerifyQbesSetupPost(
    rnQbesOrderSearch?: RnQbesOrderSearch,
    observe: any = "body",
    reportProgress = false,
    options?: { httpHeaderAccept?: undefined },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/api/v2/organizations/verifyQbesSetup`,
      rnQbesOrderSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }
}
