<div class="modal-header">
  <h3 class="modal-title">{{ data.Header }}</h3>
</div>
<div class="modal-body">
  <p [innerHtml]="data.Notification | safeHtml"></p>
</div>
<div class="modal-footer">
  <app-button
    id="btnClose"
    (clickActionEmitter)="close()"
    label="CLOSE"
    noContentPadding="true"
    buttonStyle="primary"
  >
  </app-button>
</div>
