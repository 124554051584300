import { Component, OnDestroy, OnInit } from "@angular/core";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { Subscription } from "rxjs";
import { AppInsightsService } from "../../../appInsights/app-insights.service";
import { RefreshService } from "../../../services/refresh/refresh.service";
import { DuoService } from "../../../services/rnapi2-service/apis/api";
import {
  RnCommonId,
  RnUsersProfileVM,
} from "../../../services/rnapi2-service/models/models";
import { SelectionService } from "../../../services/selection/selection.service";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { EnvService } from "../../../../core/services/env.service";

@Component({
  selector: "app-disable-org-mfa-dialog",
  templateUrl: "./disable-org-mfa-dialog.component.html",
  styleUrls: ["./disable-org-mfa-dialog.component.scss"],
})
export class DisableOrgMfaDialogComponent implements OnInit, OnDestroy {
  user: RnUsersProfileVM;
  isProcessing = false;
  message = "";
  assetUrl: string;
  enabled = true;
  orgID: number;
  private subscriptions: Subscription[] = [];

  constructor(
    private ref: DynamicDialogRef,
    private duoService: DuoService,
    private appInsightsService: AppInsightsService,
    private refreshService: RefreshService,
    private selectionService: SelectionService,
    private rnToastService: RnToastService,
  ) {}

  ngOnInit(): void {
    this.assetUrl = EnvService.EnvVariables().assetUrl;

    this.subscriptions.push(
      this.selectionService.SelectedOrgConfig.subscribe((result) => {
        this.orgID = result?.ID;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  close(): void {
    this.ref.close(this.enabled);
  }

  disableMfa() {
    this.isProcessing = true;
    this.message = "Disabling Organization MFA...";
    this.enabled = false;
    const rnCommonId: RnCommonId = new RnCommonId();
    rnCommonId.Id = this.orgID.toString();

    this.duoService.apiV2DuoDisableorgmfaPost(rnCommonId).subscribe(
      (result) => {
        this.refreshService.refreshAllWithHandler(() => {
          this.appInsightsService.logEvent(
            "Organization Multi-Factor Authentication has been turned off.",
          );
          this.isProcessing = false;
          this.rnToastService.showSuccess(
            "Multi-Factor Authentication has been turned off for all organizations.",
          );
          this.close();
        });
      },
      (error) => {
        this.rnToastService.showError(
          "There was a problem disabling multi-factor authentication for this org.",
        );
        this.close();
      },
    );
  }
}
