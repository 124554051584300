import { GridColumnConfiguration } from "./grid-column-configuration";
import { DataRetrievalParameters } from "../../../../../core/models/data-retrieval-parameters";
import { LazyLoadEvent } from "primeng/api";
import { Observable, Subject } from "rxjs";
import { GridDataResponse } from "./grid-data-response";
import { Directive, OnDestroy } from "@angular/core";
import { TableSelectable } from "../../../../../core/interfaces/table-selectable";
import { GridCheckLimitColumnConfiguration } from "./grid-check-limit-column-configuration";

@Directive()
export class GridConfiguration implements OnDestroy {
  public ShowHeader: boolean;
  public HeaderClass: string;
  public GridHeader: string;
  public GridClass: string;
  public ColumnConfiguration: GridColumnConfiguration[];
  public GridData: any[];
  public TotalRecords: number;
  public FirstRow: number;
  public getClassForRow: (rowData: any) => string;
  public getClassForCell: (
    col: GridColumnConfiguration,
    rowData: any,
  ) => string;
  public getColSpanForCell: (
    col: GridColumnConfiguration,
    rowData: any,
  ) => string;
  public setUrlData: (params: DataRetrievalParameters) => void;
  public getDataForGrid: (
    params: DataRetrievalParameters,
  ) => Observable<GridDataResponse>;
  public Loading = false;
  public LoadingText: string;
  public RowsPerPageOptions = [10, 25, 50, 100];
  public NumberPageLinks = 7;
  public IsLazyLoading = true;
  public UsePaginator = true;
  public SortField = "";
  public SortOrder = 1;
  public ShowCountHeader = true;
  public CountHeaderItemsName: string;
  public CurrentFirstRowNumber: number;
  public CurrentPageNumber: Observable<number>;
  public DisableSetLoading = false;
  public TableClass: string;
  public constructor() {
    this.CurrentPageNumber = this.currentPageNumber.asObservable();
  }

  private currentPageNumber = new Subject<number>();
  public lazyLoadData(event: LazyLoadEvent) {
    try {
      if (event.first === undefined) {
        this.GridData = [];
        this.TotalRecords = 0;
        this.Loading = false;
        this.CurrentFirstRowNumber = 0;
        this.currentPageNumber.next(0);
        return;
      }
      if (!this.DisableSetLoading) {
        this.Loading = true;
      }
      if (this.getDataForGrid || this.setUrlData) {
        const params = new DataRetrievalParameters();
        params.PageSize = parseInt(event.rows.toString());

        params.PageNumber = event.first / params.PageSize;
        this.currentPageNumber.next(params.PageNumber);
        params.SortOrder = event.sortField;
        params.IsDescendingOrder = event.sortOrder < 1;
        this.SortField = event.sortField;
        this.SortOrder = event.sortOrder;
        this.CurrentFirstRowNumber = event.first + 1;
        if (this.getDataForGrid) {
          this.subscriptions.push(
            this.getDataForGrid(params).subscribe((r) => {
              this.GridData = r.Data;
              this.TotalRecords = r.TotalRecords;
              this.Loading = false;
            }),
          );
        } else if (this.setUrlData) {
          this.setUrlData(params);
        }
      } else {
        this.GridData = [];
        this.TotalRecords = 0;
        this.Loading = false;
      }
    } catch (e) {
      console.log("grid configuration issue: ", e);
    }
  }

  public getDataForCell(col: GridColumnConfiguration, rowData: any) {
    if (col.CustomDataRetrieval) {
      return col.CustomDataRetrieval(rowData);
    }

    if (this.getCustomDataForCell) {
      return this.getCustomDataForCell(col, rowData);
    }
    return rowData[col.FieldName];
  }

  public RetrieveClassForRow(rowData: any): string {
    if (this.getClassForRow) {
      return this.getClassForRow(rowData);
    }

    return "";
  }
  public RetrieveColSpanForCell(
    col: GridColumnConfiguration,
    rowData: any,
  ): string {
    if (this.getColSpanForCell) {
      return this.getColSpanForCell(col, rowData);
    }

    return "1";
  }
  public getCustomDataForCell: (
    col: GridColumnConfiguration,
    rowData: any,
  ) => string;

  public RetrieveClassForCell(
    col: GridColumnConfiguration,
    rowData: any,
  ): string {
    if (this.getClassForCell) {
      return this.getClassForCell(col, rowData);
    }

    return "";
  }

  public RnSelectionEnabled = false;
  public RnCheckboxClick(
    $event,
    col: GridCheckLimitColumnConfiguration,
    rowData: any,
  ) {
    if (this.RnSelectionEnabled && col) {
      const item = rowData as TableSelectable;
      if (item) {
        if (col.MaxLimit && (col.GetNumSelected || col.SelectedItems)) {
          const maxLimit = col.MaxLimit();
          if (maxLimit >= 0) {
            const selectedCount = col.GetNumSelected
              ? col.GetNumSelected()
              : col.SelectedItems.length;
            if (!item.RnTableSelected && maxLimit <= selectedCount) {
              if (col.LimitExceeded) {
                col.LimitExceeded();
              }
              $event.preventDefault();
              $event.stopPropagation();
              return false;
            }
          }
        }
      }
    }
  }

  public RnCheckboxChange(
    $event,
    col: GridCheckLimitColumnConfiguration,
    rowData: any,
  ) {
    if (this.RnSelectionEnabled && col) {
      const item = rowData as TableSelectable;
      if (item) {
        if (col.MaxLimit && (col.GetNumSelected || col.SelectedItems)) {
          const maxLimit = col.MaxLimit();
          const selectedCount = col.GetNumSelected
            ? col.GetNumSelected()
            : col.SelectedItems.length;
          if (!item.RnTableSelected && maxLimit <= selectedCount) {
            if (col.LimitExceeded) {
              col.LimitExceeded();
            }
            $event.preventDefault();
            $event.stopPropagation();
            return;
          }
        }
        if (col.SelectedItems) {
          if (!item.RnTableSelected) {
            col.SelectedItems.push(item);
          } else {
            const start = col.SelectedItems.indexOf(item);
            col.SelectedItems.splice(start, 1);
          }
        }
        item.RnTableSelected = !item.RnTableSelected;
        if (col.CheckChange) {
          col.CheckChange($event, rowData);
        }
      }
    }
  }

  public RnSelectAll: boolean;
  public selectAllDisabled: boolean;

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public subscriptions = [];

  public loadingDataCompleted(
    data: any[],
    totalRecordCount: number,
    firstRow: number,
    currPageNumber: number,
  ) {
    this.GridData = data;
    this.TotalRecords = totalRecordCount;
    this.FirstRow = firstRow;
    this.CurrentFirstRowNumber = firstRow + 1;
    this.Loading = false;
    this.currentPageNumber.next(currPageNumber);
  }
}
