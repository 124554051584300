<div class="flex-row primaryFrame">
  <div class="content">
    <div id="primaryframelabel">{{ frameLabel }}</div>
    <div class="primaryframeSearch" *ngIf="showSearch">
      <app-search-control
        id="primaryFrameSearchControl"
        class="float-right"
        [Configuration]="searchControlConfiguration"
        (SearchOccurred)="searchOccurred($event)"
      ></app-search-control>
    </div>
  </div>
</div>
