import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "rnQuantityChange",
})
export class RnQuantityChangePipe implements PipeTransform {
  transform(quantity: number, ...args: unknown[]): unknown {
    if (args && args.length > 0) {
      const changeType = args[0].toString().toLowerCase();
      switch (changeType) {
        case "addvalues": {
          const otherValue = parseFloat(args[1].toString());
          return quantity + otherValue;
        }
        case "remainvalue": {
          const otherValue = parseFloat(args[1].toString());
          const availValue = parseFloat(args[2].toString());
          return availValue - (quantity + otherValue);
        }
        case "hasquantity": {
          return quantity > 0;
        }
      }
    }
    return null;
  }
}
