import { MatMenuTrigger } from "@angular/material/menu";

/**
 * GridMenuItem is used by grid menu column configuration to define menu items
 */
export class GridMenuItem {
  constructor(
    public id: string,
    public text: string,
    public action: (rowData: any, trigger: MatMenuTrigger) => void,
    public iconClass?: string,
    public iconSource?: string,
  ) {}
}
