import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "escapeCharacter",
})
export class EscapeCharacterPipe implements PipeTransform {
  transform(value: any) {
    const map = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&#39;",
    };
    return value.replace(/[&<>"']/g, (m) => map[m]);
  }
}
