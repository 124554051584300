/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
} from "@angular/common/http";
import { CustomHttpParameterCodec } from "../encoder";
import { Observable } from "rxjs";

import { RnHCActiveDataNodeReportListRNResponseRelay } from "../models/models";
import { RnHCDataNodePayloadAdd } from "../models/models";
import { RnHCDataNodePayloadStatus } from "../models/models";
import { RnHCDataNodeResponsePayloadAddRNResponseRelay } from "../models/models";
import { RnHCDataNodeResponsePayloadListRNResponseRelay } from "../models/models";
import { RnHCDataNodeResponsePayloadStatusRNResponseRelay } from "../models/models";

import { BASE_PATH } from "../variables";
import { Configuration } from "../configuration";

@Injectable({
  providedIn: "root",
})
export class OpsService {
  protected basePath = "http://localhost";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== "string") {
      if (typeof basePath !== "string") {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string,
  ): HttpParams {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string,
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)),
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            value.toISOString().substr(0, 10),
          );
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k,
            )),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }

  /**
   * ( Sets the status of a datanode matching the given slug to Active )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; AzureAD&lt;br/&gt;&lt;b&gt;Roles:&lt;/b&gt; Ops_Data_Node_Writer
   * @param rnHCDataNodePayloadStatus
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OpsDatanodeActivatePost(
    rnHCDataNodePayloadStatus?: RnHCDataNodePayloadStatus,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnHCDataNodeResponsePayloadStatusRNResponseRelay>;
  public apiV2OpsDatanodeActivatePost(
    rnHCDataNodePayloadStatus?: RnHCDataNodePayloadStatus,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnHCDataNodeResponsePayloadStatusRNResponseRelay>>;
  public apiV2OpsDatanodeActivatePost(
    rnHCDataNodePayloadStatus?: RnHCDataNodePayloadStatus,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnHCDataNodeResponsePayloadStatusRNResponseRelay>>;
  public apiV2OpsDatanodeActivatePost(
    rnHCDataNodePayloadStatus?: RnHCDataNodePayloadStatus,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnHCDataNodeResponsePayloadStatusRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/ops/datanode/activate`,
      rnHCDataNodePayloadStatus,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets a list of data nodes with slugs that start with the given query )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; AzureAD&lt;br/&gt;&lt;b&gt;Roles:&lt;/b&gt; Ops_Data_Node_Reader
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OpsDatanodeActivenodereportGet(
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnHCActiveDataNodeReportListRNResponseRelay>;
  public apiV2OpsDatanodeActivenodereportGet(
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnHCActiveDataNodeReportListRNResponseRelay>>;
  public apiV2OpsDatanodeActivenodereportGet(
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnHCActiveDataNodeReportListRNResponseRelay>>;
  public apiV2OpsDatanodeActivenodereportGet(
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.get<RnHCActiveDataNodeReportListRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/ops/datanode/activenodereport`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets a list of data nodes with slugs that start with the given query )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; AzureAD&lt;br/&gt;&lt;b&gt;Roles:&lt;/b&gt; Ops_Data_Node_Reader
   * @param slug
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OpsDatanodeListGet(
    slug?: string,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnHCDataNodeResponsePayloadListRNResponseRelay>;
  public apiV2OpsDatanodeListGet(
    slug?: string,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnHCDataNodeResponsePayloadListRNResponseRelay>>;
  public apiV2OpsDatanodeListGet(
    slug?: string,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnHCDataNodeResponsePayloadListRNResponseRelay>>;
  public apiV2OpsDatanodeListGet(
    slug?: string,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (slug !== undefined && slug !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>slug,
        "slug",
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.get<RnHCDataNodeResponsePayloadListRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/ops/datanode/list`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Sets the status of a datanode matching the given slug to Staged )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; AzureAD&lt;br/&gt;&lt;b&gt;Roles:&lt;/b&gt; Ops_Data_Node_Writer
   * @param rnHCDataNodePayloadStatus
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OpsDatanodeStagePost(
    rnHCDataNodePayloadStatus?: RnHCDataNodePayloadStatus,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnHCDataNodeResponsePayloadStatusRNResponseRelay>;
  public apiV2OpsDatanodeStagePost(
    rnHCDataNodePayloadStatus?: RnHCDataNodePayloadStatus,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnHCDataNodeResponsePayloadStatusRNResponseRelay>>;
  public apiV2OpsDatanodeStagePost(
    rnHCDataNodePayloadStatus?: RnHCDataNodePayloadStatus,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnHCDataNodeResponsePayloadStatusRNResponseRelay>>;
  public apiV2OpsDatanodeStagePost(
    rnHCDataNodePayloadStatus?: RnHCDataNodePayloadStatus,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnHCDataNodeResponsePayloadStatusRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/ops/datanode/stage`,
      rnHCDataNodePayloadStatus,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Creates a new file server with datanodes named by the given prefix and suffix )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; AzureAD&lt;br/&gt;&lt;b&gt;Roles:&lt;/b&gt; Ops_Data_Node_Writer
   * @param rnHCDataNodePayloadAdd
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2OpsFileserverAddPost(
    rnHCDataNodePayloadAdd?: RnHCDataNodePayloadAdd,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnHCDataNodeResponsePayloadAddRNResponseRelay>;
  public apiV2OpsFileserverAddPost(
    rnHCDataNodePayloadAdd?: RnHCDataNodePayloadAdd,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnHCDataNodeResponsePayloadAddRNResponseRelay>>;
  public apiV2OpsFileserverAddPost(
    rnHCDataNodePayloadAdd?: RnHCDataNodePayloadAdd,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnHCDataNodeResponsePayloadAddRNResponseRelay>>;
  public apiV2OpsFileserverAddPost(
    rnHCDataNodePayloadAdd?: RnHCDataNodePayloadAdd,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnHCDataNodeResponsePayloadAddRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/ops/fileserver/add`,
      rnHCDataNodePayloadAdd,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }
}
