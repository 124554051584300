/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export class RnUserDriveVM {
  UserDriveMappingID?: number;
  ConnectPointID?: number;
  ChildConnectPointIDs?: Array<number> | null;
}
