<div class="modal-content hide-y-overflow">
  <div class="modal-header">
    <h4>Change Status</h4>
  </div>

  <div class="modal-footer text-align-left">
    <app-button
      id="btnSubmit"
      label="Change Status"
      buttonStyle="primary"
      (clickActionEmitter)="canSubmit()"
    >
    </app-button>

    <app-button
      id="btnCancel"
      label="Cancel"
      buttonStyle="link"
      (clickActionEmitter)="close()"
    >
    </app-button>
  </div>
</div>
