import { DatePipe } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { SelectItem } from "primeng/api";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { DataRetrievalParameters } from "../../../../core/models/data-retrieval-parameters";
import { EventModel } from "../../../../core/models/event-model.model";
import { EnvService } from "../../../../core/services/env.service";
import { LookupService } from "../../../services/rnapi2-service/apis/api";
import {
  RnCommonSearch,
  RnEventsVMSearchResultsVM,
  RnLUEventTypeOrderByTypes,
  RnOrganizationsProfileVM,
} from "../../../services/rnapi2-service/models/models";
import { SelectionService } from "../../../services/selection/selection.service";
import { GridAffectedColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-affected-column-configuration";
import { GridColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-column-configuration";
import { GridConfiguration } from "../../third-party-wrappers/grid/configuration/grid-configuration";
import { GridFormatDateColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-format-date";
import { SearchControlConfiguration } from "../search-control/configuration/search-control-configuration";
import { LoggedInInfoService } from "../../../../shared/services/loggedInInfo/logged-in-info.service";
import { formatDate } from "@angular/common";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import * as dayjs from "dayjs";
dayjs.extend(customParseFormat);

@Component({
  selector: "app-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.scss"],
})
export class EventsComponent implements OnInit, OnDestroy, OnChanges {
  loadingEvents = true;
  frmGroup: FormGroup;
  searchText: string;

  textSearchOccurred(event: string): void {
    const searchText = event.toLocaleLowerCase();
    this.localParams.inputSearchText.next(searchText);
  }

  @Output() SearchOccurred = new EventEmitter<EventModel>();
  public selectedOrg: RnOrganizationsProfileVM;

  eventsApiSearchParams: RnCommonSearch = new RnCommonSearch(); //used when performing API search
  routeParams: EventModel = new EventModel(); //controlled by URL
  localParams: EventModel = new EventModel(); //controlled by user inputs

  eventTypes: SelectItem[] = []; //drives the dropdown
  selectedEventType: SelectItem; //UI dropdown bound to this
  searchControlConfiguration: SearchControlConfiguration;

  userID: string;
  organizationId: number;

  @Input() EventsSearchResults: RnEventsVMSearchResultsVM;
  @Input() IsLoadingEvents = false;
  //@Input() set ClearSearchCriteria(clear: boolean) {
  //  this.clearSearchCriteria = clear;
  //  if (this.clearAllSearchCriteria) {
  //    this.clearAllSearchCriteria();
  //  }
  //}
  //private clearSearchCriteria = false;
  @Input() ClearSearchCriteria = [];

  currentTabName: number;

  beginDate: string;
  endDate: string;

  eventStartDate: Date;
  eventEndDate: Date;
  maxDate: string;
  minDate: string;
  eventStartDateString: string;
  eventEndDateString: string;

  dateRange: Date[];

  initialDateRange: Date[];

  startDateHandler: any;
  endDateHandler: any;

  public eventsGridConfiguration: GridConfiguration = new GridConfiguration();
  protected subscriptions = [];
  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected lookupService: LookupService,
    protected activatedRoute: ActivatedRoute,
    protected selectionService: SelectionService,
    private loggedInInfoService: LoggedInInfoService,
  ) {}

  ngOnInit(): void {
    this.frmGroup = this.formBuilder.group({
      eventTypeSelect: ["All"],
    });
    this.setDatesToPastWeek();
    this.initialDateRange = [
      this.localParams.beginDate,
      this.localParams.endDate,
    ];
    this.searchControlConfiguration = new SearchControlConfiguration();
    this.searchControlConfiguration.EditFieldPlaceholderText = "Search Events";
    this.searchControlConfiguration.MagnifyingGlassClass =
      "events-search-magnify";
    this.searchControlConfiguration.ShowMagnifyingGlassIcon = true;
    this.eventModel = this.eventModelSubject.asObservable();

    this.localParams.inputSearchText = new Subject<string>();
    const inputSearchSub = this.localParams.inputSearchText
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((inputText) => {
        this.localParams.currentSearchText = inputText;
        this.updateRouteQueryParams();
        this.SearchOccurred.emit(this.localParams);
        this.eventModelSubject.next(this.localParams);
      });
    this.subscriptions.push(inputSearchSub);
    //get orgid
    const selectTopLevel = this.selectionService.SelectedTopLevelOrg.subscribe(
      (p) => {
        // If we don't have a selected org, we can default to the logged in user's org
        // This will function properly as long as we don't have to worry about internal users
        this.organizationId = !p?.ID
          ? this.loggedInInfoService.GetLoggedInUserOrg()?.ID
          : p?.ID;
      },
    );
    this.subscriptions.push(selectTopLevel);

    this.initEventTypesLookups();
    this.initGridAndWatchTableParams();
    this.initRouteWatcher();
    this.startDateHandler = this.debounce(
      this.startDateChanged.bind(this),
      500,
    );
    this.endDateHandler = this.debounce(this.endDateChanged.bind(this), 500);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  //init helpers
  initRouteWatcher() {
    //watches the route and refreshes the grid when params change
    const paramMapSub = this.activatedRoute.paramMap.subscribe((p) => {
      const queryParamMapSub = this.activatedRoute.queryParamMap.subscribe(
        (m) => {
          //this.userId = p.get('id');
          //this.activeIndex = p.get('activeIndex');
          this.routeParams.selectedEventType = m.has("lookupType")
            ? m.get("lookupType")
            : null;
          this.routeParams.currentSearchText = m.has("searchText")
            ? m.get("searchText")
            : null;
          this.routeParams.pageNumber = m.has("pageNumber")
            ? Number(m.get("pageNumber"))
            : null;
          this.routeParams.pageSize = m.has("pageSize")
            ? Number(m.get("pageSize"))
            : null;
          this.routeParams.isDescendingOrder = m.has("isDescendingOrder")
            ? m.get("isDescendingOrder").toLowerCase() === "true"
            : null;
          this.routeParams.sortOrder = m.has("sortBy") ? m.get("sortBy") : null;
          this.routeParams.beginDate = m.has("startDate")
            ? new Date(m.get("startDate"))
            : null;
          this.routeParams.endDate = m.has("endDate")
            ? new Date(m.get("endDate"))
            : null;
          this.paramPageName = m.has("activeTab") ? m.get("activeTab") : null;
          if (this.paramPageName === "events") {
            this.localParams.set(this.routeParams);
            this.selectedEventType = this.eventTypes.find(
              (x) => x.value === this.routeParams.selectedEventType,
            );
            this.localParams.isDirty = false;
          }
        },
      );
      this.subscriptions.push(queryParamMapSub);
    });
    this.subscriptions.push(paramMapSub);
  }

  initGridAndWatchTableParams() {
    //sets up the basic grid definition
    this.eventsGridConfiguration = new GridConfiguration();
    this.eventsGridConfiguration.GridClass = "event-table";
    this.eventsGridConfiguration.ShowCountHeader = false;
    this.eventsGridConfiguration.CountHeaderItemsName = "Events";
    this.eventsGridConfiguration.FirstRow = 0;
    this.eventsGridConfiguration.setUrlData = (
      params: DataRetrievalParameters,
    ) => {
      //when the grid filter params change (page number, sorting, etc), this is called
      this.localParams.pageNumber = params.PageNumber;
      this.localParams.pageSize = params.PageSize;
      if (params.SortOrder && params.SortOrder !== undefined) {
        if (
          params.SortOrder !== this.localParams.sortOrder ||
          this.localParams.isDescendingOrder !== params.IsDescendingOrder
        ) {
          this.localParams.pageNumber = 0;
        }
        this.localParams.sortOrder = params.SortOrder;
        this.localParams.isDescendingOrder = params.IsDescendingOrder;
      }
      this.eventModelSubject.next(this.localParams);
      this.SearchOccurred.emit(this.localParams);
    };

    this.eventsGridConfiguration.ColumnConfiguration = [];
    const datePipe = new DatePipe("en-US");
    const recDate = new GridFormatDateColumnConfiguration(
      "CreateDate",
      "Timestamp",
      "col-20-width",
      "orderByCreateDate",
      datePipe,
    );
    recDate.GetDateCol = (rowData: any) => {
      return "";
    };
    this.eventsGridConfiguration.ColumnConfiguration.push(recDate);
    this.eventsGridConfiguration.ColumnConfiguration.push(
      new GridColumnConfiguration(
        "EventDesc",
        "Event",
        "col-30-width",
        "orderByDescription",
      ),
    );
    this.eventsGridConfiguration.ColumnConfiguration.push(
      new GridColumnConfiguration(
        "EventTypeDesc",
        "Type",
        "col-20-width",
        "orderByType",
      ),
    );

    // One last check here because that selectTopLevel subscription above doesn't fire on Client Events for some reason.
    // If the selection service doesn't have a selected top level org id, we fall back to the logged in user's id
    if (!this.organizationId) {
      const selectedTopLevel = this.selectionService.getSelectedTopLevelOrg();
      this.organizationId = !selectedTopLevel
        ? this.loggedInInfoService.GetLoggedInUserOrg().ID
        : selectedTopLevel.ID;
    }

    const userOrgName = new GridAffectedColumnConfiguration(
      ["Org: ", "User: "],
      ["OrganizationName", "UserName"],
      ["OrganizationID", "UserID"],
      [`/#/organization/${this.organizationId}?subOrgId=`, "/#/user/"],
      "Affected",
      ["col-15-width", "col-15-width"],
      "",
      [
        this.affectedOrgHyperlinkDisplay.bind(this),
        this.affectedUserHyperlinkDisplayLogic.bind(this),
      ],
      "",
      "detailsLink",
      ["n/a", ""],
    );
    this.eventsGridConfiguration.ColumnConfiguration.push(userOrgName);

    const doneBy = new GridAffectedColumnConfiguration(
      [""],
      ["CreatedByUserName"],
      ["CreatedByUserID"],
      ["/#/user/"],
      "Done By",
      ["col-15-width", "col-15-width"],
      "",
      false,
      "",
      "detailsLink",
      [],
    );

    this.eventsGridConfiguration.ColumnConfiguration.push(doneBy);
    this.eventsGridConfiguration.getClassForCell = (
      col: GridColumnConfiguration,
      rowData: any,
    ) => {
      if (col.FieldName == "EventDesc") return "break";
    };
    this.eventsGridConfiguration.Loading = false;
    this.localParams.isDirty = true;
  }

  initEventTypesLookups() {
    this.eventTypes = [];
    const lookupServiceSub = this.lookupService
      .apiV2LookupEventtypesGet(
        RnLUEventTypeOrderByTypes.OrderByDescription,
        null,
        0,
        0,
        false,
        "response",
        false,
      )
      .subscribe(
        (data) => {
          // Need to do this as the changes are not picked up if we just modify this.eventTypes.
          // this.eventTypes has to be assigned a new value for the change to be picked up.
          const all = data.body.data.Results;
          const eventTypesForUI = [];
          eventTypesForUI.push({ label: "All Events", value: "All" });
          eventTypesForUI.push(
            ...all.map((i) => {
              return {
                label: i.Desc,
                value: i.Name,
              };
            }),
          );

          //if there was a query param when the page was first loaded, set that here now
          this.selectedEventType = eventTypesForUI.find(
            (x) => x.value == this.routeParams.selectedEventType,
          );
          if (!this.selectedEventType) {
            this.selectedEventType = eventTypesForUI.find(
              (x) => x.value === "All",
            );
          }
          this.eventTypes = eventTypesForUI;
          this.loadingEvents = false;
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
      );
    this.subscriptions.push(lookupServiceSub);
  }

  //grid helpers
  affectedOrgHyperlinkDisplay(dataRow, currentIdentifier): boolean {
    return true;
  }

  affectedUserHyperlinkDisplayLogic(dataRow, currentIdentifier): boolean {
    return false;
  }

  //handlers
  setBeginDate(beginDate) {
    this.beginDate = beginDate;
    const tempDate = new Date(beginDate);

    this.localParams.beginDate =
      tempDate?.toString() !== "Invalid Date"
        ? this.localParams.toUtcDate(tempDate)
        : null;
    this.updateRouteQueryParams();
    this.eventModelSubject.next(this.localParams);
    this.SearchOccurred.emit(this.localParams);
  }

  setEndDate(endDate) {
    this.endDate = endDate;

    const tempDate = new Date(endDate);
    this.localParams.endDate =
      tempDate?.toString() !== "Invalid Date"
        ? this.localParams.toUtcDate(tempDate)
        : null;
    this.updateRouteQueryParams();
    this.eventModelSubject.next(this.localParams);
    this.SearchOccurred.emit(this.localParams);
  }

  setDates(events) {
    if (!events || !events[0] || !events[1]) {
      return;
    }

    const minYearStr = EnvService.EnvVariables().beginYearRange;
    const minYear = Number(minYearStr);
    this.beginDate = events[0];
    const tempBeginDate = new Date(events[0]);
    this.localParams.beginDate =
      tempBeginDate?.toString() !== "Invalid Date" &&
      tempBeginDate.getFullYear() >= minYear
        ? this.localParams.toUtcDate(tempBeginDate)
        : null;

    this.endDate = events[1];
    const tempEndDate = new Date(events[1]);
    this.localParams.endDate =
      tempEndDate?.toString() !== "Invalid Date" &&
      tempEndDate.getFullYear() >= minYear
        ? this.localParams.toUtcDate(tempEndDate)
        : null;

    this.updateRouteQueryParams();
    this.eventModelSubject.next(this.localParams);
    this.SearchOccurred.emit(this.localParams);
  }

  onChangeInputText(event) {
    this.localParams.inputSearchText = event;
  }

  onChangeEventType() {
    this.localParams.selectedEventType =
      this.eventType &&
      this.eventType.value &&
      this.eventType.value !== "" &&
      this.eventType.value.toLowerCase() !== "all"
        ? this.eventType.value
        : "";
    this.updateRouteQueryParams();
    this.eventModelSubject.next(this.localParams);
    this.SearchOccurred.emit(this.localParams);
  }
  //route updater
  updateRouteQueryParams() {
    this.localParams.isDirty = true;

    //handling edge case with table sorting of Timestamp
    if (!this.localParams.isDifferent(this.routeParams)) {
      this.eventsGridConfiguration.Loading = false;
    }
    if (
      this.localParams.beginDate !== this.routeParams.beginDate ||
      this.localParams.endDate !== this.routeParams.endDate ||
      this.localParams.currentSearchText !==
        this.routeParams.currentSearchText ||
      this.localParams.selectedEventType !== this.routeParams.selectedEventType
    ) {
      this.localParams.pageNumber = 0;
      this.eventsGridConfiguration.FirstRow = 0;
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      switch (propName) {
        case "EventsSearchResults": {
          const eventsResult = changes[propName]
            .currentValue as RnEventsVMSearchResultsVM;
          if (eventsResult) {
            if (this.eventsGridConfiguration) {
              this.eventsGridConfiguration.loadingDataCompleted(
                eventsResult.Results,
                eventsResult.TotalNumberRecords,
                this.localParams.pageSize * this.localParams.pageNumber,
                this.localParams.pageNumber,
              );
            }
          }
          break;
        }
        case "IsLoadingEvents": {
          if (this.eventsGridConfiguration) {
            this.eventsGridConfiguration.Loading = changes[propName]
              .currentValue as boolean;
          }
          break;
        }
        case "ClearSearchCriteria": {
          this.clearAllSearchCriteria();
        }
      }
    }
  }

  debounce = (mainFunction, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        mainFunction(...args);
      }, delay);
    };
  };

  startDateChanged($event) {
    const dayDate = dayjs($event?.target?.value);
    const date = dayDate.toDate();
    const tempMaxDay = dayjs(this.maxDate);
    if (date && dayDate <= tempMaxDay) {
      this.localParams.beginDate = date;
      this.updateRouteQueryParams();
      this.SearchOccurred.emit(this.localParams);
      this.eventModelSubject.next(this.localParams);
      this.initialDateRange[0] = date;
      this.eventStartDate = date;
      this.minDate = dayjs(this.eventStartDate).format("YYYY-MM-DD");
      this.eventStartDateString = this.minDate;
    }
  }

  endDateChanged($event) {
    const dayDate = dayjs($event?.target?.value);
    const date = dayDate.toDate();
    const tempMinDay = dayjs(this.minDate);
    if (date && dayDate >= tempMinDay) {
      this.localParams.endDate = date;
      this.updateRouteQueryParams();
      this.SearchOccurred.emit(this.localParams);
      this.eventModelSubject.next(this.localParams);
      this.initialDateRange[1] = date;
      this.eventEndDate = date;
      this.maxDate = dayjs(this.eventEndDate).format("YYYY-MM-DD");
      this.eventEndDateString = this.maxDate;
    }
  }

  dateRangeChanged($event) {
    const dates = $event as Date[];
    if (dates && dates.length === 2 && dates[0] && dates[1]) {
      this.localParams.beginDate = dates[0];
      this.localParams.endDate = dates[1];
      this.updateRouteQueryParams();
      this.SearchOccurred.emit(this.localParams);
      this.eventModelSubject.next(this.localParams);
      this.initialDateRange = dates;
    }
  }
  private clearAllSearchCriteria(): void {
    this.localParams.currentSearchText = "";
    this.localParams.selectedEventType = "";
    const dateRange = this.getInitialDateRange();
    this.dateRangeChanged(dateRange);
  }

  private setDatesToPastWeek() {
    const dateRange = this.getInitialDateRange();
    this.localParams.beginDate = dateRange[0];
    this.localParams.endDate = dateRange[1];
    this.initialDateRange = this.dateRange;
    this.eventStartDate = dateRange[0];
    this.minDate = dayjs(this.eventStartDate).format("YYYY-MM-DD");
    this.eventStartDateString = this.minDate;
    this.eventEndDate = dateRange[1];
    this.maxDate = dayjs(this.eventEndDate).format("YYYY-MM-DD");
    this.eventEndDateString = this.maxDate;
  }

  private getInitialDateRange(): Date[] {
    const today = new Date();
    const startDate = new Date();
    const newToday = formatDate(startDate, "dd/MM/yyyy", "en-US");
    const newStart = formatDate(
      startDate.setDate(startDate.getDate() - 6),
      "dd/MM/yyyy",
      "en-US",
    );
    const formattedStartDate = dayjs(newStart, "D/M/yyyy").toDate();
    const newTodayDate = dayjs(newToday, "D/M/yyyy").toDate();
    return [formattedStartDate, newTodayDate];
  }

  get eventType() {
    return this.frmGroup.get("eventTypeSelect");
  }
  protected paramPageName: string;
  private eventModelSubject = new BehaviorSubject<EventModel>(null);
  protected eventModel: Observable<EventModel>;
}
