import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject } from "rxjs";
import { ValidatePromoCodeVM } from "../../components/rn-business-components/change-package-inventory/validate-promo-code-vm";
import { CartItem } from "./cart-item";
import { ShoppingCart } from "./shopping-cart";

@Injectable({
  providedIn: "root",
})
export class CartService {
  cart: ShoppingCart = new ShoppingCart();
  promo: ValidatePromoCodeVM = new ValidatePromoCodeVM();
  private cartSub = new BehaviorSubject<ShoppingCart>(this.cart);
  cartOb: Observable<ShoppingCart> = this.cartSub.asObservable();
  private nonPromoItems: CartItem[] = [];

  addToCart(product: CartItem) {
    const nonPromoIdx = this.nonPromoItems.findIndex(
      (p) =>
        p.Name === product.Name &&
        (!product.OrgID || product.OrgID === p.OrgID),
    );
    if (nonPromoIdx < 0) {
      const cartItem: CartItem = JSON.parse(
        JSON.stringify(product),
      ) as CartItem;
      this.nonPromoItems.push(Object.assign({}, cartItem));
    }

    const i = this.cart.items.findIndex(
      (p) =>
        p.Name === product.Name &&
        (!product.OrgID || product.OrgID === p.OrgID),
    );
    if (i > -1) {
      if (product.Quantity > 1) {
        this.cart.items[i].Quantity += product.Quantity;
      } else {
        this.cart.items[i].Quantity++;
      }
    } else {
      this.cart.items.push(product);
    }
    this.calculateTotal();
    this.calculateCount();
    if (this.promo && this.promo.promoCodeIsValid) {
      this.updateTotal(this.promo);
    }
    this.cartSub.next(this.cart);
  }

  removeFromCart(product) {
    const i = this.cart.items.findIndex(
      (p) =>
        p.PackageID == product.PackageID &&
        (!product.OrgID || product.OrgID === p.OrgID),
    );
    if (i >= 0) {
      if (this.cart.items[i].Quantity > 1) {
        this.cart.items[i].Quantity--;
      } else {
        this.cart.items.splice(i, 1);
      }
      this.calculateTotal();
      this.calculateCount();
      this.cartSub.next(this.cart);
    }
  }

  getCart(): Observable<ShoppingCart> {
    return of(this.cart);
  }

  getItems(): CartItem[] {
    return this.cart.items;
  }

  getPackageIDs(): number[] {
    const packageIDs = [];
    this.getItems()
      .filter((x) => x.ItemType === "Package")
      .forEach((c) => {
        if (c.PackageID) {
          packageIDs.push(c.PackageID);
        }
      });
    return packageIDs;
  }

  getAllIds(): number[] {
    return this.getItems().map((a) => a.PackageID);
  }

  getAddOns(): CartItem[] {
    return this.cart.items.filter((x) => x.ItemType === "Add-On");
  }

  calculateCount() {
    const count = this.cart.items
      .map((i) => i.Quantity)
      .reduce((acc, val) => acc + val, 0);
    this.cart.TotalItems = count;
  }

  calculateTotal() {
    if (!this.cart.items) {
      this.cart.CartTotal = 0;
      this.cart.CartMonthlyTotal = 0;
    }
    this.cart.CartTotal = this.cart.items.reduce((a, b) => {
      const value = !(b.DiscountPercent > 0)
        ? b.Price * b.Quantity
        : b.Price * ((100 - b.DiscountPercent) / 100) * b.Quantity;
      return a + value;
    }, 0);

    this.cart.CartMonthlyTotal = this.calculateMonthlyTotal();
  }

  calculateMonthlyTotal() {
    return this.cart.items
      .filter((i) => {
        return i.IsMonthly;
      })
      .reduce((a, b) => {
        const value =
          (b.NextBillPrice ? b.NextBillPrice : b.Price) * b.Quantity;
        return a + value;
      }, 0);
  }

  clearCart() {
    this.cart.items = [];
    this.calculateTotal();
    this.calculateCount();
    this.cartSub.next(this.cart);
    this.promo = new ValidatePromoCodeVM();
    this.nonPromoItems = [];
  }

  hasDiscounts() {
    if (this.cart.items) {
      const temp = this.cart.items.filter((i) => i.DiscountPercent > 0);
      return temp && temp.length > 0;
    }
    return false;
  }

  updateTotal(validatedPromo) {
    let nextBillTotal = 0;
    this.cart.CartTotal = 0;
    nextBillTotal = 0;
    //if a promo applies
    if (validatedPromo && validatedPromo.promoCodeIsValid) {
      if (!this.promo || !this.promo.promoCode) {
        this.nonPromoItems = JSON.parse(
          JSON.stringify(this.cart.items),
        ) as CartItem[];
      }

      this.promo = validatedPromo;
      const ids = this.promo.promoCode.PackageIds;
      this.cart.items.forEach((item) => {
        if (
          validatedPromo?.promoCode?.PackageID == -1 ||
          ids.indexOf(item.PackageID) >= 0
        ) {
          //someone applied a discount before adding promo?
          const previousDiscount = item.DiscountPercent;

          item.DiscountPercent = this.promo.promoCode.DiscountPercent;

          //undo the previous discount if there was one
          if (previousDiscount > 0) {
            item.NextBillPrice = item.Price;
          }

          //only show percent off next month if the promo lasts longer than one month
          if (validatedPromo.promoCode.PromoBillCycles > 1) {
            //now update it for the item
            item.NextBillPrice =
              item.NextBillPrice * ((100 - item.DiscountPercent) / 100);
          }
        }

        this.cart.CartTotal =
          item.DiscountPercent > 0
            ? this.cart.CartTotal +
              item.Price * ((100 - item.DiscountPercent) / 100)
            : this.cart.CartTotal +
              item.Price * (1 - item.DiscountPercent) * item.Quantity;
        nextBillTotal = nextBillTotal + item.NextBillPrice;
      });
    }
    //else just the default prices
    else {
      this.cart.items.forEach((item) => {
        if (this.promo) {
          const idx = this.nonPromoItems.findIndex((i) => {
            return item.PackageID == i.PackageID;
          });
          if (idx >= 0) {
            const nonPromoItem = this.nonPromoItems[idx];
            item.DiscountPercent = nonPromoItem.DiscountPercent;
            item.NextBillPrice = nonPromoItem.NextBillPrice;
          } else {
            item.DiscountPercent = 0;
          }
        }
        this.cart.CartTotal =
          item.DiscountPercent > 0
            ? this.cart.CartTotal +
              item.Price * ((100 - item.DiscountPercent) / 100)
            : this.cart.CartTotal +
              item.Price * (1 - item.DiscountPercent) * item.Quantity;
        nextBillTotal = nextBillTotal + item.NextBillPrice;
      });
      this.promo = null;
    }

    this.cart.CartMonthlyTotal = this.calculateMonthlyTotal();
    return nextBillTotal;
  }

  backupCartItems(): CartItem[] {
    const result: CartItem[] = [];
    this.cart.items.forEach((i) => {
      result.push(Object.assign({}, i));
    });

    return result;
  }

  restoreCartItems(backup: CartItem[]) {
    this.clearCart();
    backup.forEach((i) => {
      this.addToCart(i);
    });
  }

  removeFullRow(product) {
    const i = this.cart.items.findIndex(
      (p) =>
        p.PackageID === product.PackageID &&
        (!product.OrgID || product.OrgID === p.OrgID),
    );
    if (i >= 0) {
      this.cart.items.splice(i, 1);
      this.calculateTotal();
      this.calculateCount();
      this.cartSub.next(this.cart);
    }
  }

  getPromo() {
    return this.promo;
  }
}
