<div class="login-notification">
  <div class="modal-header header-border-none">
    <div [ngSwitch]="State">
      <h3 *ngSwitchCase="'returntotoplevel'">Rightworks Maintenance Mode</h3>
      <h3 *ngSwitchCase="'logoutonly'">
        Rightworks services for {{ OrganizationName }} are offline for
        maintenance
      </h3>
    </div>
  </div>
  <div class="modal-body">
    <div [ngSwitch]="State">
      <div *ngSwitchCase="'returntotoplevel'">
        <span>
          Hello {{ FirstName }}. <br />
          We are currently working on some Rightworks services
          behind-the-scenes. While this is processing you will not be able to
          access this client. Please wait 5 minutes and try your action again.
          if you have any questions, please contact
          {{ technical_support_phone }}.
        </span>
      </div>
      <div *ngSwitchCase="'logoutonly'">
        <span>
          Hello {{ FirstName }}. We're currently working on some Rightworks
          services behind-the-scenes. While we're working, you won't be able to
          access AppHub or your Rightworks cloud-hosted desktop. If you have any
          questions in the meantime, you can call
          {{ technical_support_phone }} and they will be happy to assist.
        </span>
      </div>
    </div>
    <br />
    <div>
      <div *ngIf="showOrgName">
        <b>Organization: </b> {{ OrganizationName }}
      </div>
      <div>
        <b>Maintenance started: </b>
        {{ StartDate | utcToLocalDate | date: "MM/dd/yyyy hh:mm:ss a Z" }}
      </div>
      <div><b>Reason: </b> {{ Reason }}</div>
    </div>
  </div>
  <div class="modal-footer footer-top-border">
    <div class="flex-row no-bottom-margin">
      <div [ngSwitch]="State">
        <app-button
          *ngSwitchCase="'returntotoplevel'"
          id="btnReturn"
          (clickActionEmitter)="goToTopLevelOrg()"
          label="Return to Top-Level Organization"
          buttonStyle="primary"
        >
        </app-button>
        <app-button
          *ngSwitchCase="'logoutonly'"
          id="btnLogout"
          (clickActionEmitter)="logout()"
          label="Logout"
          buttonStyle="teritary"
        >
        </app-button>
      </div>
    </div>
  </div>
</div>
