/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
} from "@angular/common/http";
import { CustomHttpParameterCodec } from "../encoder";
import { Observable } from "rxjs";

import { RnAddOrgIDToSignalRGroupPayload, RnTokenInputDto, RnUserLoginStateVMRNResponseRelay } from "../models/models";
import { RnAddUserIDToSignalRGroupPayload } from "../models/models";
import { RnApplicationVMListRNResponseRelay } from "../models/models";
import { RnBooleanNullableRNResponseRelay } from "../models/models";
import { RnBooleanRNResponseRelay } from "../models/models";
import { RnBooleanStringTupleRNResponseRelay } from "../models/models";
import { RnCommonId } from "../models/models";
import { RnCommonIdMulti } from "../models/models";
import { RnCommonSearch } from "../models/models";
import { RnDriveMappingVMListRNResponseRelay } from "../models/models";
import { RnEventsVMSearchResultsVMRNResponseRelay } from "../models/models";
import { RnHCValidateToken } from "../models/models";
import { RnInt64RNResponseRelay } from "../models/models";
import { RnObjectRNResponseRelay } from "../models/models";
import { RnPackageUsersAction } from "../models/models";
import { RnPollingMessageForUIUserRNResponseRelay } from "../models/models";
import { RnRNResponseMultiRNResponseRelay } from "../models/models";
import { RnRemoteAppFileVMRNResponseRelay } from "../models/models";
import { RnRemoveOrgIDFromSignalRGroupPayload } from "../models/models";
import { RnRemoveUserIDFromSignalRGroupPayload } from "../models/models";
import { RnStringRNResponseRelay } from "../models/models";
import { RnUpdateToken } from "../models/models";
import { RnUpdateTokenVMRNResponseRelay } from "../models/models";
import { RnUserAzureLoginVMRNResponseRelay } from '../models/models';
import { RnUserCreateFull } from "../models/models";
import { RnUserCreateUpdateXid } from "../models/models";
import { RnUserCustomFieldCreateUpdate } from "../models/models";
import { RnUserDriveMappingsSetMultiple } from "../models/models";
import { RnUserFeedback } from "../models/models";
import { RnUserForgotPassword } from "../models/models";
import { RnUserIsEmailUnique } from "../models/models";
import { RnUserItemFlavor } from "../models/models";
import { RnUserLogin } from "../models/models";
import { RnUserLoginVMRNResponseRelay } from "../models/models";
import { RnUserNotifyOrgMFA } from "../models/models";
import { RnUserPackagePoolAction } from "../models/models";
import { RnUserPasswordReset } from "../models/models";
import { RnUserUpdate } from "../models/models";
import { RnUserUpdatePayload } from "../models/models";
import { RnUserUserTypeAdd } from "../models/models";
import { RnUsersProfileVMRNResponseRelay } from "../models/models";
import { RnUsersSearchVMSearchResultsVMRNResponseRelay } from "../models/models";
import { BASE_PATH } from "../variables";
import { Configuration } from "../configuration";
import { RnUserDriveMappingsSetInfo } from "../models/userDriveMappingsSetInfo-d";
import { RnDriveMappingMultipleUser } from "../models/driveMappingMultipleUser-d";
import { RnUserInsightApplications } from "../models/userInsightApplications-d";

@Injectable({
  providedIn: "root",
})
export class UserService {
  protected basePath = "http://localhost";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== "string") {
      if (typeof basePath !== "string") {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string,
  ): HttpParams {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string,
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)),
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            value.toISOString().substr(0, 10),
          );
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
          (httpParams = this.addToHttpParamsRecursive(
            httpParams,
            value[k],
            key != null ? `${key}.${k}` : k,
          )),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }

  /**
     * ( Generates a token based on an already generated token )
     * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
     * @param rnTokenInputDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public apiV2TokenBasedAuthenticationPost(rnTokenInputDto?: RnTokenInputDto, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' }): Observable<RnUserLoginVMRNResponseRelay>
    ;
  public apiV2TokenBasedAuthenticationPost(rnTokenInputDto?: RnTokenInputDto, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' }): Observable<HttpResponse<RnUserLoginVMRNResponseRelay>>;
  public apiV2TokenBasedAuthenticationPost(rnTokenInputDto?: RnTokenInputDto, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' }): Observable<HttpEvent<RnUserLoginVMRNResponseRelay>>;
  public apiV2TokenBasedAuthenticationPost(rnTokenInputDto?: RnTokenInputDto, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' }): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined = this.configuration.apiKeys["Bearer"] || this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set('Authorization', key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'text/plain',
        'application/json',
        'text/json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.post<RnUserLoginVMRNResponseRelay>(`${this.configuration.basePath}/api/v2/token-based-authentication`,
      rnTokenInputDto,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * ( Add OrgID To SignalR Group )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnAddOrgIDToSignalRGroupPayload
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersAddorgidtosignalrgroupPost(
    rnAddOrgIDToSignalRGroupPayload?: RnAddOrgIDToSignalRGroupPayload,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnBooleanRNResponseRelay>;
  public apiV2UsersAddorgidtosignalrgroupPost(
    rnAddOrgIDToSignalRGroupPayload?: RnAddOrgIDToSignalRGroupPayload,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnBooleanRNResponseRelay>>;
  public apiV2UsersAddorgidtosignalrgroupPost(
    rnAddOrgIDToSignalRGroupPayload?: RnAddOrgIDToSignalRGroupPayload,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnBooleanRNResponseRelay>>;
  public apiV2UsersAddorgidtosignalrgroupPost(
    rnAddOrgIDToSignalRGroupPayload?: RnAddOrgIDToSignalRGroupPayload,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnBooleanRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/addorgidtosignalrgroup`,
      rnAddOrgIDToSignalRGroupPayload,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Add UserID To SignalR Group )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnAddUserIDToSignalRGroupPayload
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersAdduseridtosignalrgroupPost(
    rnAddUserIDToSignalRGroupPayload?: RnAddUserIDToSignalRGroupPayload,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnBooleanRNResponseRelay>;
  public apiV2UsersAdduseridtosignalrgroupPost(
    rnAddUserIDToSignalRGroupPayload?: RnAddUserIDToSignalRGroupPayload,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnBooleanRNResponseRelay>>;
  public apiV2UsersAdduseridtosignalrgroupPost(
    rnAddUserIDToSignalRGroupPayload?: RnAddUserIDToSignalRGroupPayload,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnBooleanRNResponseRelay>>;
  public apiV2UsersAdduseridtosignalrgroupPost(
    rnAddUserIDToSignalRGroupPayload?: RnAddUserIDToSignalRGroupPayload,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnBooleanRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/adduseridtosignalrgroup`,
      rnAddUserIDToSignalRGroupPayload,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Add/update the role (userType) of the selected user )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; CHNGUSRROL
   * @param rnUserUserTypeAdd Contains the id of the user to modify and the id of the role to set them to
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersAddusertypePost(
    rnUserUserTypeAdd?: RnUserUserTypeAdd,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnBooleanNullableRNResponseRelay>;
  public apiV2UsersAddusertypePost(
    rnUserUserTypeAdd?: RnUserUserTypeAdd,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnBooleanNullableRNResponseRelay>>;
  public apiV2UsersAddusertypePost(
    rnUserUserTypeAdd?: RnUserUserTypeAdd,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnBooleanNullableRNResponseRelay>>;
  public apiV2UsersAddusertypePost(
    rnUserUserTypeAdd?: RnUserUserTypeAdd,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnBooleanNullableRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/addusertype`,
      rnUserUserTypeAdd,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets the applications that are available for a User )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonId Contains the Id of the user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersApplicationsPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnApplicationVMListRNResponseRelay>;
  public apiV2UsersApplicationsPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnApplicationVMListRNResponseRelay>>;
  public apiV2UsersApplicationsPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnApplicationVMListRNResponseRelay>>;
  public apiV2UsersApplicationsPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnApplicationVMListRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/applications`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
     * ( Get Azure login info for a given user Id )
     * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
     * @param rnCommonId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public apiV2UsersAzureVirtualDesktopsGet(rnCommonId?: RnCommonId, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' }): Observable<RnUserAzureLoginVMRNResponseRelay>
    ;
  public apiV2UsersAzureVirtualDesktopsGet(rnCommonId?: RnCommonId, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' }): Observable<HttpResponse<RnUserAzureLoginVMRNResponseRelay>>;
  public apiV2UsersAzureVirtualDesktopsGet(rnCommonId?: RnCommonId, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' }): Observable<HttpEvent<RnUserAzureLoginVMRNResponseRelay>>;
  public apiV2UsersAzureVirtualDesktopsGet(rnCommonId?: RnCommonId, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' }): Observable<any> {

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (rnCommonId !== undefined && rnCommonId !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>rnCommonId.Id,
        "userid",
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined = this.configuration.apiKeys["Bearer"] || this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set('Authorization', key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'text/plain',
        'application/json',
        'text/json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<RnUserAzureLoginVMRNResponseRelay>(`${this.configuration.basePath}/api/v2/users/azure-virtual-desktops`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * ( Clears an item that has failed to install to a user )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; ASNRMOWNPK,ASNRMALLPK,ACCAPPCTLG
   * @param rnUserItemFlavor Contains the Id of the User and information on the Item that failed to install to the user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersClearfaileditemPost(
    rnUserItemFlavor?: RnUserItemFlavor,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2UsersClearfaileditemPost(
    rnUserItemFlavor?: RnUserItemFlavor,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2UsersClearfaileditemPost(
    rnUserItemFlavor?: RnUserItemFlavor,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2UsersClearfaileditemPost(
    rnUserItemFlavor?: RnUserItemFlavor,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/clearfaileditem`,
      rnUserItemFlavor,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersCloseallappsPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnBooleanRNResponseRelay>;
  public apiV2UsersCloseallappsPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnBooleanRNResponseRelay>>;
  public apiV2UsersCloseallappsPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnBooleanRNResponseRelay>>;
  public apiV2UsersCloseallappsPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnBooleanRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/closeallapps`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnDriveMappingMulitpleUser
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersCreatedrivemappingsPost(
    rnDriveMappingMulitpleUser?: RnDriveMappingMultipleUser,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnRNResponseMultiRNResponseRelay>;
  public apiV2UsersCreatedrivemappingsPost(
    rnDriveMappingMulitpleUser?: RnDriveMappingMultipleUser,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnRNResponseMultiRNResponseRelay>>;
  public apiV2UsersCreatedrivemappingsPost(
    rnDriveMappingMulitpleUser?: RnDriveMappingMultipleUser,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnRNResponseMultiRNResponseRelay>>;
  public apiV2UsersCreatedrivemappingsPost(
    rnDriveMappingMulitpleUser?: RnDriveMappingMultipleUser,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnRNResponseMultiRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/createdrivemappings`,
      rnDriveMappingMulitpleUser,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnDriveMappingMultipleUser
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersDeletedrivemappingsPost(
    rnDriveMappingMultipleUser?: RnDriveMappingMultipleUser,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnRNResponseMultiRNResponseRelay>;
  public apiV2UsersDeletedrivemappingsPost(
    rnDriveMappingMultipleUser?: RnDriveMappingMultipleUser,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnRNResponseMultiRNResponseRelay>>;
  public apiV2UsersDeletedrivemappingsPost(
    rnDriveMappingMultipleUser?: RnDriveMappingMultipleUser,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnRNResponseMultiRNResponseRelay>>;
  public apiV2UsersDeletedrivemappingsPost(
    rnDriveMappingMultipleUser?: RnDriveMappingMultipleUser,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnRNResponseMultiRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/deletedrivemappings`,
      rnDriveMappingMultipleUser,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * (Create new user for an organization)
   * &lt;br&gt;    &lt;b&gt;Roles:&lt;/b&gt; ADDNWUSERS  &lt;br&gt;    &lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer Token&lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; ADDNWUSERS
   * @param rnUserCreateFull
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersCreateuserPost(
    rnUserCreateFull?: RnUserCreateFull,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnInt64RNResponseRelay>;
  public apiV2UsersCreateuserPost(
    rnUserCreateFull?: RnUserCreateFull,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnInt64RNResponseRelay>>;
  public apiV2UsersCreateuserPost(
    rnUserCreateFull?: RnUserCreateFull,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnInt64RNResponseRelay>>;
  public apiV2UsersCreateuserPost(
    rnUserCreateFull?: RnUserCreateFull,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnInt64RNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/createuser`,
      rnUserCreateFull,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Close the users associated with the user Ids in the payload )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; DELETUSERS
   * @param rnPackageUsersAction Contains the ids of the users that will be closed
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersDeleteusersPost(
    rnPackageUsersAction?: RnPackageUsersAction,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnRNResponseMultiRNResponseRelay>;
  public apiV2UsersDeleteusersPost(
    rnPackageUsersAction?: RnPackageUsersAction,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnRNResponseMultiRNResponseRelay>>;
  public apiV2UsersDeleteusersPost(
    rnPackageUsersAction?: RnPackageUsersAction,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnRNResponseMultiRNResponseRelay>>;
  public apiV2UsersDeleteusersPost(
    rnPackageUsersAction?: RnPackageUsersAction,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnRNResponseMultiRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/deleteusers`,
      rnPackageUsersAction,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets events associated with the user Id in the payload )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; VIEWEVENTS
   * @param rnCommonSearch Contains the id of the user and other general search parameters (page, orderBy, etc)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersEventsPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnEventsVMSearchResultsVMRNResponseRelay>;
  public apiV2UsersEventsPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnEventsVMSearchResultsVMRNResponseRelay>>;
  public apiV2UsersEventsPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnEventsVMSearchResultsVMRNResponseRelay>>;
  public apiV2UsersEventsPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnEventsVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/events`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Notification to the system that a user has forgotten their password )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param rnUserForgotPassword Contains the id of the user that forgot their password
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersForgotpasswordPost(
    rnUserForgotPassword?: RnUserForgotPassword,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnBooleanNullableRNResponseRelay>;
  public apiV2UsersForgotpasswordPost(
    rnUserForgotPassword?: RnUserForgotPassword,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnBooleanNullableRNResponseRelay>>;
  public apiV2UsersForgotpasswordPost(
    rnUserForgotPassword?: RnUserForgotPassword,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnBooleanNullableRNResponseRelay>>;
  public apiV2UsersForgotpasswordPost(
    rnUserForgotPassword?: RnUserForgotPassword,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnBooleanNullableRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/forgotpassword`,
      rnUserForgotPassword,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Get the activation link for the selected user )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; SNDUSRWLCM
   * @param rnCommonId contains the id of the user to get the activation link for
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersGetactivationlinkPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnStringRNResponseRelay>;
  public apiV2UsersGetactivationlinkPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnStringRNResponseRelay>>;
  public apiV2UsersGetactivationlinkPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnStringRNResponseRelay>>;
  public apiV2UsersGetactivationlinkPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnStringRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/getactivationlink`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Checks user status via polling
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonSearch
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersGetadduserstatePost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnPollingMessageForUIUserRNResponseRelay>;
  public apiV2UsersGetadduserstatePost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnPollingMessageForUIUserRNResponseRelay>>;
  public apiV2UsersGetadduserstatePost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnPollingMessageForUIUserRNResponseRelay>>;
  public apiV2UsersGetadduserstatePost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnPollingMessageForUIUserRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/getadduserstate`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param rnUserDriveMappingsSetInfo
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersGetaccessmanagementtakendrivecountPost(
    rnUserDriveMappingsSetInfo?: RnUserDriveMappingsSetInfo,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnInt64RNResponseRelay>;
  public apiV2UsersGetaccessmanagementtakendrivecountPost(
    rnUserDriveMappingsSetInfo?: RnUserDriveMappingsSetInfo,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnInt64RNResponseRelay>>;
  public apiV2UsersGetaccessmanagementtakendrivecountPost(
    rnUserDriveMappingsSetInfo?: RnUserDriveMappingsSetInfo,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnInt64RNResponseRelay>>;
  public apiV2UsersGetaccessmanagementtakendrivecountPost(
    rnUserDriveMappingsSetInfo?: RnUserDriveMappingsSetInfo,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnInt64RNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/getaccessmanagementtakendrivecount`,
      rnUserDriveMappingsSetInfo,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets the drive mappings that are available for a User)
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonId Contains the Id of the user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersGetavailabledrivemappingsPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnDriveMappingVMListRNResponseRelay>;
  public apiV2UsersGetavailabledrivemappingsPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnDriveMappingVMListRNResponseRelay>>;
  public apiV2UsersGetavailabledrivemappingsPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnDriveMappingVMListRNResponseRelay>>;
  public apiV2UsersGetavailabledrivemappingsPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnDriveMappingVMListRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/getavailabledrivemappings`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Get user profile for a given user Id )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersGetuserPost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnUsersProfileVMRNResponseRelay>;
  public apiV2UsersGetuserPost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnUsersProfileVMRNResponseRelay>>;
  public apiV2UsersGetuserPost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnUsersProfileVMRNResponseRelay>>;
  public apiV2UsersGetuserPost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnUsersProfileVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/getuser`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; ASNRMOWNPK,ASNRMALLPK,ACCAPPCTLG
   * @param rnUserInsightApplications
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersInstallinsightapplicationsPost(
    rnUserInsightApplications?: RnUserInsightApplications,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2UsersInstallinsightapplicationsPost(
    rnUserInsightApplications?: RnUserInsightApplications,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2UsersInstallinsightapplicationsPost(
    rnUserInsightApplications?: RnUserInsightApplications,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2UsersInstallinsightapplicationsPost(
    rnUserInsightApplications?: RnUserInsightApplications,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/installinsightapplications`,
      rnUserInsightApplications,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Attempts to install the specified item to the User )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; ASNRMOWNPK,ASNRMALLPK,ACCAPPCTLG
   * @param rnUserItemFlavor Contains the Id of the User and information on the Item to install to the user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersInstallitemPost(
    rnUserItemFlavor?: RnUserItemFlavor,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2UsersInstallitemPost(
    rnUserItemFlavor?: RnUserItemFlavor,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2UsersInstallitemPost(
    rnUserItemFlavor?: RnUserItemFlavor,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2UsersInstallitemPost(
    rnUserItemFlavor?: RnUserItemFlavor,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/installitem`,
      rnUserItemFlavor,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Check if an email is unique
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnUserIsEmailUnique
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersIsemailuniquePost(
    rnUserIsEmailUnique?: RnUserIsEmailUnique,
    observe?: "body",
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined },
  ): Observable<any>;
  public apiV2UsersIsemailuniquePost(
    rnUserIsEmailUnique?: RnUserIsEmailUnique,
    observe?: "response",
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined },
  ): Observable<HttpResponse<any>>;
  public apiV2UsersIsemailuniquePost(
    rnUserIsEmailUnique?: RnUserIsEmailUnique,
    observe?: "events",
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined },
  ): Observable<HttpEvent<any>>;
  public apiV2UsersIsemailuniquePost(
    rnUserIsEmailUnique?: RnUserIsEmailUnique,
    observe: any = "body",
    reportProgress = false,
    options?: { httpHeaderAccept?: undefined },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/api/v2/users/isemailunique`,
      rnUserIsEmailUnique,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Generates a token for a user with provided credentials )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param rnUserLogin
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersLoginPost(
    rnUserLogin?: RnUserLogin,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnUserLoginVMRNResponseRelay>;
  public apiV2UsersLoginPost(
    rnUserLogin?: RnUserLogin,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnUserLoginVMRNResponseRelay>>;
  public apiV2UsersLoginPost(
    rnUserLogin?: RnUserLogin,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnUserLoginVMRNResponseRelay>>;
  public apiV2UsersLoginPost(
    rnUserLogin?: RnUserLogin,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnUserLoginVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/login`,
      rnUserLogin,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersLoginstateGet(observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' }): Observable<RnUserLoginStateVMRNResponseRelay>
    ;
  public apiV2UsersLoginstateGet(observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' }): Observable<HttpResponse<RnUserLoginStateVMRNResponseRelay>>;
  public apiV2UsersLoginstateGet(observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' }): Observable<HttpEvent<RnUserLoginStateVMRNResponseRelay>>;
  public apiV2UsersLoginstateGet(observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' }): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined = this.configuration.apiKeys["Bearer"] || this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set('Authorization', key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'text/plain',
        'application/json',
        'text/json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<RnUserLoginStateVMRNResponseRelay>(`${this.configuration.basePath}/api/v2/users/loginstate`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param rnUserNotifyOrgMFA
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersNotifyorgmfaenabledPost(
    rnUserNotifyOrgMFA?: RnUserNotifyOrgMFA,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnBooleanRNResponseRelay>;
  public apiV2UsersNotifyorgmfaenabledPost(
    rnUserNotifyOrgMFA?: RnUserNotifyOrgMFA,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnBooleanRNResponseRelay>>;
  public apiV2UsersNotifyorgmfaenabledPost(
    rnUserNotifyOrgMFA?: RnUserNotifyOrgMFA,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnBooleanRNResponseRelay>>;
  public apiV2UsersNotifyorgmfaenabledPost(
    rnUserNotifyOrgMFA?: RnUserNotifyOrgMFA,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnBooleanRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/notifyorgmfaenabled`,
      rnUserNotifyOrgMFA,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Package Pool Action
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnUserPackagePoolAction
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersPackagepoolactionPost(
    rnUserPackagePoolAction?: RnUserPackagePoolAction,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2UsersPackagepoolactionPost(
    rnUserPackagePoolAction?: RnUserPackagePoolAction,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2UsersPackagepoolactionPost(
    rnUserPackagePoolAction?: RnUserPackagePoolAction,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2UsersPackagepoolactionPost(
    rnUserPackagePoolAction?: RnUserPackagePoolAction,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/packagepoolaction`,
      rnUserPackagePoolAction,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Package Pool Action Multiple
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnUserPackagePoolAction
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersPackagepoolactionmultiplePost(
    rnUserPackagePoolAction?: Array<RnUserPackagePoolAction>,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnRNResponseMultiRNResponseRelay>;
  public apiV2UsersPackagepoolactionmultiplePost(
    rnUserPackagePoolAction?: Array<RnUserPackagePoolAction>,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnRNResponseMultiRNResponseRelay>>;
  public apiV2UsersPackagepoolactionmultiplePost(
    rnUserPackagePoolAction?: Array<RnUserPackagePoolAction>,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnRNResponseMultiRNResponseRelay>>;
  public apiV2UsersPackagepoolactionmultiplePost(
    rnUserPackagePoolAction?: Array<RnUserPackagePoolAction>,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnRNResponseMultiRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/packagepoolactionmultiple`,
      rnUserPackagePoolAction,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Updates user profile
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnUserUpdate
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersProfilePost(
    rnUserUpdate?: RnUserUpdate,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2UsersProfilePost(
    rnUserUpdate?: RnUserUpdate,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2UsersProfilePost(
    rnUserUpdate?: RnUserUpdate,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2UsersProfilePost(
    rnUserUpdate?: RnUserUpdate,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/profile`,
      rnUserUpdate,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Returns the info to run a remote app for the user )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnUserItemFlavor Contains the Id of the User and information on the Item to run a remote app
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersRemoteapprdpfilePost(
    rnUserItemFlavor?: RnUserItemFlavor,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnRemoteAppFileVMRNResponseRelay>;
  public apiV2UsersRemoteapprdpfilePost(
    rnUserItemFlavor?: RnUserItemFlavor,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnRemoteAppFileVMRNResponseRelay>>;
  public apiV2UsersRemoteapprdpfilePost(
    rnUserItemFlavor?: RnUserItemFlavor,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnRemoteAppFileVMRNResponseRelay>>;
  public apiV2UsersRemoteapprdpfilePost(
    rnUserItemFlavor?: RnUserItemFlavor,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnRemoteAppFileVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/remoteapprdpfile`,
      rnUserItemFlavor,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Removes an item that has been installed to a user )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; ASNRMOWNPK,ASNRMALLPK,ACCAPPCTLG
   * @param rnUserItemFlavor Contains the Id of the User and information on the Item that needs to be removed from the user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersRemoveitemPost(
    rnUserItemFlavor?: RnUserItemFlavor,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2UsersRemoveitemPost(
    rnUserItemFlavor?: RnUserItemFlavor,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2UsersRemoveitemPost(
    rnUserItemFlavor?: RnUserItemFlavor,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2UsersRemoveitemPost(
    rnUserItemFlavor?: RnUserItemFlavor,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/removeitem`,
      rnUserItemFlavor,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Remove OrgID From SignalR Group )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnRemoveOrgIDFromSignalRGroupPayload
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersRemoveorgidfromsignalrgroupPost(
    rnRemoveOrgIDFromSignalRGroupPayload?: RnRemoveOrgIDFromSignalRGroupPayload,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnBooleanRNResponseRelay>;
  public apiV2UsersRemoveorgidfromsignalrgroupPost(
    rnRemoveOrgIDFromSignalRGroupPayload?: RnRemoveOrgIDFromSignalRGroupPayload,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnBooleanRNResponseRelay>>;
  public apiV2UsersRemoveorgidfromsignalrgroupPost(
    rnRemoveOrgIDFromSignalRGroupPayload?: RnRemoveOrgIDFromSignalRGroupPayload,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnBooleanRNResponseRelay>>;
  public apiV2UsersRemoveorgidfromsignalrgroupPost(
    rnRemoveOrgIDFromSignalRGroupPayload?: RnRemoveOrgIDFromSignalRGroupPayload,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnBooleanRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/removeorgidfromsignalrgroup`,
      rnRemoveOrgIDFromSignalRGroupPayload,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Remove UserID From SignalR Group )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnRemoveUserIDFromSignalRGroupPayload
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersRemoveuseridfromsignalrgroupPost(
    rnRemoveUserIDFromSignalRGroupPayload?: RnRemoveUserIDFromSignalRGroupPayload,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnBooleanRNResponseRelay>;
  public apiV2UsersRemoveuseridfromsignalrgroupPost(
    rnRemoveUserIDFromSignalRGroupPayload?: RnRemoveUserIDFromSignalRGroupPayload,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnBooleanRNResponseRelay>>;
  public apiV2UsersRemoveuseridfromsignalrgroupPost(
    rnRemoveUserIDFromSignalRGroupPayload?: RnRemoveUserIDFromSignalRGroupPayload,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnBooleanRNResponseRelay>>;
  public apiV2UsersRemoveuseridfromsignalrgroupPost(
    rnRemoveUserIDFromSignalRGroupPayload?: RnRemoveUserIDFromSignalRGroupPayload,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnBooleanRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/removeuseridfromsignalrgroup`,
      rnRemoveUserIDFromSignalRGroupPayload,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Resend the welcome emails for the selected users )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; SNDUSRWLCM
   * @param rnCommonIdMulti contains the ids of users to resend welcome emails
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersResendwelcomeemailsPost(
    rnCommonIdMulti?: RnCommonIdMulti,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnRNResponseMultiRNResponseRelay>;
  public apiV2UsersResendwelcomeemailsPost(
    rnCommonIdMulti?: RnCommonIdMulti,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnRNResponseMultiRNResponseRelay>>;
  public apiV2UsersResendwelcomeemailsPost(
    rnCommonIdMulti?: RnCommonIdMulti,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnRNResponseMultiRNResponseRelay>>;
  public apiV2UsersResendwelcomeemailsPost(
    rnCommonIdMulti?: RnCommonIdMulti,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnRNResponseMultiRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/resendwelcomeemails`,
      rnCommonIdMulti,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * (Retry creating a new user for an organization after a failure)
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; ADDNWUSERS
   * @param rnCommonId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersRetryusercreatePost(
    rnCommonId?: RnCommonId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnInt64RNResponseRelay>;
  public apiV2UsersRetryusercreatePost(
    rnCommonId?: RnCommonId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnInt64RNResponseRelay>>;
  public apiV2UsersRetryusercreatePost(
    rnCommonId?: RnCommonId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnInt64RNResponseRelay>>;
  public apiV2UsersRetryusercreatePost(
    rnCommonId?: RnCommonId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnInt64RNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/retryusercreate`,
      rnCommonId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Searches for users by providing search criteria )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Internal&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; VIEWUSRDET
   * @param rnCommonSearch
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersSearchPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnUsersSearchVMSearchResultsVMRNResponseRelay>;
  public apiV2UsersSearchPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnUsersSearchVMSearchResultsVMRNResponseRelay>>;
  public apiV2UsersSearchPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnUsersSearchVMSearchResultsVMRNResponseRelay>>;
  public apiV2UsersSearchPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnUsersSearchVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/search`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Send emails to the user(s) to reset the password )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonIdMulti Contains a list users to send the password reset emails
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersSendresetpasswordemailsPost(
    rnCommonIdMulti?: RnCommonIdMulti,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnRNResponseMultiRNResponseRelay>;
  public apiV2UsersSendresetpasswordemailsPost(
    rnCommonIdMulti?: RnCommonIdMulti,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnRNResponseMultiRNResponseRelay>>;
  public apiV2UsersSendresetpasswordemailsPost(
    rnCommonIdMulti?: RnCommonIdMulti,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnRNResponseMultiRNResponseRelay>>;
  public apiV2UsersSendresetpasswordemailsPost(
    rnCommonIdMulti?: RnCommonIdMulti,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnRNResponseMultiRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/sendresetpasswordemails`,
      rnCommonIdMulti,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Adds a user feedback entry )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param rnUserFeedback Contains information on the user\&#39;s feedback
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersSenduserfeedbackPost(
    rnUserFeedback?: RnUserFeedback,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2UsersSenduserfeedbackPost(
    rnUserFeedback?: RnUserFeedback,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2UsersSenduserfeedbackPost(
    rnUserFeedback?: RnUserFeedback,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2UsersSenduserfeedbackPost(
    rnUserFeedback?: RnUserFeedback,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/senduserfeedback`,
      rnUserFeedback,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Sets drive mappings for an individual user )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; GRNTORGACC
   * @param rnUserDriveMappingsSetMultiple
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersSetdrivemappingsPost(
    rnUserDriveMappingsSetMultiple?: RnUserDriveMappingsSetMultiple,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnBooleanRNResponseRelay>;
  public apiV2UsersSetdrivemappingsPost(
    rnUserDriveMappingsSetMultiple?: RnUserDriveMappingsSetMultiple,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnBooleanRNResponseRelay>>;
  public apiV2UsersSetdrivemappingsPost(
    rnUserDriveMappingsSetMultiple?: RnUserDriveMappingsSetMultiple,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnBooleanRNResponseRelay>>;
  public apiV2UsersSetdrivemappingsPost(
    rnUserDriveMappingsSetMultiple?: RnUserDriveMappingsSetMultiple,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnBooleanRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/setdrivemappings`,
      rnUserDriveMappingsSetMultiple,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Resets a user\&#39;s password )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param rnUserPasswordReset Contains the id of the user and the necessary info to reset the password
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersSetpasswordPost(
    rnUserPasswordReset?: RnUserPasswordReset,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnBooleanStringTupleRNResponseRelay>;
  public apiV2UsersSetpasswordPost(
    rnUserPasswordReset?: RnUserPasswordReset,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnBooleanStringTupleRNResponseRelay>>;
  public apiV2UsersSetpasswordPost(
    rnUserPasswordReset?: RnUserPasswordReset,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnBooleanStringTupleRNResponseRelay>>;
  public apiV2UsersSetpasswordPost(
    rnUserPasswordReset?: RnUserPasswordReset,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnBooleanStringTupleRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/setpassword`,
      rnUserPasswordReset,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Sets user custom value )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnUserCustomFieldCreateUpdate
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersSetusercustomvaluePost(
    rnUserCustomFieldCreateUpdate?: RnUserCustomFieldCreateUpdate,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnBooleanRNResponseRelay>;
  public apiV2UsersSetusercustomvaluePost(
    rnUserCustomFieldCreateUpdate?: RnUserCustomFieldCreateUpdate,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnBooleanRNResponseRelay>>;
  public apiV2UsersSetusercustomvaluePost(
    rnUserCustomFieldCreateUpdate?: RnUserCustomFieldCreateUpdate,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnBooleanRNResponseRelay>>;
  public apiV2UsersSetusercustomvaluePost(
    rnUserCustomFieldCreateUpdate?: RnUserCustomFieldCreateUpdate,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnBooleanRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/setusercustomvalue`,
      rnUserCustomFieldCreateUpdate,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Generates a softauth token that is used for users with trial license )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersSoftauthPost(
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnUserLoginVMRNResponseRelay>;
  public apiV2UsersSoftauthPost(
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnUserLoginVMRNResponseRelay>>;
  public apiV2UsersSoftauthPost(
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnUserLoginVMRNResponseRelay>>;
  public apiV2UsersSoftauthPost(
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnUserLoginVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/softauth`,
      null,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Unlock the AD accounts of the selected users )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; UNLOKUSERS
   * @param rnCommonIdMulti contains the ids of the users to unlock
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersUnlockusersPost(
    rnCommonIdMulti?: RnCommonIdMulti,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnRNResponseMultiRNResponseRelay>;
  public apiV2UsersUnlockusersPost(
    rnCommonIdMulti?: RnCommonIdMulti,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnRNResponseMultiRNResponseRelay>>;
  public apiV2UsersUnlockusersPost(
    rnCommonIdMulti?: RnCommonIdMulti,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnRNResponseMultiRNResponseRelay>>;
  public apiV2UsersUnlockusersPost(
    rnCommonIdMulti?: RnCommonIdMulti,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnRNResponseMultiRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/unlockusers`,
      rnCommonIdMulti,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Update the user based onthe contents of the payload )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnUserUpdatePayload Contains a list of Properties on the user to modify
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersUpdatePost(
    rnUserUpdatePayload?: RnUserUpdatePayload,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnBooleanRNResponseRelay>;
  public apiV2UsersUpdatePost(
    rnUserUpdatePayload?: RnUserUpdatePayload,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnBooleanRNResponseRelay>>;
  public apiV2UsersUpdatePost(
    rnUserUpdatePayload?: RnUserUpdatePayload,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnBooleanRNResponseRelay>>;
  public apiV2UsersUpdatePost(
    rnUserUpdatePayload?: RnUserUpdatePayload,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnBooleanRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/update`,
      rnUserUpdatePayload,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Generates a new token by providing old generated valid token )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param rnUpdateToken
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersUpdatetokenPost(
    rnUpdateToken?: RnUpdateToken,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnUpdateTokenVMRNResponseRelay>;
  public apiV2UsersUpdatetokenPost(
    rnUpdateToken?: RnUpdateToken,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnUpdateTokenVMRNResponseRelay>>;
  public apiV2UsersUpdatetokenPost(
    rnUpdateToken?: RnUpdateToken,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnUpdateTokenVMRNResponseRelay>>;
  public apiV2UsersUpdatetokenPost(
    rnUpdateToken?: RnUpdateToken,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnUpdateTokenVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/updatetoken`,
      rnUpdateToken,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Add or Update the XID of a user )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; ADDUPDTXID
   * @param rnUserCreateUpdateXid Contains the info to add or update the user\&#39;s XID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersUpdatexidPost(
    rnUserCreateUpdateXid?: RnUserCreateUpdateXid,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnBooleanRNResponseRelay>;
  public apiV2UsersUpdatexidPost(
    rnUserCreateUpdateXid?: RnUserCreateUpdateXid,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnBooleanRNResponseRelay>>;
  public apiV2UsersUpdatexidPost(
    rnUserCreateUpdateXid?: RnUserCreateUpdateXid,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnBooleanRNResponseRelay>>;
  public apiV2UsersUpdatexidPost(
    rnUserCreateUpdateXid?: RnUserCreateUpdateXid,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnBooleanRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/updatexid`,
      rnUserCreateUpdateXid,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Notification to the system that a user has forgotten their password )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param rnHCValidateToken Contains the id of the user that forgot their password
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2UsersValidatehmactokenPost(
    rnHCValidateToken?: RnHCValidateToken,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnBooleanStringTupleRNResponseRelay>;
  public apiV2UsersValidatehmactokenPost(
    rnHCValidateToken?: RnHCValidateToken,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnBooleanStringTupleRNResponseRelay>>;
  public apiV2UsersValidatehmactokenPost(
    rnHCValidateToken?: RnHCValidateToken,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnBooleanStringTupleRNResponseRelay>>;
  public apiV2UsersValidatehmactokenPost(
    rnHCValidateToken?: RnHCValidateToken,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnBooleanStringTupleRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/users/validatehmactoken`,
      rnHCValidateToken,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }
}
