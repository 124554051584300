<div class="list-container">
  <div
    *ngFor="let c of cardList"
    [ngClass]="{ 'card-spacing': canUseNewTiles }"
  >
    <app-application-card
      [userID]="userID"
      [card]="c"
      (cardRequestEvent)="handleCardEvent($event)"
      [canUseOpenButton]="canUseOpenButton"
      [canUseNewTiles]="canUseNewTiles"
    ></app-application-card>
  </div>
  <div
    *ngFor="let l of localAppList"
    [ngClass]="{ 'card-spacing': canUseNewTiles }"
  >
    <app-local-app-card
      [userID]="userID"
      [card]="l"
      [canUseNewTiles]="canUseNewTiles"
    ></app-local-app-card>
  </div>
</div>
