import { TableSelectable } from "../../../../../core/interfaces/table-selectable";
import { GridColumnBaseConfiguration } from "./grid-column-base-configuration";

export class GridCheckLimitColumnConfiguration extends GridColumnBaseConfiguration {
  constructor(
    fieldName: string,
    title: string,
    classname: string,
    sortOrder: string,
  ) {
    super("checkboxLimit", fieldName, title, classname, sortOrder);
    this.SortableColumnDisabled = true;
  }

  public MaxLimit: () => number;
  public LimitExceeded: () => void;
  public GetNumSelected: () => number;
  public SelectedItems: TableSelectable[];

  public TotalItemCount: () => number;
  public SelectAllChange: ($event, allSelected: boolean) => void;
  public SelectAllDisabled: () => boolean;
  public SelectAllTooltip: () => string;
  public SelectAllAvailable: boolean;
  public selectAllDisabled: boolean;
  public CheckChange: ($event, rowData: any) => void;
}
