/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export class RnOrganizationConfigurationVM {
  ID?: number;
  DistributedOrganization?: boolean;
  ChannelPartner?: boolean;
  InitiatedBillingSeparation?: boolean;
  DisallowSubOrganizations?: boolean;
  DisallowDirectBilling?: boolean;
  AllowedToChangeParentBill?: boolean;
  DisallowDirectBillingReason?: string | null;
  OverridingAppPools?: Array<string> | null;
  InitiatedBillingSeparationDate?: string;
  DisallowLicensePurchases?: boolean;
  DistributedOrgDefaultPackageID?: number | null;
  DisallowMFA?: boolean;
  EnableMFA?: string | null;
  DisallowLicenses?: boolean;
  DisallowEditingOrgInfo?: boolean;
  DisallowCollaboration?: boolean;
  DisallowRNSupport?: boolean;
  DisallowAccountOwnerTransfer?: boolean;
  DisallowSettingsTab?: boolean;
  IsInternal?: boolean;
  WelcomeWizardShown?: boolean;
  AllowSalesforceChat?: boolean;
  MopTenantStatus?: string | null;
  ShowUpgradeToAppHub?: boolean;
  IsTestOrg?: boolean;
  DisallowRemoteDesktopMenuItem?: boolean;
  HideSetupWizardLinks?: boolean;
  AllowPromoCodeInput?: boolean;
  CustomerSegment?: string | null;
  IndustryType?: string | null;
  OtherIndustryTypeName?: string | null;
}
