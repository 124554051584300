var SalesforceChatWrapper = function () {

  this.loadChat = function (chatSettings) {
    var initESW = function (gslbBaseURL) {
      if(chatSettings.chatHasLoaded)
        { 
          return 
        }
      embedded_svc.settings.displayHelpButton = chatSettings.showHelpBtn;
      embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'
      embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      embedded_svc.settings.entryFeature = 'LiveAgent';
      embedded_svc.init(
        chatSettings.BaseURL,
        chatSettings.SupportURL,
        gslbBaseURL,
        chatSettings.InitId,
        chatSettings.DeploymentName,
        {
          baseLiveAgentContentURL: chatSettings.BaseLiveAgentContentURL,
          deploymentId: chatSettings.DeploymentId,
          buttonId: chatSettings.ButtonId,
          baseLiveAgentURL: chatSettings.BaseLiveAgentUrl,
          eswLiveAgentDevName: chatSettings.ESWLiveAgentDevName,
          isOfflineSupportEnabled: false
        }
      );
    };

    // Pre-chat info, determines how this stuff gets populated up in Salesforce for tracking/reports
    embedded_svc.settings.extraPrechatInfo = [{
      "entityName": "Contact",
      "showOnCreate": true,
      "linkToEntityName": "Case",
      "linkToEntityField": "ContactId",
      "saveToTranscript": "ContactId",
      "entityFieldMaps": [{
        "doCreate": false,
        "doFind": false,
        "fieldName": "FirstName",
        "isExactMatch": true,
        "label": "First Name"
      }, {
        "doCreate": false,
        "doFind": false,
        "fieldName": "LastName",
        "isExactMatch": true,
        "label": "Last Name"
      }, {
        "doCreate": false,
        "doFind": true,
        "fieldName": "Email",
        "isExactMatch": true,
        "label": "Email"
      }],
    }, {
      "entityName": "Case",
      "showOnCreate": true,
      "saveToTranscript": "CaseId",
      "entityFieldMaps": [{
        "isExactMatch": false,
        "fieldName": "Subject",
        "doCreate": true,
        "doFind": false,
        "label": "Issue"
      },

      {
        "isExactMatch": false,
        "fieldName": "ReceivedAlert",
        "doCreate": true,
        "doFind": false,
        "value": "False",
        "label": "ReceivedAlert"
      },

      {
        "isExactMatch": false,
        "fieldName": "Origin",
        "doCreate": true,
        "doFind": false,
        "label": "Origin"
      }]

    }];

    embedded_svc.settings.extraPrechatFormDetails = [
      { "label": "First Name", "transcriptFields": ["Pre_Chat_Form_First_Name__c"] },
      { "label": "Last Name", "transcriptFields": ["Pre_Chat_Form_Last_Name__c"] },
      { "label": "Email", "transcriptFields": ["Pre_Chat_Form_Email__c"] },
      { "label": "Company Name", "transcriptFields": ["Pre_Chat_Form_Company_Name__c"] },
      { "label": "Brief Summary of Issue", "transcriptFields": ["Pre_Chat_Form_Issue_Description__c"] },
    ];

    embedded_svc.settings.prepopulatedPrechatFields = {
      FirstName: chatSettings.FirstName, 
      LastName: chatSettings.LastName, 
      Email: chatSettings.Email, 
      SuppliedCompany: chatSettings.OrganizationName 
    };

    embedded_svc.settings.targetElement = document.body;
    if (!window.embedded_svc) {
      var s = document.createElement('script');
      s.setAttribute('src', chatSettings.JSSource);
      s.onload = function () {
        initESW(null);
      };
      document.body.appendChild(s);
    } else {
      initESW('https://service.force.com');
    }
        
    var displayButton = document.getElementsByClassName("embeddedServiceHelpButton")[0];
    var displaySidebar = document.getElementsByClassName("embeddedServiceSidebar")[0];

      if(displayButton){
        if(chatSettings.showHelpBtn == false){
          displayButton.style.display="none";
        }else{
          displayButton.style.display="initial";
        }
      }
      if(displaySidebar){
      if(chatSettings.showHelpBtn == false){
        displaySidebar.style.display="none";
      }
    }
  }
}
module.exports = SalesforceChatWrapper;
