<div class="side-nav-bar sideBarContainer">
  <div class="main-actions">
    <ul>
      <li>
        <a
          tabindex="0"
          (keyup.enter)="goToAppHubUsersPage()"
          [ngClass]="{ isSelected: isAppHubContext }"
          data-testid="linkMyApps"
          class="no-href-link"
          (click)="goToAppHubUsersPage()"
        >
          <span class="navItemText">
            <app-icon source="rn-grid" imgClass="filter-white"></app-icon>&nbsp;
            My Apps
          </span>
        </a>
      </li>
      <li *ngIf="!isAzure">
        <a
          tabindex="0"
          (keyup.enter)="openFileManager()"
          class="no-href-link"
          (click)="openFileManager()"
          data-testid="linkFileManager"
        >
          <span class="navItemText">
            <app-icon source="rn-file" imgClass="filter-white"></app-icon>&nbsp;
            File Manager
          </span>
        </a>
      </li>
      <li *ngIf="showAdminConsole">
        <a
          tabindex="0"
          (keyup.enter)="goToAdminConsole()"
          [ngClass]="{ isSelected: isAdminContext }"
          data-testid="linkAdminConsole"
          class="no-href-link"
          (click)="goToAdminConsole()"
        >
          <span class="navItemText">
            <app-icon source="rn-settings" imgClass="filter-white"></app-icon
            >&nbsp; Admin Controls
          </span>
        </a>
      </li>
      <li *ngIf="connectUrl && showVisitConnect">
        <a
          tabindex="0"
          (keyup.enter)="goToConnect()"
          data-testid="linkVisitConnect"
          data-pendo-id="apphub-sidenav-visitConnect-link"
          class="no-href-link"
          (click)="goToConnect()"
        >
          <span class="navItemText"> Visit Connect </span>
        </a>
      </li>
      @if (showPracticeProtect && practiceProtectUrl) {
        <li>
          <a
            tabindex="0"
            (keyup.enter)="goToPracticeProtect()"
            data-testid="apphub-sidenav-practice-protect-link"
            data-pendo-id="apphub-sidenav-practice-protect-link"
            class="no-href-link"
            (click)="goToPracticeProtect()"
          >
            <span class="navItemText"> Practice Protect </span>
          </a>
        </li>
      }
    </ul>
  </div>
  <div *ngIf="isSendFeedbackShown" class="feedback">
    <ul>
      <li class="sendFeedBackButton">
        <a
          tabindex="0"
          (keyup.enter)="sendFeedback()"
          class="no-href-link"
          (click)="sendFeedback()"
          data-testid="linkSendFeedback"
        >
          <span class="navItemText">
            <app-icon source="rn-message-circle"></app-icon>
          </span>
          <span class="navItemText sendFeedbackText">SEND FEEDBACK</span>
        </a>
      </li>
    </ul>
  </div>
</div>
