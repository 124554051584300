import { Pipe, PipeTransform } from "@angular/core";
import { PackageCardData } from "../../components/rn-business-components/package-card/package-card-data";
import { RnUsersProfileVM } from "../../services/rnapi2-service/models/usersProfileVM-d";

@Pipe({
  name: "rnPackage",
})
export class RnPackagePipe implements PipeTransform {
  transform(pkg: PackageCardData, ...args: unknown[]): unknown {
    if (pkg && args && args.length > 0) {
      const prop = args[0].toString().toLowerCase();
      switch (prop) {
        case "pkgtooltip": {
          const canPurchase: boolean = args[1] as boolean;
          const canPurchaseNeedsPOF: boolean = args[2] as boolean;
          const selectedPackageID: number = args[3] as number;
          const userData: RnUsersProfileVM = args[4] as RnUsersProfileVM;
          if (
            pkg.PackageID > 0 &&
            pkg.CatalogVisibility !== "Unpurchasable" &&
            pkg.CurrentQuantity === pkg.CurrentUsed &&
            !canPurchase &&
            !canPurchaseNeedsPOF &&
            pkg.PackageID !== selectedPackageID
          ) {
            return "There are no remaining packages to assign. <br/> Contact the Account Owner to have them add more to their account.";
          }
          const taxOrAccountingAddons = this.getTaxOrAccountingAddons(
            pkg,
            userData,
          );
          if (
            this.isPackageDisabled(
              pkg,
              selectedPackageID,
              userData,
              canPurchase,
            ) &&
            taxOrAccountingAddons.length > 0
          ) {
            return (
              "This package is not available when the " +
              taxOrAccountingAddons[0] +
              " is assigned"
            );
          }
          pkg.isTooltipDisabled = true;
          return "";
        }
        case "isdisabled": {
          const canPurchase: boolean = args[1] as boolean;
          const selectedPackageID: number = args[2] as number;
          const userData: RnUsersProfileVM = args[3] as RnUsersProfileVM;
          return this.isPackageDisabled(
            pkg,
            selectedPackageID,
            userData,
            canPurchase,
          );
        }
        case "addonisdisabled": {
          const addonIsDisabled: boolean = args[1] as boolean;
          const purchRestricted: boolean = args[2] as boolean;
          return (
            addonIsDisabled ||
            (purchRestricted && pkg.CurrentQuantity === pkg.CurrentUsed)
          );
        }
        case "maxintuitaddonsforpackage": {
          const allPackages: PackageCardData[] = args[1] as PackageCardData[];
          const intuitQbesOrgBasePackage: PackageCardData =
            args[2] as PackageCardData;
          const pkgInList = allPackages.find(
            (p) => p.PackageID == pkg.PackageID,
          );
          if (
            !(
              pkgInList &&
              pkgInList.PackageCategoryID == 2 && //Intuit
              pkgInList.PackageType == "Entitlement" &&
              pkgInList.CatalogVisibility == "External"
            )
          ) {
            return null;
          }

          const intuitOrgQbesSeatsCount = intuitQbesOrgBasePackage
            ? intuitQbesOrgBasePackage.CurrentQuantity
            : 0;
          let otherAddonsCount = 0;
          for (const p of allPackages) {
            if (
              p.PackageCategoryID == 2 && //Intuit
              p.PackageType == "Entitlement" &&
              p.CatalogVisibility == "External"
            ) {
              otherAddonsCount += p.CurrentQuantity;
            }
          }

          if (
            pkgInList.PackageCategoryID == 2 && //Intuit
            pkgInList.PackageType == "Entitlement" &&
            pkgInList.CatalogVisibility == "External"
          ) {
            let max = intuitOrgQbesSeatsCount - otherAddonsCount;
            if (max < 0) {
              max = 0;
            }
            return max;
          }
        }
      }
    }
    return null;
  }

  getTaxOrAccountingAddons(
    pkg: PackageCardData,
    userData: RnUsersProfileVM,
  ): string[] {
    let result: string[] = [];
    if (userData) {
      userData.AssignedAddOns.forEach((a) => {
        if (
          pkg.ExcludingPackageNames?.filter((p) => {
            return p === a;
          }).length > 0
        ) {
          result = pkg.ExcludingPackageNames.filter((p) => {
            return p === a;
          });
        }
      });
    }

    return result;
  }

  isPackageDisabled(
    pkg: PackageCardData,
    selectedPackgeID: number,
    userData: RnUsersProfileVM,
    canPurchase: boolean,
  ): boolean {
    let result = false;
    if (pkg.PackageID === selectedPackgeID || pkg.PackageID < 0) {
      return false;
    }
    if (userData) {
      userData.AssignedAddOns.forEach((a) => {
        if (
          pkg.ExcludingPackageNames.filter((p) => {
            return p === a;
          }).length > 0
        ) {
          result = true;
        }
      });
    }

    if (
      pkg.CatalogVisibility === "Unpurchasable" &&
      pkg.CurrentQuantity === pkg.CurrentUsed
    ) {
      result = true;
    }

    if (
      pkg.CatalogVisibility !== "Unpurchasable" &&
      pkg.CurrentQuantity === pkg.CurrentUsed &&
      !canPurchase
    ) {
      result = true;
    }

    return result;
  }
}
