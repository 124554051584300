import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { UserProfileUpdateEvent } from "./profile-update-event.model";
import { RoleUpdateEvent } from "./role-update-event.model";

/**
 * ProfileModalService emits an event when the user updates their profile.
 */
@Injectable({
  providedIn: "root",
})
export class ProfileModalService {
  private subject = new Subject<any>();
  private profileUpdateSubject = new Subject<UserProfileUpdateEvent>();
  private roleUpdateSubject = new Subject<RoleUpdateEvent>();

  /**
   * @deprecated Use updateProfile instead.
   */
  setName(name: string) {
    this.subject.next(name);
  }

  refreshModal(bool: boolean) {
    this.subject.next(bool);
  }

  onUpdate(): Observable<any> {
    return this.subject.asObservable();
  }

  updateProfile(event: UserProfileUpdateEvent) {
    this.profileUpdateSubject.next(event);
  }

  profileUpdated(): Observable<UserProfileUpdateEvent> {
    return this.profileUpdateSubject.asObservable();
  }

  updateRole(event: RoleUpdateEvent) {
    this.roleUpdateSubject.next(event);
  }

  roleUpdated(): Observable<RoleUpdateEvent> {
    return this.roleUpdateSubject.asObservable();
  }
}
