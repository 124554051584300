/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export class RnOrdersVM {
  OrderID?: number;
  OrganizationID?: number;
  OrganizationXID?: string | null;
  SalesAgentID?: number;
  OrderSourceID?: number;
  OrderNumber?: string | null;
  OrderTypeID?: number;
  OrderProgramName?: string | null;
  OrderPayload?: string | null;
  OrderStatus?: string | null;
  OrderExternalID?: string | null;
  OrderReceivedDate?: string;
  OrganizationName?: string | null;
  SalesAgentName?: string | null;
  OrderSourceName?: string | null;
  OrderTypeName?: string | null;
}
