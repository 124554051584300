<app-button
  [id]="'btn' + ButtonConfiguration.ProcessID"
  [buttonStyle]="buttonClass"
  [disabled]="buttonDisabled"
  [label]="buttonText"
  [showIcon]="ButtonConfiguration.UseIcons"
  [iconClass]="buttonIcon"
  [imgSource]="ButtonConfiguration.UsePrecedingIcons ? buttonImage : ''"
  [imgClass]="imageClass"
  (clickActionEmitter)="buttonClicked($event)"
></app-button>
