import { Component, Input, OnInit } from "@angular/core";
import { LicensePurchaseConfig } from "../license-purchase-config";
import { LicensePurchaseResponse } from "../license-purchase-info";

@Component({
  selector: "app-license-purchase-items",
  templateUrl: "./license-purchase-items.component.html",
  styleUrls: ["./license-purchase-items.component.scss"],
})
export class LicensePurchaseItemsComponent implements OnInit {
  @Input() purchases: LicensePurchaseResponse[];
  @Input() licenseConfig: LicensePurchaseConfig;
  @Input() useBold = false;
  @Input() styleClass = "";
  @Input() purchaseChange = [];

  public nextBillDate: string;

  ngOnInit(): void {
    const tempDate = new Date(this.licenseConfig.paymentInfo?.NextBillDate);
    const yearVal = tempDate.getFullYear() % 2000;
    const monthVal = tempDate.getMonth() + 1;
    this.nextBillDate = `${monthVal}/${tempDate.getDate()}/${yearVal}`;
  }

  isMonthly(item: LicensePurchaseResponse): boolean {
    return item.typeName === "Leased";
  }
}
