import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { DialogTrackingService } from "../../../services/dialog/dialog-tracking.service";
import { RnUsersVM } from "../../../services/rnapi2-service/models/models";
import { OrgRenameUserLoggedInConfig } from "./rename-error-config";

@Component({
  selector: "app-rename-error",
  templateUrl: "./rename-error.component.html",
  styleUrls: ["./rename-error.component.scss"],
})
export class OrgRenameUserLoggedInDialogComponent implements OnInit {
  type: string;
  usersLoggedIn: RnUsersVM[];
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dialogTrackService: DialogTrackingService,
  ) {}

  ngOnInit(): void {
    this.dialogConfig = this.config.data as OrgRenameUserLoggedInConfig;
    this.type = this.dialogConfig.type;
    this.usersLoggedIn = this.dialogConfig.users.sort((u1, u2) => {
      const orgComp = u1.OrganizationName.localeCompare(u2.OrganizationName);
      if (orgComp === 0) {
        return u1.FullName.localeCompare(u2.FullName);
      }
      return orgComp;
    });
  }

  close() {
    this.dialogTrackService.closeDialog(this.ref);
  }

  private dialogConfig: OrgRenameUserLoggedInConfig;
}
