import { Directive, ElementRef, OnDestroy, OnInit, Self } from "@angular/core";
import { NgControl } from "@angular/forms";
import { fromEvent, Subscription } from "rxjs";

@Directive({
  selector: "[appTrimInput]",
})
export class TrimInputDirective implements OnInit, OnDestroy {
  constructor(
    private elementRef: ElementRef,
    @Self() private ngControl: NgControl,
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      fromEvent(this.elementRef.nativeElement, "blur").subscribe(() => {
        const currentValue: string = this.ngControl.value.toString();
        const whitespace = " ";
        if (
          currentValue.startsWith(whitespace) ||
          currentValue.endsWith(whitespace)
        ) {
          this.ngControl.control.patchValue(currentValue.trim());
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private subscriptions: Subscription[] = [];
}
