import { Component, OnInit } from "@angular/core";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/dynamicdialog";
import { CopyActivationLinkConfiguration } from "../../../../core/models/copyActivationLinkConfiguration";
import { ClipboardService } from "ngx-clipboard";
import { UserService } from "../../../services/rnapi2-service/apis/api";
import { RnCommonId } from "../../../services/rnapi2-service/models/models";
import { DialogTrackingService } from "../../../services/dialog/dialog-tracking.service";

@Component({
  selector: "app-copy-activation",
  templateUrl: "./copy-activation.component.html",
  styleUrls: ["./copy-activation.component.scss"],
})
export class CopyActivationComponent implements OnInit {
  dialogConfig: CopyActivationLinkConfiguration;
  state = "loading";
  activationLink = "Loading";
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private clipboardService: ClipboardService,
    private userService: UserService,
    private dialogTrackService: DialogTrackingService,
  ) {
    this.dialogConfig = config.data;
  }

  ngOnInit(): void {
    const payload: RnCommonId = {
      Id: this.dialogConfig.userID.toString(),
      AffectedOrganizationId: this.dialogConfig.organizationID,
    };
    this.userService
      .apiV2UsersGetactivationlinkPost(payload, "response")
      .subscribe((response) => {
        if (response.body.Success) {
          this.activationLink = response.body.data;
          this.state = "";
        }
      });
  }

  copy(): void {
    this.clipboardService.copy(this.activationLink);
    this.state = "copied";
    setTimeout(() => {
      this.state = "";
    }, 2000);
  }

  dismiss(): void {
    this.dialogTrackService.closeDialog(this.ref);
  }
}
