import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { RnOnSidebarClose } from "../../../core/interfaces/RnOnSidebarClose";
import { Guid } from "../../utilities/guid/guid.utilities";

@Injectable({
  providedIn: "root",
})
export class RnsidebarService {
  private subjectPayload: RnsidebarServiceSubjectPayload =
    new RnsidebarServiceSubjectPayload();
  private subject = new BehaviorSubject(this.subjectPayload);

  private loadingSubject = new Subject<boolean>();
  loading$ = this.loadingSubject.asObservable();

  trigger(
    component: SidebarComponents,
    data: any,
    styles?: string,
    qboAccountID?: string,
  ) {
    let componentSelector = "";
    switch (component) {
      case SidebarComponents.appmanageaddons: {
        componentSelector = "app-manage-addons";
        break;
      }
      case SidebarComponents.appmanagepackages: {
        componentSelector = "app-manage-packages";
        break;
      }

      case SidebarComponents.accessmanagement: {
        componentSelector = "app-access-management";
        break;
      }
      case SidebarComponents.userevents: {
        componentSelector = "app-user-events";
        break;
      }
      case SidebarComponents.licensepurchaselease: {
        componentSelector = "app-license-purchase";
        break;
      }
      case SidebarComponents.purchaseQboSub: {
        componentSelector = "app-qbo-purchase";
        break;
      }
      case SidebarComponents.licenseAssign: {
        componentSelector = "app-license-assign";
        break;
      }
      case SidebarComponents.accessmanagementAssign: {
        componentSelector = "app-access-management-assign";
        break;
      }
    }
    this.subject.next({
      componentName: componentSelector,
      componentData: data,
      componentStyle: styles,
      pendingChanges: false,
      refreshUser: undefined,
      visibleSidebar: true,
      showConfirmation: false,
      rnOnSidebarClose: undefined,
      additionalData: qboAccountID,
    });
  }

  showConfirmation() {
    const oldValues = this.subject.getValue();
    this.subject.next({
      componentName: oldValues.componentName,
      componentData: oldValues.componentData,
      componentStyle: oldValues.componentStyle,
      pendingChanges: oldValues.pendingChanges,
      refreshUser: oldValues.refreshUser,
      visibleSidebar: true,
      showConfirmation: true,
      rnOnSidebarClose: oldValues.rnOnSidebarClose,
      additionalData: oldValues.additionalData,
    });
  }

  setPendingChanges(pendingChanges: boolean) {
    const oldValues = this.subject.getValue();
    if (oldValues.pendingChanges !== pendingChanges) {
      this.subject.next({
        componentName: oldValues.componentName,
        componentData: oldValues.componentData,
        componentStyle: oldValues.componentStyle,
        pendingChanges: pendingChanges,
        refreshUser: oldValues.refreshUser,
        visibleSidebar: oldValues.visibleSidebar,
        showConfirmation: false,
        rnOnSidebarClose: oldValues.rnOnSidebarClose,
        additionalData: oldValues.additionalData,
      });
    }
  }

  hide() {
    const oldValues = this.subject.getValue();
    this.subject.next({
      componentName: oldValues.componentName,
      componentData: oldValues.componentData,
      componentStyle: oldValues.componentStyle,
      pendingChanges: false,
      refreshUser: oldValues.refreshUser,
      visibleSidebar: false,
      showConfirmation: false,
      rnOnSidebarClose: oldValues.rnOnSidebarClose,
      additionalData: oldValues.additionalData,
    });
  }

  clear() {
    this.subject.next({
      componentName: "",
      componentData: undefined,
      componentStyle: "",
      pendingChanges: false,
      refreshUser: "",
      visibleSidebar: false,
      showConfirmation: false,
      rnOnSidebarClose: undefined,
      additionalData: undefined,
    });
  }

  setOnClose(rnOnSidebarClose: RnOnSidebarClose) {
    const oldValues = this.subject.getValue();
    this.subject.next({
      componentName: oldValues.componentName,
      componentData: oldValues.componentData,
      componentStyle: oldValues.componentStyle,
      pendingChanges: oldValues.pendingChanges,
      refreshUser: oldValues.refreshUser,
      visibleSidebar: oldValues.visibleSidebar,
      showConfirmation: false,
      rnOnSidebarClose: rnOnSidebarClose,
      additionalData: oldValues.additionalData,
    });
  }

  refreshUser(param?: any) {
    const subjectPayload: RnsidebarServiceSubjectPayload =
      new RnsidebarServiceSubjectPayload();
    subjectPayload.refreshUser = Guid.newGuid();
    subjectPayload.componentData = param;
    this.subject.next(subjectPayload);
  }

  setValue(param?: any) {
    const subjectPayload: RnsidebarServiceSubjectPayload =
      new RnsidebarServiceSubjectPayload();
    subjectPayload.valueToSet = param;
    this.subject.next(subjectPayload);
  }

  getObservable() {
    return this.subject.asObservable();
  }

  startLoading() {
    this.loadingSubject.next(true);
  }

  stopLoading() {
    this.loadingSubject.next(false);
  }
}

export class ValueSet {
  name: string;
  value: string;
}

export class RnsidebarServiceSubjectPayload {
  componentName = "";
  componentData?: any = undefined;
  componentStyle?: string = undefined;
  pendingChanges = false;
  visibleSidebar = false;
  refreshUser?: string = undefined;
  showConfirmation = false;
  rnOnSidebarClose: RnOnSidebarClose;
  valueToSet?: ValueSet = undefined;
  additionalData?: string = undefined;
}

export enum SidebarComponents {
  appmanageaddons,
  appmanagepackages,
  accessmanagement,
  userevents,
  licensepurchaselease,
  purchaseQboSub,
  licenseAssign,
  accessmanagementAssign,
}
