import { Component, Input } from "@angular/core";

@Component({
  selector: "app-label",
  templateUrl: "./label.component.html",
  styleUrls: ["./label.component.scss"],
})
export class LabelComponent {
  @Input() set value(data: string) {
    this.labelValue = data;
    this.getTooltip();
    this.getTruncated();
  }
  @Input() set truncateSize(value: number) {
    this.labelTruncateSize = value;
    this.getTooltip();
    this.getTruncated();
  }
  @Input() cssClass = "";

  public tooltip: string;
  public truncatedVal: string;

  getTooltip() {
    this.tooltip =
      this.labelTruncateSize &&
      this.labelValue?.length >= this.labelTruncateSize
        ? this.labelValue
        : "";
  }

  getTruncated() {
    this.truncatedVal =
      this.labelTruncateSize && this.labelValue?.length > this.labelTruncateSize
        ? this.labelValue.slice(0, this.labelTruncateSize - 1) + "…"
        : this.labelValue;
  }

  private labelValue = "";
  private labelTruncateSize: number;
}
