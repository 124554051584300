import { Component, Input } from "@angular/core";
import { LicensePurchaseConfig } from "../license-purchase-config";
import { LicensePurchaseResponse } from "../license-purchase-info";

@Component({
  selector: "app-purchase-summary",
  templateUrl: "./purchase-summary.component.html",
  styleUrls: ["./purchase-summary.component.scss"],
})
export class PurchaseSummaryComponent {
  @Input() purchases: LicensePurchaseResponse[];
  @Input() licenseConfig: LicensePurchaseConfig;
}
