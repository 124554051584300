import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { RnOrganizationsProfileVM } from "src/app/shared/services/rnapi2-service";

@Component({
  selector: "app-qbo-time",
  templateUrl: "./qbo-time.component.html",
  styleUrls: ["./qbo-time.component.scss"],
})
export class QboTimeComponent {
  @Input() timeForm: FormGroup;
  @Output() QboTimeSelected = new EventEmitter<any>();
  public noTime = false;
  @Input() set qboTimeData(data: any) {
    this.QboTimeData = data;
  }

  public existingQboPlans: any;
  public isChangeSubType: boolean;
  @Input() set qboExistingPlan(data: any) {
    this.existingQboPlans = data;
  }
  @Input() set isChangeSub(data: boolean) {
    this.isChangeSubType = data;
  }
  public QboTimeData: any;
  public orgProfile: RnOrganizationsProfileVM;
  public selectedIndex: number;
  index: number = null;
  lastIndex = -1;

  closeAccordion() {
    this.index = this.lastIndex--;
  }

  selectionOccured(any?) {
    if (any === 100) {
      this.QboTimeSelected.emit([{ PackageName: "iDontNeedTime" }]);
      this.setRow(any);
    }
    this.QboTimeSelected.emit(any);
  }

  public setRow(_index: number) {
    this.selectedIndex = _index;
  }
}
