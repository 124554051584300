import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import {
  OrganizationService,
  ZuoraService,
} from "../../../services/rnapi2-service/apis/api";
import {
  RnCommonId,
  RnOrganizationPaymentOnFileVM,
  RnOrganizationsProfileVM,
  RnZuoraInvoiceGroup,
} from "../../../services/rnapi2-service/models/models";
import { SelectionService } from "../../../services/selection/selection.service";
import { RnToastService } from "../../../services/toast/rntoast.service";
import * as dayjs from "dayjs";

@Component({
  selector: "invoices",
  templateUrl: "./invoices.component.html",
  styleUrls: ["./invoices.component.scss"],
})
export class InvoicesComponent implements OnInit, OnDestroy {
  public selectedOrg: RnOrganizationsProfileVM = new RnOrganizationsProfileVM();
  public rnOrganizationPaymentOnFileVM: RnOrganizationPaymentOnFileVM;
  private subscriptions: Subscription[] = [];
  public tabs: InvoiceTabItem[] = [];
  public invoiceGroups: RnZuoraInvoiceGroup[] = [];
  public loading = false;

  constructor(
    private selectionService: SelectionService,
    private organizationService: OrganizationService,
    private rnToastService: RnToastService,
    private zuoraService: ZuoraService,
  ) {}

  ngOnInit(): void {
    if (this.selectionService.SelectedOrgProfile) {
      this.loading = true;
      const selectedOrgSub = this.selectionService.SelectedOrgProfile.subscribe(
        (result) => {
          this.selectedOrg = result;
          if (this.selectedOrg === null) return;

          if (
            this.organizationService.apiV2OrganizationsCurrentpaymentinfoPost
          ) {
            const paymentInfoSub = this.organizationService
              .apiV2OrganizationsCurrentpaymentinfoPost({
                Id: this.selectedOrg.ID.toString(),
              })
              .subscribe(
                (result) => {
                  if (result.Success) {
                    this.rnOrganizationPaymentOnFileVM = result.data;

                    if (
                      !this.rnOrganizationPaymentOnFileVM ||
                      !this.rnOrganizationPaymentOnFileVM.PaymentMethods ||
                      this.rnOrganizationPaymentOnFileVM.PaymentMethods
                        .length == 0
                    ) {
                      return;
                    }

                    const rnCommonId: RnCommonId = {
                      Id: this.selectedOrg.ZuoraID,
                      AffectedOrganizationId: this.selectedOrg.ID,
                    };

                    if (
                      this.zuoraService.apiV2ZuoraGetorganizationinvoicesPost
                    ) {
                      const invoicesSub = this.zuoraService
                        .apiV2ZuoraGetorganizationinvoicesPost(rnCommonId)
                        .subscribe(
                          (result) => {
                            this.invoiceGroups = result.data;
                            result.data.forEach((value) => {
                              const tabItem: InvoiceTabItem = {
                                heading: value.Heading,
                                invoices: value.Invoices,
                                selected: false,
                              };
                              this.tabs.push(tabItem);
                            });

                            if (this.tabs.length > 0) {
                              this.tabs[0].selected = true;
                            }
                          },
                          (error) => {
                            this.rnToastService.showError(
                              "There was a problem getting the invoices for this organization",
                            );
                          },

                          () => {
                            this.loading = false;
                          },
                        );
                      this.subscriptions.push(invoicesSub);
                    }
                  } else {
                    console.error(result.Messages);
                  }
                },
                (error) => {
                  console.error(error);
                },
              );
            this.subscriptions.push(paymentInfoSub);
          }
        },
        (err) => {
          console.error(err);
        },
      );
      this.subscriptions.push(selectedOrgSub);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public getInvoicePdf(invoiceId, invoiceNumber: string, invoiceDate) {
    const rnCommonId: RnCommonId = {
      Id: invoiceId,
      AffectedOrganizationId: this.selectedOrg.ID,
    };
    if (this.zuoraService) {
      const fileName = this.selectedOrg.Name.replace(/\s/g, "");
      const todayDate = new Date();
      const datePipe = new DatePipe("en-US");
      const datePiped = datePipe.transform(todayDate, "yyyy-MMM-dd");
      const zuoraSub = this.zuoraService
        .apiV2ZuoraGetinvoicepdfPost(rnCommonId)
        .subscribe(
          (result) => {
            if (result) {
              const filename =
                this.selectedOrg.Name.replace(/[^A-Z0-9]/gi, "") +
                "_" +
                invoiceNumber +
                "_" +
                dayjs(invoiceDate).format("YYYY-MMM-DD") +
                ".pdf";
              const contentType = "application/pdf";

              const linkElement = document.createElement("a");
              try {
                const blob = new Blob([result], { type: contentType });
                const url = window.URL.createObjectURL(blob);

                linkElement.setAttribute("href", url);
                linkElement.setAttribute("download", filename);

                const clickEvent = new MouseEvent("click", {
                  view: window,
                  bubbles: true,
                  cancelable: false,
                });
                linkElement.dispatchEvent(clickEvent);
              } catch (ex) {
                console.log(ex);
              }
            } else {
              console.log(
                "There was an error in loading the PDF for the invoice",
              );
              this.rnToastService.showError(
                "There was an error in loading the PDF for the invoice.</strong> Please contact support for further assistance.",
              );
            }
          },
          (error) => {
            console.error(error);
            this.rnToastService.showError(
              "There was an error in loading the PDF for the invoice.</strong> Please contact support for further assistance.",
            );
          },
        );

      this.subscriptions.push(zuoraSub);
    }
  }
}
export class InvoiceTabItem {
  heading: string;
  invoices: any;
  selected = false;
}
