/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export class RnPingVM {
  Type?: string | null;
  Version?: string | null;
  BuildTime?: string;
  ServiceUpTime?: string | null;
  PerformanceCheck?: string | null;
  HealthCheck?: string | null;
  DatabaseVersion?: string | null;
  PrimaryADServer?: string | null;
  OneTimeScriptsVersion?: string | null;
  ReleaseType?: string | null;
}
