import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { OrganizationService } from "../rnapi2-service/apis/api";
import { RnCommonSearch } from "../rnapi2-service/models/models";

@Injectable({
  providedIn: "root",
})
export class LookupStoreService {
  constructor(private organizationService: OrganizationService) {}

  public GetProseriesLacerteSalesnumber(): Observable<string> {
    return this.checkOrGetConfigLookupValue(
      this.proseries_lacerte_salesnumber,
      "proseries_lacerte_salesnumber",
    );
  }

  public GetIntuitSalesNumber(): Observable<string> {
    return this.checkOrGetConfigLookupValue(
      this.intuit_sales_number,
      "intuit_sales_number",
    );
  }

  public GetAdditionalAddonsIntuitInfo(): Observable<string> {
    return this.checkOrGetConfigLookupValue(
      this.additionaladdons_intuit_info,
      "additionaladdons_intuit_info",
    );
  }

  public GetIntuitAddonsInfo(): Observable<string> {
    return this.checkOrGetConfigLookupValue(
      this.intuitaddons_info,
      "intuitaddons_info",
    );
  }

  private proseries_lacerte_salesnumber: string;
  private intuit_sales_number: string;
  private additionaladdons_intuit_info: string;
  private intuitaddons_info: string;

  private checkOrGetConfigLookupValue(
    value: string,
    configLookup: string,
  ): Observable<string> {
    if (!value || value === "") {
      const payload = new RnCommonSearch();
      payload.search = configLookup;
      return this.organizationService
        .apiV2OrganizationsGetconfigurablevaluePost(payload)
        .pipe(
          map((response) => {
            return response.data;
          }),
        );
    }

    return of(value);
  }
}
