import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  OverlayListenerOptions,
  OverlayOptions,
  SelectItem,
} from "primeng/api";
import { Subscription } from "rxjs";
import { OrganizationService } from "../../../services/rnapi2-service/apis/api";
import {
  RnLicenseFullVM,
  RnOrganizationLicensesSearch,
} from "../../../services/rnapi2-service/models/models";

@Component({
  selector: "app-deploy-quickbooks",
  templateUrl: "./deploy-quickbooks.component.html",
  styleUrls: ["./deploy-quickbooks.component.scss"],
})
export class DeployQuickbooksComponent implements OnChanges {
  @Input() licenseSelectForm: FormGroup;
  @Output() noLicenseEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedLicenseEvent: EventEmitter<RnLicenseFullVM> =
    new EventEmitter<RnLicenseFullVM>();
  @Output() pendingLicenseCheckEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() licensesEvent: EventEmitter<RnLicenseFullVM[]> = new EventEmitter<
    RnLicenseFullVM[]
  >();
  @Input() OrganizationID: number;
  @Input() RefreshData: any;
  licenses: RnLicenseFullVM[];
  qbVersSelect: SelectItem[] = [];
  qbCountriesSelect: SelectItem[];
  qbEditionsSelect: SelectItem[];
  qbFlavorsSelect: SelectItem[];
  private licenseData: any[];
  private noLicenses: boolean;
  private uniqueLicenseCount: number;
  private uniqueLicenseIds: any[] = [];
  private stagedLicenses: any[];
  private isOwner: boolean;
  private selectedLicense: any;
  public loadingVersions = true;
  private passedOnChanges = false;
  private subscriptions: Subscription[] = [];

  constructor(private organizationService: OrganizationService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const forceRefresh = changes["RefreshData"];

    if (
      (!this.passedOnChanges &&
        this.licenseSelectForm &&
        this.OrganizationID) ||
      forceRefresh
    ) {
      this.pendingLicenseCheckEvent.emit(true);
      const payload: RnOrganizationLicensesSearch = {
        AffectedOrganizationId: this.OrganizationID,
        Id: this.OrganizationID.toString(),
        includeAllFlavors: true,
        isDescendingOrder: false,
        pageNumber: 1,
        pageSize: 1000,
      };

      this.subscriptions.push(
        this.organizationService
          .apiV2OrganizationsGetavailablelicensesfororgPost(payload)
          .subscribe((response) => {
            this.stagedLicenses = response.data;
            if (this.stagedLicenses.length >= 0) {
              this.licenseData = [];
              this.loadQbVersion();
            } else {
              this.noLicenseEvent.emit(this.noLicenses);
              this.pendingLicenseCheckEvent.emit(false);
            }
          }),
      );
      this.passedOnChanges = true;
    }
  }

  private isEmpty = (passedObj) =>
    !(
      passedObj &&
      passedObj === Object(passedObj) &&
      Object.keys(passedObj).length !== 0
    );

  private loadQbVersion() {
    this.stagedLicenses.forEach((item) => {
      if (
        (item.CountBlockingUsers < item.LicenseCount ||
          item.LicenseCount == -1) &&
        item.Edition.indexOf("Advanced Inventory") < 0
      ) {
        this.licenseData.push(item);
      }
    });
    this.licenses = this.licenseData;
    this.isOwner = false;
    this.noLicenses = this.isEmpty(this.licenseData);
    this.noLicenseEvent.emit(this.noLicenses);
    this.pendingLicenseCheckEvent.emit(false);
    this.licensesEvent.emit(this.licenses);

    let versionsForUI = [
      ...new Set(this.licenseData.map((item) => item.Version)),
    ];

    // Need to do this as the changes are not picked up if we just modify qbVers.
    // this.qbVers has to be assigned a new value for the change to be picked up.
    versionsForUI = versionsForUI.sort().reverse();
    versionsForUI.unshift("");
    this.qbVersSelect = versionsForUI.map((v) => {
      return {
        label: v,
        value: v,
      };
    });
    this.qbVersion.setValue("");

    this.qbCountriesSelect = [];
    this.qbEditionsSelect = [];
    this.qbFlavorsSelect = [];
    this.qbCountry.setValue("");
    this.qbEdition.setValue("");
    this.qbFlavor.setValue("");
    this.loadingVersions = false;
  }

  public changeQbVersion() {
    this.qbCountriesSelect = [];
    this.qbEditionsSelect = [];
    this.qbFlavorsSelect = [];

    let countriesForUI = [
      ...new Set(
        this.licenseData
          .filter((d) => {
            return d.Version === this.qbVersion.value;
          })
          .map((item) => item.Country),
      ),
    ];
    countriesForUI = countriesForUI.sort().reverse();
    countriesForUI.unshift("");
    this.qbCountriesSelect = countriesForUI.map((c) => {
      return {
        label: c,
        value: c,
      };
    });

    this.qbCountry.setValue("");
    this.qbEdition.setValue("");
    this.qbFlavor.setValue("");
  }

  public changeQbCountry() {
    this.qbEditionsSelect = [];
    this.qbFlavorsSelect = [];

    let editionsForUI = [
      ...new Set(
        this.licenseData
          .filter((d) => {
            return (
              d.Version === this.qbVersion.value &&
              d.Country === this.qbCountry.value
            );
          })
          .map((item) => item.Edition),
      ),
    ];
    editionsForUI = editionsForUI.sort();
    editionsForUI.unshift("");
    this.qbEditionsSelect = editionsForUI.map((e) => {
      return {
        label: e,
        value: e,
      };
    });

    this.qbEdition.setValue("");
    this.qbFlavor.setValue("");
  }

  public changeQbEdition() {
    this.qbFlavorsSelect = [];

    let flavorsForUI = [
      ...new Set(
        this.licenseData
          .filter((d) => {
            return (
              d.Version === this.qbVersion.value &&
              d.Country === this.qbCountry.value &&
              d.Edition === this.qbEdition.value
            );
          })
          .map((item) => item.Flavor),
      ),
    ];
    flavorsForUI = flavorsForUI.sort();
    flavorsForUI.unshift("");

    this.qbFlavorsSelect = flavorsForUI.map((f) => {
      return {
        label: f,
        value: f,
      };
    });
    this.qbFlavor.setValue("");
  }

  public changeQbFlavor() {
    const selectedLicense = this.licenseData.filter((d) => {
      return (
        d.Version === this.qbVersion.value &&
        d.Country === this.qbCountry.value &&
        d.Edition === this.qbEdition.value &&
        d.Flavor === this.qbFlavor.value
      );
    });
    if (selectedLicense.length > 0) {
      this.selectedLicense = selectedLicense[0];
      this.selectedLicenseEvent.emit(this.selectedLicense);
    }
  }

  getOverlayOptions(): OverlayOptions {
    return {
      listener: (event: Event, options?: OverlayListenerOptions) => {
        return false;
      },
    };
  }

  get isDeploy() {
    return this.licenseSelectForm.get("isDeploy");
  }

  get qbVersion() {
    return this.licenseSelectForm.get("qbVersion");
  }

  get qbCountry() {
    return this.licenseSelectForm.get("qbCountry");
  }

  get qbEdition() {
    return this.licenseSelectForm.get("qbEdition");
  }

  get qbFlavor() {
    return this.licenseSelectForm.get("qbFlavor");
  }
}
