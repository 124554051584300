/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnOrdersVM } from "../models/./ordersVM-d";
import { RnUsersProfileVM } from "../models/./usersProfileVM-d";
import { RnOrganizationAddressesVM } from "../models/./organizationAddressesVM-d";
import { RnOrderSummaryPackageVM } from "../models/./orderSummaryPackageVM-d";
import { RnOrderSummaryQbesLicenseVM } from "../models/./orderSummaryQbesLicenseVM-d";

export class RnNewOrgOrderVM {
  OrganizationID?: number;
  LeasedLicenses?: Array<RnOrderSummaryPackageVM> | null;
  PurchasedLicenses?: Array<RnOrderSummaryPackageVM> | null;
  AnnualLicenses?: Array<RnOrderSummaryPackageVM> | null;
  Packages?: Array<RnOrderSummaryPackageVM> | null;
  QbesLicenseItems?: Array<RnOrderSummaryQbesLicenseVM> | null;
  BillingAddress?: RnOrganizationAddressesVM;
  AccountOwner?: RnUsersProfileVM;
  OrderFound?: boolean;
  ordersVM?: RnOrdersVM;
  OrderID?: string | null;
}
