export class GridActionButton {
  property: string;
  buttonsText: Array<GridActions>;
}

export class GridActions {
  id: number;
  class?: string = "";
  text: string;
  type: string; // Retry, Cancel
}
