import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CreditCardService {
  cardTypes = {
    VISA: {
      cardType: "Visa",
      cardPattern: /^4[0-9]{15}?$/,
      partialPattern: /^4/,
    },
    MASTERCARD: {
      cardType: "MasterCard",
      cardPattern:
        /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[0-1][0-9]|2720)[0-9]{12}$/,
      partialPattern:
        /^(?:5[1-5]|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[0-1][0-9]|2720)/,
    },
    AMERICANEXPRESS: {
      cardType: "AmericanExpress",
      cardPattern: /^3[47][0-9]{13}$/,
      partialPattern: /^3[47]/,
    },
    DISCOVER: {
      cardType: "Discover",
      cardPattern: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      partialPattern: /^6(011|5[0-9])/,
    },
    MAESTRO: {
      cardType: "Maestro",
      cardPattern: /^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/,
      partialPattern: /^(5018|5020|5038|6304|6759|6761|6763)[0-9]$/,
    },
    DINERS: {
      cardType: "Diners",
      cardPattern: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      partialPattern: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    },
  };

  public determineCardType(cardnumber: string): string {
    const keys = Object.keys(this.cardTypes);

    cardnumber = this.sanitizeNumberString(cardnumber);

    for (let i = 0; i < keys.length; ++i) {
      const key = keys[i];
      const type = this.cardTypes[key];

      if (type.cardPattern.test(cardnumber)) {
        return type.cardType;
      }
    }

    return null;
  }

  public determinceCardTypePartial(cardnumber: string): string {
    const keys = Object.keys(this.cardTypes);

    cardnumber = this.sanitizeNumberString(cardnumber);

    for (let i = 0; i < keys.length; ++i) {
      const key = keys[i];
      const type = this.cardTypes[key];

      if (type.partialPattern.test(cardnumber)) {
        return type.cardType;
      }
    }

    return null;
  }

  public getCardIcon(cardType: string): string {
    switch (cardType) {
      case "Visa":
        return "fa fa-cc-visa";
        break;

      case "MasterCard":
        return "fa fa-cc-mastercard";
        break;

      case "Discover":
        return "fa fa-cc-discover";
        break;

      case "AmericanExpress":
        return "fa fa-cc-amex";
        break;

      default:
        return "fa fa-credit-card";
        break;
    }
  }

  public getCardMask(cardType: string): string {
    switch (cardType) {
      case "Visa":
        return "9999 9999 9999 9999";
        break;

      case "MasterCard":
        return "9999 9999 9999 9999";
        break;

      case "Discover":
        return "9999 9999 9999 9999";
        break;

      case "AmericanExpress":
        return "9999 999999 99999";
        break;

      default:
        return "9999 9999 9999 9999";
        break;
    }
  }

  private sanitizeNumberString(number) {
    if (typeof number !== "string") {
      return "";
    }

    return number.replace(/[^\d]/g, "");
  }
}
