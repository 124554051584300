import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import {
  LookupService,
  OrganizationService,
  UserService,
} from "../../../services/rnapi2-service/apis/api";
import {
  RnCommonSearch,
  RnUserTypesVM,
  RnUserUserTypeAdd,
} from "../../../services/rnapi2-service/models/models";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { Subscription } from "rxjs";
import { SelectItem } from "primeng/api";
import { DialogTrackingService } from "../../../services/dialog/dialog-tracking.service";
import { NotificationDialogService } from "../../../services/notificationDialog/notification-dialog.service";
import { GenericDialogConfiguration } from "../generic-dialog/generic-dialog-configuration";
import * as dayjs from "dayjs";

@Component({
  selector: "billing-separation",
  templateUrl: "./billing-separation.component.html",
  styleUrls: ["./billing-separation.component.scss"],
})
export class BillingSeparationComponent implements OnInit, OnDestroy {
  public theOnlyActiveUser = "";
  public activeUsers: SelectItem[] = [{ label: "", value: "" }];
  public activeUser: any;
  public oneActiveUser = false;
  public manyActiveUsers = false;
  public anyActiveUsers = false;
  public noActiveUsers = false;
  private subscriptions: Subscription[] = [];
  public orgName: string;
  public roles?: Array<RnUserTypesVM> | null;
  private newAccountOwner = "";

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private rnToastService: RnToastService,
    private organizationService: OrganizationService,
    private userService: UserService,
    private lookupService: LookupService,
    private dialogTrackService: DialogTrackingService,
    private notificationDialogService: NotificationDialogService,
  ) {}

  ngOnInit() {
    this.orgName = this.config.data.Name;
    this.loadActiveUsers();
    this.loadUserRoles();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public loadActiveUsers() {
    const rnCommonSearch: RnCommonSearch = {
      Id: this.config.data.ID,
      OrderByText: "orderByFullName",
    };

    const getUsersSubscription = this.organizationService
      .apiV2OrganizationsUsersPost(rnCommonSearch)
      .subscribe(
        (result) => {
          if (result.Success && result.data) {
            const users = result?.data.Results.filter(
              (x) => x.UserState.toLowerCase() == "active",
            )
              ?.sort()
              .reverse();

            users.forEach((obj) => {
              this.activeUsers.push({ label: obj.FullName, value: obj.UserID });
            });

            this.noActiveUsers = users.length == 0;
            this.oneActiveUser = users.length == 1;
            this.manyActiveUsers = users.length > 1;
            this.anyActiveUsers = users.length >= 1;
            if (this.oneActiveUser) {
              this.theOnlyActiveUser =
                users[0].FirstName + " " + users[0].LastName;
              this.activeUser = users[0].UserID;
            }
          } else {
            this.rnToastService.showError(
              "There was an error loading user information.",
            );

            let errMsg = "There was an error loading user information.";
            if (result && result.Messages && result.Messages.length > 0) {
              for (let i = 0; i < result.Messages.length; i++) {
                const error = result.Messages[i];
                errMsg +=
                  "\r\nCode: " +
                  error.Code +
                  "\r\nName: " +
                  error.Name +
                  "\r\nDescription: " +
                  error.Description;
              }
            }
            console.error(errMsg);
          }
        },
        (error) => {
          this.rnToastService.showError(
            "There was an error loading user information.",
          );
        },
      );
    this.subscriptions.push(getUsersSubscription);
  }

  public loadUserRoles() {
    this.lookupService
      .apiV2LookupUsertypesGet(-1, this.config.data.ID)
      .subscribe(
        (result) => {
          if (result.Success) {
            this.roles = result.data;
          } else {
            this.rnToastService.showError(
              "There was an error loading user types.",
            );
          }
        },
        (error) => {
          this.rnToastService.showError(
            "There was an error loading user types.",
          );
        },
      );
  }

  public onSubmit(billingForm: NgForm) {
    if (billingForm.valid) {
      this.newAccountOwner = this.activeUsers.filter(
        (x) => x.value == this.activeUser,
      )[0].label;

      const confirmConfig = new GenericDialogConfiguration();
      confirmConfig.StyleClass = "confirmation";
      confirmConfig.Title = "Convert to Client-Paid Account?";
      confirmConfig.Message = `Please note that this process cannot be stopped once it has started.`;
      confirmConfig.ConfirmButtonStyleClass = "dontCreateUserButton";
      confirmConfig.DialogHeaderClass = "modal-header no-border";
      confirmConfig.DialogFooterCancelClass = "right-spacing";
      confirmConfig.ConfirmButtonText = "CONVERT";
      confirmConfig.CancelButtonText = "CANCEL";
      confirmConfig.CancelButtonStyleClass = "addUserTeritary";
      confirmConfig.confirmButtonNoContentPadding = true;
      confirmConfig.cancelButtonNoContentPadding = true;
      confirmConfig.MessageContainsHTML = false;
      confirmConfig.ShowCloseButton = true;
      confirmConfig.UseProgressButton = true;
      confirmConfig.KeepOpenAfterConfirm = true;
      confirmConfig.Confirmed = (dialogRef) => {
        this.submitClientSeparate();
      };
      this.notificationDialogService.ShowConfirmation(confirmConfig);
    }
  }

  //the function that sets the account owner, and in case of sucess, it triggers billing separatoin
  private submitClientSeparate() {
    let accountOwnerRoleId: number;
    const aoRoles = this.roles.filter((x) => x.Name === "Account Owner");
    if (aoRoles.length > 0) {
      accountOwnerRoleId = aoRoles[0].ID;
    }
    const rnUserUserTypeAdd: RnUserUserTypeAdd = {
      Users_ID: this.activeUser,
      UserType_ID: accountOwnerRoleId,
    };
    this.userService.apiV2UsersAddusertypePost(rnUserUserTypeAdd).subscribe(
      (result) => {
        if (result.Success) {
          this.BillingSeparate();
          return true;
        } else {
          this.rnToastService.showError(
            "There was an error updating usertypes.",
          );
          if (result.Messages && result.Messages.length > 0) {
            let errMsg = "";
            for (let i = 0; i < result.Messages.length; i++) {
              const error = result.Messages[i];
              errMsg +=
                "\r\nCode: " +
                error.Code +
                "\r\nName: " +
                error.Name +
                "\r\nDescription: " +
                error.Description;
              this.rnToastService.showError(error.Description);
            }
            console.error(errMsg);
          }
        }
      },
      (error) => {
        if (error.Messages && error.Messages.length > 0) {
          this.rnToastService.showError(error.Messages[0].Description);
        }
      },
      () => {
        this.close();
      },
    );
  }

  //the function that communicated with the api for billing separation
  private BillingSeparate() {
    const now = dayjs();
    const values = [now.format()];

    this.organizationService
      .apiV2OrganizationsCreateorupdateorgconfigurationPost(
        this.config.data.ID,
        "InitiatedBillingSeparation",
        values,
      )
      .subscribe(
        (result) => {
          if (result.Success) {
            // we succeeded, update rootScope config variable
            this.rnToastService.showSuccess(
              "The process to convert " +
                this.orgName +
                " to a Client-Paid Account has been successfully initiated. <br/>" +
                this.newAccountOwner +
                " will receive an email with instructions to establish their billing relationship.",
            );
            setTimeout(() => {
              location.reload();
            }, 6000);
          } else {
            this.rnToastService.showError(
              "There was a problem beginning the process of client separation for this account.",
            );
          }
        },
        () => {
          this.rnToastService.showError(
            "There was a problem beginning the process of client separation for this account.",
          );
        },
      );
  }

  public close(): void {
    this.dialogTrackService.closeDialog(this.ref, null);
  }
}
