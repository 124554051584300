<div class="mat-table-container">
  <mat-table #table [dataSource]="dataSource">
    <!-- Name Column -->
    <ng-container matColumnDef="Item">
      <mat-header-cell *matHeaderCellDef class="cell-header">
        <span class="confirmation-header">Item</span>
      </mat-header-cell>
      <mat-cell
        class="confirmation-table-container"
        *matCellDef="let element; let i = index"
      >
        {{ element.PackageName }}
      </mat-cell>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="Monthly Cost">
      <mat-header-cell
        *matHeaderCellDef
        class="float-right disp-contents cell-header"
      >
        <span class="confirmation-header">Monthly Cost</span>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element; let i = index"
        class="float-right disp-contents"
      >
        <div class="float-right confirmation-table-container">
          ${{ element.NextBillPrice | number: "1.2-2" }}
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row
      class="confirmation-options"
      *matHeaderRowDef="displayedColumns"
    ></mat-header-row>
    <mat-row
      class="confirmation-options"
      *matRowDef="let row; columns: displayedColumns"
    ></mat-row>
  </mat-table>
</div>
