import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { RnUserIsEmailUnique } from "../../../services/rnapi2-service/models/models";
import { Subscription } from "rxjs";
import { Output, EventEmitter } from "@angular/core";
import { ConstantsService } from "../../../services/constants/constants.service";

@Component({
  selector: "app-new-user-details",
  templateUrl: "./new-user-details.component.html",
  styleUrls: ["./new-user-details.component.scss"],
})
export class NewUserDetailsComponent implements OnInit, OnDestroy {
  roles: any[];
  @Input() userDetailsForm: FormGroup;
  @Input() hasAccountOwner: boolean;
  @Input() data: any;
  @Output() pendingValidationEvent = new EventEmitter<boolean>();

  public nonspecific = false;
  public rnUserIsEmailUnique: RnUserIsEmailUnique;
  public rolesTooltip: string;
  public xidHelp: string;
  private subscriptions: Subscription[] = [];

  constructor(private constantsService: ConstantsService) {}

  ngOnInit(): void {
    this.rolesTooltip = this.constantsService.getRolesTooltip(
      this.hasAccountOwner,
    );
    this.xidHelp = this.constantsService.XIDHelp;
    this.roles = this.data.UserTypes.filter((r) => r.Name != "Account Owner");
    if (!this.f.role.value) {
      let defaultRole = this.roles.filter((r) => r.Name == "User (catalog)");
      if (defaultRole.length > 0) {
        this.f.role.setValue(defaultRole[0]);
      } else {
        defaultRole = this.roles.filter((r) => r.Name == "User (no catalog)");
        this.f.role.setValue(defaultRole[0]);
      }
    }
    this.f.sendWelcomeEmail.setValue(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  changeHandler(event, value): void {
    console.log(event);
  }

  pendingValidationChanged(value: boolean) {
    this.pendingValidationEvent.emit(value);
  }

  get role() {
    return this.userDetailsForm.get("role");
  }
  get firstName() {
    return this.userDetailsForm.get("firstName");
  }
  get lastName() {
    return this.userDetailsForm.get("lastName");
  }
  get confirmEmail() {
    return this.userDetailsForm.get("confirmEmail");
  }
  get xid() {
    return this.userDetailsForm.get("xid");
  }
  get sendWelcomeEmail() {
    return this.userDetailsForm.get("sendWelcomeEmail");
  }

  get f() {
    return this.userDetailsForm.controls;
  }
}
