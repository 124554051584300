<div [formGroup]="plansForm">
  <p-accordion
    [activeIndex]="index"
    class="div-header"
    [expandIcon]="'pi pi-fw pi-chevron-down'"
    [collapseIcon]="'pi pi-fw pi-chevron-up'"
  >
    <div
      tabindex="0"
      (keyup.enter)="setRow(i)"
      *ngFor="let tab of QboPlansData; let i = index"
      [ngClass]="{
        'div-highlight': selectedIndex === i,
        'qbo-plan':
          existingQboPlans === null ||
          (tab.PackageCategoryName.trim() !== existingQboPlans.Type.trim() &&
            tab.PackageCategoryName.trim() !== 'Simple Start' &&
            isChangeSubType)
      }"
      (click)="setRow(i)"
    >
      <p-accordionTab
        class="qboAccordionTab"
        (click)="selectionOccured(tab)"
        *ngIf="
          existingQboPlans === null ||
          (tab.PackageCategoryName.trim() !== existingQboPlans.Type.trim() &&
            tab.PackageCategoryName.trim() !== 'Simple Start' &&
            isChangeSubType)
        "
      >
        <p-header>
          <span class="package-name">{{ tab.PackageCategoryName }}</span>
          <span class="package-amount"
            ><div class="float-right">
              ${{ tab.NextBillPrice | number: "1.2-2" }}
              <p class="month-label">/month</p>
            </div></span
          >
        </p-header>
        <p
          class="package-content"
          [innerHtml]="tab.HTMPDescription | safeHtml"
        ></p>
      </p-accordionTab>
    </div>
    <div
      tabindex="0"
      (keyup.enter)="selectionOccured(100)"
      *ngIf="false"
      class="div-class"
      [ngClass]="{ 'div-highlight': selectedIndex === 100 }"
      (click)="selectionOccured(100)"
    >
      I have more questions
    </div>
  </p-accordion>
</div>
