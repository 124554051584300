import {
  RnOrganizationPackagePoolsForListVM,
  RnOrganizationsProfileVM,
  RnOrganizationWLPContactInformationVM,
} from "../../../services/rnapi2-service/models/models";
import { PackageConfigInfo } from "../package-inventory/package-config-info";

export class PackageAssignConfigData {
  public OrgProfile: RnOrganizationsProfileVM;
  public OrgWlpContactInfo: RnOrganizationWLPContactInformationVM;
  public PkgConfigInfo: PackageConfigInfo;
  public Package: RnOrganizationPackagePoolsForListVM;
  public TopLevelOrgID: number;
}
