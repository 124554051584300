import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { RnOrganizationsProfileVM } from "src/app/shared/services/rnapi2-service";
import { SelectionService } from "src/app/shared/services/selection/selection.service";

@Component({
  selector: "app-qbo-checkout",
  templateUrl: "./qbo-checkout.component.html",
  styleUrls: ["./qbo-checkout.component.scss"],
})
export class QboCheckoutComponent {
  qboAddonsData = [100];

  @Input() checkoutForm: FormGroup;
  plansValue;
  payrollText: string;
  @Input() set qboCheckout(data: any) {
    data?.forEach((x) => {
      if (x.PackageName === "QuickBooks Online Elite Payroll") {
        this.payrollText = "QuickBooks Elite Time Tracking";
      } else if (x.PackageName === "QuickBooks Online Premium Payroll") {
        this.payrollText = "QuickBooks Premium Time Tracking";
      } else {
        this.payrollText = "";
      }
    });
    this.dataSource = new MatTableDataSource<any>(data);
  }
  public orgProfile: RnOrganizationsProfileVM;
  displayedColumns = ["Item", "Monthly Cost"];
  dataSource;
  selectedItems = [];
  constructor(private selectionService: SelectionService) {}
}
export interface Element {
  PackageName: string;
  NextBillPrice: string;
}
