import { DatePipe } from "@angular/common";
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  forwardRef,
  Input,
  OnDestroy,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { MatMenuTrigger } from "@angular/material/menu";
import { of, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { OrganizationService } from "../../../services/rnapi2-service/apis/api";
import {
  RnCommonSearch,
  RnOrganizationsProfileVM,
} from "../../../services/rnapi2-service/models/models";
import { OrgInfo } from "./org-lookup-data";

@Component({
  selector: "app-org-lookup",
  templateUrl: "./org-lookup.component.html",
  styleUrls: ["./org-lookup.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrgLookupComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: OrgLookupComponent,
      multi: true,
    },
  ],
})
export class OrgLookupComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @ViewChild("search") search: ElementRef;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Input() required: boolean;
  @Input() selectedOrg: RnOrganizationsProfileVM;
  @Input() placeholderText: string;

  noResults = false;

  lookupOrgs: OrgInfo[] = [];

  searchPerformed = false;
  noResultsFound = false;
  isLoading: boolean;

  constructor(
    private renderer: Renderer2,
    private orgService: OrganizationService,
  ) {}

  validate({ value }: FormControl) {
    const isNotValid = this.required && !this.orgInfo;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }
  inputSearchText: Subject<string>;
  selectedOrgText: string;

  orgInfo: OrgInfo;

  writeValue(obj: any): void {
    this.orgInfo = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(
      this.search.nativeElement,
      "disabled",
      isDisabled,
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.inputSearchText = new Subject<string>();
    const inputSearchSub = this.inputSearchText
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((inputText) => {
        if (inputText === "") {
          this.setOrgInfo(null);
          this.searchPerformed = false;
          this.lookupOrgs = [];
          return of(null);
        }
        this.isLoading = true;
        const payload = new RnCommonSearch();
        payload.Id = this.selectedOrg.ID.toString();
        payload.AffectedOrganizationId = this.selectedOrg.ID;
        payload.search = inputText;
        payload.OrderByText = "orderByName";
        payload.isDescendingOrder = false;
        payload.pageSize = 10;
        payload.pageNumber = 0;
        this.orgService
          .apiV2OrganizationsNonselfpaidsuborgsearchPost(payload, "response")
          .subscribe((r) => {
            this.isLoading = false;
            this.lookupOrgs = [];
            r.body.data.Results.forEach((o) => {
              const item = new OrgInfo();
              item.ID = o.ID;
              item.Name = o.Name;
              item.AccountOwner = o.AccountOwnerName;
              item.CreatedDate = new Date(o.CreatedDate);
              this.lookupOrgs.push(item);
            });
            if (this.lookupOrgs.length > 0) {
              this.trigger.openMenu();
              this.noResultsFound = false;
            } else {
              this.noResultsFound = true;
            }
            this.searchPerformed = true;
          });
      });

    this.subscriptions.push(inputSearchSub);
  }

  searchChanged(event): void {
    this.inputSearchText.next(event);
  }

  orgSelected(org: OrgInfo): void {
    this.setOrgInfo(org);
  }

  //noResultsFound(): boolean {
  //  return this.searchPerformed && this.lookupOrgs.length === 0;
  //}

  searchBlur($event): void {
    this.onTouch(this.orgInfo);
  }

  getToolTipForOrg(org: OrgInfo): string {
    const datepipe: DatePipe = new DatePipe("en-US");
    const formattedDate = datepipe.transform(org.CreatedDate, "MMM dd, yyyy");
    let result = `<div style='font-size: 21px; font-weight:700;'>${org.Name}</div>`;
    result += `<div><span style='font-size: 13px; font-weight:700;'>Account Owner: </span><span style='font-size: 13px;'>${org.AccountOwner}</span></div>`;
    result += `<div><span style='font-size: 13px; font-weight:700;'>Customer Since: </span><span style='font-size: 13px;'>${formattedDate}</span></div>`;

    return result;
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  private subscriptions: Subscription[] = [];
  private setOrgInfo(newValue: OrgInfo) {
    this.orgInfo = newValue;
    this.selectedOrgText = newValue?.Name;
    this.onChange(this.orgInfo);
  }
}
