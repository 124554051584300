import { HttpResponse } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { RnCatalogItemVM } from "src/app/core/models/catalogItemVM-d";
import {
  OrderService,
  OrganizationService,
  RnCommonId,
  RnOrganizationsProfileVM,
  RnRNAddOnPayload,
  RnRNLicenseItem,
  RnRNOrderHeader,
  RnRNOrderItem,
} from "src/app/shared/services/rnapi2-service";
import { RnsidebarService } from "src/app/shared/services/sidebar/rnsidebar.service";
import { AddUserStep } from "../../add-user/add-user-step";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoggedInInfoService } from "src/app/shared/services/loggedInInfo/logged-in-info.service";
import { IdService } from "src/app/shared/services/id/id.service";
import { SelectionService } from "src/app/shared/services/selection/selection.service";
import { RnRNModifyVendorAccountItem } from "src/app/shared/services/rnapi2-service/models/rNModifyVendorAccountItem-d";
import { RnRNModifyVendorAccountPayload } from "src/app/shared/services/rnapi2-service/models/rNModifyVendorAccountPayload-d";
import { RnRNModifyVendorSubscriptionPayload } from "src/app/shared/services/rnapi2-service/models/rNModifyVendorSubscriptionPayload-d";
import { RnRNModifyVendorSubscriptionItem } from "src/app/shared/services/rnapi2-service/models/rNModifyVendorSubscriptionItem-d";
import { RnToastService } from "src/app/shared/services/toast/rntoast.service";

@Component({
  selector: "app-qbo-purchase",
  templateUrl: "./qbo-purchase.component.html",
  styleUrls: ["./qbo-purchase.component.scss"],
})
export class QboPurchaseComponent implements OnInit, OnDestroy {
  mainForm: FormGroup;
  dataLoaded = true;
  isPayrolleliteOrPremium = false;
  existingItem: any = null;
  @Output() componentLoaded = new EventEmitter<boolean>();
  addons: RnCatalogItemVM[] = [];
  private subscriptions: Subscription[] = [];
  @Output() QboPlansSelected = new EventEmitter<any>();
  @Output() QboPayrollSelected = new EventEmitter<any>();
  @Output() QboTimeSelected = new EventEmitter<any>();
  @Input() set data(any) {
    if (any === "addPayrollOrTime") {
      this.startingStep = 2;
      this.isAddOnAttach = true;
    } else if (any.Name === "QuickBooks Online") {
      this.startingStep = 1;
      this.isChangeSubscription = true;
      this.existingItem = any;
    } else if (any.Name === "QuickBooks Online Payroll") {
      this.startingStep = 2;
      this.isChangeSubscription = true;
      this.existingItem = any;
    } else if (any.Name === "QuickBooks Time") {
      this.startingStep = 3;
      this.isChangeSubscription = true;
      this.existingItem = any;
    }
  }
  @Input() set additionalData(any) {
    if (any !== null) {
      this.qboAccountID = any;
    }
  }
  isChangeSubscription = false;
  isAddOnAttach = false;
  qboAccountID: string;
  iDontNeedPayroll = false;
  iDontNeedTime = false;
  public orgProfile: RnOrganizationsProfileVM;
  isProcessing = false;
  startingStep = 1;
  checkoutData = [];
  QboTimeData = [];
  QboPlansData = [];
  QboPayrollData = [];
  SelectedPlan: any;
  currentStep = 0;
  steps: AddUserStep[];
  // This isn't ideal, but I get why it was done with the names originally because Marketing people want their
  // special little titles and fancy-pants shit for everything in VERY specific special cases 'n stuff
  // Re-jiggering this to go by SKU because the good folks in Marketing can't decide what they want
  // package names to be half the time.

  // Keeping these arrays around because whether or not a payroll/time addon gets selected affects the behavior of this slideout thingy
  // We'll be populating them with SKUs based on what we pull out of the catalog
  qboPlansAddonsList = [];
  qboTimeAddonsList = [];
  qboPayrollAddonsList = [];
  childrenData = [];
  constructor(
    private organizationService: OrganizationService,
    private rnsidebarService: RnsidebarService,
    private loggedInInfoService: LoggedInInfoService,
    private orderService: OrderService,
    private toastService: RnToastService,
    private formBuilder: FormBuilder,
    private selectionService: SelectionService,
  ) {
    this.subscriptions.push(
      this.selectionService.SelectedOrgProfile.subscribe((p) => {
        this.orgProfile = p;
      }),
    );
  }

  ngOnInit(): void {
    this.loadPackagesData(this.orgProfile.ID.toString()).subscribe((resp) => {
      const result = resp.body.data.Addons;
      this.QboTimeData = result.filter((el) => {
        return el.CatalogCategoryName === "QBOTimeAddons";
      });
      this.QboTimeData.forEach((x) => {
        this.qboTimeAddonsList.push(x.SKU);
        this.childrenData.push(x.SKU);
        if (x.SKU === "QB-TIME-EL") {
          x.PackageCategoryName = "Elite";
        } else if (x.SKU === "QB-TIME-PREM") {
          x.PackageCategoryName = "Premium";
        } else {
          x.PackageCategoryName = x.PackageName;
        }
      });
      this.QboTimeData = this.QboTimeData.sort(
        (a, b) => b.NextBillPrice - a.NextBillPrice,
      );
      this.QboPlansData = result.filter((el) => {
        return el.CatalogCategoryName === "OSQBOAddOns";
      });
      this.QboPlansData.forEach((x) => {
        this.qboPlansAddonsList.push(x.SKU);
        if (x.SKU === "OS-QBO-ADV") {
          x.PackageCategoryName = "Advanced";
        } else if (x.SKU === "OS-QBO-PLUS") {
          x.PackageCategoryName = "Plus";
        } else if (x.SKU === "OS-QBO-ESSTLS") {
          x.PackageCategoryName = "Essentials";
        } else if (x.SKU === "OS-QBO-SS") {
          x.PackageCategoryName = "Simple Start";
        } else {
          x.PackageCategoryName = x.PackageName;
        }
      });
      this.QboPlansData = this.QboPlansData.sort(
        (a, b) => b.NextBillPrice - a.NextBillPrice,
      );

      this.QboPayrollData = result.filter((el) => {
        return el.CatalogCategoryName === "QBOPayrollAddons";
      });

      this.QboPayrollData.forEach((x) => {
        this.qboPayrollAddonsList.push(x.SKU);
        this.childrenData.push(x.SKU);
        if (x.SKU === "QBO-EL-PR") {
          x.PackageCategoryName = "Elite";
        } else if (x.SKU === "QBO-PREM-PR") {
          x.PackageCategoryName = "Premium";
        } else if (x.SKU === "QBO-CORE-PR") {
          x.PackageCategoryName = "Core";
        } else {
          x.PackageCategoryName = x.PackageName;
        }
      });
      this.QboPayrollData = this.QboPayrollData.sort(
        (a, b) => b.NextBillPrice - a.NextBillPrice,
      );
      this.dataLoaded = false;
    });

    this.steps = [];
    this.currentStep = this.startingStep;
    const QboPlans: AddUserStep = {
      ID: "QboPlans",
      title: "Choose the Right QuickBooks Online Plan for You",
      formName: "QboPlans",
      desc: "Each plan comes with our world-class support and data migration.",
    };
    this.steps.push(QboPlans);
    const QboPayroll: AddUserStep = {
      ID: "QboPayroll",
      title: "Add QuickBooks Payroll",
      formName: "QboPayroll",
      desc: "Select your plan, and manage your payroll right from QuickBooks online.",
    };
    this.steps.push(QboPayroll);

    const QboTime: AddUserStep = {
      ID: "QboTime",
      title: "Add QuickBooks Time",
      formName: "QboTime",
      desc: "Select a QuickBooks Time plan, and easily manage time tracking for your business.",
    };
    this.steps.push(QboTime);

    const QboCheckout: AddUserStep = {
      ID: "QboCheckout",
      title: "Check Out",
      formName: "QboCheckout",
      desc: "Purchase your customized QuickBooks Online package below and get started.",
    };
    this.steps.push(QboCheckout);

    const QboConfirmation: AddUserStep = {
      ID: "QboConfirmation",
      title: "Purchase Confirmation",
      formName: "QboConfirmation",
      desc: "Thank you for your purchase. You can manage your plan on the QuickBooks tab in AppHub, and reach out to support at any time.",
    };
    this.steps.push(QboConfirmation);

    this.mainForm = this.formBuilder.group({
      QboPlans: this.formBuilder.group({
        QboPlansSelected: ["", Validators.required.bind(Validators)],
      }),
      QboPayroll: this.formBuilder.group({
        QboPayrollSelected: ["", Validators.required.bind(Validators)],
      }),
      QboTime: this.formBuilder.group({
        QboTimeSelected: ["", Validators.required.bind(Validators)],
      }),
      QboCheckout: this.formBuilder.group({}),
      QboConfirmation: this.formBuilder.group({}),
    });

    this.componentLoaded.emit(true);
  }

  qboPlanSelected(plan: any) {
    this.QboPlansSelected.emit(plan);

    if (this.checkoutData.length > 0) {
      this.checkoutData = this.checkoutData.filter((el) => {
        return this.childrenData.some((f) => {
          return el.PackageSKU === f;
        });
      });
      this.checkoutData.push(plan);
    } else {
      this.checkoutData.push(plan);
    }

    this.checkoutData = this.checkoutData.slice();
  }

  qboPayrollSelected(payroll: any) {
    this.QboPayrollSelected.emit(payroll);
    if (payroll === 100) {
      this.iDontNeedPayroll = true;
      this.isPayrolleliteOrPremium = false;
      this.checkoutData = this.checkoutData.filter(
        (a) => !this.qboPayrollAddonsList.includes(a.SKU),
      );
    } else {
      this.iDontNeedPayroll = false;
    }

    if (this.qboPayrollAddonsList.some((a) => a === payroll.SKU)) {
      this.checkoutData = this.checkoutData.filter(
        (a) =>
          !this.qboPayrollAddonsList.includes(a.SKU) &&
          !this.qboTimeAddonsList.includes(a.SKU),
      );
      this.checkoutData.push(payroll);
      this.isPayrolleliteOrPremium = true;
    }
  }

  qboTimeSelected(time: any) {
    this.QboTimeSelected.emit(time);
    if (time === 100) {
      this.iDontNeedTime = true;
      this.checkoutData = this.checkoutData.filter(
        (a) => !this.qboTimeAddonsList.includes(a.SKU),
      );
    } else {
      this.iDontNeedTime = false;
    }

    if (this.qboTimeAddonsList.some((a) => a === time.SKU)) {
      this.checkoutData = this.checkoutData.filter(
        (a) =>
          !this.qboPayrollAddonsList.includes(a.SKU) &&
          !this.qboTimeAddonsList.includes(a.SKU),
      );
      this.checkoutData.push(time);
    }

    this.checkoutData = this.checkoutData.filter((el) => {
      return el.PackageName !== "iDontNeedTime";
    });
  }

  getOrderItems(selectedItem: any) {
    const orderItems = [];
    for (const item of selectedItem) {
      const o: RnRNOrderItem = {
        PackageID: item.PackageID,
        Quantity: 1,
        PromoCode: "-1",
      };
      orderItems.push(o);
    }
    return orderItems;
  }

  getModifyVendorAccountItems(selectedItem: any) {
    const orderItems = [];
    for (const item of selectedItem) {
      const o: RnRNModifyVendorAccountItem = {
        AccountId: this.qboAccountID,
        PackageID: item.PackageID,
      };
      orderItems.push(o);
    }
    return orderItems;
  }

  getModifyVendorSubscriptionItems(selectedItem: any) {
    const orderItems = [];
    for (const item of selectedItem) {
      const o: RnRNModifyVendorSubscriptionItem = {
        VendorAccountID: this.qboAccountID.split(":")[0],
        VendorBaseSubscriptionID: this.qboAccountID.split(":")[1],
        PackageID: item.PackageID,
      };
      orderItems.push(o);
    }
    return orderItems;
  }

  confirmSaveChanges(selectedItem: any) {
    const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    if (!loggedInUser) {
      return;
    }
    if (this.isAddOnAttach) {
      //to used for add-on
      const payload: RnRNModifyVendorAccountPayload =
        new RnRNModifyVendorAccountPayload();
      payload.VendorName = "Intuit_QBO";
      payload.AffectedOrganizationId = this.orgProfile.ID;
      payload.Items = this.getModifyVendorAccountItems(selectedItem);
      const errorMessage =
        "The order was not processed correctly. Try again in a few moments. If this continues to happen, please contact Customer Support.";
      this.orderService
        .apiV2OrdersModifyvendorAccountPost(payload, "response")
        .subscribe(
          (response) => {
            if (response.body && response.body.Success) {
              this.rnsidebarService.setPendingChanges(false);
              this.isProcessing = false;
              this.currentStep = this.currentStep + 1;
            }
          },
          (error) => {
            this.rnsidebarService.setPendingChanges(false);
            this.isProcessing = false;
            this.toastService.showError(error.error.Messages[0].Description);
          },
        );
    } else if (this.isChangeSubscription && this.existingItem) {
      //to used for upgrade/downgrade
      const payload: RnRNModifyVendorSubscriptionPayload =
        new RnRNModifyVendorSubscriptionPayload();
      payload.VendorName = "Intuit_QBO";
      payload.AffectedOrganizationId = this.orgProfile.ID;
      payload.Items = this.getModifyVendorSubscriptionItems(selectedItem);
      const errorMessage =
        "The order was not processed correctly. Try again in a few moments. If this continues to happen, please contact Customer Support.";
      this.orderService
        .apiV2OrdersModifyvendorSubscriptionPost(payload, "response")
        .subscribe((response) => {
          if (response.body && response.body.Success) {
            this.rnsidebarService.setPendingChanges(false);
            this.isProcessing = false;
            this.currentStep = this.currentStep + 1;
          }
        });
    } else {
      //to used for add
      const payload: RnRNAddOnPayload = new RnRNAddOnPayload();
      payload.PaymentOnFileID =
        this.loggedInInfoService.GetLoggedInUserOrg().PaymentOnFileID;
      payload.OrganizationID = this.orgProfile.ID;
      payload.OrderItems = this.getOrderItems(selectedItem);
      payload.QbesLicenseItems = new Array<RnRNLicenseItem>();
      payload.PromotionCodeID = null;
      payload.ForceSkipEmail = false;
      const header: RnRNOrderHeader = {
        Order_XID: IdService.makeID(),
        Order_Source: "AppHub",
        Order_Date: new Date().toISOString().slice(0, 10).replace(/-/g, "/"),
        SalesAgent_TAG: loggedInUser.UserID.toString(),
      };
      payload.Header = header;

      const errorMessage =
        "The order was not processed correctly. Try again in a few moments. If this continues to happen, please contact Customer Support.";
      this.orderService
        .apiV2OrdersCreateaddonPost(payload, "response")
        .subscribe((response) => {
          if (response.body && response.body.Success) {
            this.rnsidebarService.setPendingChanges(false);
            this.isProcessing = false;
            this.currentStep = this.currentStep + 1;
          }
        });
    }
  }

  processing(): boolean {
    return this.isProcessing;
  }
  isFirstStep(): boolean {
    return this.currentStep <= 1;
  }

  isLastStep(): boolean {
    return this.currentStep == this.steps.length - 1;
  }

  handleNext(): void {
    let nextStep = this.currentStep + 1;
    if (this.isLastStep()) {
      this.isProcessing = true;
      this.processing();
      this.confirmSaveChanges(this.checkoutData);
    } else {
      while (nextStep < this.steps.length) {
        if (this.canNavigateToStep(nextStep - 1)) {
          break;
        }
        nextStep++;
      }
      this.currentStep = nextStep;
    }
    this.postNext();
  }

  handlePrevious(): void {
    if (this.isFirstStep()) {
      this.currentStep = 1;
      return;
    } else {
      let prevStep = this.currentStep - 1;

      while (prevStep > 1) {
        if (this.canNavigateToStep(prevStep - 1)) {
          break;
        }
        prevStep--;
      }
      this.currentStep = prevStep;
    }
  }

  private postNext(): void {
    const currStep = this.steps[this.currentStep - 1];
  }

  private canNavigateToStep(stepIdx: number): boolean {
    const step = this.steps[stepIdx];
    switch (step.ID) {
      case "QboPlans":
        return !this.isChangeSubscription; //qboplan selected ;
      case "QboPayroll":
        return (
          !this.isChangeSubscription ||
          (this.existingItem != null &&
            this.existingItem.Name === "QuickBooks Online Payroll")
        ); // QboPayroll selected;
      case "QboTime":
        return (
          ((!this.isPayrolleliteOrPremium || this.iDontNeedPayroll) &&
            !this.isChangeSubscription) ||
          (this.existingItem != null &&
            this.existingItem.Name === "QuickBooks Time")
        ); // QboTime selected;
      case "QboCheckout":
        return true; // QboCheckout
      case "QboConfirmation":
        return true;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public loadPackagesData(orgId: string): Observable<HttpResponse<any>> {
    const payload = new RnCommonId();
    payload.Id = orgId;
    return this.organizationService.apiV2OrganizationsGetallcatalogitemsfororgPost(
      payload,
      "response",
    );
  }

  onSidebarClose(): void {
    this.rnsidebarService.refreshUser();
  }
}
export interface checkoutData {
  Item: string;
  MonthlyCost: string;
}
