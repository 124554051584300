<div>
  <div>
    <div class="modal-content">
      <div class="row">
        <div class="col-md-2">
          <span class="fa-stack fa-2x">
            <i class="fak fa-rn-shield-filled fa-stack-2x"></i>
            <i class="fak fa-rn-lock-outline fa-stack-1x fa-inverse fa-md"></i>
          </span>
        </div>
        <div class="col-md-9 p-text">
          <span class="messageTitle"
            >Protect your account with Multi-Factor Authentication (MFA)</span
          >
          <br />
          <br />
          <p class="messageSection">
            The Account Owner has required you to setup multi-factor
            authentication (MFA) for your Rightworks account. When enabled the
            security feature will be used for AppHub, Remote Desktop, and File
            Manager.
          </p>
          <br />
          <p class="messageSection">
            Once this feature is fully enabled you will be asked to authenticate
            each time you login.
          </p>
        </div>
        <div class="col-md-1">&nbsp;</div>
      </div>
    </div>
    <hr />
    <div class="modal-footer">
      <app-button
        id="btnEnableMFA"
        (clickActionEmitter)="enableMfa()"
        label="Enable MFA"
        buttonStyle="primary"
      >
      </app-button>
    </div>
  </div>
</div>
