import { UserTokenD } from "./user-token-d";

export class UserToken implements UserTokenD {
  UPN: string;
  aud: string;
  authlevel: string;
  baseApiUrl: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  loginid: string;
  organizationid: string;
  role: string[];
  sub: string;
  userid: string;
}
