/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export enum RnLicenseOrderByTypes {
  OrderByCreateDate = "orderByCreateDate",
  OrderByLicense1 = "orderByLicense1",
  OrderByLicense2 = "orderByLicense2",
  OrderByDisplayName = "orderByDisplayName",
  OrderByType = "orderByType",
  OrderByAutoAssign = "orderByAutoAssign",
}
