import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RnLicenseFullVM } from "src/app/shared/services/rnapi2-service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { DialogTrackingService } from "../../../services/dialog/dialog-tracking.service";
import { RnLicenseUpdateDTO } from "../../../services/rnapi2-service/models/models";
import { LicenseService } from "src/app/shared/services/rnapi2-service";
import { Subscription } from "rxjs";
@Component({
  selector: "app-license-edit",
  templateUrl: "./license-edit.component.html",
  styleUrls: ["./license-edit.component.scss"],
})
export class LicenseEditComponent implements OnInit {
  public frmGroup!: FormGroup;
  public submitted = false;
  public normalButtonText = "Add Organization";
  public pageTitle;
  public licenseInfo: RnLicenseFullVM;
  private subscriptions: Subscription[] = [];
  constructor(
    public config: DynamicDialogConfig,
    private dialogTrackService: DialogTrackingService,
    public ref: DynamicDialogRef,
    private formBuilder: FormBuilder,
    private licenseService: LicenseService,
  ) {}

  ngOnInit(): void {
    this.licenseInfo = this.config.data;
    this.frmGroup = this.formBuilder.group({
      licenseType: ["", Validators.required.bind(Validators)],
      license1: ["", Validators.required.bind(Validators)],
      license2: ["", Validators.required.bind(Validators)],
    });
    if (this.licenseInfo.DisplayName) {
      this.licenseType.setValue(this.licenseInfo.DisplayName);
    }
    if (this.licenseInfo.License1) {
      this.license1.setValue(this.licenseInfo.License1);
    }
    if (this.licenseInfo.License2) {
      this.license2.setValue(this.licenseInfo.License2);
    }
  }
  public close(): void {
    this.dialogTrackService.closeDialog(this.ref, null);
  }
  get licenseType() {
    return this.frmGroup.get("licenseType");
  }
  get license1() {
    return this.frmGroup.get("license1");
  }
  get license2() {
    return this.frmGroup.get("license2");
  }
  saveChanges() {
    const changes: any[] = [];
    const newLicense1 = this.frmGroup.controls["license1"].value.replace(
      /\D/g,
      "",
    );
    const newLicense2 = this.frmGroup.controls["license2"].value.replace(
      /\D/g,
      "",
    );
    if (newLicense1 != this.licenseInfo.License1) {
      changes.push({ Key: "License1", Value: newLicense1 });
    }
    if (newLicense2 != this.licenseInfo.License2) {
      changes.push({ Key: "License2", Value: newLicense2 });
    }
    if (changes.length <= 0) {
      return;
    }
    this.licenseInfo.License1 = newLicense1;
    this.licenseInfo.License2 = newLicense2;

    const payload: RnLicenseUpdateDTO = {
      AffectedOrganizationId: this.licenseInfo.OrganizationID,
      LicensesID: Number(this.licenseInfo.LicenseID),
      ItemsToUpdate: changes,
    };
    const licenseUpdatePost = this.licenseService
      .apiV2LicensesUpdatePost(payload)
      .subscribe((r) => {
        console.log("License updated successfully");
      });
    this.subscriptions.push(licenseUpdatePost);
    this.dialogTrackService.closeDialog(this.ref, null);
  }
}
