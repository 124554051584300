import { Injectable } from "@angular/core";
import { LoggedInInfoService } from "../loggedInInfo/logged-in-info.service";
import { OrganizationService } from "../rnapi2-service/apis/organization.service";
import { RnCommonSearch } from "../rnapi2-service/models/commonSearch-d";
import { RnToastService } from "../toast/rntoast.service";

@Injectable({
  providedIn: "root",
})
export class WriteOffService {
  constructor(
    private loggedInInfoService: LoggedInInfoService,
    private organizationService: OrganizationService,
    private toastService: RnToastService,
  ) {}

  public checkHandleWLPOrgInWriteOff(): void {
    const loggedInOrg = this.loggedInInfoService.GetLoggedInUserOrg();
    if (
      loggedInOrg &&
      loggedInOrg.WhiteLabelPartnerID > 1 &&
      this.loggedInInfoService.loggedInUserHasRights(["VIEWORGDET"])
    ) {
      if (loggedInOrg.Status == "WriteOff") {
        const payload = new RnCommonSearch();
        payload.search = "rn_billing_phone_number";
        this.organizationService
          .apiV2OrganizationsGetconfigurablevaluePost(payload, "response")
          .subscribe((r) => {
            this.toastService.showErrorSticky(
              `<strong>This account is currently in a write-off state. For more information or to resolve this matter, please contact our Billing team at ${r.body.data}.</strong>`,
            );
          });
      }
    }
  }
}
