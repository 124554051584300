/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnEventLogInfo } from "../models/./eventLogInfo-d";

export class RnOrganizationBillingInformationCreate {
  OrganizationID?: number;
  cardNumber?: string | null;
  expirationMonth?: number;
  expirationYear?: number;
  ACHBankName?: string | null;
  ACHAccountName?: string | null;
  ACHAccountType?: string | null;
  ACHAccountNumber?: string | null;
  ACHRoutingNumber?: string | null;
  addresssLine1?: string | null;
  addresssLine2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zipCode?: string | null;
  cardType?: string | null;
  securityCode?: string | null;
  NameOnCard?: string | null;
  FirstName?: string | null;
  LastName?: string | null;
  Billing_Type_ID?: number;
  CardTypeID?: number;
  Payment_Name?: string | null;
  PaymentMethodType?: string | null;
  CompanyName?: string | null;
  isDefault?: boolean;
  expireOn?: string;
  UserId?: number;
  EventLogInfo?: RnEventLogInfo;
  AffectedOrganizationId?: number;
  AffectedUserId?: number;
}
