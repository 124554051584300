import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "rnLicenseManagement",
})
export class RnLicenseManagementPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (args && args.length > 0) {
      const prop = args[0].toString().toLowerCase();
      switch (prop) {
        case "orgLicenseRemovalDisabled": {
          const selected = value as number;
          return selected <= 0;
        }
      }
    }
    return null;
  }
}
