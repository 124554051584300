/*
This class will be used for to send payload for the RNAPI2 API "api/v2/orders/modifyvendoraccount"
*/

import { RnRNModifyVendorAccountItem } from "./rNModifyVendorAccountItem-d";

export class RnRNModifyVendorAccountPayload {
  VendorName: string;
  AffectedOrganizationId: number;
  Items: Array<RnRNModifyVendorAccountItem>;
}
