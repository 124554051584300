export class GridColumnBaseConfiguration {
  constructor(
    typeName: string,
    fieldName: string,
    title: string,
    classname: string,
    sortOrder: string,
  ) {
    this.FieldName = fieldName;
    this.Title = title;
    this.Class = classname;
    this.SortOrder = sortOrder;
    this._typeName = typeName;
    this.SortableColumnDisabled = false;
  }
  public TypeName(dataRow: any): string {
    return this._typeName;
  }
  public FieldName: string;
  public Title: string;
  public Class: string;
  public SortOrder: string;
  public SortableColumnDisabled: boolean;
  public CustomDataRetrieval: (dataRow: any) => string;
  public ExtraData: any;
  public Tooltip: (dataRow: any) => string;

  public GetIsDisabled: (dataRow: any) => boolean;

  private _typeName: string;
}
