/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export class RnPackPoolMV {
  PackagePoolID?: number;
  PackageID?: number;
  PackageName?: string | null;
  PackageCategoryID?: number;
  PackageType?: string | null;
  CurrentQuantity?: number;
  CurrentUsed?: number;
  HTMPDescription?: string | null;
  ListPrice?: number;
  DiscountPrice?: number;
  NextBillPrice?: number;
  DiscountPercent?: number;
  CatalogVisibility?: string | null;
  SortOrder?: number;
  IsOverrideable?: boolean;
  PackageCategory?: string | null;
  CatalogCategoryID?: number | null;
  SKU?: string | null;
  PackageCategoryName?: string | null;
  RequiresLicense?: boolean;
  CatalogCategoryName?: string | null;
  CatalogCategoryDescription?: string | null;
  UnitTypeDesc?: string | null;
  ExternallyPurchasable?: boolean;
}
