import { HttpResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  UrlSegment,
} from "@angular/router";
import { Observable } from "rxjs";
import { OrganizationService } from "src/app/shared/services/rnapi2-service/apis/organization.service";
import { UserLoginState } from "../../../../../core/models/user.login.state";
import { RnDialogService } from "../../../../services/dialog/rndialog.service";
import { LoggedInInfoService } from "../../../../services/loggedInInfo/logged-in-info.service";
import { NavigationService } from "../../../../services/navigation/navigation.service";
import {
  RnCommonSearch,
  RnOrganizationsProfileVM,
  RnOrganizationsProfileVMRNResponseRelay,
  RnWLPInformationVM,
} from "../../../../services/rnapi2-service/models/models";
import { OrganizationCacheService } from "src/app/shared/services/organization-cache/organization-cache.service";
import { FeatureFlagService } from "src/app/shared/services/feature-flag/feature-flag.service";

declare let pendo: any;

@Component({
  selector: "app-rn-navigation-bar",
  templateUrl: "./rn-navigation-bar.component.html",
  styleUrls: ["./rn-navigation-bar.component.scss"],
})
export class RnNavigationBarComponent implements OnInit, OnDestroy {
  public showRNSupportItems: boolean;
  public internalLinkIsVisible: boolean;
  public wlpOrgDescription: string;
  public wlpGettingStartedWebsite: string;
  public wlpOrgs: RnWLPInformationVM[];
  public isSendFeedbackShown: boolean;
  public orgProfile: RnOrganizationsProfileVM;
  public isAppHubContext = false;
  public isAdminContext = false;
  public showAdminConsole = false;
  public showVisitConnect = false;
  public connectUrl = "";
  public showPracticeProtect = false;
  public practiceProtectUrl = "";
  public customerType: string;
  public isAzure = false;
  constructor(
    private loggedInInfoService: LoggedInInfoService,
    private navigationService: NavigationService,
    private organizationService: OrganizationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private rnDialogService: RnDialogService,
    private organizationCacheService: OrganizationCacheService,
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.loggedInInfoService.LoggedInUser.subscribe((u) => {
        this.loggedInUser = u;
      }),
    );
    const orgID = this.loggedInUser?.OrganizationID?.toString();
    const orginzationDataPost = this.loadOrganizationData(orgID).subscribe(
      (r) => {
        this.orgProfile = r.body.data;
      },
      (err) => {
        //this has to exist for the next line to fire.
      },
      () => {
        //ensures that the load completes before executing remaining code. This way, the "Visit Connect" sidebar loads only after the customer type is found:
        this.customerType = this.orgProfile.CustomerType;
        this.showVisitConnect =
          this.loggedInUser &&
          this.loggedInInfoService.loggedInUserHasRights(["MGINSIGHT"]) &&
          this.customerType == "Rightworks";
        this.sendFeedbackLogic();
      },
    );
    this.subscriptions.push(orginzationDataPost);
    this.activatedRoute.url.subscribe((u) => {
      this.currentUrl = u;
    });

    this.subscriptions.push(
      this.loggedInInfoService.LoggedInUser.subscribe((u) => {
        if (u) {
          this.internalLinkIsVisible = u.IsInternal();
          this.wlpOrgDescription = u.OrganizationWLPDescription;
          this.isAzure = u.OrgDataCenterTypeName === "Azure";
        }
      }),
    );

    this.subscriptions.push(
      this.loggedInInfoService.LoggedInuserOrgConfig.subscribe((c) => {
        if (c) {
          this.showRNSupportItems = !c.DisallowRNSupport;
        }
      }),
    );

    this.subscriptions.push(
      this.loggedInInfoService.WLPInfo.subscribe((w) => {
        if (w) {
          this.wlpOrgs = [];
          w.forEach((org) => {
            if (
              org.InternalSecurityGroups.length > 0 &&
              this.loggedInInfoService.loggedInUserHasRights(
                org.InternalSecurityGroups,
              )
            ) {
              this.wlpOrgs.push(org);
            }
          });
        }
      }),
    );

    this.loggedInInfoService.WLPContactInfo.subscribe((c) => {
      if (c) {
        this.wlpGettingStartedWebsite = c.GettingStartedWebsite;
      }
    });

    let payload: RnCommonSearch = {
      search: "rn_connect_url",
    };
    this.subscriptions.push(
      this.organizationService
        .apiV2OrganizationsGetconfigurablevaluePost(payload)
        .subscribe((r) => {
          this.connectUrl = r.data;
        }),
    );

    payload = {
      search: "rn_practice_protect_url",
    };
    this.subscriptions.push(
      this.organizationService
        .apiV2OrganizationsGetconfigurablevaluePost(payload)
        .subscribe((r) => {
          this.practiceProtectUrl = r.data;
          if (!this.practiceProtectUrl) {
            console.warn("Practice Protect URL config value is empty or null");
          }
        }),
    );

    this.subscriptions.push(
      this.featureFlagService
        .getFlag("enable-PP-URL-sidebar", false)
        .subscribe((r: boolean) => {
          this.showPracticeProtect = r;
        }),
    );

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.setContexts();
      }
    });
    this.setContexts();
    this.showAdminConsole =
      this.loggedInUser &&
      this.loggedInInfoService.loggedInUserHasRights(["VIEWORGDET"]);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  setContexts(): void {
    this.isAppHubContext =
      window.location.href.indexOf(
        "user/" + this.loggedInUser?.UserID?.toString(),
      ) >= 0;
    this.isAdminContext =
      window.location.href.indexOf(
        "organization/" + this.loggedInUser?.OrganizationID?.toString(),
      ) >= 0 ||
      (window.location.href.indexOf("user/") >= 0 && !this.isAppHubContext);
  }

  sendFeedbackLogic(): void {
    const payload = new RnCommonSearch();
    if (this.orgProfile?.CustomerType) {
      payload.search = "show_sendfeedback";
      this.subscriptions.push(
        this.organizationService
          .apiV2OrganizationsGetconfigurablevaluePost(payload, "response")
          .subscribe((r) => {
            if (r.body.data && r.body.data !== "") {
              const allowedList = r.body.data;
              this.isSendFeedbackShown =
                allowedList.split(",").indexOf(this.customerType) > -1;
            }
          }),
      );
    }
  }

  async goToAppHubUsersPage(): Promise<void> {
    if (this.loggedInUser && this.loggedInUser.ProfileCreated) {
      await this.navigationService.OpenUser(
        this.loggedInUser.UserID,
        this.loggedInUser.OrganizationID,
      );
    }
  }

  openFileManager(): void {
    window.open("https://filemanager.rightnetworks.com", "_blank");
  }

  async goToAdminConsole(): Promise<void> {
    if (this.loggedInUser && this.loggedInUser.ProfileCreated) {
      await this.navigationService.OpenOrganization(
        this.loggedInUser.OrganizationID,
      );
    }
  }

  goToConnect(): void {
    window.open(this.connectUrl, "_blank");
  }

  goToPracticeProtect(): void {
    window.open(this.practiceProtectUrl, "_blank");
  }

  sendFeedback(): void {
    this.rnDialogService.SendFeedbackDialog();
  }

  loadOrganizationData(
    orgId: string,
  ): Observable<HttpResponse<RnOrganizationsProfileVMRNResponseRelay>> {
    return this.organizationCacheService.getOrganizationProfile(orgId);
  }

  showHomeLink(): boolean {
    return this.loggedInUser.IsInternal();
  }
  async goHome(): Promise<void> {
    await this.navigationService.GoHome();
  }

  public launchDownloadSetupWizard(): void {
    this.navigationService.showDownloadSetupDialog(false);
  }

  public async openInternalWLPOrg(wlpOrgID: number): Promise<void> {
    await this.navigationService.OpenOrganization(wlpOrgID);
  }

  private subscriptions = [];
  private loggedInUser: UserLoginState;
  private currentUrl: UrlSegment[];
}
