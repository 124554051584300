/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnBillItem } from "../models/./billItem-d";

export class RnEstimatedBillVM {
  AddOnItems?: Array<RnBillItem> | null;
  readonly AddOnTotal?: number;
  LicenseItems?: Array<RnBillItem> | null;
  readonly LicenseTotal?: number;
  PackageItems?: Array<RnBillItem> | null;
  readonly PackageTotal?: number;
  PackageNamesPreventingBillingSep?: Array<string> | null;
}
