import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { OrganizationService } from "../../../services/rnapi2-service/apis/api";
import {
  RnCommonId,
  RnEstimatedBillVM,
  RnOrganizationPaymentOnFileVM,
  RnOrganizationsProfileVM,
} from "../../../services/rnapi2-service/models/models";
import { SelectionService } from "../../../services/selection/selection.service";
import { RnToastService } from "../../../services/toast/rntoast.service";

@Component({
  selector: "estimated-bill-breakdown",
  templateUrl: "./estimated-bill-breakdown.component.html",
  styleUrls: ["./estimated-bill-breakdown.component.scss"],
})
export class EstimatedBillBreakdownComponent implements OnInit, OnDestroy {
  public selectedOrg: RnOrganizationsProfileVM = new RnOrganizationsProfileVM();
  public rnOrganizationPaymentOnFileVM: RnOrganizationPaymentOnFileVM;
  public estimatedBill: RnEstimatedBillVM;
  public loading = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private selectionService: SelectionService,
    private organizationService: OrganizationService,
    private rnToastService: RnToastService,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    const selectedOrgSub = this.selectionService.SelectedOrgProfile.subscribe(
      (result) => {
        this.selectedOrg = result;
        if (this.selectedOrg === null) return;

        const paymentInfoSub = this.organizationService
          .apiV2OrganizationsCurrentpaymentinfoPost({
            Id: this.selectedOrg.ID.toString(),
          })
          .subscribe(
            (result) => {
              if (result.Success) {
                this.rnOrganizationPaymentOnFileVM = result.data;
                this.loadEstimatedBillData();
              } else {
                console.error(result.Messages);
              }
            },
            (error) => {
              console.error(error);
            },
          );
        this.subscriptions.push(paymentInfoSub);
      },
    );
    this.subscriptions.push(selectedOrgSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private loadEstimatedBillData(): void {
    const rnCommonId: RnCommonId = {
      Id: this.selectedOrg.ID.toString(),
      AffectedOrganizationId: this.selectedOrg.ID,
    };
    this.organizationService
      .apiV2OrganizationsGetEstimatedBillPost(rnCommonId)
      .subscribe(
        (result) => {
          if (result && result.data && result.Success && result.data) {
            this.estimatedBill = result.data;
          } else {
            let errMsg = "";
            for (let i = 0; i < result.Messages.length; i++) {
              const error = result.Messages[i];
              errMsg +=
                "\r\nCode: " +
                error.Code +
                "\r\nName: " +
                error.Name +
                "\r\nDescription: " +
                error.Description;
            }
            this.rnToastService.showError(
              "There was a problem retrieving the estimated bill for this organization.",
            );
          }
        },
        (error) => {
          this.rnToastService.showError(
            "There was a problem getting the estimated bill for this organization",
          );
        },
        () => {
          this.loading = false;
        },
      );
  }
}
