import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { Dropdown } from "src/app/core/models/Dropdown";
import { OrgBillingInfoMessage } from "src/app/core/models/signalr/org-billing-Info-message";
import { SignalrPollingBaseMessage } from "src/app/core/models/signalr/signalr-polling-base-message";
import { AuthenticationService } from "src/app/shared/security/authentication.service";
import { AddressService } from "src/app/shared/services/address/address.service";
import { ConstantsService } from "src/app/shared/services/constants/constants.service";
import { NotificationDialogService } from "src/app/shared/services/notificationDialog/notification-dialog.service";
import { SignalrPollingService } from "src/app/shared/services/signalr-polling/signalr-polling.service";
import { Guid } from "src/app/shared/utilities/guid/guid.utilities";
import { CreditCardService } from "../../../services/credit-card/credit-card.service";
import { LoggedInInfoService } from "../../../services/loggedInInfo/logged-in-info.service";
import { NavigationService } from "../../../services/navigation/navigation.service";
import { RefreshService } from "../../../services/refresh/refresh.service";
import {
  LookupService,
  OrganizationService,
} from "../../../services/rnapi2-service/apis/api";
import {
  RnOrganizationBillingInformationCreate,
  RnOrganizationPaymentOnFileVM,
  RnPaymentMethodsVM,
} from "../../../services/rnapi2-service/models/models";
import { SelectionService } from "../../../services/selection/selection.service";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { GenericDialogConfiguration } from "../generic-dialog/generic-dialog-configuration";
import { BillingFormData } from "./billing-form.data";

@Component({
  selector: "app-billing-form",
  templateUrl: "./billing-form.component.html",
  styleUrls: ["./billing-form.component.scss"],
})
export class BillingFormComponent implements OnInit, OnDestroy {
  public wlpBillingInfoMsg = "";
  public frmGroup!: FormGroup;
  public submitted = false;
  public billingInfoConst;
  public orgName;
  public address: any = {
    country: {
      label: "USA",
      value: "United States",
    },
  };
  public paymentMethods: RnPaymentMethodsVM[];
  private subscriptions: Subscription[] = [];
  public rnOrganizationPaymentOnFileVM: RnOrganizationPaymentOnFileVM;
  public submitButtonText = "UPDATE PAYMENT METHOD";
  private signalRHandlerID: Guid;
  public canEditPaymentMethodType = false;
  public cancelTooltip = "";
  public shouldLogout = true;
  public paymentFields = [
    "bankName",
    "bankAccountType",
    "bankAccountHolderName",
    "bankAccountRoutingNumber",
    "bankAccountNumber",
    "nameOnCard",
    "cardNumber",
    "mmExpirationDate",
    "yyyyExpirationDate",
    "CVV",
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BillingFormData,
    private mdDialogRef: MatDialogRef<BillingFormComponent>,
    private constantsService: ConstantsService,
    private addressService: AddressService,
    private rnToastService: RnToastService,
    private organizationService: OrganizationService,
    private lookupService: LookupService,
    private creditCardService: CreditCardService,
    private formBuilder: FormBuilder,
    private loggedInInfoService: LoggedInInfoService,
    private notificationService: NotificationDialogService,
    private refreshService: RefreshService,
    private navigationService: NavigationService,
    private authService: AuthenticationService,
    private signalrPollingService: SignalrPollingService,
    private selectionService: SelectionService,
  ) {}

  ngOnInit() {
    if (this.loggedInInfoService.GetWLPConfig) {
      const wlpConfigData = this.loggedInInfoService?.GetWLPConfig()?.data;
      if (wlpConfigData) {
        const infoMessageIndex = Object.keys(wlpConfigData).indexOf(
          "billing_info_message",
        );
        if (infoMessageIndex >= 0) {
          this.wlpBillingInfoMsg =
            Object.values(wlpConfigData)[infoMessageIndex];
        }
      }
    }
    this.InitializeForm();
  }

  // This method initializes the form with the provided default values
  InitializeForm(): void {
    this.frmGroup = this.formBuilder.group({
      paymentMethod: ["CC"],
      country: [
        {
          value: {
            label: "USA",
            value: "United States",
          },
        },
        Validators.required.bind(Validators),
      ],
      address1: ["", Validators.required.bind(Validators)],
      address2: [""],
      postalCode: ["", Validators.required.bind(Validators)],
      city: ["", Validators.required.bind(Validators)],
      state: [""],
      stateText: [""],
      bankName: [""],
      bankAccountType: [""],
      bankAccountHolderName: [""],
      bankAccountRoutingNumber: [""],
      bankAccountNumber: [""],
      nameOnCard: [""],
      cardNumber: [""],
      mmExpirationDate: [""],
      yyyyExpirationDate: [""],
      CVV: [""],
    });

    this.billingFormData = this.data;
    this.orgName = this.billingFormData.organization.Name;
    this.billingInfoConst = this.constantsService.billingInfoConst;

    this.rnOrganizationPaymentOnFileVM = this.billingFormData.pof;
    if (
      this.rnOrganizationPaymentOnFileVM &&
      this.rnOrganizationPaymentOnFileVM.PaymentMethods &&
      this.rnOrganizationPaymentOnFileVM.PaymentMethods.length > 0
    ) {
      if (
        this.rnOrganizationPaymentOnFileVM.PaymentMethods[0]
          .PaymentMethodType === "ACH"
      ) {
        this.paymentMethod.setValue("ACH");
      } else {
        this.paymentMethod.setValue("CC");
      }
    } else {
      this.submitButtonText = "Create Payment Method";
    }

    this.canEditPaymentMethodType =
      this.loggedInInfoService.loggedInUserHasRights(["UPDPYMTHTP"]) ||
      (this.rnOrganizationPaymentOnFileVM &&
      this.rnOrganizationPaymentOnFileVM.PaymentMethods &&
      this.rnOrganizationPaymentOnFileVM.PaymentMethods.length > 0
        ? this.rnOrganizationPaymentOnFileVM.PaymentMethods[0]
        .PaymentMethodType == "ACH"
        : false);
    const paymentMethodsSub = this.lookupService
      .apiV2LookupPaymentmethodsGet()
      .subscribe((result) => {
        this.paymentMethods = result.data.Results;
      });
    this.subscriptions.push(paymentMethodsSub);
    if (this.billingFormData.organization.Status === "Suspended") {
      this.cancelTooltip =
        "You cannot proceed to AppHub until you have updated your payment information.";
    } else if (this.billingFormData.missingBillingInfoOnLogin) {
      this.cancelTooltip =
        "Your Accountant has indicated that you will be paying for your own Rightworks products by declaring you the Account Owner. Please enter your billing information before continuing.";
    }
  }
  validCCData(): boolean {
    let checkCardValues = this.frmGroup.controls.cardNumber.valid && this.frmGroup.controls.nameOnCard.valid && this.frmGroup.controls.mmExpirationDate.valid && this.frmGroup.controls.yyyyExpirationDate.valid && this.frmGroup.controls.CVV.valid;
    let checkCardAddress = this.frmGroup.controls.address1.valid && this.frmGroup.controls.address2.valid && this.frmGroup.controls.postalCode.valid && this.frmGroup.controls.city.valid && this.frmGroup.controls.state.valid;
    return this.paymentMethod.value === "CC" && checkCardValues && checkCardAddress;
  }

  validACHData(): boolean {
    let checkACHValues = this.frmGroup.controls.bankName.valid && this.frmGroup.controls.bankAccountType.valid && this.frmGroup.controls.bankAccountHolderName.valid && this.frmGroup.controls.bankAccountRoutingNumber.valid && this.frmGroup.controls.bankAccountNumber.valid;
    let checkACHAddress = this.frmGroup.controls.address1.valid && this.frmGroup.controls.address2.valid && this.frmGroup.controls.postalCode.valid && this.frmGroup.controls.city.valid && this.frmGroup.controls.state.valid;
    return this.paymentMethod.value === "ACH" && checkACHValues && checkACHAddress;
  }

  getZuoraStateDialogConfig(): GenericDialogConfiguration {
    let ConfirmButtonText = "Log Out";
    const loggedInUserOrg = this.loggedInInfoService.GetLoggedInUserOrg();
    if (loggedInUserOrg.WhileLabelPartnerName !== "Rightworks") {
      this.shouldLogout = false;
      ConfirmButtonText = "OK";
    }
    const cc = new GenericDialogConfiguration();
    cc.Title = "Payment Processing";
    cc.StyleClass = "confirmation";
    cc.ConfirmButtonText = ConfirmButtonText;
    cc.HideCancel = true;
    cc.ShowCloseButton = false;
    cc.DisableClose = true;
    cc.MessageContainsHTML = true;
    cc.DialogFooterClass = "rightAlignButtons";
    cc.ConfirmButtonStyleClass = "primary";
    cc.DialogHeaderClass = "modal-header no-border";
    cc.DialogFooterCancelClass = "right-spacing";
    cc.Message = `Thank you for submitting updated payment information.</br>Your account is now in a payment processing status and functionality will continue to be restricted until the payment has been successfully processed. Please note that this could take up to an hour, and the Account Owner will receive an email with a status update.`;
    cc.Confirmed = async () => {
      await this.logout();
    };
    return cc;
  }

  async logout(): Promise<void> {
    if (this.shouldLogout) {
      await this.navigationService.GoToLoginPage();
      await this.authService.logout();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  checkSpaceClicked(key: KeyboardEvent) {
    if (key.keyCode === 32 || key.which == 32 || key.code == "Space") {
      const element = document.getElementById("CC");
      // simulate click on radio for select payment
      if (element)
        setTimeout(() => {
          element.click();
        }, 100);
    }
  }

  public setPaymentMethodValidators() {
    this.clearAllValidators();
    if (this.paymentMethod.value == "CC") {
      this.clearAllValidators();
      this.nameOnCard.setValidators([Validators.required.bind(Validators)]);
      this.cardNumber.setValidators([Validators.required.bind(Validators)]);
      this.mmExpirationDate.setValidators([
        Validators.required.bind(Validators),
      ]);
      this.yyyyExpirationDate.setValidators([
        Validators.required.bind(Validators),
      ]);
      this.CVV.setValidators([Validators.required.bind(Validators)]);
    } else {
      this.clearAllValidators();
      this.bankAccountHolderName.setValidators([
        Validators.required.bind(Validators),
      ]);
      this.bankAccountNumber.setValidators([
        Validators.required.bind(Validators),
      ]);
      this.bankAccountRoutingNumber.setValidators([
        Validators.required.bind(Validators),
      ]);
      this.bankAccountType.setValidators([
        Validators.required.bind(Validators),
      ]);
      this.bankName.setValidators([Validators.required.bind(Validators)]);
    }
    this.updateAllValuesAndValidity();
  }

  clearAllValidators() {
    // clear all credit card or ACH validators (skip address related validators)
    Object.keys(this.frmGroup.controls).forEach((key) => {
      if (this.paymentFields.includes(key))
        this.frmGroup.controls[key].clearValidators();
      this.frmGroup.controls[key].updateValueAndValidity();
    });
    this.InitializeForm();
  }

  updateAllValuesAndValidity() {
    Object.keys(this.frmGroup.controls).forEach((key) => {
      this.frmGroup.controls[key].updateValueAndValidity();
    });
  }

  validateDate(month, year) {
    let validMonth = false;
    let validYear = false;
    if (year || year == 0) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      if (year >= currentYear && year < currentYear + 11) {
        validYear = true;
      }
    } else {
      validYear = true;
    }
    if (month || month == 0) {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();
      if (year) {
        if (
          year &&
          year <= currentYear &&
          month >= currentMonth &&
          month >= 1 &&
          month <= 12
        ) {
          validMonth = true;
        } else if (year && year > currentYear && month >= 1 && month <= 12) {
          validMonth = true;
        }
      } else {
        if (month >= 1 && month <= 12) {
          validMonth = true;
        }
      }
    } else {
      validMonth = true;
    }

    return validMonth && validYear;
  }

  public onChangeSameBillingAddress(checked: boolean) {
    if (checked) {
      const getCountry = this.addressService
        .getCountryInfo()
        .allCountries.find(
          (c) => c.code == this.billingFormData.organization.Address.Country,
        );
      const CountryCombo: Dropdown = {
        label: getCountry.code,
        value: getCountry.name,
      };
      let stateCombo: Dropdown;
      let getState;
      if (
        CountryCombo.value === "United States" ||
        CountryCombo.value === "Canada"
      ) {
        if (CountryCombo.value === "United States") {
          getState = this.addressService
            .getCountryInfo()
            .usStates.find(
              (s) =>
                s.code ==
                this.billingFormData.organization.Address.StateProvince,
            );
        } else {
          getState = this.addressService
            .getCountryInfo()
            .caRegions.find(
              (s) =>
                s.code ==
                this.billingFormData.organization.Address.StateProvince,
            );
        }
        stateCombo = {
          label: getState.code,
          value: getState.name,
        };
      }

      this.address = {
        country: CountryCombo,
        address1: this.billingFormData.organization.Address.Address1,
        address2: this.billingFormData.organization.Address.Address2,
        postalCode: this.billingFormData.organization.Address.PostalCodeID,
        city: this.billingFormData.organization.Address.City,
        state: stateCombo ? stateCombo.label : undefined,
        stateText: this.billingFormData.organization.Address.StateProvince,
      };
    } else {
      this.address = {
        country: {
          label: "USA",
          value: "United States",
        },
      };
    }
  }

  public onSubmit() {
    this.submitted = true;
    let payload: RnOrganizationBillingInformationCreate;
    let state = "";
    if (
      this.country.value.value !== "Canada" &&
      this.country.value.value !== "United States"
    ) {
      state = this.stateText.value;
    } else {
      state = this.state.value;
    }
    if (this.paymentMethod.value === "ACH") {
      payload = {
        OrganizationID: this.billingFormData.organization.ID,
        country: this.country.value.label,
        addresssLine1: this.address1.value,
        addresssLine2: this.address2.value,
        zipCode: this.postalCode.value,
        city: this.city.value,
        state: state,
        CompanyName: this.billingFormData.organization.Name,
        Payment_Name: "Default",
        PaymentMethodType: "ACH",
        isDefault: true,
        expireOn: "0001-01-01T00:00:00",
        ACHBankName: this.bankName.value,
        ACHAccountName: this.bankAccountHolderName.value,
        ACHAccountType: this.bankAccountType.value,
        ACHAccountNumber: this.bankAccountNumber.value,
        ACHRoutingNumber: this.bankAccountRoutingNumber.value,
      };
    } else {
      const cardNumber = (this.cardNumber.value as string).replace(
        /[^\d]/g,
        "",
      );
      const cardType = this.creditCardService.determineCardType(cardNumber);
      const paymentMethod1 = this.paymentMethods.filter(
        (u) => u.PaymentMethodName == cardType,
      )[0];
      const cardTypeId = paymentMethod1?.PaymentMethodID;

      payload = {
        OrganizationID: this.billingFormData.organization.ID,
        country: this.country.value.label,
        addresssLine1: this.address1.value,
        addresssLine2: this.address2.value,
        zipCode: this.postalCode.value,
        city: this.city.value,
        state: state,
        CompanyName: this.billingFormData.organization.Name,
        Payment_Name: "Card on File",
        PaymentMethodType: cardType,
        isDefault: true,
        expireOn: "0001-01-01T00:00:00",
        NameOnCard: this.nameOnCard.value,
        cardNumber: cardNumber,
        cardType: cardType,
        expirationMonth: this.mmExpirationDate.value,
        expirationYear: this.yyyyExpirationDate.value,
        securityCode: this.CVV.value,
        Billing_Type_ID: cardTypeId,
      };
    }
    const updateBillingInfoSub = this.organizationService
      .apiV2OrganizationsUpdatebillinginfoPost(payload)
      .subscribe(
        (result) => {
          if (result.Success) {
            this.signalRHandlerID =
              this.signalrPollingService.AddMessageHandler((message) => {
                const notification = JSON.parse(
                  message,
                ) as SignalrPollingBaseMessage;
                if (
                  notification &&
                  notification.Payload &&
                  notification.Payload.AreaDataType ===
                  "Org_Billing_Info_Payload" &&
                  notification.Payload.Area.toUpperCase() === "ORG" &&
                  notification.Payload.AreaID ===
                  this.billingFormData.organization.ID
                ) {
                  this.signalrPollingService.RemoveMessageHandler(
                    this.signalRHandlerID,
                  );
                  const data = JSON.parse(
                    notification.Payload.AreaData,
                  ) as OrgBillingInfoMessage;
                  if (data.Succeeded) {
                    if (this.billingFormData.limitedRefresh) {
                      this.refreshService.refreshLoggedInUserOrg(() => {
                        this.refreshService.refreshPOF(() => {
                          this.rnToastService.showSuccess(
                            "Billing info has been updated.",
                          );
                          const pofComplete = true;
                          const pofHappened =
                            this.loggedInInfoService.LoggedInUserOrg.subscribe(
                              (u) => (u.HasDirectPaymentOnFile = pofComplete),
                            );
                          this.subscriptions.push(pofHappened);
                          this.submitted = false;
                          this.close(true);
                        });
                      });
                    } else {
                      this.refreshService.refreshAllWithHandler(() => {
                        this.refreshService.refreshPOF(() => {
                          this.rnToastService.showSuccess(
                            "Billing info has been updated.",
                          );
                          const pofComplete = true;
                          const pofHappened =
                            this.loggedInInfoService.LoggedInUserOrg.subscribe(
                              (u) => (u.HasDirectPaymentOnFile = pofComplete),
                            );
                          this.subscriptions.push(pofHappened);
                          this.submitted = false;
                          this.close(true);
                          if (this.billingFormData.missingBillingInfoOnLogin) {
                            this.navigationService.showDownloadSetupDialog(
                              true,
                            );
                          }

                          const topLevelOrg =
                            this.selectionService.getSelectedTopLevelOrg();

                          if (
                            topLevelOrg.DirectPaymentOnFileStateName ===
                            "Pending" ||
                            topLevelOrg.DirectPaymentOnFileStateName ===
                            "Denied"
                          ) {
                            const confirmConfig =
                              this.getZuoraStateDialogConfig();
                            this.notificationService.ShowConfirmation(
                              confirmConfig,
                            );
                          }
                        });
                      });
                    }
                  } else {
                    this.submitFailed();
                  }
                }
              });
          } else this.submitFailed();
        },
        (err) => {
          this.submitFailed();
        },
      );
    this.subscriptions.push(updateBillingInfoSub);
  }

  public close(changed = false): void {
    this.mdDialogRef.close(changed);
  }

  get paymentMethod() {
    return this.frmGroup.get("paymentMethod");
  }

  get country() {
    return this.frmGroup.get("country");
  }

  get address1() {
    return this.frmGroup.get("address1");
  }

  get address2() {
    return this.frmGroup.get("address2");
  }

  get postalCode() {
    return this.frmGroup.get("postalCode");
  }

  get city() {
    return this.frmGroup.get("city");
  }

  get state() {
    return this.frmGroup.get("state");
  }

  get stateText() {
    return this.frmGroup.get("stateText");
  }

  get bankName() {
    return this.frmGroup.get("bankName");
  }

  get bankAccountType() {
    return this.frmGroup.get("bankAccountType");
  }

  get bankAccountHolderName() {
    return this.frmGroup.get("bankAccountHolderName");
  }

  get bankAccountRoutingNumber() {
    return this.frmGroup.get("bankAccountRoutingNumber");
  }

  get bankAccountNumber() {
    return this.frmGroup.get("bankAccountNumber");
  }

  get nameOnCard() {
    return this.frmGroup.get("nameOnCard");
  }

  get cardNumber() {
    return this.frmGroup.get("cardNumber");
  }

  get mmExpirationDate() {
    return this?.frmGroup?.get("mmExpirationDate");
  }

  get yyyyExpirationDate() {
    return this?.frmGroup?.get("yyyyExpirationDate");
  }

  get CVV() {
    return this.frmGroup.get("CVV");
  }

  private submitFailed(): void {
    this.rnToastService.showError("Billing update has failed!");
    this.submitted = false;
  }
  public billingFormData: BillingFormData;
}
