import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RnApplicationVM, RnUserItemFlavor, UserService } from '../../../services/rnapi2-service';
import { InstallableAppConfiguration } from './installable-app-configuration';
import { Subscription } from 'rxjs';
import { SignalrPollingService } from '../../../services/signalr-polling/signalr-polling.service';
import { SignalRService } from '../../../notifications/signalr.service';
import { SignalrPollingBaseMessage } from '../../../../core/models/signalr/signalr-polling-base-message';
import { RnToastService } from '../../../services/toast/rntoast.service';

@Component({
  selector: 'app-installable-app',
  templateUrl: './installable-app.component.html',
  styleUrl: './installable-app.component.scss'
})
export class InstallableAppComponent implements OnInit, OnDestroy {
  public Title: string;
  public Message: string;
  public MessageContainsHTML: boolean;
  public StyleClass: string;
  public CancelButtonText: string;
  public CancelButtonStyleClass: string;
  public ConfirmButtonStyleClass: string;
  public DialogHeaderClass: string;
  public DialogBodyClass: string;
  public DialogFooterClass: string;
  public HeaderClass: string;
  public DialogFooterCancelClass: string;
  public DialogFooterConfirmClass: string;
  public UseButtonForCancel: boolean;
  public ShowCloseButton: boolean;
  public KeepOpenAfterConfirm = false;
  public HideCancel = false;
  public DisableClose = false;
  public showConfirmIcon = false;
  public showConfirmIconImageSource: string;
  public confirmImageIconClass: string;
  public confirmButtonNoContentPadding = false;
  public cancelButtonNoContentPadding = false;
  public IsWorking: boolean;
  public showDontShowAgain: boolean = false;
  public dontShowAgainText: string = "";
  public dontShowAgainVal: boolean = false;
  public application: RnApplicationVM;
  public uninstalledButtonText: string = "";
  public installedButtonText: string = "";
  public userID: string = "";

  public Configuration: InstallableAppConfiguration;

  optingIn: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: InstallableAppConfiguration,
    private mdDialogRef: MatDialogRef<GenericDialogComponent>,
    private userService: UserService,
    private signalrPollingService: SignalrPollingService,
    private signalRService: SignalRService,
    private toastService: RnToastService) {

    this.Configuration = data;
    this.Title = this.Configuration.Title;
    this.Message = this.Configuration.Message;
    this.MessageContainsHTML = this.Configuration.MessageContainsHTML;
    this.StyleClass = this.Configuration.StyleClass;
    this.CancelButtonText =
      this.Configuration &&
        this.Configuration.CancelButtonText &&
        this.Configuration.CancelButtonText !== ""
        ? this.Configuration.CancelButtonText
        : "Cancel";
    this.CancelButtonStyleClass =
      this.Configuration &&
        this.Configuration.CancelButtonStyleClass &&
        this.Configuration.CancelButtonStyleClass !== ""
        ? this.Configuration.CancelButtonStyleClass
        : "";
    this.uninstalledButtonText =
      this.Configuration &&
        this.Configuration.UninstalledButtonText &&
      this.Configuration.UninstalledButtonText !== ""
      ? this.Configuration.UninstalledButtonText
        : "Install";
    this.installedButtonText =
      this.Configuration &&
      this.Configuration.InstalledButtonText &&
      this.Configuration.InstalledButtonText !== ""
      ? this.Configuration.InstalledButtonText
        : "Launch";
    this.ConfirmButtonStyleClass =
      this.Configuration &&
        this.Configuration.ConfirmButtonStyleClass &&
        this.Configuration.ConfirmButtonStyleClass !== ""
        ? this.Configuration.ConfirmButtonStyleClass
        : "destroy";
    this.DialogHeaderClass =
      this.Configuration &&
        this.Configuration.DialogHeaderClass &&
        this.Configuration.DialogHeaderClass !== ""
        ? this.Configuration.DialogHeaderClass
        : "modal-header";
    this.DialogBodyClass =
      this.Configuration &&
        this.Configuration.DialogBodyClass &&
        this.Configuration.DialogBodyClass !== ""
        ? this.Configuration.DialogBodyClass
        : "modal-content";
    this.DialogFooterClass =
      this.Configuration &&
        this.Configuration.DialogFooterClass &&
        this.Configuration.DialogFooterClass !== ""
        ? this.Configuration.DialogFooterClass
        : "modal-footer";
    this.DialogFooterCancelClass =
      this.Configuration &&
        this.Configuration.DialogFooterConfirmClass &&
        this.Configuration.DialogFooterCancelClass !== ""
        ? this.Configuration.DialogFooterCancelClass
        : "";
    this.DialogFooterConfirmClass =
      this.Configuration &&
        this.Configuration.DialogFooterConfirmClass &&
        this.Configuration.DialogFooterConfirmClass !== ""
        ? this.Configuration.DialogFooterConfirmClass
        : "";
    this.HeaderClass =
      this.Configuration &&
        this.Configuration.HeaderClass &&
        this.Configuration.HeaderClass !== ""
        ? this.Configuration.HeaderClass
        : "modal-header-text";
    this.UseButtonForCancel = this.Configuration.UseButtonForCancel;
    this.ShowCloseButton = this.Configuration.ShowCloseButton;
    this.KeepOpenAfterConfirm = this.Configuration.KeepOpenAfterConfirm;
    this.HideCancel = this.Configuration.HideCancel;
    this.DisableClose = this.Configuration.DisableClose;
    this.showConfirmIcon =
      this.Configuration && this.Configuration.showConfirmIcon
        ? this.Configuration.showConfirmIcon
        : false;
    this.showConfirmIconImageSource =
      this.Configuration &&
        this.Configuration.showConfirmIconImageSource &&
        this.Configuration.showConfirmIconImageSource !== ""
        ? this.Configuration.showConfirmIconImageSource
        : "";
    this.confirmImageIconClass =
      this.Configuration &&
        this.Configuration.confirmImageIconClass &&
        this.Configuration.confirmImageIconClass !== ""
        ? this.Configuration.confirmImageIconClass
        : "";
    this.showDontShowAgain = this.Configuration.showDontShowAgain;
    this.dontShowAgainText = this.Configuration.dontShowAgainText || "Don't show this message again";
    this.application = this.Configuration.Application;
    this.userID = this.Configuration.UserID;
  }

  ngOnInit() {
    if (!this.Configuration.DisableClose)
      window.document
        .querySelector(".cdk-overlay-backdrop")
        ?.addEventListener("click", this.CloseFromBody.bind(this));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    this.signalrPollingService.RemoveUserTrackingInfo(+this.userID);
  }

  public CancelClick(): void {
    this.mdDialogRef.close();
    if (this.Configuration && this.Configuration.Canceled) {
      this.Configuration.Canceled();
    }
  }

  public CloseClick(): void {
    if (this.Configuration && this.Configuration.OnClose) {
      this.mdDialogRef.close();
      this.Configuration.OnClose();
    } else {
      // If we don't have a set delegate for closing, try the Cancel button
      // event (for dialogs that pre-date the separate events)
      this.CancelClick();
    }
  }

  public ConfirmClick(): void {
    if (this.KeepOpenAfterConfirm) {
      this.IsWorking = true;
    } else {
      this.mdDialogRef.close();
    }
    if (this.Configuration.showDontShowAgain && this.dontShowAgainVal) {
      this.Configuration.checkboxActionHandler(this.dontShowAgainVal);
      this.Configuration.Confirmed(this);
    }
    else if (this.Configuration && this.Configuration.Confirmed) {
      this.Configuration.Confirmed(this);
    }
  }

  public uninstalledStateClick(): void {
    // install the app
    this.optingIn = true;

    const payload = new RnUserItemFlavor();
    payload.UserId = +this.userID;
    payload.ItemFlavorId = this.application.ItemFlavorId;
    payload.Install = true;
    this.subscriptions.push(
      this.userService.apiV2UsersInstallitemPost(payload, "response").subscribe(
        (r) => {
          this.signalrPollingService.AddUserTrackingInfo(+this.userID);
          this.signalRService.messages.subscribe((message) => {
            const notification = JSON.parse(message) as SignalrPollingBaseMessage;
            if (
              notification &&
              notification.Payload &&
              notification.Payload.AreaDataType.toUpperCase() ===
              "APPLICATION_INSTALL_PAYLOAD" &&
              notification.Payload.Area.toUpperCase() === "USER" &&
              notification.Payload.AreaID === +this.userID
            ) {
              const data = JSON.parse(notification.Payload.AreaData);

              if (data.Status === 'Installed') {
                this.application.Installed = true;
                this.optingIn = false;
              }
            }
          });
        },
        (e) => {
          this.optingIn = false;
          this.toastService.showError("We have encountered an error processing your request. Please try again.");
        },
      ),
    );
  }

  public dontShowAgainClicked(event) {
    this.dontShowAgainVal = event;
  }

  public CloseFromBody(): void {
    const evt = event.target as Element;
    if (
      window.document.querySelector(".close-clicker") &&
      evt &&
      evt.classList.contains("cdk-overlay-backdrop")
    ) {
      window.document
        .querySelector(".close-clicker")
        .dispatchEvent(new Event("click"));
    }
  }

  public CloseFromBodyClick(): void {
    if (this.Configuration && this.Configuration.OnCloseFromBody) {
      this.mdDialogRef.close();
      this.Configuration.OnCloseFromBody();
    } else {
      // If we don't have a specific delegate when the close from body fires, do the regular close event
      this.CloseClick();
    }
  }

  public CloseDialog(): void {
    this.mdDialogRef.close();
  }

  private subscriptions: Subscription[] = [];
}
