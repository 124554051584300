import { RnLicenseBaseVM } from "../../../services/rnapi2-service/models/models";
import { OrgInfo } from "../org-lookup/org-lookup-data";

export class LicensePurchaseOrgInfo {
  ID: number;
  Name: string;
}

export class LicensePurchaseInfo {
  qbVersion = "";
  qbEdition = "";
  quantity: number;
  userCount: number;
  destOrgType = "";
  dest: OrgInfo;
}

export class LicensePurchaseResponse {
  license: RnLicenseBaseVM;
  orgID: number;
  purchaseID: string;
  quantity: number;
  typeName: string;
  orgName: string;
}

export class LicensePurchaseInfoResponse {
  isPurchase: boolean;
  purchasedItems: LicensePurchaseResponse[];
}
