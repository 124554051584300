import { RnCatalogItemVM } from "src/app/shared/services/rnapi2-service/models/catalogItemVM-d";
import { RnPackPoolMV } from "../../../services/rnapi2-service/models/models";

export class PackageCardData {
  public PackageID: number;
  public PackageName: string;
  public PackageSKU: string;
  public PackageSlug: string;
  public PackageDesc: string;
  public PackageDescHtml: string;
  public ListPrice: number;
  public DiscountPrice: number;
  public DiscountPercent: number;
  public NextBillPrice: number;
  public PackageTypeID: number;
  public PackageCategoryID: number;
  public PackageType: string;
  public SortOrder: number;
  public IsOverrideable: boolean;
  public HasTerminalServices: boolean;
  public PackageCategoryName: string;
  public CatalogCategoryID: number;
  public CatalogCategoryName: string;
  public CatalogCategoryDesc: string;
  public CatalogCategoryHtmlDesc: string;
  public CatalogSortOrder: number;
  public CatalogVisibility: string;
  public CurrentQuantity: number;
  public UnitTypeDesc: string;
  public CurrentUsed: number;
  public PackagePoolID: number;
  public ExcludingPackageIds: Array<number>;
  public RequiredPackagesIds: Array<number>;
  public ExcludingPackageNames: Array<string>;
  public RequiredPackagesNames: Array<string>;
  public InitialQuantity: number;
  public Selected = false;
  public BuyNew = false;
  public AddOnAdded = false;
  public InfoOnly = false;
  public UsesLicenses?: boolean;
  public ExternallyPurchasable: boolean;
  public PackageTypeName?: string | null;
  public PackageDiscountTypeID?: number;
  public PackageDiscountTypeName?: string | null;
  public IsDiscountable?: boolean;
  public CatalogVisibilityID?: number;
  public PackageUIType?: string | null;
  public PackageCategoryDescription?: string | null;
  public NonHostedDesktop = false;
  public MaxItemsAllowed: number;
  public isTooltipDisabled?: boolean;

  public AssignFromRnCatalogItemVM(source: RnCatalogItemVM): void {
    this.PackageID = source.PackageID;
    this.PackageName = source.PackageName;
    this.PackageSKU = source.PackageSKU;
    this.PackageSlug = source.Slug;
    this.PackageDesc = source.PackageDescription;
    this.PackageDescHtml = source.PackageHTMLDescription;
    this.ListPrice = source.ListPrice;
    this.DiscountPrice = source.DiscountPrice;
    this.DiscountPercent = source.DiscountPercent;
    this.NextBillPrice = source.NextBillPrice;
    this.PackageTypeID = source.PackageTypeID;
    this.PackageCategoryID = source.PackageCategoryID;
    this.PackageType = source.PackageTypeDescription;
    this.SortOrder = source.SortOrder;
    this.IsOverrideable = source.IsOverrideable;
    this.HasTerminalServices = source.HasTerminalServices;
    this.PackageCategoryName = source.PackageCategoryName;
    this.PackageCategoryDescription = source.PackageCategoryDescription;
    this.CatalogCategoryID = source.CatalogCategoryID;
    this.CatalogCategoryName = source.CatalogCategoryName;
    this.CatalogCategoryDesc = source.CatalogCategoryDescription;
    this.CatalogCategoryHtmlDesc = source.CatalogCategoryHtmlDescription;
    this.CatalogSortOrder = source.CatalogSortOrder;
    this.CatalogVisibility = source.CatalogVisibility;
    this.CurrentQuantity = source.CurrentQuantity;
    this.CurrentUsed = source.CurrentUsed;
    this.ExcludingPackageIds = source.ExcludingPackageIds;
    this.ExcludingPackageNames = source.ExcludingPackageNames;
    this.RequiredPackagesIds = source.RequiredPackagesIds;
    this.RequiredPackagesNames = source.RequiredPackagesNames;
    this.PackagePoolID = source.PackagePoolID;
    this.UsesLicenses = source.RequiresLicense;
    this.UnitTypeDesc = source.UnitTypeDesc;
    this.ExternallyPurchasable = source.ExternallyPurchasable;
    this.PackageTypeName = source.PackageTypeName;
    this.PackageDiscountTypeID = source.PackageDiscountTypeID;
    this.PackageDiscountTypeName = source.PackageDiscountTypeName;
    this.IsDiscountable = source.IsDiscountable;
    this.CatalogVisibilityID = source.CatalogVisibilityID;
    this.PackageUIType = source.PackageUIType;
    this.InitialQuantity = source.CurrentQuantity;
    this.NonHostedDesktop = source.HasExternalHosting;
    this.MaxItemsAllowed = source.MaxItemsAllowed;
    this.isTooltipDisabled = source.isTooltipDisabled;
  }
}
