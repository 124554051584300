import { Injectable } from "@angular/core";
import { PackageCardData } from "../../components/rn-business-components/package-card/package-card-data";
import { BehaviorSubject, Observable } from "rxjs";
import { PackageUpdateEventData } from "./package-update-event-data.model";

@Injectable({
  providedIn: "root",
})
export class PackagesService {
  private packageUpdatSubject = new BehaviorSubject<PackageUpdateEventData>(
    null,
  );

  public isApplicableDeployQuickBooks(
    selectedPackage: PackageCardData,
    licenses: any,
    addOns: PackageCardData[],
  ): boolean {
    const addOnsRequiringLicense = addOns.filter(
      (x) => x.UsesLicenses === true,
    );
    const selectionsRequireLicense =
      selectedPackage.UsesLicenses || addOnsRequiringLicense.length > 0;
    const assignableLicenses = licenses.filter(
      (x) => x.IsAutoAssignable === true,
    );
    const hasAssignableLicenses =
      assignableLicenses && assignableLicenses.length > 0;
    if (hasAssignableLicenses && selectionsRequireLicense) {
      return true;
    }
    return false;
  }

  updatePackageEvent(event: PackageUpdateEventData) {
    this.packageUpdatSubject.next(event);
  }

  onUpdatePackageEvent(): Observable<PackageUpdateEventData> {
    return this.packageUpdatSubject.asObservable();
  }
}
