/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export class RnRNSubOrganization {
  OrgNumber?: string | null;
  Display_Name?: string | null;
  XID?: string | null;
  Parent_ID?: number;
  ParentFolderID?: number;
  SalesAgent_ID?: number;
  Address1?: string | null;
  Address2?: string | null;
  City?: string | null;
  State?: string | null;
  PostalCode?: string | null;
  Country?: string | null;
}
