import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { RnOrganizationsProfileVM } from "src/app/shared/services/rnapi2-service";

@Component({
  selector: "app-qbo-payroll",
  templateUrl: "./qbo-payroll.component.html",
  styleUrls: ["./qbo-payroll.component.scss"],
})
export class QboPayrollComponent {
  @Input() payrollForm: FormGroup;
  @Output() QboPayrollSelected = new EventEmitter<any>();
  @Input() set qboPayrollData(data: any) {
    this.QboPayrollData = data;
  }
  public existingQboPlans: any;
  public isChangeSubType: boolean;
  @Input() set qboExistingPlan(data: any) {
    this.existingQboPlans = data;
  }
  @Input() set isChangeSub(data: boolean) {
    this.isChangeSubType = data;
  }
  public noPayroll = false;
  public QboPayrollData: any;
  public orgProfile: RnOrganizationsProfileVM;
  public selectedIndex: number;
  index: number = null;
  lastIndex = -1;

  closeAccordion() {
    this.index = this.lastIndex--;
  }

  selectionOccured(any?) {
    if (any === 100) {
      this.QboPayrollSelected.emit([{ PackageName: "iDontNeedPayroll" }]);
      this.setRow(any);
    }
    this.QboPayrollSelected.emit(any);
  }

  public setRow(_index: number) {
    this.selectedIndex = _index;
  }
}
