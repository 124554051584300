import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { filter } from "rxjs/operators";
import { LoggedInInfoService } from "src/app/shared/services/loggedInInfo/logged-in-info.service";
import { RnLicenseBaseVM } from "../../../../services/rnapi2-service/models/models";
import { RnToastService } from "../../../../services/toast/rntoast.service";
import { Guid } from "../../../../utilities/guid/guid.utilities";
import { OrgInfo } from "../../org-lookup/org-lookup-data";
import { PackageCardData } from "../../package-card/package-card-data";
import { LicenseInfoByType } from "../license-info-by-type";
import { LicensePurchaseConfig } from "../license-purchase-config";
import {
  LicensePurchaseInfo,
  LicensePurchaseResponse,
} from "../license-purchase-info";

@Component({
  selector: "app-license-type-detail",
  templateUrl: "./license-type-detail.component.html",
  styleUrls: ["./license-type-detail.component.scss"],
})
export class LicenseTypeDetailComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() set selectedLicenseType(type: PackageCardData) {
    this.SelectedLicenseType = type;
    this.setupInfo();
  }
  @Input() licensePurchConfig: LicensePurchaseConfig;
  @Input() set licenseInfoByType(info: LicenseInfoByType) {
    this.LicenseInfoByType = info;
    this.setupInfo();
  }
  @Input() initiateAddItem: boolean;

  @Input() purchases: LicensePurchaseResponse[];

  @Output() formValidChange = new EventEmitter<boolean>();
  @Output() licensePurchaseAdded = new EventEmitter<LicensePurchaseResponse>();

  @ViewChild("licenseForm") public form: NgForm;

  public editionTooltip: string;
  public product_sales_phone: string;
  public SelectedLicenseType: PackageCardData;
  public LicenseInfoByType: LicenseInfoByType;
  public selectedLicenseTypeInfo: RnLicenseBaseVM[];
  constructor(
    private toastService: RnToastService,
    private loggedInInfoService: LoggedInInfoService,
  ) {}

  formData: LicensePurchaseInfo;

  versionInfoSelect: SelectItem[];
  editionInfoSelect: SelectItem[];
  quantityInfoSelect: SelectItem[];

  ngOnInit(): void {
    if (this.loggedInInfoService.GetWLPConfig) {
      const wlpConfigData = this.loggedInInfoService?.GetWLPConfig()?.data;
      if (wlpConfigData) {
        this.product_sales_phone =
          Object.values(wlpConfigData)[
            Object.keys(wlpConfigData).indexOf("product_sales_phone_number")
          ];
      }
    }

    this.formData = new LicensePurchaseInfo();
    this.setupInfo();
  }

  ngAfterViewInit(): void {
    this.form.statusChanges
      .pipe(filter(() => this.form.valid))
      .subscribe(() => {
        this.onFormValid();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === "initiateAddItem") {
        this.emitPurchaseItem();
      }
    }
  }

  emitPurchaseItem(): void {
    const response = new LicensePurchaseResponse();

    let selection = [];
    if (this.formData) {
      if (this.SelectedLicenseType.PackageType === "leased") {
        selection = selection = this.selectedLicenseTypeInfo.filter((l) => {
          return (
            l.Country === "US" &&
            l.Version === this.formData.qbVersion &&
            l.Edition === this.formData.qbEdition
          );
        });
      } else {
        let productText = `${this.formData.qbVersion} QuickBooks US ${this.formData.qbEdition} License`;

        if (this.formData.userCount > 0) {
          productText += ` (${this.formData.userCount} User${this.formData.userCount > 1 ? "s)" : ")"}`;
        }
        selection = this.selectedLicenseTypeInfo.filter((l) => {
          return l.PackageName.toLowerCase() == productText.toLowerCase();
        });
      }

      if (!selection || selection.length === 0) {
        let missingLicenseName = `${this.formData.qbVersion} QuickBooks US ${this.formData.qbEdition}`;

        if (this.formData.userCount > 0) {
          missingLicenseName += ` (${this.formData.userCount} User${this.formData.userCount > 1 ? "s)" : ")"}`;
        }
        const content = `We are currently unable to fulfill your selection of license type ${missingLicenseName}. Please choose a different license/quantity or call ${this.product_sales_phone} for additional support.`;

        this.toastService.showError(content);
        return;
      }
      response.license = selection[0];
      response.orgID =
        this.formData.destOrgType === "subOrg"
          ? this.formData.dest.ID
          : this.licensePurchConfig.selectedOrg.ID;
      response.orgName =
        this.formData.destOrgType === "subOrg"
          ? this.formData.dest.Name
          : this.licensePurchConfig.selectedOrg.Name;
      response.quantity = this.formData.quantity;
      response.purchaseID = Guid.newGuid();
      response.typeName =
        response.license.LicenseTypeName === "AnnualSubscription"
          ? "Annual"
          : response.license.LicenseTypeName;

      this.licensePurchaseAdded.emit(response);
    }

    if (this.form) {
      this.form.resetForm();
    }
    this.setFormData();
    this.onFormValid();
  }

  onFormValid(): void {
    if (this.form && !this.form.pristine) {
      const valid =
        this.form.valid &&
        (this.formData.destOrgType === "thisorg" ||
          (this.formData.destOrgType === "subOrg" &&
            this.formData.dest &&
            this.formData.dest.ID > 0));
      this.formValidChange.emit(valid);
    }
  }

  changeQbVersion(): void {
    const temp = this.selectedLicenseTypeInfo.filter((l) => {
      return l.Version === this.formData.qbVersion;
    });
    const tempArray = [];
    temp.forEach((l) => {
      if (tempArray.indexOf(l.Edition) < 0) {
        tempArray.push(l.Edition);
      }
    });
    this.editionInfoSelect = this.createDropdownOptions(
      tempArray.sort((a, b) => {
        return a.localeCompare(b);
      }),
    );
    this.onFormValid();
  }

  changeQbEdition(): void {
    const testEdition = this.formData.qbEdition.toLowerCase();
    let quantityInfo = [];
    if (testEdition === "pro" || testEdition === "pro plus") {
      quantityInfo = ["1", "2", "3"];
    } else if (testEdition === "premier" || testEdition === "premier plus") {
      quantityInfo = ["1", "2", "3", "4", "5"];
    } else if (testEdition === "premier accountant") {
      quantityInfo = ["1", "2", "3", "4", "5", "10"];
    }

    this.quantityInfoSelect = this.createDropdownOptions(quantityInfo);

    if (
      this.SelectedLicenseType.PackageType === "subscription" ||
      this.SelectedLicenseType.PackageType === "purchase"
    ) {
      this.formData.quantity = 1;
    } else if (!this.formData.quantity) {
      this.formData.quantity = 1;
    }
    this.onFormValid();
  }

  changeQbQuantity(): void {
    this.onFormValid();
  }

  removeSelectedOrg(): void {
    this.formData.dest = undefined;
    this.onFormValid();
  }

  showQuantityEdit(): boolean {
    return this.SelectedLicenseType?.PackageType === "leased";
  }

  showOrgSelection(): boolean {
    return (
      this.licensePurchConfig.selectedOrg.HasClientOrgs &&
      (this.formData.dest === null || this.formData.dest === undefined)
    );
  }

  showRemoveOrgSelection(): boolean {
    return (
      this.licensePurchConfig.selectedOrg.HasClientOrgs &&
      this.formData.dest !== null &&
      this.formData.dest !== undefined
    );
  }

  orgRadioIsDisabled(): boolean {
    if (this.SelectedLicenseType?.PackageType === "leased") {
      return !this.formData.quantity || this.formData.quantity < 1;
    } else {
      return !this.formData.userCount || this.formData.userCount < 1;
    }
  }

  private setupInfo(): void {
    if (this.LicenseInfoByType && this.SelectedLicenseType) {
      const versionInfo = [];
      switch (this.SelectedLicenseType.PackageType) {
        case "purchase":
          this.selectedLicenseTypeInfo = this.LicenseInfoByType.purchaseInfo;
          break;
        case "leased":
          this.selectedLicenseTypeInfo = this.LicenseInfoByType.leasedInfo;
          break;
        case "subscription":
          this.selectedLicenseTypeInfo =
            this.LicenseInfoByType.subscriptionInfo;
      }

      this.setTooltips();

      this.selectedLicenseTypeInfo.forEach((l) => {
        if (versionInfo.indexOf(l.Version) < 0) {
          versionInfo.push(l.Version);
        }
      });
      versionInfo.sort((a, b) => {
        return b.localeCompare(a);
      });
      this.versionInfoSelect = this.createDropdownOptions(versionInfo);

      if (this.form) {
        this.form.resetForm();
      }
      this.setFormData();
    }
  }

  private createDropdownOptions(options): SelectItem[] {
    const selectOptions = options.map((o) => {
      return {
        label: o,
        value: o,
      };
    });
    selectOptions.unshift({ label: "", value: "" });
    return selectOptions;
  }

  private setTooltips(): void {
    switch (this.SelectedLicenseType.PackageType) {
      case "purchase":
        this.editionTooltip =
          '<p data-testid="tooltipMsg"><b>Pro</b> is the standard version of QuickBooks.<br><br><b>Premier</b> adds industry-specific tools and reporting capabilities.<br><br><b>Premier Accountant</b> includes all <b>Premier</b> features, plus extra accountant-oriented features like bulk transaction management to help manage your clients more effectively.</p>';
        break;
      case "leased":
        this.editionTooltip =
          '<p data-testid="tooltipMsg"><b>Pro</b> is the standard version of QuickBooks.<br><br><b>Premier</b> adds industry-specific tools and reporting capabilities.<br><br><b>Premier Accountant</b> includes all <b>Premier</b> features, plus extra accountant-oriented features like bulk transaction management to help manage your clients more effectively.</p>';
        break;
      case "subscription":
        this.editionTooltip =
          '<p data-testid="tooltipMsg"><b>Pro Plus</b> is the standard version of QuickBooks.<br><br><b>Premier Plus</b> adds industry-specific tools and reporting capabilities.</p>';
        break;
    }
  }

  private setFormData(): void {
    if (this.formData) {
      if (this.licensePurchConfig.selectedOrg.HasClientOrgs) {
        this.formData.destOrgType = "";
        this.formData.dest = null;
      } else {
        this.formData.destOrgType = "thisorg";
        this.formData.dest = new OrgInfo();
        this.formData.dest.ID = this.licensePurchConfig.selectedOrg.ID;
        this.formData.dest.Name = this.licensePurchConfig.selectedOrg.Name;
      }
    }
  }
}
