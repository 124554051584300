import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  LookupService,
  RnCommonId,
  RnCountryCodesVM,
  RnCountryCodesVMSearchResultsVMRNResponseRelay,
  RnLUCountryCodesOrderByTypes,
  RnStringStringKeyValuePair,
  RnUserCreateUpdateXid,
  RnUserIsEmailUnique,
  RnUsersProfileVM,
  RnUsersProfileVMRNResponseRelay,
  RnUserTypesVM,
  RnUserUpdatePayload,
  UserService,
  RnUserUserTypeAdd,
  RnOrganizationsProfileVM,
  RnOrganizationUpdateAccountOwner,
} from "src/app/shared/services/rnapi2-service";
import {
  forkJoin,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
  catchError,
  Subscriber,
} from "rxjs";
import { Output, EventEmitter } from "@angular/core";
import { ConstantsService } from "src/app/shared/services/constants/constants.service";
import { ModalService } from "@rn-platform/frontend-shared-ui-common";
import { LoggedInInfoService } from "src/app/shared/services/loggedInInfo/logged-in-info.service";
import { noWhitespaceValidator } from "src/app/shared/validation/white-space-validator.service";
import { HttpResponse } from "@angular/common/http";
import { UserLoginState } from "src/app/core/models/user.login.state";
import { UserTypesVM } from "../../../../../core/models/UserTypesVM";
import { RnToastService } from "src/app/shared/services/toast/rntoast.service";
import { GenericDialogConfiguration } from "../../generic-dialog/generic-dialog-configuration";
import { NotificationDialogService } from "src/app/shared/services/notificationDialog/notification-dialog.service";
import { OrganizationService } from "src/app/shared/services/rnapi2-service";
import { AuthenticationService } from "src/app/shared/security/authentication.service";
import { OrganizationCacheService } from "src/app/shared/services/organization-cache/organization-cache.service";
import { ProfileModalService } from "src/app/shared/services/profileModalService/profile-modal.service";
import { UserProfileUpdateEvent } from "src/app/shared/services/profileModalService/profile-update-event.model";
import { RoleUpdateEvent } from "src/app/shared/services/profileModalService/role-update-event.model";

const PhoneRegEx = "\\-?\\d*\\.?\\d{1,2}";
const PinRegEx = /^\d{4}$/;
const EmailRegEx =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const AccountOwnerRole = "Account Owner"; // TODO: what's a good place for constants like this?
const RightworksCustomerType = "Rightworks";

@Component({
  selector: "app-user-details-modal",
  templateUrl: "./user-details-modal.component.html",
  styleUrls: ["./user-details-modal.component.scss"],
})
export class UserDetailsModalComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() modalId: string;
  @Output() pendingValidationEvent = new EventEmitter<boolean>();

  private loggedInUser: UserLoginState;
  user: RnUsersProfileVM;
  userDetailsForm: FormGroup;
  loggedInUserState: UserLoginState;
  rnUserIsEmailUnique: RnUserIsEmailUnique;
  countryCodes: RnCountryCodesVM[] = [];
  visibleRoles: RnUserTypesVM[] = [];
  userOrganization: RnOrganizationsProfileVM;
  xidHelp: string;
  idPrefix: "edit-user-modal-";
  emailUnique = true;
  phoneNumberMask = "(000) 000-0000999";
  supportPINMask = "0000";
  isPINRequired = true;
  userInfoInEditMode = false;
  subscriptions: Subscription[] = [];
  loadingRoles = true;
  loadingCountries = true;
  userLoading = true;
  isSaving = false;
  canEditContactInfo = false;
  canEditUserRole = false;
  canEditXID = false;
  canEditSupportPin = false;
  phoneNumberRequired = false;
  userIsReadOnly = false;

  constructor(
    public constantsService: ConstantsService,
    public userService: UserService,
    public loggedInInfoService: LoggedInInfoService,
    public lookupService: LookupService,
    public organizationService: OrganizationService,
    public modalService: ModalService,
    public formBuilder: FormBuilder,
    public cdr: ChangeDetectorRef,
    public toastService: RnToastService,
    public notificationDialogService: NotificationDialogService,
    public authenticationService: AuthenticationService,
    public organizationCacheService: OrganizationCacheService,
    public profileModalService: ProfileModalService,
  ) {}

  @Output() savingEvent = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.initForm();
    const sub = this.loggedInInfoService.LoggedInUser.subscribe((u) => {
      this.loggedInUserState = u;
      this.cdr.detectChanges();
    });
    this.subscriptions.push(sub);
    this.xidHelp = this.constantsService.XIDHelp;
  }

  private initForm(): void {
    this.userDetailsForm = this.formBuilder.group({
      firstName: new FormControl(null, Validators.required.bind(Validators)),
      lastName: new FormControl(null, Validators.required.bind(Validators)),
      email: new FormControl(null, [
        Validators.required.bind(Validators),
        noWhitespaceValidator,
        Validators.pattern(EmailRegEx),
      ]),
      countryCode: new FormControl(null),
      phoneNumber: new FormControl(null, []),
      role: new FormControl(null, Validators.required.bind(Validators)),
      xid: new FormControl(null),
      supportPIN: new FormControl(null, []),
    });
  }

  /**
   * Initialize the component for a specific user
   * @param userId user ID to load
   */
  public initForUser(userId: number, organizationId: number): void {
    if (!userId || !organizationId) {
      console.error(
        `Invalid user or organization ID initializing profile modal, userId: '${userId}', organizationId: '${organizationId}'`,
      );
      this.toastService.showError(
        "An error occurred initializing profile form information",
      );
      return;
    }

    this.initForm();
    this.resetLoaderStatus();
    this.loggedInInfoService.refreshLoggedInUser();
    this.loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    this.loadCountryCodes();
    this.loadUser(userId, organizationId);
  }

  /**
   * load country codes if not already loaded
   */
  private loadCountryCodes(): void {
    if (this.countryCodes.length === 0) {
      const sub = this.lookupService
        .apiV2LookupCountrycodesGet(
          RnLUCountryCodesOrderByTypes.OrderByName,
          null,
          0,
          0,
          false,
          "response",
        )
        .pipe(
          map(
            (
              response: HttpResponse<RnCountryCodesVMSearchResultsVMRNResponseRelay>,
            ) => response.body.data.Results,
          ),
        )
        .subscribe((countryCodes: RnCountryCodesVM[]) => {
          this.loadingCountries = false;
          this.countryCodes = countryCodes;
        });
      this.subscriptions.push(sub);
    }
  }

  /**
   * load user being edited
   * @param userId user id
   */
  private loadUser(userId: number, organizationId: number): void {
    this.userLoading = true;
    const payload: RnCommonId = {
      Id: userId.toString(),
    };
    const sub = forkJoin([
      this.userService
        .apiV2UsersGetuserPost(payload, "response")
        .pipe(
          map(
            (response: HttpResponse<RnUsersProfileVMRNResponseRelay>) =>
              response.body.data,
          ),
        ),
      this.organizationCacheService
        .getOrganizationProfile(organizationId.toString())
        .pipe(map((response) => response.body.data)),
    ]).subscribe({
      next: ([user, org]) => {
        this.user = user;
        this.userOrganization = org;
        this.setSecurityFlagsForUser(this.user);
        this.loadRoles(this.user, org);
        this.setupSupportPINValidator(this.user);
        this.setupPhoneNumberValidators(this.user);
        this.fillInUserInfoForm(this.user);
        this.cdr.detectChanges();
        this.userLoading = false;
      },
      error: (err) => {
        this.userLoading = false;
        console.error(err);
        this.toastService.showError("Error loading user info");
      },
    });

    this.subscriptions.push(sub);
  }

  /**
   * Set security flags based on user and logged in user
   * @param user user being edited
   */
  private setSecurityFlagsForUser(user: RnUsersProfileVM): void {
    const editingSelf = user.UserID === this.loggedInUser.UserID;
    const editingAccountOwner = this.user.UserTypeID === 1;

    // Cannot change user role if editing self
    this.canEditUserRole =
      this.loggedInInfoService.loggedInUserHasRights(["CHNGUSRROL"]) &&
      !editingSelf &&
      !editingAccountOwner;

    // Can edit XID if user has rights or editing self
    this.canEditXID = this.loggedInInfoService.loggedInUserHasRights([
      "ADDUPDTXID",
    ]);

    // Can edit support PIN if user has rights or editing self
    this.canEditSupportPin =
      this.user?.ProfileCreated &&
      (this.loggedInInfoService.loggedInUserHasRights(["EDTSPRTPIN"]) ||
        editingSelf);
    this.isPINRequired = user?.ProfileCreated;

    // Can edit contact info if user has rights or editing self
    this.canEditContactInfo =
      this.loggedInInfoService.loggedInUserHasRights(["EDTUSRCONT"]) ||
      editingSelf;

    // Cannot edit contact info if editing account owner and logged in user is not the account owner or internal
    this.userIsReadOnly =
      !this.loggedInUserState.IsInternal() &&
      editingAccountOwner &&
      !editingSelf;

    // Phone number is not require if the user is not active
    this.phoneNumberRequired = user.ProfileCreated;
  }

  /**
   * Load available roles for the user
   */
  private loadRoles(
    userProfile: RnUsersProfileVM,
    organizationProfile: RnOrganizationsProfileVM,
  ): void {
    this.loadingRoles = true;

    const override =
      this.loggedInUser.IsInternal &&
      this.loggedInInfoService.loggedInUserHasRights(["CHNGACCOWN"]);

    const sub = this.lookupService
      .apiV2LookupUsertypesGet(-1, userProfile.UserID, override, "response")
      .pipe(
        map((response) => {
          let visibleRoles = [];
          const internalUser = this.loggedInUser.IsInternal.bind(
            this.loggedInUser,
          );
          const orgPaysForSelf = !(
            organizationProfile.ID === organizationProfile.IdThatPaysForThisOrg
          );
          const chngAccOwn = this.loggedInInfoService.loggedInUserHasRights([
            "CHNGACCOWN",
          ]);
          if (!chngAccOwn) {
            visibleRoles = response.data.filter((r) => {
              return r.Name !== AccountOwnerRole;
            });
          } else if (
            internalUser &&
            chngAccOwn &&
            organizationProfile.CustomerType !== RightworksCustomerType
          ) {
            visibleRoles = response.data;
          } else if (
            (internalUser && orgPaysForSelf) ||
            (!internalUser &&
              this.loggedInUser.UserType === AccountOwnerRole &&
              this.loggedInUser.OrganizationID === userProfile.OrganizationID &&
              orgPaysForSelf &&
              this.loggedInUser.UserID !== userProfile.UserID &&
              organizationProfile.CustomerType === RightworksCustomerType)
          ) {
            visibleRoles = response.data;
          } else {
            visibleRoles = response.data;
          }
          this.visibleRoles = visibleRoles.sort((r1, r2) => {
            const role1 = r1 as UserTypesVM;
            const role2 = r2 as UserTypesVM;
            return role1.Name.localeCompare(role2.Name);
          });
        }),
      )
      .subscribe({
        next: () => {
          this.loadingRoles = false;
          // Wait a tick to set the role, so the form is ready so item is selected
          setTimeout(() => {
            this.userDetailsForm.controls["role"].setValue(
              userProfile?.UserTypeID,
            );
            this.cdr.detectChanges();
          });
        },
        error: (err) => {
          this.loadingRoles = false;
          console.error(err);
          this.toastService.showError("Error loading roles");
        },
      });

    this.subscriptions.push(sub);
  }

  setupSupportPINValidator(user: RnUsersProfileVM): void {
    this.userDetailsForm.controls["supportPIN"].clearValidators();
    const validators = [Validators.pattern(PinRegEx)];
    if (this.isPINRequired && this.canEditSupportPin) {
      validators.push(Validators.required.bind(Validators));
    }
    this.userDetailsForm.controls["supportPIN"].setValidators(validators);
    this.userDetailsForm.controls["supportPIN"].updateValueAndValidity();
  }

  /**
   * Phone only required if user is active
   * @param user user being edited
   */
  setupPhoneNumberValidators(user: RnUsersProfileVM) {
    const phoneValidators = [Validators.pattern(PhoneRegEx)];
    this.userDetailsForm.controls["phoneNumber"].clearValidators();
    if (user.ProfileCreated) {
      const countryCodeValidators = [];
      countryCodeValidators.push(Validators.required.bind(Validators));
      phoneValidators.push(Validators.required.bind(Validators));
      this.userDetailsForm.controls["countryCode"].setValidators(
        countryCodeValidators,
      );
    }
    this.userDetailsForm.controls["phoneNumber"].setValidators(phoneValidators);
    this.userDetailsForm.controls["phoneNumber"].updateValueAndValidity();
  }

  updatePhoneValidationForCountry(_: any) {
    this.userDetailsForm.controls["phoneNumber"].clearValidators();
    this.userDetailsForm.controls["countryCode"].clearValidators();
    this.setupPhoneNumberValidators(this.user);
  }

  private fillInUserInfoForm(user: RnUsersProfileVM): void {
    this.userDetailsForm.controls["firstName"].setValue(user?.FirstName);
    this.userDetailsForm.controls["lastName"].setValue(user?.LastName);
    this.userDetailsForm.controls["email"].setValue(user?.EmailAddress);
    const countryCodeString = user?.CountryCode?.trim()
      ? user?.CountryCode?.trim()
      : "";
    this.userDetailsForm.controls["countryCode"].setValue(countryCodeString);
    this.userDetailsForm.controls["phoneNumber"].setValue(
      this.user?.AreaCode?.trim() + user?.PhoneNumber?.trim(),
    );
    this.userDetailsForm.controls["countryCode"].updateValueAndValidity();
    this.userDetailsForm.controls["xid"].setValue(user?.ExternalID);
    this.userDetailsForm.controls["supportPIN"].setValue(
      user?.SupportPIN ? user?.SupportPIN.trim() : "",
    );
    this.userDetailsForm.markAsPristine();
  }

  /**
   * Save user info saves both user info and xid
   */
  saveUserInfo() {
    this.isSaving = true;
    this.savingEvent.emit(this.isSaving);
    const saving = forkJoin([
      this.updateUserInfo(),
      this.updateRoleInfo(),
      this.updateXID(),
    ])
      .pipe(
        map((r) => {
          return { profileUpdated: r[0], roleUpdated: r[1], xidUpdated: r[2] };
        }),
      )
      .subscribe({
        next: (r: any) => {
          this.modalService.close(this.modalId);
          this.isSaving = false;
          this.savingEvent.emit(this.isSaving);
          if (r.profileUpdated) {
            this.profileModalService.updateProfile(
              new UserProfileUpdateEvent(
                this.user.UserID,
                this.userDetailsForm.value["firstName"],
                this.userDetailsForm.value["lastName"],
                this.userDetailsForm.value["email"],
              ),
            );
          }
          if (r.roleUpdated) {
            const userRole = this.getSelectedUserType();
            this.profileModalService.updateRole(
              new RoleUpdateEvent(this.user.UserID, userRole),
            );
          }
        },
        error: (err) => {
          this.modalService.close(this.modalId);
          this.isSaving = false;
          this.savingEvent.emit(this.isSaving);
          console.error(err);
          this.toastService.showError(
            "An error occurred while updating user information.",
          );
        },
      });
    this.subscriptions.push(saving);
  }

  /**
   * updateUserInfo
   * @returns API response, or null if no changes were made
   */
  private updateUserInfo(): Observable<boolean> {
    const payload = new RnUserUpdatePayload();
    payload.User_ID = this.user.UserID;
    payload.AffectedOrganizationId = this.loggedInUserState?.OrganizationID;
    payload.ItemsToUpdate = [];
    let needUpdate = false;
    if (this.userDetailsForm.value["firstName"] !== this.user?.FirstName) {
      const fNameUpdate = new RnStringStringKeyValuePair();
      fNameUpdate.Key = "FirstName";
      fNameUpdate.Value = this.userDetailsForm.value["firstName"];
      payload.ItemsToUpdate.push(fNameUpdate);
      needUpdate = true;
    }
    if (this.userDetailsForm.value["lastName"] !== this.user?.LastName) {
      const lNameUpdate = new RnStringStringKeyValuePair();
      lNameUpdate.Key = "LastName";
      lNameUpdate.Value = this.userDetailsForm.value["lastName"];
      payload.ItemsToUpdate.push(lNameUpdate);
      needUpdate = true;
    }
    if (this.userDetailsForm.value["email"] !== this.user?.EmailAddress) {
      const eMailUpdate = new RnStringStringKeyValuePair();
      eMailUpdate.Key = "Email";
      eMailUpdate.Value = this.userDetailsForm.value["email"];
      payload.ItemsToUpdate.push(eMailUpdate);
      needUpdate = true;
    }
    if (
      this.userDetailsForm.value["countryCode"] !==
      this.user?.CountryCode?.trim()
    ) {
      const cCodeUpdate = new RnStringStringKeyValuePair();
      cCodeUpdate.Key = "CountryCode";
      cCodeUpdate.Value = this.userDetailsForm.value["countryCode"];
      payload.ItemsToUpdate.push(cCodeUpdate);
      needUpdate = true;
    }

    const phoneNumberFull = this.userDetailsForm.value["phoneNumber"];
    const areaCode = phoneNumberFull.substring(0, 3);
    const phoneNumber = phoneNumberFull.substring(3);

    if (areaCode && areaCode !== this.user?.AreaCode) {
      const areaCodeUpdate = new RnStringStringKeyValuePair();
      areaCodeUpdate.Key = "AreaCode";
      areaCodeUpdate.Value = areaCode;
      payload.ItemsToUpdate.push(areaCodeUpdate);
      needUpdate = true;
    }
    if (phoneNumber && phoneNumber !== this.user?.PhoneNumber.trim()) {
      const phoneNoUpdate = new RnStringStringKeyValuePair();
      phoneNoUpdate.Key = "PhoneNumber";
      phoneNoUpdate.Value = phoneNumber;
      payload.ItemsToUpdate.push(phoneNoUpdate);
      needUpdate = true;
    }

    const supportPINEditVal = this.userDetailsForm.value["supportPIN"];
    const supportPINCurrVal = this.user?.SupportPIN;
    if (
      supportPINCurrVal !== supportPINEditVal &&
      !(supportPINEditVal === "" && !supportPINCurrVal)
    ) {
      const supportPINUpdate = new RnStringStringKeyValuePair();
      supportPINUpdate.Key = "SupportPIN";
      supportPINUpdate.Value = this.userDetailsForm.value["supportPIN"];
      payload.ItemsToUpdate.push(supportPINUpdate);
      needUpdate = true;
    }
    if (needUpdate) {
      return this.userService
        .apiV2UsersUpdatePost(payload, "response")
        .pipe(map((r) => r.body.Success));
    }
    return of(true);
  }

  private updateXID(): Observable<boolean> {
    if (this.userDetailsForm.value["xid"] !== this.user?.ExternalID) {
      const xidUpdatePayload = new RnUserCreateUpdateXid();
      xidUpdatePayload.UserID = this.user?.UserID;
      xidUpdatePayload.AffectedOrganizationId =
        this.loggedInUserState?.OrganizationID;
      xidUpdatePayload.Xid = this.userDetailsForm.value["xid"];
      xidUpdatePayload.IsCreate =
        !this.user?.ExternalID || this.user?.ExternalID.trim() === "";
      return this.userService
        .apiV2UsersUpdatexidPost(xidUpdatePayload, "response")
        .pipe(map((r) => r.body.Success));
    }
    return of(true);
  }

  private updateRoleInfo(): Observable<boolean> {
    if (this.userDetailsForm.value["role"] !== this.user?.UserTypeID) {
      const roleUpdatePayload = new RnUserUserTypeAdd();
      roleUpdatePayload.Users_ID = this.user.UserID;
      roleUpdatePayload.UserType_ID = this.userDetailsForm.value["role"];
      const typeName = this.getSelectedUserType();
      if (typeName === AccountOwnerRole) {
        return this.updateAccountOwner();
      } else {
        return this.userService
          .apiV2UsersAddusertypePost(roleUpdatePayload, "response")
          .pipe(map((r) => r.body.Success));
      }
    }
    return of(true);
  }

  /**
   * Update account owner will trigger the account owner transfer dialog
   * @returns Observable<boolean>
   */
  private updateAccountOwner(): Observable<boolean> {
    const config = new GenericDialogConfiguration();

    config.StyleClass = "login-notification";
    config.ConfirmButtonStyleClass = "primary";
    config.DialogHeaderClass = "modal-header no-border";
    config.DialogFooterCancelClass = "right-spacing";
    config.KeepOpenAfterConfirm = true;
    config.UseProgressButton = true;

    return new Observable<boolean>((observer) => {
      config.Confirmed = (dialogRef) =>
        this.updateAccountOwnerConfirmed(dialogRef, observer);
      config.Canceled = () => {
        observer.next(false);
        observer.complete();
      };
      config.ConfirmButtonText = "Transfer Ownership";
      config.MessageContainsHTML = true;
      config.Title = "Transfer Ownership";
      const firstName: string = this.userDetailsForm.value["firstName"];
      const lastName: string = this.userDetailsForm.value["lastName"];
      const email: string = this.userDetailsForm.value["email"];
      config.Message =
        `You are about to transfer Account ownership to <strong>${firstName} ${lastName}</strong>` +
        ` (${email}) from <strong>${this.userOrganization.AccountOwnerInfo.FullName}</strong> (${this.userOrganization.AccountOwnerInfo.Email}).`;
      this.notificationDialogService.ShowConfirmation(config);
    });
  }

  private updateAccountOwnerConfirmed(
    dialogRef: any,
    observer: Subscriber<boolean>,
  ) {
    const accountOwnerUpdatePayload = new RnOrganizationUpdateAccountOwner();
    accountOwnerUpdatePayload.CurrentAONewUserType = "AccountAdmin";
    accountOwnerUpdatePayload.CurrentAOUserID =
      this.userOrganization.AccountOwnerID;
    accountOwnerUpdatePayload.NewAOUserID = this.user.UserID;
    accountOwnerUpdatePayload.OrganizationID = this.user.OrganizationID;
    accountOwnerUpdatePayload.AffectedOrganizationId =
      this.loggedInUserState?.OrganizationID;

    const sub = this.organizationService
      .apiV2OrganizationsUpdateAccountOwnerPost(
        accountOwnerUpdatePayload,
        "response",
      )
      .pipe(
        switchMap(async (response) => {
          if (response.status === 200) {
            const roleIdStr = this.userDetailsForm.value["role"];
            const roleId = Number.parseInt(roleIdStr);
            this.user.UserTypeID = roleId;
            this.user.UserType = this.getSelectedUserType();
            dialogRef.CloseDialog();
            this.toastService.showSuccess(
              "Account Owner successfully transferred.",
            );
            await this.authenticationService.logout();
            observer.next(true);
            observer.complete();
          } else {
            this.toastService.showError(
              "An error occurred transfering Account Owner.",
            );
            observer.next(false);
            observer.complete();
          }
        }),
        catchError((error) => {
          this.toastService.showError(
            "An error occurred transfering Account Owner.",
          );
          console.error("Error updating account owner:", error);
          observer.next(false);
          observer.complete();
          return of(false);
        }),
      )
      .subscribe();
    this.subscriptions.push(sub);
  }

  private getSelectedUserType(): string {
    const roleIdStr = this.userDetailsForm.value["role"];
    const roleId = Number.parseInt(roleIdStr);
    const selectedRole = this.visibleRoles.filter((r) => {
      return r.ID === roleId;
    });
    const typeName = selectedRole[0].Name;
    return typeName;
  }

  public checkEmailUnique(emailUnique: boolean) {
    this.emailUnique = emailUnique;
  }

  cancel() {
    this.modalService.close(this.modalId);
  }

  canSave(): boolean {
    return (
      this.userDetailsForm.dirty &&
      this.userDetailsForm.valid &&
      this.emailUnique
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  pendingValidationChanged(value: boolean) {
    this.pendingValidationEvent.emit(value);
  }

  public showLoader = (): boolean =>
    this.userLoading ||
    this.loadingCountries ||
    this.loadingRoles ||
    this.isSaving;

  private resetLoaderStatus(): void {
    this.userLoading = false;
    this.loadingCountries = false;
    this.loadingRoles = false;
    this.isSaving = false;
    this.savingEvent.emit(this.isSaving);
  }

  public getLoaderText = (): string => {
    return this.isSaving ? "Saving..." : "Loading...";
  };

  get f() {
    return this.userDetailsForm?.controls;
  }
}
