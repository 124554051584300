/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnUserDriveVM } from "../models/./userDriveVM-d";

export class RnUsersForOrgSubOrgSearchForLicenseVM {
  AppIsInstalledForLicense?: boolean;
  InstalledAppName?: string | null;
  InstalledAppEditionName?: string | null;
  UserID?: number;
  LastNameFirstName?: string | null;
  UserEmail?: string | null;
  OrganizationID?: number;
  OrganizationName?: string | null;
  FullName?: string | null;
  MappedDrives?: Array<RnUserDriveVM> | null;
  HasTerminalServices?: boolean;
  AssignedPackage?: string | null;
  IsOverrideable?: boolean;
  AssignedPackageID?: number;
  DirectBilledPackages?: Array<string> | null;
}
