import { AbstractControl, ValidatorFn } from "@angular/forms";

export function allowedEmailValidator(
  default_email_extension: string,
  userType: string,
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const isInvalid =
      (control?.value?.indexOf(default_email_extension) >= 0 &&
        userType === "System Admin") ||
      control?.value?.indexOf(default_email_extension) < 0;
    return isInvalid ? null : { allowedemail: true };
  };
}
