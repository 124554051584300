import { GridColumnBaseConfiguration } from "./grid-column-base-configuration";

export class GridHyperlinkColumnConfiguration extends GridColumnBaseConfiguration {
  constructor(
    fieldName: string,
    title: string,
    classname: string,
    sortOrder: string,
  ) {
    super("hyperlink", fieldName, title, classname, sortOrder);
    this.CustomDataRetrieval = (dataRow: any) => {
      return this.GetLinkText(dataRow);
    };
  }
  public LinkText: string;
  public GetCustomLinkText: (dataRow: any) => string;
  public GetHref: (dataRow: any) => string;
  public Target: string;

  public GetLinkText(dataRow: any) {
    if (this.LinkText && this.LinkText !== "") {
      return this.LinkText;
    } else if (this.GetCustomLinkText) {
      return this.GetCustomLinkText(dataRow);
    }
    return dataRow[this.FieldName];
  }

  public TypeName(dataRow: any): string {
    if (dataRow) {
      const href = this.GetHref ? this.GetHref(dataRow) : "";
      const clickEnabled = this.GetLinkIsEnabled(dataRow);
      if ((href && href !== "") || clickEnabled) {
        return super.TypeName(dataRow);
      }
    }
    return "generic";
  }

  public GetLinkIsEnabled(dataRow: any): boolean {
    if (this.LinkIsEnableHandler) {
      return this.LinkIsEnableHandler(dataRow);
    }

    return true;
  }

  public LinkIsEnableHandler: (dataRow: any) => boolean;

  public GetClickAvailable(dataRow: any): boolean {
    if (this.ClickAvailableHandler) {
      return this.ClickAvailableHandler(dataRow);
    }

    return false;
  }

  public ClickAvailableHandler: (dataRow: any) => boolean;

  public RunClickCommand(dataRow: any): void {
    if (this.ClickCommandHandler) {
      this.ClickCommandHandler(dataRow, this.ExtraData);
    }
  }

  public ClickCommandHandler: (dataRow: any, extraData: any) => void;
}
