export class OrgInfo {
  ID: number;
  Name: string;
  AccountOwner: string;
  CreatedDate: Date;
}

export class OrgLookupData {
  orgName: string;
  orgInfo: OrgInfo;
}
