import { GridActionButton } from "./grid-action-button";
import { GridColumnBaseConfiguration } from "./grid-column-base-configuration";

export class GridActionButtonColumnConfiguration extends GridColumnBaseConfiguration {
  buttonConfig: Array<GridActionButton> = new Array<GridActionButton>();
  predicate: (dataRow: any) => boolean;

  constructor(
    fieldName: string,
    title: string,
    classname: string,
    sortOrder: string,
    buttonConfig: Array<GridActionButton>,
  ) {
    super("actions", fieldName, title, classname, sortOrder);
    this.buttonConfig = buttonConfig;
  }
}
