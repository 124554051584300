import { Pipe, PipeTransform } from "@angular/core";
import { LoggedInInfoService } from "../../services/loggedInInfo/logged-in-info.service";
import { RnUsersProfileVM } from "../../services/rnapi2-service/models/usersProfileVM-d";

@Pipe({
  name: "rnUserProfile",
})
export class RnUserProfilePipe implements PipeTransform {
  constructor(private loggedInInfoService: LoggedInInfoService) {}
  transform(userProfile: RnUsersProfileVM, ...args: unknown[]): unknown {
    if (userProfile && args && args.length > 0) {
      const prop = args[0].toString().toLowerCase();
      switch (prop) {
        case "fullnameshortened": {
          let firstName = "";
          if (userProfile.FirstName) {
            firstName = userProfile.FirstName;
            if (firstName.length > 15) {
              firstName = firstName.substr(0, 13) + "...";
            }
          }

          let lastName = "";
          if (userProfile.LastName) {
            lastName = userProfile.LastName;
            if (lastName.length > 15) {
              lastName = lastName.substr(0, 13) + "...";
            }
          }

          return firstName + " " + lastName;
        }
        case "showlegacyname": {
          return userProfile.UserName ? true : false;
        }
        case "legacyname": {
          return userProfile.UserName ? userProfile.UserName : "";
        }
        case "packageinfotext": {
          return userProfile.AssignedPackage &&
            userProfile.AssignedPackage !== ""
            ? userProfile.AssignedPackage
            : "None";
        }
        case "fullname": {
          return userProfile.FirstName + " " + userProfile.LastName;
        }
        case "accessmgmreadonlydesc": {
          if (
            this.loggedInInfoService.GetLoggedInUser().UserID ===
            userProfile.UserID
          )
            return "These are the organization and folders you have access to on your hosted desktop.";
          else
            return `These are the organization and folders ${userProfile.FirstName} ${userProfile.LastName} has access to on their hosted desktop.`;
        }
      }
    }
    return "";
  }
}
