import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ApplicationCard } from "../../../../core/models/application-card.model";
import { RnWLPLocalAppVM } from "../../../services/rnapi2-service";

@Component({
  selector: "app-card-list",
  templateUrl: "./card-list.component.html",
  styleUrls: ["./card-list.component.scss"],
})
export class CardListComponent {
  @Input() cardList: ApplicationCard[] = [];
  @Input() localAppList: RnWLPLocalAppVM[] = [];
  @Input() userID: number;
  @Input() canUseOpenButton = false;
  @Output() cardEvent = new EventEmitter<ApplicationCard>();
  @Input() canUseNewTiles = false;

  constructor() {}

  handleCardEvent(request: ApplicationCard) {
    this.cardEvent.emit(request);
  }
}
