import { CommonModule, CurrencyPipe } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { ClipboardModule } from "ngx-clipboard";
import { AccordionModule } from "primeng/accordion";
import { MessageService } from "primeng/api";
import { AutoCompleteModule } from "primeng/autocomplete";
import { CalendarModule } from "primeng/calendar";
import { CheckboxModule } from "primeng/checkbox";
import { DropdownModule } from "primeng/dropdown";
import { DialogService, DynamicDialogComponent } from "primeng/dynamicdialog";
import { FocusTrapModule } from "primeng/focustrap";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { InputTextModule } from "primeng/inputtext";
import { MenuModule } from "primeng/menu";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { RadioButtonModule } from "primeng/radiobutton";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";
import { ToastModule } from "primeng/toast";
import { TooltipModule } from "primeng/tooltip";
import { TreeModule } from "primeng/tree";
import { AccessManagementComponent } from "./components/rn-business-components/access-management/access-management.component";
import { AddUserComponent } from "./components/rn-business-components/add-user/add-user.component";
import { ApplicationCardComponent } from "./components/rn-business-components/application-card/application-card.component";
import { BillingFormComponent } from "./components/rn-business-components/billing-form/billing-form.component";
import { BillingSeparationComponent } from "./components/rn-business-components/billing-separation/billing-separation.component";
import { BusinessAddressComponent } from "./components/rn-business-components/business-address/business-address.component";
import { CardListComponent } from "./components/rn-business-components/card-list/card-list.component";
import { ChangePackageComponent } from "./components/rn-business-components/change-package/change-package.component";
import { CopyActivationComponent } from "./components/rn-business-components/copy-activation/copy-activation.component";
import { DeployQuickbooksComponent } from "./components/rn-business-components/deploy-quickbooks/deploy-quickbooks.component";
import { DetailedBillingReportsComponent } from "./components/rn-business-components/detailed-billing-reports/detailed-billing-reports.component";
import { DownloadSetupWizardComponent } from "./components/rn-business-components/download-setup-wizard/download-setup-wizard.component";
import { EstimatedBillBreakdownComponent } from "./components/rn-business-components/estimated-bill-breakdown/estimated-bill-breakdown.component";
import { EventsComponent } from "./components/rn-business-components/events/events.component";
import { InvoicesComponent } from "./components/rn-business-components/invoices/invoices.component";
import { MaintenanceModeNotificationComponent } from "./components/rn-business-components/maintenance-mode-notification/maintenance-mode-notification.component";
import { ManageAddonsComponent } from "./components/rn-business-components/manage-addons/manage-addons.component";
import { NewUserDetailsComponent } from "./components/rn-business-components/new-user-details/new-user-details.component";
import { OrganizationAddressComponent } from "./components/rn-business-components/organization-address/organization-address.component";
import { OrganizationChangeStatusComponent } from "./components/rn-business-components/organization-change-status/organization-change-status.component";
import { OrganizationInfoCreateComponent } from "./components/rn-business-components/organization-info-create/organization-info-create.component";
import { PaymentFormAchComponent } from "./components/rn-business-components/payment-form-ach/payment-form-ach.component";
import { PaymentFormCcComponent } from "./components/rn-business-components/payment-form-cc/payment-form-cc.component";
import { PrimaryFrameComponent } from "./components/rn-business-components/primary-frame/primary-frame.component";
import { OrgRenameUserLoggedInDialogComponent } from "./components/rn-business-components/rename-error/rename-error.component";
import { ReviewUserComponent } from "./components/rn-business-components/review-user/review-user.component";
import { SearchControlComponent } from "./components/rn-business-components/search-control/search-control.component";
import { RnFooterComponent } from "./components/rn-business-components/sidebar/rn-footer/rn-footer.component";
import { RnHeaderComponent } from "./components/rn-business-components/sidebar/rn-header/rn-header.component";
import { RnNavigationBarComponent } from "./components/rn-business-components/sidebar/rn-navigation-bar/rn-navigation-bar.component";
import { RnSidebarComponent } from "./components/rn-business-components/sidebar/rn-sidebar/rn-sidebar.component";
import { UserActionDialogComponent } from "./components/rn-business-components/user-action-dialog/user-action-dialog.component";
import { UserKeepDropPackageDialogComponent } from "./components/rn-business-components/user-keep-drop-package-dialog/user-keep-drop-package-dialog.component";
import { DatePickerComponent } from "./components/third-party-wrappers/date-picker/date-picker.component";
import { GridComponent } from "./components/third-party-wrappers/grid/grid.component";
import { ProcessButtonComponent } from "./components/third-party-wrappers/process-button/process-button.component";
import { ButtonComponent } from "./components/third-party-wrappers/button/button.component";
import { IdleDialogComponent } from "./idle/dialog/idle-dialog.component";
import { SafeHtmlPipe } from "./pipes/safeHtml.pipe";
import { UtcToLocalDatePipe } from "./pipes/utcToLocalDate/utc-to-local-date.pipe";
import { AuthResponseInteceptor } from "./security/auth-response-inteceptor";
import { AuthGuardService } from "./security/auth.guard.service";
import { AuthenticationService } from "./security/authentication.service";
import { NotAuthorizedComponent } from "./security/not-authorized/not-authorized.component";
import { AddressService } from "./services/address/address.service";
import { RnDialogService } from "./services/dialog/rndialog.service";
import { LoggedInInfoService } from "./services/loggedInInfo/logged-in-info.service";
import { RnErrorHandlerService } from "./services/rn-error-handler/rn-error-handler.service";
import { RnToastService } from "./services/toast/rntoast.service";
import { PackageInventoryComponent } from "./components/rn-business-components/package-inventory/package-inventory.component";
import { DirectInventoryTableComponent } from "./components/rn-business-components/package-inventory/direct-inventory-table/direct-inventory-table.component";
import { WlpInventoryTableComponent } from "./components/rn-business-components/package-inventory/wlp-inventory-table/wlp-inventory-table.component";
import { ChangePackageInventoryComponent } from "./components/rn-business-components/change-package-inventory/change-package-inventory.component";
import { PackageCardComponent } from "./components/rn-business-components/package-card/package-card.component";
import { QuantityControlComponent } from "./components/third-party-wrappers/quantity-control/quantity-control.component";
import { PackageManagementComponent } from "./components/rn-business-components/package-management/package-management.component";
import { PackageAssignmentComponent } from "./components/rn-business-components/package-assignment/package-assignment.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { NotificationDialogService } from "./services/notificationDialog/notification-dialog.service";
import { NotificationDialogComponent } from "./services/notificationDialog/notification-dialog/notification-dialog.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatDividerModule } from "@angular/material/divider";
import { ShoppingCartComponent } from "./components/rn-business-components/shopping-cart/shopping-cart.component";
import { ShoppingCartIconComponent } from "./components/rn-business-components/shopping-cart-icon/shopping-cart-icon.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { LookupStoreService } from "./services/lookup-store/lookup-store.service";
import { UserUpdatePinComponent } from "./components/rn-business-components/user-update-pin/user-update-pin.component";
import { UserUpdateProfileComponent } from "./components/rn-business-components/user-update-profile/user-update-profile.component";
import { LicenseManagementComponent } from "./components/rn-business-components/license-management/license-management.component";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { LicenseAddEditComponent } from "./components/rn-business-components/license-add-edit/license-add-edit.component";
import { UsersManagementComponent } from "./components/rn-business-components/users-management/users-management.component";
import { LicensePurchaseComponent } from "./components/rn-business-components/license-purchase/license-purchase.component";
import { OrgLookupComponent } from "./components/rn-business-components/org-lookup/org-lookup.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatRippleModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { CreateRenameFolderComponent } from "./components/rn-business-components/create-rename-folder/create-rename-folder.component";
import { DeleteFldrConfirmComponent } from "./components/rn-business-components/delete-fldr-confirm/delete-fldr-confirm.component";
import { SidebarModule } from "primeng/sidebar";
import { RightSideBarComponent } from "./components/rn-business-components/sidebar/rn-right-sidebar/rn-right-sidebar.component";
import { RnsidebarService } from "./services/sidebar/rnsidebar.service";
import { ManageAddonsSidebarComponent } from "./components/rn-business-components/manage-addons-sidebar/manage-addons-sidebar.component";
import { OrgRenameUserLoggedInDialogNotificationComponent } from "./services/notificationDialog/rename-error/rename-error.component";
import { ManagePackagesSidebarComponent } from "./components/rn-business-components/manage-packages-sidebar/manage-packages-sidebar.component";
import { UserEventsComponent } from "./components/rn-business-components/user-events/user-events.component";
import { DateRangePickerComponent } from "./components/third-party-wrappers/date-range-picker/date-range-picker.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { CdkTreeModule } from "@angular/cdk/tree";
import { RnConfirmationComponent } from "./components/rn-business-components/sidebar/rn-confirmation/rn-confirmation.component";
import { LicenseTypeSelectComponent } from "./components/rn-business-components/license-purchase/license-type-select/license-type-select.component";
import { LicenseTypeDetailComponent } from "./components/rn-business-components/license-purchase/license-type-detail/license-type-detail.component";
import { LicenseDetailsComponent } from "./components/rn-business-components/license-details/license-details.component";
import { LicensePurchaseItemsComponent } from "./components/rn-business-components/license-purchase/license-purchase-items/license-purchase-items.component";
import { PurchaseSummaryComponent } from "./components/rn-business-components/license-purchase/purchase-summary/purchase-summary.component";
import { EscapeCharacterPipe } from "./pipes/escapeCharacter/escape-character.pipe";
import { LicenseAssignComponent } from "./components/rn-business-components/license-assign/license-assign.component";

import { EditMfaDialogComponent } from "./components/rn-business-components/edit-mfa-dialog/edit-mfa-dialog.component";
import { UserFeedbackDialogComponent } from "./components/rn-business-components/user-feedback-dialog/user-feedback-dialog.component";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { EmailVerficationComponent } from "./components/rn-business-components/email-verfication/email-verfication.component";
import { IconComponent } from "./components/rn-business-components/icon/icon.component";
import { LoaderComponent } from "./components/third-party-wrappers/loader/loader.component";
import { SelectComponent } from "./components/third-party-wrappers/select/select.component";
import { RnPopoverComponent } from "./components/third-party-wrappers/rnpopover/rnpopover.component";
import { SalesforceChatComponent } from "./components/third-party-wrappers/salesforce-chat/salesforce-chat.component";
import { DuoDialogComponent } from "./components/rn-business-components/duo-dialog/duo-dialog.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { GenericDialogComponent } from "./components/rn-business-components/generic-dialog/generic-dialog.component";
import { InputSwitchModule } from "primeng/inputswitch";
import { SwitchComponent } from "./components/third-party-wrappers/switch/switch.component";
import { DisableOrgMfaDialogComponent } from "./components/rn-business-components/disable-org-mfa-dialog/disable-org-mfa-dialog.component";
import { MustSetupMfaDialogComponent } from "./components/rn-business-components/must-setup-mfa-dialog/must-setup-mfa-dialog.component";
import { LabelComponent } from "./components/third-party-wrappers/label/label.component";
import { PromoCodeComponent } from "./components/rn-business-components/promo-code/promo-code.component";
import { ListFormatPipe } from "./pipes/listFormat/list-format.pipe";
import { RnOrganizationInfoPipe } from "./pipes/rnOrganizationInfo/rn-organization-info.pipe";
import { RnOrganizationTreeNodePipe } from "./pipes/rnOrganizationTreeNode/rn-organization-tree-node.pipe";
import { RnUserProfilePipe } from "./pipes/rnUserProfile/rn-user-profile.pipe";
import { RnApplicationCardPipe } from "./pipes/rnApplicationCard/rn-application-card.pipe";
import { RnPackagePipe } from "./pipes/rnPackage/rn-package.pipe";
import { RnPackageInventoryPipe } from "./pipes/rnPackageInventory/rn-package-inventory.pipe";
import { RnLicensePurchaseItemsPipe } from "./pipes/rnLicensePurchaseItems/rn-license-purchase-items.pipe";
import { RnOrgInfoPipe } from "./pipes/rnOrgInfo/rn-org-info.pipe";
import { RnQuantityChangePipe } from "./pipes/rnQuantityChange/rn-quantity-change.pipe";
import { RnIdleServicePipe } from "./pipes/rnIdleService/rn-idle-service.pipe";
import { RnOrganizationConfigPipe } from "./pipes/rnOrganizationConfig/rn-organization-config.pipe";
import { RnUserMgmtPipe } from "./pipes/rnUserMgmt/rn-user-mgmt.pipe";
import { RnGridPipe } from "./pipes/rnGrid/rn-grid.pipe";
import { RnTextPipe } from "./pipes/rnText/rn-text.pipe";
import { RnOrgTreePipe } from "./pipes/rnOrgTree/rn-org-tree.pipe";
import { RnAddUserPipe } from "./pipes/rnAddUser/rn-add-user.pipe";
import { RnPurchaseQboPipe } from "./pipes/rnPurchaseQbo/rn-purchase-qbo.pipe";
import { RnPackageGroupPipe } from "./pipes/rnPackageGroup/rn-package-group.pipe";
import { RnChangePackagePipe } from "./pipes/rnChangePackage/rn-change-package.pipe";
import { RnCopyActivationPipe } from "./pipes/rnCopyActivation/rn-copy-activation.pipe";
import { RnEditMfaDialogPipe } from "./pipes/rnEditMfaDialog/rn-edit-mfa-dialog.pipe";
import { RnLicenseManagementPipe } from "./pipes/rnLicenseManagement/rn-license-management.pipe";
import { RnManageAddonsPipe } from "./pipes/rnManageAddons/rn-manage-addons.pipe";
import { TrimInputDirective } from "./directives/trim-input.directive";
import { HttpErrorInterceptor } from "./interceptors/http-error.interceptor";
import { MatTreeModule } from "@angular/material/tree";
import { A11yModule } from "@angular/cdk/a11y";
import { BidiModule } from "@angular/cdk/bidi";
import { ObserversModule } from "@angular/cdk/observers";
import { OverlayModule } from "@angular/cdk/overlay";
import { PlatformModule } from "@angular/cdk/platform";
import { PortalModule } from "@angular/cdk/portal";
//import {ScrollDispatcher} from '@angular/cdk/scrolling';
import { CdkStepperModule } from "@angular/cdk/stepper";
import { CdkTableModule } from "@angular/cdk/table";
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { FilterPipe } from "./components/third-party-wrappers/filer-pipe/filer-pipe.component";
import { AccessManagementAssignComponent } from "./components/rn-business-components/access-management-assign/access-management-assign.component";
import { QboSubscriptionManagementComponent } from "./components/rn-business-components/license-management/qbo-subscription-management/qbo-subscription-management.component";
import { CustomMatPaginatorIntl } from "./components/third-party-wrappers/mat-paginator-control/custom-mat-paginator-intl";
import { LocalAppCardComponent } from "./components/rn-business-components/local-app-card/local-app-card.component";
import { QboPurchaseComponent } from "./components/rn-business-components/license-management/qbo-purchase/qbo-purchase.component";
import { QboPlansComponent } from "./components/rn-business-components/license-management/qbo-purchase/qbo-plans/qbo-plans.component";
import { QboPayrollComponent } from "./components/rn-business-components/license-management/qbo-purchase/app-qbo-payroll/qbo-payroll.component";
import { QboTimeComponent } from "./components/rn-business-components/license-management/qbo-purchase/qbo-time/qbo-time.component";
import { QboCheckoutComponent } from "./components/rn-business-components/license-management/qbo-purchase/qbo-checkout/qbo-checkout.component";
import { QboConfirmationComponent } from "./components/rn-business-components/license-management/qbo-purchase/qbo-confirmation/qbo-confirmation.component";
import { LicenseEditComponent } from "./components/rn-business-components/license-edit/license-edit.component";
import { ModalService, UiCommonModule } from "@rn-platform/frontend-shared-ui-common";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { UserDetailsModalComponent } from "./components/rn-business-components/sidebar/user-details-modal/user-details-modal.component";
import { InstallableAppComponent } from "./components/rn-business-components/installable-app/installable-app.component";

@NgModule({
  declarations: [
    NotAuthorizedComponent,
    IdleDialogComponent,
    GridComponent,
    SearchControlComponent,
    DuoDialogComponent,
    RnHeaderComponent,
    RnFooterComponent,
    RnSidebarComponent,
    RnNavigationBarComponent,
    DownloadSetupWizardComponent,
    OrganizationAddressComponent,
    OrganizationInfoCreateComponent,
    ProcessButtonComponent,
    ButtonComponent,
    CopyActivationComponent,
    AccessManagementComponent,
    ChangePackageComponent,
    ManageAddonsComponent,
    UserActionDialogComponent,
    UserKeepDropPackageDialogComponent,
    SafeHtmlPipe,
    AddUserComponent,
    NewUserDetailsComponent,
    DeployQuickbooksComponent,
    ReviewUserComponent,
    OrganizationChangeStatusComponent,
    ApplicationCardComponent,
    CardListComponent,
    PrimaryFrameComponent,
    MaintenanceModeNotificationComponent,
    UtcToLocalDatePipe,
    DatePickerComponent,
    BillingFormComponent,
    BillingSeparationComponent,
    PaymentFormAchComponent,
    PaymentFormCcComponent,
    BusinessAddressComponent,
    EventsComponent,
    OrgRenameUserLoggedInDialogComponent,
    EstimatedBillBreakdownComponent,
    InvoicesComponent,
    DetailedBillingReportsComponent,
    PackageInventoryComponent,
    DirectInventoryTableComponent,
    WlpInventoryTableComponent,
    ChangePackageInventoryComponent,
    PackageCardComponent,
    QuantityControlComponent,
    PackageManagementComponent,
    PackageAssignmentComponent,
    NotificationDialogComponent,
    ShoppingCartComponent,
    ShoppingCartIconComponent,
    UserUpdateProfileComponent,
    UserUpdatePinComponent,
    LicenseManagementComponent,
    LicenseAddEditComponent,
    UsersManagementComponent,
    LicensePurchaseComponent,
    OrgLookupComponent,
    RightSideBarComponent,
    CreateRenameFolderComponent,
    DeleteFldrConfirmComponent,
    ManageAddonsSidebarComponent,
    OrgRenameUserLoggedInDialogNotificationComponent,
    ManagePackagesSidebarComponent,
    UserEventsComponent,
    DateRangePickerComponent,
    RnConfirmationComponent,
    LicenseDetailsComponent,
    LicenseTypeSelectComponent,
    LicenseTypeDetailComponent,
    LicensePurchaseItemsComponent,
    PurchaseSummaryComponent,
    EscapeCharacterPipe,
    LicenseAssignComponent,
    AccessManagementAssignComponent,
    EditMfaDialogComponent,
    DisableOrgMfaDialogComponent,
    MustSetupMfaDialogComponent,
    UserFeedbackDialogComponent,
    EmailVerficationComponent,
    IconComponent,
    LoaderComponent,
    SelectComponent,
    SalesforceChatComponent,
    RnPopoverComponent,
    GenericDialogComponent,
    SwitchComponent,
    LabelComponent,
    PromoCodeComponent,
    ListFormatPipe,
    PromoCodeComponent,
    RnOrganizationInfoPipe,
    RnOrganizationTreeNodePipe,
    RnUserProfilePipe,
    RnApplicationCardPipe,
    RnPackagePipe,
    RnPackageInventoryPipe,
    RnLicensePurchaseItemsPipe,
    RnOrgInfoPipe,
    RnQuantityChangePipe,
    RnIdleServicePipe,
    RnOrganizationConfigPipe,
    RnUserMgmtPipe,
    RnGridPipe,
    RnTextPipe,
    RnOrgTreePipe,
    RnAddUserPipe,
    RnPurchaseQboPipe,
    RnPackageGroupPipe,
    RnChangePackagePipe,
    RnCopyActivationPipe,
    RnEditMfaDialogPipe,
    RnLicenseManagementPipe,
    RnManageAddonsPipe,
    TrimInputDirective,
    FilterPipe,
    QboSubscriptionManagementComponent,
    LocalAppCardComponent,
    QboPurchaseComponent,
    QboPlansComponent,
    QboPayrollComponent,
    QboTimeComponent,
    QboCheckoutComponent,
    QboConfirmationComponent,
    LicenseEditComponent,
    UserDetailsModalComponent,
    InstallableAppComponent,
  ],
  imports: [
    CommonModule,
    TableModule,
    OverlayPanelModule,
    FormsModule,
    MenuModule,
    TooltipModule,
    TreeModule,
    ReactiveFormsModule,
    DropdownModule,
    AutoCompleteModule,
    InputTextModule,
    FocusTrapModule,
    ClipboardModule,
    ToastModule,
    MessagesModule,
    MessageModule,
    CheckboxModule,
    MatCardModule,
    CalendarModule,
    RadioButtonModule,
    InputMaskModule,
    InputNumberModule,
    ProgressSpinnerModule,
    TabViewModule,
    AccordionModule,
    MatDialogModule,
    MatMenuModule,
    MatDividerModule,
    MatTooltipModule,
    NgbTooltipModule,
    MatAutocompleteModule,
    SidebarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    NgbModule,
    InputSwitchModule,
    MatTreeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatSortModule,
    MatBadgeModule,
    MatBottomSheetModule,
    UiCommonModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [
    GridComponent,
    SearchControlComponent,
    RnHeaderComponent,
    RnFooterComponent,
    RnSidebarComponent,
    OrganizationAddressComponent,
    ProcessButtonComponent,
    ButtonComponent,
    SafeHtmlPipe,
    ApplicationCardComponent,
    CardListComponent,
    PrimaryFrameComponent,
    UtcToLocalDatePipe,
    DatePickerComponent,
    EventsComponent,
    EstimatedBillBreakdownComponent,
    InvoicesComponent,
    DetailedBillingReportsComponent,
    PackageInventoryComponent,
    DirectInventoryTableComponent,
    WlpInventoryTableComponent,
    ChangePackageInventoryComponent,
    PackageManagementComponent,
    UserUpdateProfileComponent,
    UserUpdatePinComponent,
    LicenseManagementComponent,
    MatCardModule,
    ShoppingCartIconComponent,
    ShoppingCartComponent,
    AddUserComponent,
    ShoppingCartComponent,
    ChangePackageComponent,
    ManageAddonsComponent,
    DeployQuickbooksComponent,
    UsersManagementComponent,
    ToastModule,
    MessagesModule,
    MessageModule,
    SidebarModule,
    RightSideBarComponent,
    LicenseDetailsComponent,
    LicenseAssignComponent,
    RnConfirmationComponent,
    EscapeCharacterPipe,
    EditMfaDialogComponent,
    DisableOrgMfaDialogComponent,
    MustSetupMfaDialogComponent,
    EmailVerficationComponent,
    LoaderComponent,
    SelectComponent,
    IconComponent,
    SalesforceChatComponent,
    InputSwitchModule,
    SwitchComponent,
    ListFormatPipe,
    SwitchComponent,
    RnOrganizationInfoPipe,
    RnOrganizationTreeNodePipe,
    RnUserProfilePipe,
    RnApplicationCardPipe,
    RnPackagePipe,
    RnPackageInventoryPipe,
    RnLicensePurchaseItemsPipe,
    RnOrgInfoPipe,
    RnQuantityChangePipe,
    RnIdleServicePipe,
    RnOrganizationConfigPipe,
    RnUserMgmtPipe,
    RnGridPipe,
    RnTextPipe,
    RnOrgTreePipe,
    TrimInputDirective,
    CdkTreeModule,
    MatTreeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    UiCommonModule,
    NgxMaskDirective,
    NgxMaskPipe,
    // CDK
    A11yModule,
    BidiModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    //ScrollDispatchModule,
    CdkStepperModule,
    CdkTableModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        AuthenticationService,
        AuthGuardService,
        DialogService,
        RnDialogService,
        RnsidebarService,
        LoggedInInfoService,
        ModalService,
        AddressService,
        MessageService,
        RnToastService,
        RnErrorHandlerService,
        NotificationDialogService,
        LookupStoreService,
        CurrencyPipe,
        {
          provide: MatPaginatorIntl,
          useClass: CustomMatPaginatorIntl,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthResponseInteceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpErrorInterceptor,
          multi: true,
        },
        {
          provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
          useValue: { appearance: "standard" },
        },
        MatDatepickerModule,
        MatNativeDateModule,
        EscapeCharacterPipe,
        provideNgxMask(),
      ],
    };
  }
}
