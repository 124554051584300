<app-rn-header *ngIf="showHeader()"></app-rn-header>
<p-toast position="top-center" [baseZIndex]="99999">
  <ng-template let-message pTemplate="message">
    <p data-testid="toastMsg" [innerHtml]="message.detail | safeHtml"></p>
  </ng-template>
</p-toast>
<div *ngIf="showAppLoading()" class="resource-loader">
  <img
    class="dark-image"
    src="{{ assetUrl }}/logos/phoenix-right-networks-dark.png"
    alt="Rightworks"
  />
  <br />
  <br />
  <app-loader text="Loading AppHub resources..." inline="true"></app-loader>
</div>

<div *ngIf="!showAppLoading()" [ngClass]="{ 'app-content': showSidebar() }">
  <app-rn-sidebar *ngIf="showSidebar()"></app-rn-sidebar>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
  <p-sidebar
    [(visible)]="visibleSidebar"
    position="right"
    [style]="{ width: '75%' }"
    class="{{ sideBarComponentStyles }} sidebarBackgroundZIndex"
    blockScroll="true"
    (onHide)="onHideSideBar(NavSideBar)"
    #NavSideBar
    [autoZIndex]="false"
  >
    <app-rn-right-sidebar
      *ngIf="!destorySidebar"
      [componentName]="sideBarComponentName"
      [data]="sideBarComponentData"
      [additionalData]="sideBarComponentAdditionalData"
    ></app-rn-right-sidebar>
  </p-sidebar>
  <p-sidebar
    *ngIf="showConfirmation"
    [(visible)]="showConfirmation"
    position="right"
    [style]="{ width: '75%' }"
    blockScroll="true"
    [dismissible]="false"
    [showCloseIcon]="false"
    (onShow)="onShowConfirmation()"
    [autoZIndex]="false"
  >
    <app-rn-confirmation
      (confirmationResult)="receiveConfirmationResult($event)"
    ></app-rn-confirmation>
  </p-sidebar>
</div>

<app-rn-footer *ngIf="showFooter()"></app-rn-footer>
