import { OnChanges, SimpleChanges } from "@angular/core";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { Subscription } from "rxjs";
import { ZipCodeInfo } from "src/app/core/models/zip-code-info";
import { AddressService } from "src/app/shared/services/address/address.service";
import { ZipCodeService } from "src/app/shared/services/rnapi2-service/apis/zipCode.service";

@Component({
  selector: "app-business-address",
  templateUrl: "./business-address.component.html",
  styleUrls: ["./business-address.component.scss"],
})
export class BusinessAddressComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public address: any = {};
  @Input() parentForm!: FormGroup;
  @Input() public submitted = false;
  @Input() isReadOnly = false;
  public stateCombo = true;
  public states: SelectItem[];
  public zipCodeBadInput = false;
  public countries: SelectItem[];
  private originalZipCodePattern = /^\d{5}(?:[-\s]\d{4})?$/;
  public zipCodePattern: RegExp = this.originalZipCodePattern;
  private subscriptions: Subscription[] = [];
  private res: ZipCodeInfo = null;
  public countryFormValue: any;

  constructor(
    private addressService: AddressService,
    private zipCodeService: ZipCodeService,
  ) {}

  ngOnInit(): void {
    this.loadCountries();
    this.loadAddress();
    //get form value
    this.countryFormValue = this.country.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName !== "submitted") {
        this.loadAddress();
      }
    }
  }

  private loadAddress() {
    this.country.setValue(this.address?.country);
    this.onChangeCountry();
    this.address1.setValue(this.address?.address1);
    this.address2.setValue(this.address?.address2);
    this.postalCode.setValue(this.address?.postalCode);
    this.ZipCodeAddress(this.address);
    this.city.setValue(this.address?.city);
    this.state.setValue(this.address?.stateText);
    this.stateText.setValue(this.address?.stateText);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private loadCountries(): void {
    const countryList = this.addressService.getCountryInfo().allCountries;
    this.countries = [];
    if (countryList) {
      for (let i = 0; i < countryList.length; i++) {
        this.countries.push({
          label: countryList[i].code,
          value: countryList[i].name,
        });
      }
    }

    //default value country USA
    this.country.setValue({
      label: "USA",
      value: "United States",
    });

    this.onChangeCountry();
  }

  public onDropdownKeydown(event: KeyboardEvent) {
    if (event.key === "Tab") {
      // Reset the value back to its original value
      if (this.countryFormValue) {
        this.country.setValue({
          label: this.countryFormValue.label,
          value: this.countryFormValue.value,
        });
        // To check value is changed or not on tab click
        this.onChangeCountry(true);
      } else {
        this.country.setValue({
          label: "USA",
          value: "United States",
        });
      }
    }
  }

  public onChangeCountry(fromTabEvent = false, e?: any): void {
    let stateList;
    if (this.country.value) {
      if (this.country.value.value === "United States") {
        stateList = this.addressService.getCountryInfo().usStates;
        this.stateCombo = true;
        this.zipCodePattern = this.originalZipCodePattern;
      } else if (this.country.value.value === "Canada") {
        stateList = this.addressService.getCountryInfo().caRegions;
        this.stateCombo = true;
        this.zipCodePattern = new RegExp("");
      } else {
        this.stateCombo = false;
        this.zipCodePattern = new RegExp("");
      }
      this.states = [{ label: "", value: "" }];
      if (stateList) {
        for (let i = 0; i < stateList.length; i++) {
          this.states.push({
            label: stateList[i].name,
            value: stateList[i].code,
          });
        }
      }
      if (!fromTabEvent && e?.originalEvent?.key != "Tab") {
        this.countryFormValue = this.country.value;
      }
    }

    if (fromTabEvent) {
      this.postalCode.setValue(this.address?.postalCode);
      this.city.setValue(this.address?.city);
      this.state.setValue(this.address?.stateText || "");
      this.stateText.setValue(this.address?.stateText || "");
    } else {
      this.state.setValue({ label: "", value: "" });
      this.city.setValue("");
      this.stateText.setValue("");
      this.postalCode.setValue("");
    }

    setTimeout(() => {
      this.state.updateValueAndValidity();
      this.stateText.updateValueAndValidity();
    }, 500);
  }

  public onBlurZipCode() {
    if (this.postalCode.value && this.country.value.value === "United States") {
      this.city.markAsUntouched();
      this.state.markAsUntouched();
      this.zipCodeBadInput = false;
      this.city.setValue("");
      this.state.setValue("");

      const zipSub = this.zipCodeService
        .apiV2GeneralZipCodeGet(this.postalCode.value, "response")
        .subscribe((r) => {
          if (r) {
            if (r.body.ZipCode) {
              const addressServiceResponse = r.body.ZipCode;
              if (addressServiceResponse) {
                this.res = addressServiceResponse;
              }
              if (r.body.Error) {
                this.zipCodeBadInput = true;
                this.state.setValue("");
              } else {
                this.zipCodeBadInput = false;
                this.city.setValue(this.res.City);
                this.state.setValue(this.res.State);
              }
            }
          }
        });
      this.subscriptions.push(zipSub);
    }
  }

  public ZipCodeAddress(address?: any) {
    if (this.postalCode.value && this.country.value.value === "United States") {
      this.city.markAsUntouched();
      this.state.markAsUntouched();
      this.zipCodeBadInput = false;
      this.city.setValue("");
      this.state.setValue("");

      const zipAddress = this.zipCodeService
        .apiV2GeneralZipCodeGet(this.postalCode.value, "response")
        .subscribe((r) => {
          if (r) {
            if (r.body.ZipCode) {
              const addressServiceResponse = r.body.ZipCode;
              if (addressServiceResponse) {
                this.res = addressServiceResponse;
              }
              if (r.body.Error) {
                this.zipCodeBadInput = true;
                this.state.setValue("");
              } else {
                this.zipCodeBadInput = false;
                this.city.setValue(this.res.City);
                this.state.setValue(this.res.State);

                if (address) {
                  this.city.setValue(this.address.city);
                  this.state.setValue(this.address.stateText);
                  this.stateText.setValue(this.address.stateText);
                }
              }
            }
          }
        });
      this.subscriptions.push(zipAddress);
    }
  }

  get country() {
    return this.parentForm.get("country");
  }

  get address1() {
    return this.parentForm.get("address1");
  }

  get address2() {
    return this.parentForm.get("address2");
  }

  get postalCode() {
    return this.parentForm.get("postalCode");
  }

  get city() {
    return this.parentForm.get("city");
  }

  get state() {
    return this.parentForm.get("state");
  }

  get stateText() {
    return this.parentForm.get("stateText");
  }
}
