import { Component, OnInit } from "@angular/core";
import { NavigationService } from "../../services/navigation/navigation.service";
import { AuthenticationService } from "../authentication.service";

@Component({
  selector: "app-not-authorized",
  templateUrl: "./not-authorized.component.html",
  styleUrls: ["./not-authorized.component.scss"],
})
export class NotAuthorizedComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private navService: NavigationService,
  ) {}

  ngOnInit(): void {
    setTimeout(async () => {
      if (this.authService.userIsLoggedIn()) {
        await this.navService.GoToStartPage();
      } else {
        await this.navService.GoToLoginPage();
      }
    }, 3000);
  }
}
