import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PackageCardData } from "../../package-card/package-card-data";

@Component({
  selector: "app-license-type-select",
  templateUrl: "./license-type-select.component.html",
  styleUrls: ["./license-type-select.component.scss"],
})
export class LicenseTypeSelectComponent {
  @Input() licenseTypes: PackageCardData[];
  @Output() typeSelected = new EventEmitter<PackageCardData>();

  typeSelect($event) {
    const type = $event as PackageCardData;
    this.typeSelected.emit(type);
  }
}
