/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export class RnDataNodesVM {
  ID?: number;
  Name?: string | null;
  Desc?: string | null;
  FileServerID?: number;
  FileServerName?: string | null;
  Slug?: string | null;
  OUName?: string | null;
  DataHostName?: string | null;
  DataShare?: string | null;
  DistinguishedName?: string | null;
  EffStartDate?: string;
  EffEndDate?: string;
  CreatedDate?: string;
  CreatedBy?: string | null;
  DeviceStatusName?: string | null;
  DataCenterName?: string | null;
}
