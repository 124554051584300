/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export class RnOrganizationWLPContactInformationVM {
  BillingPhone?: string | null;
  SupportPhone?: string | null;
  SupportWebsite?: string | null;
  Description?: string | null;
  SalesPhone?: string | null;
  CustomerSuccessPhone?: string | null;
  TermsAndCondWebsite?: string | null;
  GettingStartedWebsite?: string | null;
  SupportEmail?: string | null;
  BillingEmail?: string | null;
  MainWebsite?: string | null;
  SubscriptionCancelationPhone?: string | null;
  CustomerSuccessEmail?: string | null;
  SalesNumberMessageForAdditionalPurchases?: string | null;
  MaxUsagePurchaseMsg?: string | null;
}
