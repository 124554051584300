import { Injectable, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { forkJoin } from "rxjs";
import { LoggedInInfoService } from "../loggedInInfo/logged-in-info.service";
import { OrganizationService } from "../rnapi2-service/apis/organization.service";
import { RnCommonId } from "../rnapi2-service/models/commonId-d";
import { SelectionService } from "../selection/selection.service";

@Injectable({
  providedIn: "root",
})
export class RefreshService implements OnDestroy {
  constructor(
    private selectionService: SelectionService,
    private organizationService: OrganizationService,
    private loggedInInfoService: LoggedInInfoService,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  public refreshAll(): void {
    this.refreshAllWithHandler(null);
  }

  public refreshAllWithHandler(handler: () => void) {
    const selectedTopLevelOrg = this.selectionService.getSelectedTopLevelOrg();
    const selectedOrg = this.selectionService.getSelectedOrg();

    const getTopLevelOrgPayload = new RnCommonId();
    getTopLevelOrgPayload.Id = selectedTopLevelOrg.ID.toString();
    const getOrgPayload = new RnCommonId();
    getOrgPayload.Id = selectedOrg.ID.toString();

    this.subscriptions.push(
      forkJoin(
        this.organizationService.apiV2OrganizationsProfilePost(
          getTopLevelOrgPayload,
        ),
        this.organizationService.apiV2OrganizationsGetorganizationPost(
          getTopLevelOrgPayload,
        ),
        this.organizationService.apiV2OrganizationsGetorganizationPost(
          getOrgPayload,
        ),
        this.organizationService.apiV2OrganizationsGetorganizationconfigurationPost(
          getTopLevelOrgPayload,
        ),
        this.organizationService.apiV2OrganizationsSuborgtreePost(
          getTopLevelOrgPayload,
        ),
      ).subscribe(
        ([
          topLevelOrgProfResp,
          topLevelOrgResp,
          orgResp,
          configResp,
          treeResp,
        ]) => {
          this.selectionService.selectedTopLevelOrgChange(
            topLevelOrgProfResp.data,
            treeResp.data,
            configResp.data,
          );
          this.loggedInInfoService.SetLoggedInUserOrg(topLevelOrgResp.data);
          if (handler) {
            handler();
          }
        },
      ),
    );
  }

  public refreshLoggedInUserOrg(handler: () => void) {
    const selectedTopLevelOrg = this.selectionService.getSelectedTopLevelOrg();
    const selectedOrg = this.selectionService.getSelectedOrg();

    const getTopLevelOrgPayload = new RnCommonId();
    getTopLevelOrgPayload.Id = selectedTopLevelOrg.ID.toString();
    const getOrgPayload = new RnCommonId();
    getOrgPayload.Id = selectedOrg.ID.toString();

    this.subscriptions.push(
      this.organizationService
        .apiV2OrganizationsGetorganizationPost(getTopLevelOrgPayload)
        .subscribe((topLevelOrgResp) => {
          this.loggedInInfoService.SetLoggedInUserOrg(topLevelOrgResp.data);
          if (handler) {
            handler();
          }
        }),
    );
  }

  public refreshPOF(handler: () => void): void {
    if (!this.loggedInInfoService.loggedInUserHasRights(["UPDTBILING"])) {
      return;
    }
    const orgID = this.loggedInInfoService.GetLoggedInUserOrg().ID;
    const payload = new RnCommonId();
    payload.Id = orgID.toString();
    this.subscriptions.push(
      this.organizationService
        .apiV2OrganizationsCurrentpaymentinfoPost(payload, "response")
        .subscribe((r) => {
          if (r.body.Success) {
            this.loggedInInfoService.SetLoggedInUserPOF(r.body.data);
          }
          if (handler) {
            handler();
          }
        }),
    );
  }

  private subscriptions: Subscription[] = [];
}
