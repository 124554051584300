import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { RnOrganizationsProfileVM } from "src/app/shared/services/rnapi2-service";
@Component({
  selector: "app-qbo-plans",
  templateUrl: "./qbo-plans.component.html",
  styleUrls: ["./qbo-plans.component.scss"],
})
export class QboPlansComponent {
  public QboPlansData: any;
  public existingQboPlans: any;
  public isChangeSubType: boolean;
  public orgProfile: RnOrganizationsProfileVM;
  @Input() plansForm: FormGroup;
  @Output() QboPlansSelected = new EventEmitter<any>();
  @Input() set qboPlansData(data: any) {
    this.QboPlansData = data;
    this.QboPlansData.forEach((x) => {
      //x.NextBillPrice = parseFloat(x.NextBillPrice).toFixed(2)
    });
  }
  @Input() set qboExistingPlan(data: any) {
    this.existingQboPlans = data;
  }
  @Input() set isChangeSub(data: boolean) {
    this.isChangeSubType = data;
  }
  public selectedIndex: number;
  index: number = null;
  lastIndex = -1;

  selectionOccured(any?) {
    if (any === 100) {
      this.QboPlansSelected.emit([]);
      this.setRow(any);
    }
    this.QboPlansSelected.emit(any);
  }

  public setRow(_index: number) {
    this.selectedIndex = _index;
  }
}
