import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormGroup, Validators } from "@angular/forms";
import { CreditCardService } from "../../../services/credit-card/credit-card.service";

@Component({
  selector: "app-payment-form-cc",
  templateUrl: "./payment-form-cc.component.html",
  styleUrls: ["./payment-form-cc.component.scss"],
})
export class PaymentFormCcComponent implements OnInit {
  @Input() parentForm!: FormGroup;
  @Input() public submitted = false;

  public fourByFourMask = "0000 0000 0000 0000";
  public amexMask = "0000 000000 00000";

  public cardType = "";
  public cardIcon = "fa fa-credit-card";
  public cardMask = this.fourByFourMask;
  public validYear = true;
  public validMonth = true;
  public cvvminlength = 3;
  public cvvmaxlength = 3;
  public cvvplaceholder = "XXX";

  constructor(private creditCardService: CreditCardService) {}

  ngOnInit(): void {
    this.ccValidator = (
      control: AbstractControl,
    ): { [key: string]: boolean } => {
      const ccNum = control.value;
      let cardType = this.creditCardService.determineCardType(ccNum);
      const fullMatch = !!cardType;
      if (!fullMatch) {
        cardType = this.creditCardService.determinceCardTypePartial(ccNum);
      }
      this.cardType = cardType;
      this.cardIcon = this.creditCardService.getCardIcon(
        fullMatch ? cardType : "",
      );
      this.cardMask =
        cardType === "AmericanExpress" ? this.amexMask : this.fourByFourMask;
      this.cvvminlength = fullMatch && cardType === "AmericanExpress" ? 4 : 3;
      this.cvvmaxlength = fullMatch && cardType === "AmericanExpress" ? 4 : 3;
      this.cvvplaceholder =
        fullMatch && cardType === "AmericanExpress" ? "XXXX" : "XXX";

      return fullMatch ? null : { ccInvalid: true };
    };
    this.parentForm
      .get("cardNumber")
      .setValidators([Validators.required.bind(Validators), this.ccValidator]);

    this.parentForm
      .get("nameOnCard")
      .setValidators([Validators.required.bind(Validators)]);
    this.parentForm
      .get("mmExpirationDate")
      .setValidators([Validators.required.bind(Validators)]);
    this.parentForm
      .get("yyyyExpirationDate")
      .setValidators([Validators.required.bind(Validators)]);
    this.parentForm
      .get("CVV")
      .setValidators([Validators.required.bind(Validators)]);
  }

  onBlurCreditCardNumber(number) {
    this.cardType = this.creditCardService.determineCardType(number);
    this.cardIcon = this.creditCardService.getCardIcon(this.cardType);
  }

  onBlurDate() {
    this.validMonth = false;
    this.validYear = false;

    if (this.yyyyExpirationDate.value || this.yyyyExpirationDate.value == 0) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      if (
        this.yyyyExpirationDate.value >= currentYear &&
        this.yyyyExpirationDate.value < currentYear + 11
      ) {
        this.validYear = true;
      }
    } else {
      this.validYear = true;
    }

    if (this.mmExpirationDate.value || this.mmExpirationDate.value == 0) {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();
      if (this.yyyyExpirationDate.value) {
        if (
          this.yyyyExpirationDate.value &&
          this.yyyyExpirationDate.value <= currentYear &&
          this.mmExpirationDate.value >= currentMonth &&
          this.mmExpirationDate.value >= 1 &&
          this.mmExpirationDate.value <= 12
        ) {
          this.validMonth = true;
        } else if (
          this.yyyyExpirationDate.value &&
          this.yyyyExpirationDate.value > currentYear &&
          this.mmExpirationDate.value >= 1 &&
          this.mmExpirationDate.value <= 12
        ) {
          this.validMonth = true;
        }
      } else {
        if (
          this.mmExpirationDate.value >= 1 &&
          this.mmExpirationDate.value <= 12
        ) {
          this.validMonth = true;
        }
      }
    } else {
      this.validMonth = true;
    }
  }

  get nameOnCard() {
    return this.parentForm.get("nameOnCard");
  }

  get cardNumber() {
    return this.parentForm.get("cardNumber");
  }

  get mmExpirationDate() {
    return this.parentForm.get("mmExpirationDate");
  }

  get yyyyExpirationDate() {
    return this.parentForm.get("yyyyExpirationDate");
  }

  get CVV() {
    return this.parentForm.get("CVV");
  }

  private ccValidator: any;
}
