import { Component, OnDestroy, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subscription } from "rxjs";
import { DialogTrackingService } from "../../../services/dialog/dialog-tracking.service";
import { RnErrorHandlerService } from "../../../services/rn-error-handler/rn-error-handler.service";
import { OrganizationService } from "../../../services/rnapi2-service/apis/api";
import {
  RnObjectRNResponseRelay,
  RnOrganizationContainerDelete,
} from "../../../services/rnapi2-service/models/models";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { DeleteFldrConfig } from "./delete-fldr-config";

@Component({
  selector: "app-delete-fldr-confirm",
  templateUrl: "./delete-fldr-confirm.component.html",
  styleUrls: ["./delete-fldr-confirm.component.scss"],
})
export class DeleteFldrConfirmComponent implements OnInit, OnDestroy {
  public folderConfig: DeleteFldrConfig;
  working = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private orgService: OrganizationService,
    private rnErrorHandlerService: RnErrorHandlerService,
    private toastService: RnToastService,
    private dialogTrackService: DialogTrackingService,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.folderConfig = this.config.data as DeleteFldrConfig;
  }

  close() {
    if (!this.working) {
      this.dialogTrackService.closeDialog(this.ref, false);
    }
  }

  private subscriptions: Subscription[] = [];
  public actualDeleteFolder() {
    this.working = true;
    const payload = new RnOrganizationContainerDelete();
    payload.OrgFolderID = this.folderConfig.OrgFolderID;
    payload.AffectedOrganizationId = this.folderConfig.OrganizationID;

    this.orgService
      .apiV2OrganizationsDeleteorgcontainerPost(payload, "response")
      .subscribe(
        (r) => {
          if (r.body.Success) {
            setTimeout(() => {
              this.dialogTrackService.closeDialog(this.ref, true);
            }, 5000);
          }
        },
        (error) => {
          const rnErr = error as RnObjectRNResponseRelay;
          if (rnErr) {
            this.rnErrorHandlerService.handleError(
              "An error occurred attempting to delete a folder.",
              rnErr.Messages,
            );
          }
          this.toastService.showError(
            "There was an error deleting the folder.",
          );
          this.working = false;
        },
      );
  }
}
