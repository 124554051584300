import { Pipe, PipeTransform } from "@angular/core";
import { LoggedInInfoService } from "../../services/loggedInInfo/logged-in-info.service";
import {
  RnOrganizationsProfileVM,
  RnUsersVM,
} from "../../services/rnapi2-service/models/models";

@Pipe({
  name: "rnUserMgmt",
})
export class RnUserMgmtPipe implements PipeTransform {
  constructor(private loggedInInfoService: LoggedInInfoService) {}

  transform(value: unknown, ...args: unknown[]): unknown {
    if (args && args.length > 0) {
      const param = args[0].toString().toLowerCase();
      switch (param) {
        case "isdeleteuservisible": {
          const isUsingWelcomeWizard = value as boolean;
          return (
            !isUsingWelcomeWizard &&
            this.loggedInInfoService.loggedInUserHasRights(["DELETUSERS"])
          );
        }
        case "isdeleteuserdisabled":
          {
            const selectedUsers = args[1] as RnUsersVM[];
            const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
            if (loggedInUser) {
              const hasSelection = selectedUsers?.length > 0;
              const foundUser = selectedUsers?.find(
                (u) =>
                  u.Role == "Account Owner" ||
                  u.Role == "Intuit PCG Administrator" ||
                  u.Role == "Drake Administrator" ||
                  loggedInUser.UserID == u.UserID.valueOf(),
              );
              return !hasSelection || foundUser !== undefined;
            }
          }
          break;
        case "isresetpasswordvisible": {
          const isUsingWelcomeWizard = value as boolean;
          return (
            !isUsingWelcomeWizard &&
            this.loggedInInfoService.loggedInUserHasRights(["RSTUSERPWS"])
          );
        }
        case "isresetpassworddisabled": {
          const countSelection = value as number;
          return countSelection > 0;
        }
        case "isresendwelcomeemailvisible": {
          const isUsingWelcomeWizard = value as boolean;
          return (
            !isUsingWelcomeWizard &&
            this.loggedInInfoService.loggedInUserHasRights(["SNDUSRWLCM"])
          );
        }
        case "isresendwelcomeemaildisabled": {
          const selectedUsers = args[1] as RnUsersVM[];
          const hasSelection = selectedUsers?.length > 0;
          const foundUser = selectedUsers?.find((u) => u.SupportPIN);
          return !hasSelection || foundUser !== undefined;
        }
        case "isunlockaccountvisible": {
          const isUsingWelcomeWizard = value as boolean;
          return (
            !isUsingWelcomeWizard &&
            this.loggedInInfoService.loggedInUserHasRights(["UNLOKUSERS"])
          );
        }
        case "resetactivationtooltip": {
          const selectedUsers = args[1] as RnUsersVM[];
          const foundUser = selectedUsers?.find((u) => u.SupportPIN);
          return foundUser !== undefined
            ? '<span data-testid="tooltipMsg"> Cannot send activation email for users that have already activated</span>'
            : "";
        }
        case "cangeneratereport": {
          const orgHasPOF = (value as RnOrganizationsProfileVM)
            ?.HasDirectPaymentOnFile;
          return (
            orgHasPOF &&
            this.loggedInInfoService.loggedInUserHasRights(["UPDTBILING"])
          );
        }
      }
    }
    return null;
  }
}
