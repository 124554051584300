export class GenericDialogConfiguration {
  public Title: string;
  public Message: string;
  public MessageContainsHTML: boolean;
  public ShowCloseButton: boolean;
  public CloseOnNavigation: boolean;
  public ConfirmButtonText: string;
  public ConfirmButtonStyleClass: string;
  public CancelButtonText: string;
  public CancelButtonStyleClass: string;
  public DialogHeaderClass: string;
  public DialogBodyClass: string;
  public DialogFooterClass: string;
  public DialogFooterConfirmClass: string;
  public DialogFooterCancelClass: string;
  public DialogWidth: number;
  public DialogWidthUnit: string;
  public DialogHeight: number;
  public DialgoHeightUnit: string;
  public HeaderClass: string;
  public StyleClass: string;
  public Position: string;
  public UseButtonForCancel: boolean;
  public KeepOpenAfterConfirm: boolean;
  public UseProgressButton: boolean;
  public DisableClose: boolean;
  public showConfirmIcon: boolean;
  public showConfirmIconImageSource: string;
  public confirmImageIconClass: string;
  public Confirmed: (dialogRef?) => void;
  public Canceled: (dialogRef?) => void;
  public OnClose: (dialogRef?) => void;
  public OnCloseFromBody: (dialogRef?) => void;
  public confirmButtonNoContentPadding: boolean;
  public cancelButtonNoContentPadding: boolean;
  // this is used for the configs that formerly went to their own confirmation dialog
  // i.e. CreateRenameFolderConfig, DeleteFldrConfig, etc.
  public Metadata: any;
  public HideCancel: boolean;
  public showDontShowAgain: boolean;
  public dontShowAgainText: string;
  public checkboxActionHandler: (value) => void;
  ;
}
