import { Component, Input } from "@angular/core";
import { RnWLPLocalAppVM } from "../../../services/rnapi2-service";

@Component({
  selector: "app-local-app-card",
  templateUrl: "./local-app-card.component.html",
  styleUrls: ["./../application-card/application-card.component.scss"],
})
export class LocalAppCardComponent {
  @Input() card: RnWLPLocalAppVM;
  @Input() userID: number;
  @Input() canUseNewTiles = false;

  install() {
    window.location.href = this.card.AppDownloadURL;
  }
}
