import { HttpResponse } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { forkJoin, Observable, Subscription } from "rxjs";
import { OrganizationCatalogInfoVM } from "src/app/shared/services/rnapi2-service/models/OrganizationCatalogInfoVM";
import { CartService } from "../../../services/cart/cart.service";
import { LoggedInInfoService } from "../../../services/loggedInInfo/logged-in-info.service";
import {
  OrganizationService,
  UserService,
} from "../../../services/rnapi2-service/apis/api";
import {
  RnCommonId,
  RnOrganizationConfigurationVM,
  RnOrganizationConfigurationVMRNResponseRelay,
  RnOrganizationPaymentOnFileVM,
  RnOrganizationsProfileVM,
  RnOrganizationsProfileVMRNResponseRelay,
  RnOrganizationWLPContactInformationVM,
  RnOrganizationWLPContactInformationVMRNResponseRelay,
  RnUserPackagePoolAction,
} from "../../../services/rnapi2-service/models/models";
import { RnsidebarService } from "../../../services/sidebar/rnsidebar.service";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { PackageCardData } from "../package-card/package-card-data";

@Component({
  selector: "app-manage-addons-sidebar",
  templateUrl: "./manage-addons-sidebar.component.html",
  styleUrls: ["./manage-addons-sidebar.component.scss"],
})
export class ManageAddonsSidebarComponent implements OnInit, OnDestroy {
  @ViewChild("cartIcon") cartIcon: ElementRef;
  @ViewChild("cart") cart: ElementRef;

  @Input() data: any;
  @Output() componentLoaded = new EventEmitter<boolean>();

  public packageData: PackageCardData[];
  public paymentInfo: RnOrganizationPaymentOnFileVM;
  public addOnsForm: FormGroup;
  public orgProfile: RnOrganizationsProfileVM;
  public orgConfig: RnOrganizationConfigurationVM;
  public orgWlpContactInfo: RnOrganizationWLPContactInformationVM;
  public selectedPackage: PackageCardData;
  public availableAddOns: PackageCardData[];
  public subscriptions: Subscription[] = [];
  public addOns: PackageCardData[];
  public assignedAddOns: string[];
  public organizationId: number;
  public isShoppingCartVisible = false;
  public fullName: string;
  public userType: string;
  private assignedPackage: string;
  public loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private orgService: OrganizationService,
    private cartService: CartService,
    private renderer: Renderer2,
    private userService: UserService,
    private rnsidebarService: RnsidebarService,
    private rnToastService: RnToastService,
    private loggedInInfoService: LoggedInInfoService,
  ) {}

  // simulate click on cancel btn when click on outer overlay
  @HostListener("document:click", ["$event"])
  onClick(event: MouseEvent) {
    const clickedOverlay = event.target as HTMLElement;
    if (clickedOverlay?.classList.contains("p-component-overlay-leave")) {
      this.dismiss();
    }
  }

  ngOnInit(): void {
    this.componentLoaded.emit(false);

    this.renderer.listen("window", "click", (e: Event) => {
      if (
        !this.cartIcon.nativeElement.contains(e.target) &&
        !this.cart.nativeElement.contains(e.target)
      ) {
        this.forceCloseShoppingCart();
      }
    });

    this.cartService.clearCart();
    this.addOnsForm = this.formBuilder.group({
      selectedAddons: [[], Validators.required.bind(Validators)],
    });

    const payloadCommonId = new RnCommonId();
    payloadCommonId.Id = this.data.userId;

    this.subscriptions.push(
      this.userService
        .apiV2UsersGetuserPost(payloadCommonId)
        .subscribe((response) => {
          this.assignedAddOns = response.data.AssignedAddOns;
          this.assignedPackage = response.data.AssignedPackage;
          this.organizationId = response.data.OrganizationID;
          this.fullName = `${response.data.FirstName} ${response.data.LastName}`;
          this.userType = response.data.UserType;

          this.subscriptions.push(
            forkJoin(
              this.loadOrganizationData(this.organizationId.toString()),
              this.loadOrganizationConfig(this.organizationId.toString()),
              this.loadOrganizationWLPConfig(this.organizationId.toString()),
            ).subscribe(([orgProfile, orgConfig, orgWlpContactInfo]) => {
              this.orgProfile = orgProfile.body.data;
              this.orgConfig = orgConfig.body.data;
              this.orgWlpContactInfo = orgWlpContactInfo.body.data;

              this.loggedInInfoService.LoggedInUserOrgPaymentOnFile.subscribe(
                (p) => {
                  this.paymentInfo = p;
                },
              );
            }),
          );

          this.subscriptions.push(
            this.loadOrganizationPackageData().subscribe((r) => {
              if (r.body.Success) {
                this.packageData = [];
                r.body.data.CatalogItems.forEach((p) => {
                  if (
                    ((p.CatalogVisibility === "Internal" ||
                      p.CatalogVisibility === "InternalExclusive") &&
                      p.CurrentQuantity !== 0) ||
                    (p.CatalogVisibility !== "Internal" &&
                      p.CatalogVisibility !== "InternalExclusive")
                  ) {
                    const pkg = new PackageCardData();
                    pkg.AssignFromRnCatalogItemVM(p);
                    this.packageData.push(pkg);
                  }
                });
                const addOnsList = [];
                this.packageData.forEach((p) => {
                  if (p.PackageUIType === "addon" && p.PackageTypeID !== 6) {
                    addOnsList.push(p);
                  }
                });
                this.addOns = addOnsList;

                if (this.assignedPackage) {
                  const selectedpackageList = this.packageData.filter(
                    (x) => x.PackageName === this.assignedPackage,
                  );
                  if (selectedpackageList.length > 0) {
                    const pkg = selectedpackageList[0];
                    pkg.Selected = true;
                    this.selectedPackage = pkg;
                    this.availableAddOns = [];
                    const addonSelectControls = this.addOnsForm.controls;
                    addonSelectControls.selectedAddons.setValue([]);

                    this.addOns.forEach((a) => {
                      if (a.AddOnAdded) {
                        a.AddOnAdded = false;
                        if (a.BuyNew) {
                          this.cartService.removeFromCart(a);
                          a.BuyNew = false;
                          a.CurrentQuantity--;
                        }
                        a.CurrentUsed--;
                      }
                    });

                    if (
                      this.selectedPackage &&
                      this.selectedPackage.PackageID > 0
                    ) {
                      this.availableAddOns = this.addOns.filter((a) => {
                        return (
                          a.ExcludingPackageIds.filter((i) => {
                            return i === this.selectedPackage.PackageID;
                          }).length === 0
                        );
                      });
                    }
                  }
                } else {
                  this.availableAddOns = this.addOns.filter((a) => {
                    return a.NonHostedDesktop;
                  });
                }
                this.componentLoaded.emit(true);
              }
            }),
          );
        }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });

    this.cartService.clearCart();
  }

  private loadOrganizationData(
    orgId: string,
  ): Observable<HttpResponse<RnOrganizationsProfileVMRNResponseRelay>> {
    const payload = new RnCommonId();
    payload.Id = orgId;
    return this.orgService.apiV2OrganizationsProfilePost(payload, "response");
  }

  private loadOrganizationConfig(
    orgId: string,
  ): Observable<HttpResponse<RnOrganizationConfigurationVMRNResponseRelay>> {
    const payload = new RnCommonId();
    payload.Id = orgId;
    return this.orgService.apiV2OrganizationsGetorganizationconfigurationPost(
      payload,
      "response",
    );
  }

  private loadOrganizationWLPConfig(
    orgId: string,
  ): Observable<
    HttpResponse<RnOrganizationWLPContactInformationVMRNResponseRelay>
  > {
    const payload = new RnCommonId();
    payload.Id = orgId;
    return this.orgService.apiV2OrganizationsGetorganizationwlpcontactinfoPost(
      payload,
      "response",
    );
  }

  private loadOrganizationPackageData(): Observable<
    HttpResponse<OrganizationCatalogInfoVM>
  > {
    const payload = new RnCommonId();
    payload.Id = this.organizationId.toString();
    payload.AffectedOrganizationId = this.organizationId;
    return this.orgService.apiV2OrganizationsGetcatalogitemsfororgPost(
      payload,
      "response",
    );
  }

  forceCloseShoppingCart(): void {
    this.isShoppingCartVisible = false;
  }

  toggleShoppingCart(): void {
    this.isShoppingCartVisible = !this.isShoppingCartVisible;
  }

  dismiss() {
    if (this.canSubmit()) {
      this.rnsidebarService.showConfirmation();
    } else {
      this.rnsidebarService.hide();
    }
  }

  public saveChanges() {
    const addonPayloads: Array<RnUserPackagePoolAction> = [];
    this.createPayloadAddOn(addonPayloads);

    this.loading = true;
    this.subscriptions.push(
      this.userService
        .apiV2UsersPackagepoolactionmultiplePost(addonPayloads)
        .subscribe(
          (response) => {
            if (response) {
              this.loading = false;
              this.rnToastService.showSuccess("Package changed successfully");

              // this sounds like a job for SignalR, but in the meantime, let's update the user's addons based on what they added/removed
              const ao = this.availableAddOns
                .reduce((f, o) => {
                  if (o.AddOnAdded) {
                    f.push(o.PackageName);
                  }
                  return f;
                }, [])
                .sort((a, b) => {
                  return a.localeCompare(b);
                });
              this.rnsidebarService.setValue({
                name: "AssignedAddOns",
                value: ao,
              });
            }
          },
          (error) => {
            this.loading = false;
            this.rnToastService.showError(
              "An error occured when changing packages. Please contact our Technical support team",
            );
            console.log(error);
          },
        ),
    );
  }

  private createPayloadAddOn(addonPayloads: RnUserPackagePoolAction[]) {
    this.availableAddOns?.forEach((availableAddOn) => {
      const checked = this.assignedAddOns.filter(
        (assignedAddOn) => assignedAddOn == availableAddOn.PackageName,
      );
      if (availableAddOn.AddOnAdded) {
        // add or reassign
        if (checked.length == 0) {
          const atomicAction = !availableAddOn.BuyNew ? "reassign" : "add";
          this.appendToAddonPayloads(
            availableAddOn,
            addonPayloads,
            atomicAction,
          );
        }
      } else {
        // drop
        if (checked.length > 0) {
          this.appendToAddonPayloads(availableAddOn, addonPayloads, "drop");
        }
      }
    });
  }

  private appendToAddonPayloads(
    pkg: PackageCardData,
    addonPayloads: RnUserPackagePoolAction[],
    atomicAction: string,
  ) {
    const action = new RnUserPackagePoolAction();
    const promoID =
      this.cartService.promo && this.cartService.promo.promoCode
        ? this.cartService.promo.promoCode.ID
        : -1;
    action.AffectedOrganizationId = this.orgProfile.IdThatPaysForThisOrg;
    action.AffectedUserId = this.data.userId;
    action.Users_ID = this.data.userId;
    action.AtomicAction = atomicAction;
    action.KeepOldPackage = true;
    action.PromotionCodeID = promoID;

    if (atomicAction == "drop") {
      action.FromPackage_ID = pkg.PackageID;
      action.ToPackage_ID = -1;
    } else {
      // "reassign" or "add";
      action.FromPackage_ID = -2;
      action.ToPackage_ID = pkg.PackageID;
    }

    addonPayloads.push(action);
  }

  canSubmit() {
    const addonPayloads: Array<RnUserPackagePoolAction> = [];
    this.createPayloadAddOn(addonPayloads);
    return !this.loading && addonPayloads.length > 0;
  }

  PackageChanged(event) {
    this.rnsidebarService.setPendingChanges(this.canSubmit());
  }
}
