import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "app-date-range-picker",
  templateUrl: "./date-range-picker.component.html",
  styleUrls: ["./date-range-picker.component.scss"],
})
export class DateRangePickerComponent implements OnDestroy {
  @Input() set initialDateRange(dateRange: Date[]) {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      this.nonUIChange = true;
      this.range.controls["start"].setValue(dateRange[0]);
      this.range.controls["end"].setValue(dateRange[1]);
    }
  }
  @Output() dateRangeChanged = new EventEmitter<Date[]>();

  constructor() {
    this.subscriptions.push(
      this.range.valueChanges.pipe(debounceTime(200)).subscribe((e) => {
        if (this.range.valid) {
          const dates = [];
          dates.push(this.range.controls["start"].value);
          dates.push(this.range.controls["end"].value);
          if (!this.nonUIChange) {
            this.dateRangeChanged.emit(dates);
          }
          this.nonUIChange = false;
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  range = new FormGroup({
    start: new FormControl(null, Validators.required.bind(Validators)),
    end: new FormControl(null, Validators.required.bind(Validators)),
  });

  private nonUIChange: boolean;
  private subscriptions: Subscription[] = [];
}
