import { Injectable } from "@angular/core";
import { UserService } from "../rnapi2-service";
import { Observable, of } from "rxjs";
import { catchError, map, finalize, shareReplay } from "rxjs/operators";
import {
  RnUsersProfileVMRNResponseRelay,
  RnCommonId,
} from "../rnapi2-service/models/models";
import { HttpResponse } from "@angular/common/http";

/** User cache duration in milliseconds */
const userCacheTimeOut = 15000;

/**
 * CacheEntry is a data structure that holds the user profile data and the expiration time
 */
interface CacheEntry {
  data: Observable<HttpResponse<RnUsersProfileVMRNResponseRelay>>;
  expiration: number;
}

/**
 * The UserCacheService is a service that caches user profiles for a pre-determined amount of time
 * specified by the cacheTimeOut constant. It also handles ongoing requests to prevent multiple
 * simultaneous requests for the same user profile.
 */
@Injectable({
  providedIn: "root",
})
export class UserCacheService {
  constructor(public userService: UserService) {}

  private userCache: { [userId: string]: CacheEntry } = {};
  private ongoingRequests: {
    [userId: string]: Observable<HttpResponse<RnUsersProfileVMRNResponseRelay>>;
  } = {};

  /**
   * Get user profile by userId
   * @param userId id of the user
   * @param forceBustCache manual override to bust the cache entry
   * @returns user's profile response
   */
  getUser(
    userId: string,
    forceBustCache: boolean = false,
  ): Observable<HttpResponse<RnUsersProfileVMRNResponseRelay>> {
    const now = Date.now();
    const cacheEntry = this.userCache[userId];

    if (cacheEntry && cacheEntry.expiration > now && !forceBustCache) {
      return cacheEntry.data;
    }

    if (this.ongoingRequests[userId]) {
      return this.ongoingRequests[userId];
    }

    const payload: RnCommonId = {
      Id: userId,
    };

    const userObservable = this.userService
      .apiV2UsersGetuserPost(payload, "response")
      .pipe(
        shareReplay({
          bufferSize: 1,
          windowTime: userCacheTimeOut,
          refCount: false,
        }),
        map((response) => {
          this.userCache[userId] = {
            data: of(response),
            expiration: now + userCacheTimeOut,
          };
          return response;
        }),
        catchError((error) => {
          console.error(`Error fetching user with userId: ${userId}`, error);
          delete this.userCache[userId];
          return of(null); // Return a fallback value
        }),
        finalize(() => {
          delete this.ongoingRequests[userId];
        }),
      );

    this.ongoingRequests[userId] = userObservable;

    return userObservable;
  }
}
