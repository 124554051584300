/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnEventLogInfo } from "../models/./eventLogInfo-d";

export class RnHCValidateToken {
  userid: number;
  created: string;
  token: string;
  expiry: string;
  evl?: RnEventLogInfo;
}
