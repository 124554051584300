<div class="modal-header bgColWhite">
  <div class="package-assign-header">
    <div>
      <button
        type="button"
        class="close-x pullUpperRight"
        (click)="close()"
        aria-hidden="true"
      >
        <span
          uib-tooltip="Close"
          class="close-x-span"
          tooltipPosition="top"
          pTooltip="Close"
          >×</span
        >
      </button>
    </div>
    <div class="justifiedTitlebar">
      <h3 id="assignUserHeading" class="modal-title">
        Assign Package to Users
      </h3>
    </div>
  </div>
</div>
<div class="modal-body bgColWhite">
  <div class="row">
    <div class="outerBorder">
      <table show-filter="false" class="table-package-detail table-packages">
        <thead>
          <tr>
            <th>Type</th>
            <th>Assigned</th>
            <th>Remaining</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ Package.PackageName }}</td>
            <td>
              {{
                this.selectionColumnConfig.SelectedItems.length
                  | rnQuantityChange: "addvalues" : Package.NumberUsed
              }}
            </td>
            <td>
              {{
                this.selectionColumnConfig.SelectedItems.length
                  | rnQuantityChange
                    : "remainvalue"
                    : Package.NumberUsed
                    : Package.NumberAllowed
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row medium">
    <div class="col-sm-12">
      <div class="row p-t-sm p-b-sm">
        <div class="col-sm-6 package-users-select-text">
          <span class="sectionTitle"
            >Select users to assign {{ Package.PackageName }} to:</span
          >
        </div>
        <div class="col-sm-2"></div>
        <div class="col-sm-4 searchBar">
          <app-search-control
            id="packageAssignSearchControl"
            [Configuration]="searchControlConfiguration"
            (SearchOccurred)="searchOccurred($event)"
          ></app-search-control>
        </div>
      </div>
      <div class="pkg-assign-table">
        <div>
          <rn-grid [Configuration]="TableConfiguration"></rn-grid>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer bgColWhite">
  &nbsp;
  <app-button
    id="closeButton"
    label="CANCEL"
    noContentPadding="true"
    buttonStyle="addUserTeritary"
    (clickActionEmitter)="close()"
  >
  </app-button>
  <app-button
    id="btnAssign"
    [disabled]="!anyUsersSelected()"
    (clickActionEmitter)="assignPackage()"
    label="{{ AssignButtonText }}"
    showProcessing="true"
    [processing]="processing"
    noContentPadding="true"
    buttonStyle="primary"
  >
  </app-button>
</div>
