<div
  [ngClass]="{
    'height-99':
      componentName === 'app-access-management' ||
      componentName === 'app-user-events',
    'height-99-with-overflow':
      componentName !== 'app-access-management' &&
      componentName !== 'app-user-events'
  }"
>
  <app-loader *ngIf="Loading && !!componentName" text="Loading..."></app-loader>
  <div [ngSwitch]="componentName">
    <div *ngSwitchCase="'app-manage-addons'" class="height-99-with-overflow">
      <app-manage-addons-sidebar
        [data]="data"
        (componentLoaded)="componentLoaded($event)"
      ></app-manage-addons-sidebar>
    </div>
    <div *ngSwitchCase="'app-access-management'" class="height-99">
      <app-access-management
        [UserProfile]="data"
        (ComponentLoaded)="componentLoaded($event)"
      ></app-access-management>
    </div>
    <div *ngSwitchCase="'app-manage-packages'" class="height-99-with-overflow">
      <app-manage-packages-sidebar
        [data]="data"
        (componentLoaded)="componentLoaded($event)"
      ></app-manage-packages-sidebar>
    </div>
    <div *ngSwitchCase="'app-user-events'" class="height-99-with-overflow">
      <app-user-events
        [data]="data"
        (componentLoaded)="componentLoaded($event)"
      ></app-user-events>
    </div>
    <div *ngSwitchCase="'app-license-purchase'" class="height-99">
      <app-license-purchase
        [data]="data"
        (componentLoaded)="componentLoaded($event)"
      ></app-license-purchase>
    </div>
    <div *ngSwitchCase="'app-qbo-purchase'" class="height-99">
      <app-qbo-purchase
        [data]="data"
        [additionalData]="additionalData"
        (componentLoaded)="componentLoaded($event)"
      ></app-qbo-purchase>
    </div>
    <div *ngSwitchCase="'app-license-assign'" class="height-99-with-overflow">
      <app-license-assign
        [data]="data"
        (componentLoaded)="componentLoaded($event)"
      ></app-license-assign>
    </div>
    <div
      *ngSwitchCase="'app-access-management-assign'"
      class="height-99-with-overflow"
    >
      <app-access-management-assign
        [data]="data"
        (componentLoaded)="componentLoaded($event)"
      ></app-access-management-assign>
    </div>
  </div>
</div>
