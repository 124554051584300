/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnHCSalesForceOrderPayloadAO } from "../models/./hCSalesForceOrderPayloadAO-d";
import { RnHCSalesForceOrderOrganization } from "../models/./hCSalesForceOrderOrganization-d";
import { RnEventLogInfo } from "../models/./eventLogInfo-d";

export class RnHCSalesForceOrderPayload {
  Organization: RnHCSalesForceOrderOrganization;
  AccountOwner: RnHCSalesForceOrderPayloadAO;
  SalesForceAgentID?: string | null;
  evl?: RnEventLogInfo;
}
