<form [formGroup]="frmGroup">
  <div class="header_wrapper">
    <div class="col-md-4 events-search-text-container">
      <div class="ui-fluid">
        <app-search-control
          id="eventsSearchComponent"
          [searchText]="searchText"
          [Configuration]="searchControlConfiguration"
          (SearchOccurred)="textSearchOccurred($event)"
        ></app-search-control>
      </div>
    </div>
    <div class="col-md-1 no-padding"></div>
    <div class="col-md-7 no-padding d-flex">
      <div class="col-md-1 no-padding"></div>
      <div class="col-md-6 no-padding event-types-container">
        <div class="ui-fluid">
          <app-select
            componentId="eventTypeSelect"
            [items]="eventTypes"
            [overlayClass]="'event-types-container'"
            data-testid="allEventsDrop"
            value="value"
            text="label"
            defaultText="All Events"
            [frmComponent]="frmGroup"
            (changed)="onChangeEventType()"
            [loading]="loadingEvents"
          >
          </app-select>
        </div>
      </div>
      <div class="col-md-5 no-padding">
        <input
          type="date"
          class="eventDatepicker"
          data-testid="eventStartDateField"
          data-pendo-id="eventStartDateField"
          [value]="eventStartDateString"
          max="{{maxDate}}"
          (change)="startDateHandler($event)"/>
        <span class="dateRangeHyphen">-</span>
        <input
          type="date"
          class="eventDatepicker"
          data-testid="eventEndDateField"
          data-pendo-id="eventEndDateField"
          [value]="eventEndDateString"
          min="{{minDate}}"
          max="2999-12-31"
          (change)="endDateHandler($event)" />
      </div>
    </div>
  </div>
  <div data-testid="eventsContainer" class="p-lg-12 no-padding">
    <rn-grid [Configuration]="eventsGridConfiguration"></rn-grid>
  </div>
</form>
