<div class="confirm-modal expand">
  <form #billingForm="ngForm">
    <div class="confirmation">
      <div class="modal-header no-border">
        <h3 class="modal-header-text m-b-1rem">
          <b>Convert to Client-Paid Account</b>
        </h3>
      </div>

      <div class="modal-body">
        <b>Organization:</b>&nbsp;{{ orgName }}<br />
        <div *ngIf="oneActiveUser">
          <b>Account Owner:</b>&nbsp;{{ theOnlyActiveUser }}
          <br />
        </div>
        <br />
        <!-- text case many users-->
        <div *ngIf="manyActiveUsers">
          <p>
            Select a user from the list below. They will become the Account
            Owner and responsible for payment once this organization is
            converted to a Client-Paid Account.
          </p>
          <br />
          <p class="mb-0">
            If the user that you would like to designate as the Account Owner
            does not yet exist, please follow the process to add them as a user.
          </p>
          <br />
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="selectedAccountowner"
                  ><b>Select an Account Owner:</b></label
                >
                <p-dropdown
                  id="activeUser"
                  name="activeUser"
                  [(ngModel)]="activeUser"
                  [options]="activeUsers"
                  panelStyleClass="highlight-yellow"
                  class="width-100-percent"
                  required
                ></p-dropdown>
              </div>
            </div>
          </div>
        </div>
        <!-- text case 1 user-->
        <div *ngIf="oneActiveUser">
          <p>
            <b>{{ theOnlyActiveUser }} will become the Account Owner</b> and
            responsible for payment once this organization is converted to a
            Client-Paid Account.
          </p>
          <br />
          <p class="m-b-1rem">
            If you would like to designate someone other than
            {{ theOnlyActiveUser }} to be the Account Owner, please follow the
            process to add them as a user.
          </p>
        </div>
        <!-- text case no users-->
        <div *ngIf="noActiveUsers">
          <p>
            <strong
              >There are no users that have been added to this organization. You
              will need to add at least one user.</strong
            >
          </p>
          <br />
          <p class="mb-0">
            Go to the Users tab, click the Add User button and follow the
            process to add them as a user. Then return to the Billing tab and
            click on the Convert to Client-Paid Account button.
          </p>
        </div>
        <div class="no-margin-well">
          <b>What does undergoing a billing separation mean?</b>
          <ul>
            <li>
              Packages and licenses associated to users in {{ orgName }} will be
              transferred from your account, {{ config.data.ParentName }}, to
              theirs. We will take these items off of your invoice and begin
              billing them directly.
            </li>
            <li>
              The next time
              {{ theOnlyActiveUser ? theOnlyActiveUser : "the Account Owner" }}
              logs in to AppHub, they will be asked to provide a payment method
              before {{ orgName }} can continue using Rightworks.
            </li>
            <li>
              The billing information for {{ orgName }} needs to be submitted
              within 5 business days, otherwise they will be cancelled by the
              Rightworks Billing department.
            </li>
          </ul>
        </div>
      </div>

      <div class="modal-footer right">
        <app-button
          id="btnCancel"
          label="CANCEL"
          buttonStyle="addUserTeritary"
          noContentPadding="true"
          (clickActionEmitter)="close()"
        >
        </app-button>
        <app-button
          id="btnConverttoClientPaid"
          buttonStyle="primary"
          *ngIf="anyActiveUsers"
          noContentPadding="true"
          [disabled]="!billingForm.valid && anyActiveUsers"
          label="CONVERT TO CLIENT-PAID ACCOUNT"
          (clickActionEmitter)="onSubmit(billingForm)"
        >
        </app-button>
      </div>
    </div>
  </form>
</div>
