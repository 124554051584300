import { processButtonStateTypes } from "./process-button-state";
import { Observable, BehaviorSubject } from "rxjs";

export class ProcessButtonConfiguration {
  constructor() {
    this.State = this.stateSubject.asObservable();
    this.TooltipText = this.tooltipSubject.asObservable();
    this.TooltipEscape = this.tooltipEscapeSubject.asObservable();
    this.TooltipEnabled = this.tooltipEnabledSubject.asObservable();
    this.TooltipPosition = this.tooltipPositionSubject.asObservable();
  }
  public ProcessID: string;
  public ButtonClass: string;
  public NormalButtonText: string;
  public ProcessedButtonText: string;
  public ProcessingButtonText: string;
  public SucceededButtonText: string;
  public FailedButtonText: string;
  public UseIcons: boolean;
  public UsePrecedingIcons = false;
  public IconNormalClass: string;
  public IconProcessingClass: string;
  public IconProcessedClass: string;
  public IconSucceededClass: string;
  public IconFailedClass: string;
  public IconNormalImage: string;
  public IconProcessingImage: string;
  public IconProcessedImage: string;
  public IconSucceededImage: string;
  public IconFailedImage: string;
  public AutoReset = true;
  public ProcessCompleteTimeoutPeriod = 3000;
  public State: Observable<processButtonStateTypes>;
  public StopPropagation = false;
  public TooltipText: Observable<string>;
  public TooltipEscape: Observable<boolean>;
  public TooltipEnabled: Observable<boolean>;
  public TooltipPosition: Observable<string>;

  public StateChanged(newState: processButtonStateTypes) {
    this.stateSubject.next(newState);
  }

  public TooltipTextChanged(text: string) {
    this.tooltipSubject.next(text);
  }

  public TooltipEscapeChanged(escape: boolean) {
    this.tooltipEscapeSubject.next(escape);
  }

  public TooltipEnabledChanged(enabled: boolean) {
    this.tooltipEnabledSubject.next(enabled);
  }

  public TooltipPositionChanged(position: string) {
    this.tooltipPositionSubject.next(position);
  }

  public GetIsDisabled: () => boolean;

  private stateSubject = new BehaviorSubject<processButtonStateTypes>(
    processButtonStateTypes.normal,
  );
  private tooltipSubject = new BehaviorSubject<string>("");
  private tooltipEscapeSubject = new BehaviorSubject<boolean>(true);
  private tooltipEnabledSubject = new BehaviorSubject<boolean>(true);
  private tooltipPositionSubject = new BehaviorSubject<string>("");
}
