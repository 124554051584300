/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnEventLogInfo } from "../models/./eventLogInfo-d";

export class RnUserCreate {
  RNID?: string | null;
  RNOrganizationID?: number;
  FirstName?: string | null;
  LastName?: string | null;
  UserName?: string | null;
  Email?: string | null;
  State?: string | null;
  Phone?: string | null;
  UserTypeID?: number;
  SendWelcomeEmail?: boolean;
  SID?: string | null;
  XID?: string | null;
  OUName?: string | null;
  DistinguishedName?: string | null;
  HasTerminalServicesAccess?: boolean;
  TerminalServicesGroupname?: string | null;
  ProfileFolderPath?: string | null;
  HomeFolderPath?: string | null;
  DateFolderPath?: string | null;
  QuickBaseUser_RID?: string | null;
  SupportPin?: string | null;
  ts_PasswordReset?: string | null;
  AreaCode?: string | null;
  PhoneNumber?: string | null;
  CountryCode?: string | null;
  EventLogInfo?: RnEventLogInfo;
  AffectedOrganizationId?: number;
  AffectedUserId?: number;
}
