import { TreeNode } from "primeng/api";

export class RnTreeNodeData implements TreeNode {
  label?: string;
  data?: any;
  icon?: any;
  expandedIcon?: any;
  collapsedIcon?: any;
  children?: TreeNode[];
  childrenConnectPointIds?: [];
  leaf?: boolean;
  expanded?: boolean;
  type?: string;
  parent?: TreeNode;
  partialSelected?: boolean;
  styleClass?: string;
  draggable?: boolean;
  droppable?: boolean;
  selectable?: boolean;
  key?: string;
  mapped?: boolean;
  connectPointId?: number;
  parentConnectPointId?: number;
  isParent?: boolean;
}
