import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { QuantityCounts } from "../../../../feature-modules/welcomewizard/quantity-counts";
import { AppInsightsService } from "../../../appInsights/app-insights.service";
import { CartService } from "../../../services/cart/cart.service";
import { LoggedInInfoService } from "../../../services/loggedInInfo/logged-in-info.service";
import { NotificationDialogService } from "../../../services/notificationDialog/notification-dialog.service";
import { OrganizationLogicService } from "../../../services/organization-logic/organization-logic.service";
import { PackagesService } from "../../../services/packages/packages.service";
import { RefreshService } from "../../../services/refresh/refresh.service";
import {
  RnLicenseFullVM,
  RnOrganizationConfigurationVM,
  RnOrganizationPaymentOnFileVM,
  RnOrganizationsProfileVM,
  RnOrganizationWLPContactInformationVM,
  RnUserCreate,
  RnUserCreateFull,
  RnUserLicensesAssignUser,
  RnUserPackageAssign,
  RnUserPackagePoolAction,
} from "../../../services/rnapi2-service/models/models";
import { SelectionService } from "../../../services/selection/selection.service";
import { allowedEmailValidator } from "../../../validation/allowed-email-validator.service";
import { MustMatch } from "../../../validation/must-match.validator";
import { GenericDialogConfiguration } from "../generic-dialog/generic-dialog-configuration";
import { PackageCardData } from "../package-card/package-card-data";
import { AddUserStep } from "./add-user-step";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit, OnDestroy {
  @ViewChild("cartIcon") cartIcon: ElementRef;
  @ViewChild("cart") cart: ElementRef;

  mainForm: FormGroup;
  currentStep = 0;
  steps: AddUserStep[];
  OrganizationID: number;
  isShoppingCartVisible = false;
  paymentInfo: RnOrganizationPaymentOnFileVM;
  private subscriptions: Subscription[] = [];
  private pendingLicenseCheck = true;
  private noLicenses = true;
  private licenses: RnLicenseFullVM[];
  hasAccountOwner: boolean;
  addOnsList = [];
  addOns: PackageCardData[];
  selectedPackage: PackageCardData;
  private selectedLicense: RnLicenseFullVM;
  checkForInitialActiveMessage = false;
  IsUsingWelcomeWizard = false;
  OwnerActions: QuantityCounts[] = [];
  AllActions: QuantityCounts[] = [];
  public orgProfile: RnOrganizationsProfileVM;
  public orgConfig: RnOrganizationConfigurationVM;
  public orgWlpContactInfo: RnOrganizationWLPContactInformationVM;
  private validatedPromo: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mdDialogRef: MatDialogRef<AddUserComponent>,
    private formBuilder: FormBuilder,
    private organizationLogicService: OrganizationLogicService,
    private loggedInInfoService: LoggedInInfoService,
    private notificationDialogService: NotificationDialogService,
    private appInsights: AppInsightsService,
    private renderer: Renderer2,
    private cartService: CartService,
    private packagesService: PackagesService,
    private selectionService: SelectionService,
    private refreshService: RefreshService,
  ) {
    this.subscriptions.push(
      this.selectionService.SelectedOrgProfile.subscribe((p) => {
        this.orgProfile = p;
      }),
    );

    this.subscriptions.push(
      this.loggedInInfoService.LoggedInUserOrgPaymentOnFile.subscribe((r) => {
        this.paymentInfo = r;
      }),
    );
  }

  ngOnInit(): void {
    const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    if (!loggedInUser) {
      return;
    }

    this.refreshService.refreshPOF(() => {});

    this.IsUsingWelcomeWizard = this.data.IsUsingWelcomeWizard;
    if (this.IsUsingWelcomeWizard) {
      if (
        this.data.OwnerPackages.length > 0 &&
        this.data.UserPackages.length > 0
      ) {
        // combine the arrays by value
        this.AllActions = JSON.parse(JSON.stringify(this.data.OwnerPackages));
        const userPacks = JSON.parse(JSON.stringify(this.data.UserPackages));
        for (const u of userPacks) {
          const found = this.AllActions.findIndex(
            (a) => a.PackageID == u.PackageID,
          );
          if (found >= 0) {
            this.AllActions[found].Quantity += u.Quantity;
          } else {
            this.AllActions.push(u);
          }
        }
      } else {
        this.AllActions =
          this.data.OwnerPackages.length > 0
            ? JSON.parse(JSON.stringify(this.data.OwnerPackages))
            : JSON.parse(JSON.stringify(this.data.UserPackages));
      }
    }

    this.hasAccountOwner = this.IsUsingWelcomeWizard
      ? true
      : this.organizationLogicService.DoesSelectedOrgPayForItself();
    this.OrganizationID = this.data.OrganizationID;
    this.orgConfig = this.data.OrgConfig;
    this.orgWlpContactInfo = this.data.OrgWlpContactInfo;
    this.steps = [];
    this.currentStep = 1;
    const userStep: AddUserStep = {
      ID: "USER",
      title: "User Details",
      formName: "UserCreate",
      desc: "This is where you can select the role and details for this user. On the next set of screens you will be able to select the package and add-ons that they will need to use in the hosted desktop environment.",
    };
    this.steps.push(userStep);
    const pkgStep: AddUserStep = {
      ID: "PKG",
      title: "Select a Package",
      formName: "UserPackage",
      desc: "Select one of the options below based on the user's role and needs.",
    };
    this.steps.push(pkgStep);

    const addOnStep: AddUserStep = {
      ID: "ADDON",
      title: "Select Add-ons",
      formName: "",
      desc: "If needed, we will automatically purchase a copy of an add-on for you.",
    };
    this.steps.push(addOnStep);

    const licenseStep: AddUserStep = {
      ID: "LICENSE",
      title: "Deploy QuickBooks",
      formName: "UserLicense",
      desc: "We can put QuickBooks on this user's cloud-hosted desktop so they can get started right away. You can install any version of QuickBooks that your organization has a license for. <br/>If you bought or leased a license from Rightworks, it may take up to 48 hours for it to appear on your account.",
    };
    this.steps.push(licenseStep);

    const reviewStep: AddUserStep = {
      ID: "REVIEW",
      title: "Review Details",
      formName: "",
      desc: "",
    };
    this.steps.push(reviewStep);

    this.mainForm = this.formBuilder.group({
      UserCreate: this.formBuilder.group(
        {
          role: ["", Validators.required.bind(Validators)],
          firstName: ["", Validators.required.bind(Validators)],
          lastName: ["", Validators.required.bind(Validators)],
          email: [
            "",
            [
              Validators.required.bind(Validators),
              Validators.pattern(
                /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              ),
              allowedEmailValidator("rightnetworks.com", loggedInUser.UserType),
            ],
          ],
          confirmEmail: ["", Validators.required.bind(Validators)],
          sendWelcomeEmail: ["", Validators.required.bind(Validators)],
          xid: [null, Validators.maxLength(50)],
        },
        {
          validator: MustMatch("email", "confirmEmail"),
        },
      ),
      UserPackage: this.formBuilder.group({
        selectedPackage: ["", Validators.required.bind(Validators)],
      }),
      UserAddons: this.formBuilder.group({
        selectedAddons: [[], Validators.required.bind(Validators)],
      }),
      UserLicense: this.formBuilder.group({
        isDeploy: ["true"],
        qbVersion: ["", Validators.required.bind(Validators)],
        qbCountry: ["", Validators.required.bind(Validators)],
        qbEdition: ["", Validators.required.bind(Validators)],
        qbFlavor: ["", Validators.required.bind(Validators)],
      }),
    });
    this.setUserLicenseValidators();

    if (!this.IsUsingWelcomeWizard) {
      this.renderer.listen("window", "click", (e: Event) => {
        if (
          !this.cartIcon.nativeElement.contains(e.target) &&
          !this.cart.nativeElement.contains(e.target)
        ) {
          this.forceCloseShoppingCart();
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  forceCloseShoppingCart(): void {
    this.isShoppingCartVisible = false;
  }

  toggleShoppingCart(): void {
    this.isShoppingCartVisible = !this.isShoppingCartVisible;
  }

  handleNext(): void {
    if (this.isLastStep()) {
      this.createUser();
    } else {
      let nextStep = this.currentStep + 1;

      while (nextStep < this.steps.length) {
        if (this.canNavigateToStep(nextStep - 1)) {
          break;
        }
        nextStep++;
      }
      this.appInsights.logPageView(
        "AddUser-" + this.steps[this.currentStep].title,
      );
      this.currentStep = nextStep;
    }
    this.postNext();
  }

  handlePrevious(): void {
    if (this.isFirstStep()) {
      this.currentStep = 1;
      return;
    } else {
      let prevStep = this.currentStep - 1;

      if (
        this.steps[prevStep] &&
        this.steps[prevStep].ID === "ADDON" &&
        this.addOns.length > 0
      ) {
        this.resetAddOns();
        // Reset available Add Ons Array
        this.availableAddOns = this.getAvailableAddons(
          this.selectedPackage.PackageID < 0,
        );
      }

      while (prevStep > 1) {
        if (this.canNavigateToStep(prevStep - 1)) {
          break;
        }
        prevStep--;
      }
      this.currentStep = prevStep;
    }
  }

  private resetAddOns(): void {
    const addonActions: QuantityCounts[] = [];
    this.addOns.forEach((a) => {
      if (a.AddOnAdded) {
        a.AddOnAdded = false;
        if (a.BuyNew) {
          this.cartService.removeFromCart(a);
          a.BuyNew = false;
          a.CurrentQuantity--;
        }
        addonActions.push({
          PackageID: a.PackageID,
          Quantity: -1,
        });
        a.CurrentUsed--;
      }
    });
  }

  getNextLabel(): string {
    return this.isLastStep() ? "ADD USER" : "SAVE";
  }

  isFirstStep(): boolean {
    return this.currentStep <= 1;
  }

  isLastStep(): boolean {
    return this.currentStep == this.steps.length;
  }

  cancelAddUser(): void {
    const confirmConfig = new GenericDialogConfiguration();
    confirmConfig.Title = "Don't Create User?";
    confirmConfig.StyleClass = "confirmation";
    confirmConfig.ConfirmButtonText = "Don't Create User";
    confirmConfig.CancelButtonText = "Cancel";
    confirmConfig.CancelButtonStyleClass = "cancelButton";
    confirmConfig.MessageContainsHTML = true;
    confirmConfig.ConfirmButtonStyleClass = "dontCreateUserButton";
    confirmConfig.showConfirmIcon = true;
    confirmConfig.confirmImageIconClass = "deleteImage";
    confirmConfig.showConfirmIconImageSource = "rn-trash-2";
    confirmConfig.DialogHeaderClass = "modal-header no-border";
    confirmConfig.DialogFooterCancelClass = "right-spacing";
    confirmConfig.Message =
      '<p class="m-b-1rem modalBody">You are in the process of adding a new user. If you end the process now, the user will not be added and you will need to add them again.</p><p class="m-b-1rem modalBody">Click Don\'t Create User to end the process now and go back to the list of users.</p><p class="modalBody">Click Cancel to close this dialog and go back to adding the user.</p>';
    confirmConfig.Confirmed = () => {
      this.close();
    };
    this.notificationDialogService.ShowConfirmation(confirmConfig);
  }

  isCurrentFormValid(): boolean {
    const formName = this.steps[this.currentStep - 1].formName;
    if (formName) {
      const currentStep = this.steps[this.currentStep - 1];
      if (currentStep.ID === "PKG") {
        const userPackage = this.mainForm.controls["UserPackage"].value;
        if (
          userPackage &&
          userPackage.selectedPackage &&
          userPackage.selectedPackage.PackageID <= 0
        ) {
          return this.mainForm.get(formName).valid && !this.pendingValidation;
        }
      } else if (currentStep.ID === "LICENSE" && this.pendingLicenseCheck) {
        return false;
      }
      const isvalidF = this.mainForm.get(formName).valid;
      return isvalidF && !this.pendingValidation;
    } else {
      return true;
    }
  }

  pendingValidationChanged(value: boolean) {
    this.pendingValidation = value;
  }

  packagesLoaded(packages: PackageCardData[]) {
    this.packages = packages;
  }

  addonsLoaded(addons: PackageCardData[]) {
    this.addOns = addons;
  }

  packageSelected(pkg: PackageCardData) {
    this.selectedPackage = pkg;
    this.resetAddOns();
    // Stuff was removed from here that originally checked for an
    // assigned package, because now we have some add-ons that don't need a package
    const notAvailAddOns = this.addOns.filter((a) => {
      return (
        a.ExcludingPackageIds.filter((i) => {
          return i === this.selectedPackage.PackageID;
        }).length > 0 && a.AddOnAdded
      );
    });
    notAvailAddOns.forEach((a) => {
      if (a.AddOnAdded) {
        a.AddOnAdded = false;
        if (a.BuyNew) {
          this.cartService.removeFromCart(a);
          a.BuyNew = false;
          a.CurrentQuantity--;
        }
        a.CurrentUsed--;
      }
    });
    this.availableAddOns = this.getAvailableAddons(
      this.selectedPackage.PackageID < 0,
    );
  }

  getAvailableAddons(noPackageSelected: boolean): PackageCardData[] {
    if (noPackageSelected) {
      return this.addOns.filter((a) => {
        return a.NonHostedDesktop;
      });
    } else {
      return this.addOns.filter((a) => {
        return (
          a.ExcludingPackageIds.filter((i) => {
            return i === this.selectedPackage.PackageID;
          }).length === 0
        );
      });
    }
  }

  setUserLicenseValidators() {
    const versionControl = (this.mainForm.get("UserLicense") as FormGroup)
      .controls.qbVersion;
    const countryControl = (this.mainForm.get("UserLicense") as FormGroup)
      .controls.qbCountry;
    const editionControl = (this.mainForm.get("UserLicense") as FormGroup)
      .controls.qbEdition;
    const flavorControl = (this.mainForm.get("UserLicense") as FormGroup)
      .controls.qbFlavor;

    this.subscriptions.push(
      (
        this.mainForm.get("UserLicense") as FormGroup
      ).controls.isDeploy.valueChanges.subscribe((isDeploy) => {
        if (isDeploy == "true") {
          versionControl.setValidators([Validators.required.bind(Validators)]);
          countryControl.setValidators([Validators.required.bind(Validators)]);
          editionControl.setValidators([Validators.required.bind(Validators)]);
          flavorControl.setValidators([Validators.required.bind(Validators)]);
        } else {
          versionControl.setValidators(null);
          countryControl.setValidators(null);
          editionControl.setValidators(null);
          flavorControl.setValidators(null);
        }

        versionControl.updateValueAndValidity();
        countryControl.updateValueAndValidity();
        editionControl.updateValueAndValidity();
        flavorControl.updateValueAndValidity();
      }),
    );
  }

  createUser() {
    const userCreateControls = (this.mainForm.get("UserCreate") as FormGroup)
      .controls;
    const newUserPayload: RnUserCreate = {
      RNOrganizationID: this.OrganizationID,
      Email: userCreateControls.email.value,
      FirstName: userCreateControls.firstName.value,
      LastName: userCreateControls.lastName.value,
      SendWelcomeEmail: userCreateControls.sendWelcomeEmail.value,
      UserTypeID: userCreateControls.role.value.ID,
      XID: userCreateControls.xid.value,
    };

    const packageSelectControls = (
      this.mainForm.get("UserPackage") as FormGroup
    ).controls;
    const packagePayload: RnUserPackageAssign = {
      AffectedOrganizationId: this.orgProfile.IdThatPaysForThisOrg,
      AffectedUserId: -1,
      PackagePool_ID: packageSelectControls.selectedPackage.value.PackagePoolID,
      Package_ID: packageSelectControls.selectedPackage.value.PackageID,
      User_ID: -1,
    };

    const addonSelectControls = (this.mainForm.get("UserAddons") as FormGroup)
      .controls;
    const addonPayloads: Array<RnUserPackagePoolAction> | null = [];
    const addons = addonSelectControls.selectedAddons
      .value as PackageCardData[];
    if (addons) {
      addons.forEach((a) => {
        const action = new RnUserPackagePoolAction();

        action.AffectedOrganizationId = this.orgProfile.IdThatPaysForThisOrg;
        action.ToPackage_ID = a.PackageID;
        action.AtomicAction = this.getAtomicAction(a);
        action.KeepOldPackage = true;
        action.PromotionCodeID =
          this.validatedPromo &&
          this.validatedPromo.promoCode &&
          this.validatedPromo.promoCode?.PackageID == a.PackageID
            ? this.validatedPromo.promoCode?.ID
            : null;
        addonPayloads.push(action);
      });
    }

    const licensePayload: RnUserLicensesAssignUser = {
      AffectedOrganizationId: this.orgProfile.IdThatPaysForThisOrg,
      LicenseID: this.selectedLicense ? this.selectedLicense.LicenseID : 0,
      FlavorID: this.selectedLicense
        ? this.IsUsingWelcomeWizard
          ? this.selectedLicense.ItemFlavorId
          : this.selectedLicense.FlavorId
        : 0,
      UserIDs: [],
    };

    let promoID =
      this.validatedPromo && this.validatedPromo.promoCode
        ? this.validatedPromo.promoCode?.ID
        : null;

    if (!promoID) {
      promoID =
        this.cartService.promo && this.cartService.promo.promoCode
          ? this.cartService.promo.promoCode?.ID
          : null;
    }

    const fullPayload: RnUserCreateFull = {
      UserCreate: newUserPayload,
      UserPackage: packagePayload,
      UserLicense: licensePayload,
      UserAddons: addonPayloads || [],
      PromotionCodeID: promoID,
    };
    this.close(fullPayload);
  }

  close(result?: any): void {
    if (!this.IsUsingWelcomeWizard) {
      this.cartService.clearCart();
    } else if (this.IsUsingWelcomeWizard && !result) {
      // undo any cart additions for this user
      if (this.selectedPackage) {
        if (this.selectedPackage.BuyNew) {
          this.cartService.removeFromCart(this.selectedPackage);
        }

        const addonsToRemove = this.addOns.filter((a) => a.BuyNew);
        for (const addon of addonsToRemove) {
          this.cartService.removeFromCart(addon);
        }
      }
    }
    this.mdDialogRef.close(result);
  }

  private pendingValidation = false;

  public packages: PackageCardData[];
  public availableAddOns: PackageCardData[];

  private getAtomicAction(pkg: PackageCardData): string {
    if (pkg.PackagePoolID == -2) {
      return "nopackage";
    }
    // else, this is a new user so its simple. Either an add or a "reassign"
    if (!pkg.BuyNew) {
      return "reassign";
    } else {
      return "add";
    }
  }

  private canNavigateToStep(stepIdx: number): boolean {
    const step = this.steps[stepIdx];
    switch (step.ID) {
      case "USER":
        return true;
      case "PKG":
        return this.packages.length > 0;
      case "ADDON":
        return this.availableAddOns.length > 0;
      case "LICENSE": {
        const addonSelectControls = (
          this.mainForm.get("UserAddons") as FormGroup
        ).controls;
        const addOns = addonSelectControls.selectedAddons
          .value as PackageCardData[];
        return (
          this.selectedPackage &&
          this.selectedPackage.PackageID > 0 &&
          !this.noLicenses &&
          this.packagesService.isApplicableDeployQuickBooks(
            this.selectedPackage,
            this.licenses,
            addOns,
          )
        );
      }
      case "REVIEW":
        return true;
    }
  }

  private postNext(): void {
    const currStep = this.steps[this.currentStep - 1];
    if (currStep.ID === "PKG") {
      this.checkForInitialActiveMessage = true;
    }
  }

  pendingLicenseCheckEvent(value: boolean) {
    this.pendingLicenseCheck = value;
  }

  noLicenseEvent(value: boolean) {
    this.noLicenses = value;
  }

  licensesEvent(value: RnLicenseFullVM[]) {
    this.licenses = value;
  }

  selectedLicenseEvent(license: RnLicenseFullVM) {
    this.selectedLicense = license;
  }

  promoEvent(value: any) {
    this.validatedPromo = value;
  }
}
