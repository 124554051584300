/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnEventLogInfo } from "../models/./eventLogInfo-d";

export class RnUnmappedUsersSearchSort {
  PageNumber?: number | null;
  OrderByText?: string | null;
  IsDescending?: boolean;
  ConnectPointId?: number;
  IncludeOrgs?: boolean;
  IncludeSubOrgUsers?: boolean;
  Keyword?: string | null;
  OrganizationId?: number;
  PageSize?: number | null;
  EventLogInfo?: RnEventLogInfo;
  AffectedOrganizationId?: number;
  AffectedUserId?: number;
}
