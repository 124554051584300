import { Component, Input } from "@angular/core";
import { EnvService } from "../../../../core/services/env.service";

@Component({
  selector: "app-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"],
})
export class IconComponent {
  @Input() source: string;
  @Input() imgClass: string;
  @Input() useOriginalHeight = false;
  @Input() tooltip = "";
  @Input() tooltipsPosition: "top || bottom || right || left";
  @Input() tooltipStyleClass = "";
  assetUrl: string;

  constructor() {
    this.assetUrl = EnvService.EnvVariables().assetUrl;
  }

  unsetMaxHeight(): string {
    return this.useOriginalHeight ? "max-height: unset;" : "";
  }
}
