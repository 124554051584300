<div [class]="overlay">
  <div data-testid="loaderOverlay" class="overlay-content">
    <div
      [ngClass]="{ 'processing-container': scrollCenter }"
      class="loader-icon"
    >
      <app-icon
        source="rn-circle-notch"
        imgClass="loading-spinner royal-icon"
      ></app-icon>
    </div>
    <div class="loader-text">
      {{ text }}
    </div>
  </div>
</div>
