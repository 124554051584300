/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export class RnPackageSelectionInfoVM {
  ID?: number;
  Name?: string | null;
  SKU?: string | null;
  Slug?: string | null;
  Desc?: string | null;
  DescHtml?: string | null;
  ListPrice?: number;
  DiscountPrice?: number;
  DiscountPercent?: number;
  NextBillPrice?: number;
  PackageTypeID?: number;
  PackageCategoryID?: number;
  PackageType?: string | null;
  SortOrder?: number;
  IsOverrideable?: boolean;
  HasTerminalServices?: boolean;
  PackageCategoryName?: string | null;
  CatalogCategoryID?: number;
  CatalogCategoryName?: string | null;
  CatalogCategoryDesc?: string | null;
  CatalogCategoryHtmlDesc?: string | null;
  CatalogSortOrder?: number;
  CatalogVisibility?: string | null;
  CurrentQuantity?: number;
  CurrentUsed?: number;
  PackagePoolID?: number;
  ExcludingPackageIds?: Array<number> | null;
  RequiredPackagesIds?: Array<number> | null;
  ExcludingPackageNames?: Array<string> | null;
  RequiredPackagesNames?: Array<string> | null;
  UsesLicenses?: boolean;
  UnitTypeDesc?: string | null;
  ExternallyPurchasable?: boolean;
}
