import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { UserLoginState } from "../../../../core/models/user.login.state";
import { LoggedInInfoService } from "../../../services/loggedInInfo/logged-in-info.service";
import {
  LookupService,
  UserService,
} from "../../../services/rnapi2-service/apis/api";
import {
  RnCountryCodesVM,
  RnLUCountryCodesOrderByTypes,
  RnUserIsEmailUnique,
} from "../../../services/rnapi2-service/models/models";

@Component({
  selector: "app-user-update-profile",
  templateUrl: "./user-update-profile.component.html",
  styleUrls: ["./user-update-profile.component.scss"],
})
export class UserUpdateProfileComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Output() pendingValidationEvent = new EventEmitter<boolean>();
  @Input() showFieldIcons = true;
  @Input() largerCountryCode = false;
  public pendingvalidation = false;
  public countries: RnCountryCodesVM[];
  public rnUserIsEmailUnique: RnUserIsEmailUnique;
  public nonspecific = false;
  private subscriptions: Subscription[] = [];
  public loggedInUser: UserLoginState;
  public loadingCountries = true;

  constructor(
    private userService: UserService,
    private lookupService: LookupService,
    private loggedInInfoService: LoggedInInfoService,
  ) {}

  ngOnInit() {
    this.loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    this.loadCountries();
  }

  private loadCountries(): void {
    this.subscriptions.push(
      this.lookupService
        .apiV2LookupCountrycodesGet(
          RnLUCountryCodesOrderByTypes.OrderByName,
          null,
          0,
          0,
          false,
          "response",
        )
        .subscribe((r) => {
          this.countries = [];
          this.countries = this.countries.concat(r.body.data.Results);
          this.loadingCountries = false;
        }),
    );
  }

  get firstName() {
    return this.parentForm.get("firstName");
  }

  get lastName() {
    return this.parentForm.get("lastName");
  }

  get country() {
    return this.parentForm.get("country");
  }

  get areaCode() {
    return this.parentForm.get("areaCode");
  }

  get phoneNumber() {
    return this.parentForm.get("phoneNumber");
  }

  get emailAddress() {
    return this.parentForm.get("emailAddress");
  }

  public clearIfNeeded() {
    if (this.areaCode.value == "   ") {
      this.areaCode.setValue("");
    }
  }
}
