import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-switch",
  templateUrl: "./switch.component.html",
  styleUrls: ["./switch.component.scss"],
})
export class SwitchComponent implements OnInit {
  @Input() id = "";
  @Output() clickActionEmitter = new EventEmitter<MouseEvent>();

  @Input() parentForm: FormGroup;
  @Input() disabled: boolean;

  ngOnInit(): void {
    if (!this.id) throw new Error();
  }

  handleChange(event: MouseEvent) {
    this.clickActionEmitter.emit(event);
  }
}
