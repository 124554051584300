import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "listFormat",
})
export class ListFormatPipe implements PipeTransform {
  transform(list: string[]): string {
    if (!list) {
      return;
    }
    if (list.length == 1) return list[0];
    else if (list.length == 2) {
      return list[0] + " and " + list[1];
    } else if (list.length > 2) {
      const start = list.slice(0, list.length - 2);
      const end = list.slice(list.length - 2);
      return start.join() + ", " + end.join(", and ");
    }
  }
}
