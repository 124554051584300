import { ViewChild } from "@angular/core";
import { Input } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { EnvService } from "../../../../core/services/env.service";

@Component({
  selector: "app-rnpopover",
  templateUrl: "./rnpopover.component.html",
  styleUrls: ["./rnpopover.component.scss"],
})
export class RnPopoverComponent implements OnInit {
  @ViewChild("rnpopover") public rnpopover: NgbPopover;
  @Input() popoverText: string;
  @Input() clickIcon = "rn-info";
  assetUrl: string;
  clickIconSource: string;

  ngOnInit(): void {
    this.assetUrl = EnvService.EnvVariables().assetUrl;
    this.clickIconSource = `${this.assetUrl}/icons/${this.clickIcon}.svg`;
  }

  handleClose(): void {
    this.rnpopover.close();
  }

  popoverClass(): string {
    return this.popoverText?.length < 50
      ? "popover-content-sm"
      : "popover-content";
  }
}
