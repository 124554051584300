import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs";

@Component({
  selector: "app-user-update-pin",
  templateUrl: "./user-update-pin.component.html",
  styleUrls: ["./user-update-pin.component.scss"],
})
export class UserUpdatePinComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() loadNewUI? = false;
  @Output() accordionActionsHandler = new EventEmitter<any>();

  ngOnInit(): void {
    this.parentForm.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(() => {
        this.accordionActionsHandler.emit({
          actionName: "Validate tabs",
          isInputValid: this.parentForm.valid,
        });
      });
  }

  get supportPIN() {
    return this.parentForm.get("supportPIN");
  }

  proceedToNextTab() {
    this.accordionActionsHandler.emit({ index: 2 });
  }
}
