/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnNodePath } from "../models/./nodePath-d";

export class RnOrganizationTreeNode {
  name?: string | null;
  id?: number;
  orgId?: number;
  connectPointId?: number;
  type?: string | null;
  hasUsersMapped?: boolean;
  hasLicenses?: boolean;
  hasPackages?: boolean;
  hasUsers?: boolean;
  allowActions?: boolean;
  createDate?: string;
  path?: Array<RnNodePath> | null;
  nodes?: Array<RnOrganizationTreeNode> | null;
  status?: string | null;
}
