import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PreferencesService {
  public mainMenuExpandedObservable: Observable<boolean>;
  public orgMenuExpandedObservable: Observable<boolean>;
  public userMenuExpandedObservable: Observable<boolean>;
  constructor() {
    this.mainMenuExpandedObservable =
      this.mainMenuExpandedSubject.asObservable();
    this.orgMenuExpandedObservable = this.orgMenuExpandedSubject.asObservable();
    this.userMenuExpandedObservable =
      this.userMenuExpandedSubject.asObservable();
  }

  get mainMenuExpanded(): boolean {
    const currValueStr = this.getPreference(this.MAIN_MENU_EXPANDED);
    const currValue =
      currValueStr && currValueStr !== "" ? currValueStr === "true" : true;
    this.mainMenuExpandedSubject.next(currValue);
    return currValue;
  }

  set mainMenuExpanded(newValue: boolean) {
    const currValue = this.mainMenuExpandedSubject.getValue();
    this.setPreference(this.MAIN_MENU_EXPANDED, newValue ? "true" : "false");
    if (currValue != newValue) {
      this.mainMenuExpandedSubject.next(newValue);
    }
  }

  get orgMenuExpanded(): boolean {
    const currValueStr = this.getPreference(this.ORGANIZATION_MENU_EXPANDED);
    const currValue =
      currValueStr && currValueStr !== "" ? currValueStr === "true" : true;
    this.orgMenuExpandedSubject.next(currValue);
    return currValue;
  }

  set orgMenuExpanded(newValue: boolean) {
    const currValue = this.orgMenuExpandedSubject.getValue();
    this.setPreference(
      this.ORGANIZATION_MENU_EXPANDED,
      newValue ? "true" : "false",
    );
    if (currValue != newValue) {
      this.orgMenuExpandedSubject.next(newValue);
    }
  }

  get userMenuExpanded(): boolean {
    const currValueStr = this.getPreference(this.USER_MENU_EXPANDED);
    const currValue =
      currValueStr && currValueStr !== "" ? currValueStr === "true" : true;
    this.userMenuExpandedSubject.next(currValue);
    return currValue;
  }

  set userMenuExpanded(newValue: boolean) {
    const currValue = this.userMenuExpandedSubject.getValue();
    this.setPreference(this.USER_MENU_EXPANDED, newValue ? "true" : "false");
    if (currValue != newValue) {
      this.userMenuExpandedSubject.next(newValue);
    }
  }

  public refreshPreferences(): void {
    this.refreshMainMenuExpanded();
  }

  public refreshMainMenuExpanded(): void {
    let temp = this.mainMenuExpanded; //This will reset the vaule in the subject
    temp = this.orgMenuExpanded;
    temp = this.userMenuExpanded;
  }

  public clearAllPreferences(): void {
    this.mainMenuExpandedSubject.next(true);
    localStorage.removeItem(this.MAIN_MENU_EXPANDED);
    this.orgMenuExpandedSubject.next(true);
    localStorage.removeItem(this.ORGANIZATION_MENU_EXPANDED);
    this.userMenuExpandedSubject.next(true);
    localStorage.removeItem(this.USER_MENU_EXPANDED);
  }

  private getPreference(preferenceName: string): string {
    return localStorage.getItem(preferenceName);
  }

  private setPreference(preferenceName: string, newValue: string): void {
    localStorage.setItem(preferenceName, newValue);
  }

  private mainMenuExpandedSubject = new BehaviorSubject<boolean>(true);
  private orgMenuExpandedSubject = new BehaviorSubject<boolean>(true);
  private userMenuExpandedSubject = new BehaviorSubject<boolean>(true);

  private MAIN_MENU_EXPANDED = "mainMenuExpanded";
  private ORGANIZATION_MENU_EXPANDED = "orgMenuExpanded";
  private USER_MENU_EXPANDED = "userMenuExpanded";
}
