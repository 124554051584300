import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { OrgInfo } from "../../components/rn-business-components/org-lookup/org-lookup-data";

@Pipe({
  name: "rnOrgInfo",
})
export class RnOrgInfoPipe implements PipeTransform {
  transform(orgInfo: OrgInfo, ...args: unknown[]): unknown {
    if (orgInfo && args && args.length > 0) {
      const param = args[0].toString().toLowerCase();
      switch (param) {
        case "gettooltip": {
          const datepipe: DatePipe = new DatePipe("en-US");
          const formattedDate = datepipe.transform(
            orgInfo.CreatedDate,
            "MMM dd, yyyy",
          );
          let result = `<div style='font-size: 21px; font-weight:700;'>${orgInfo.Name}</div>`;
          result += `<div><span style='font-size: 13px; font-weight:700;'>Account Owner: </span><span style='font-size: 13px;'>${orgInfo.AccountOwner}</span></div>`;
          result += `<div><span style='font-size: 13px; font-weight:700;'>Customer Since: </span><span style='font-size: 13px;'>${formattedDate}</span></div>`;

          return result;
        }
      }
    }
    return null;
  }
}
