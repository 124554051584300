import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NotificationData } from "../notification-data";

@Component({
  selector: "app-notification-dialog",
  templateUrl: "./notification-dialog.component.html",
  styleUrls: ["./notification-dialog.component.scss"],
})
export class NotificationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NotificationData,
    private mdDialogRef: MatDialogRef<NotificationDialogComponent>,
  ) {}

  public close() {
    this.mdDialogRef.close();
  }
}
