import { TableSelectable } from "../../../../core/interfaces/table-selectable";
import { RnUserWithMutualExclusionForAssignPackageVM } from "../../../services/rnapi2-service/models/models";

export class UserPackageAssignmentItem
  extends RnUserWithMutualExclusionForAssignPackageVM
  implements TableSelectable
{
  public RnTableSelected: boolean;

  public constructor(user: RnUserWithMutualExclusionForAssignPackageVM) {
    super();
    this.AddOns = user.AddOns;
    this.AssignedPackage = user.AssignedPackage;
    this.AssignedPackageID = user.AssignedPackageID;
    this.DirectBilledPackages = user.DirectBilledPackages;
    this.ExcludingPackageIds = user.ExcludingPackageIds;
    this.ExcludingPackageNames = user.ExcludingPackageNames;
    this.ExclusionType = user.ExclusionType;
    this.FullName = user.FullName;
    this.HasTerminalServices = user.HasTerminalServices;
    this.IsOverrideable = user.IsOverrideable;
    this.LastNameFirstName = user.LastNameFirstName;
    this.MappedDrives = user.MappedDrives;
    this.OrganizationID = user.OrganizationID;
    this.OrganizationName = user.OrganizationName;
    this.RequiredPackagesIds = user.RequiredPackagesIds;
    this.RequiredPackagesNames = user.RequiredPackagesNames;
    this.RnTableSelected = false;
    this.UserEmail = user.UserEmail;
    this.UserID = user.UserID;
  }
}
