import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CreateRenameFolderConfig } from "../../components/rn-business-components/create-rename-folder/create-rename-folder-config";
import { CreateRenameFolderComponent } from "../../components/rn-business-components/create-rename-folder/create-rename-folder.component";
import { DownloadSetupWizardComponent } from "../../components/rn-business-components/download-setup-wizard/download-setup-wizard.component";
import { GenericDialogConfiguration } from "../../components/rn-business-components/generic-dialog/generic-dialog-configuration";
import { GenericDialogComponent } from "../../components/rn-business-components/generic-dialog/generic-dialog.component";
import { OrgRenameUserLoggedInConfig } from "../../components/rn-business-components/rename-error/rename-error-config";
import { NotificationData } from "./notification-data";
import { NotificationDialogComponent } from "./notification-dialog/notification-dialog.component";
import { OrgRenameUserLoggedInDialogNotificationComponent } from "./rename-error/rename-error.component";
import { NoopScrollStrategy } from "@angular/cdk/overlay";
import { InstallableAppComponent } from "../../components/rn-business-components/installable-app/installable-app.component";
import { InstallableAppConfiguration } from "../../components/rn-business-components/installable-app/installable-app-configuration";

@Injectable({
  providedIn: "root",
})
export class NotificationDialogService {
  constructor(private dialog: MatDialog) {}

  public ShowNotification(headerText: string, notificationText: string): void {
    const data = new NotificationData();
    data.Header = headerText;
    data.Notification = notificationText;

    const config = {
      header: "",
      width: "600px",
      height: "auto",
      "z-index": 2000,
      closable: false,
      showHeader: false,
      disableClose: true,
      data: data,
    };
    this.dialog.open(NotificationDialogComponent, config);
  }

  public ShowConfirmation(data: GenericDialogConfiguration, meta?: any) {
    const config = {
      header: "",
      width: "512px",
      height: "auto",
      "z-index": 2000,
      closable: false,
      showHeader: false,
      disableClose: true,
      closeOnNavigation: data.CloseOnNavigation ?? true,
      data: data,
      metadata: meta,
      scrollStrategy: new NoopScrollStrategy(),
    };
    this.dialog.open(GenericDialogComponent, config);
  }

  public ShowInstallableConfirmation(data: InstallableAppConfiguration) {
    const config = {
      header: "",
      width: "512px",
      height: "auto",
      "z-index": 2000,
      closable: false,
      showHeader: false,
      disableClose: true,
      closeOnNavigation: data.CloseOnNavigation ?? true,
      data: data,
      scrollStrategy: new NoopScrollStrategy(),
    };
    this.dialog.open(InstallableAppComponent, config);
  }

  public CreateRenameFolderDialog(data: CreateRenameFolderConfig) {
    const config = {
      header: "",
      width: "512px",
      height: "auto",
      "z-index": 2000,
      closable: false,
      showHeader: false,
      disableClose: true,
      data: data,
    };
    this.dialog.open(CreateRenameFolderComponent, config);
  }

  public ShowRenameFolderError(data: OrgRenameUserLoggedInConfig): void {
    const config = {
      header: "",
      width: "30%",
      height: "auto",
      "z-index": 9999,
      closable: false,
      showHeader: false,
      disableClose: true,
      data: data,
    };
    this.dialog.open(OrgRenameUserLoggedInDialogNotificationComponent, config);
  }

  public ShowDownloadSetupWizard(): void {
    const config = {
      header: "",
      width: "50%",
      contentStyle: { "max-height": "700px", overflow: "auto" },
      closable: false,
      showHeader: false,
      disableClose: true,
      "z-index": 10000,
    };
    this.dialog.open(DownloadSetupWizardComponent, config);
  }

  public CloseAllDialogs(): void {
    this.dialog.closeAll();
  }
}
