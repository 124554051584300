/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnUserCreate } from "../models/./userCreate-d";
import { RnUserPackageAssign } from "../models/./userPackageAssign-d";
import { RnEventLogInfo } from "../models/./eventLogInfo-d";
import { RnUserLicensesAssignUser } from "../models/./userLicensesAssignUser-d";
import { RnUserPackagePoolAction } from "../models/./userPackagePoolAction-d";

export class RnUserCreateFull {
  UserCreate?: RnUserCreate;
  UserPackage?: RnUserPackageAssign;
  UserLicense?: RnUserLicensesAssignUser;
  UserAddons?: Array<RnUserPackagePoolAction> | null;
  PromotionCodeID: number;
  EventLogInfo?: RnEventLogInfo;
  AffectedOrganizationId?: number;
  AffectedUserId?: number;
}
