/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnRNLicenseItem } from "../models/./rNLicenseItem-d";
import { RnRNOrderHeader } from "../models/./rNOrderHeader-d";
import { RnEventLogInfo } from "../models/./eventLogInfo-d";
import { RnRNOrderItem } from "../models/./rNOrderItem-d";

export class RnRNAddOnPayload {
  Header: RnRNOrderHeader;
  OrganizationID: number;
  PaymentOnFileID: number;
  OrderItems: Array<RnRNOrderItem>;
  QbesLicenseItems: Array<RnRNLicenseItem> | null;
  PromotionCodeID: number;
  ForceSkipEmail: boolean;
  EventLogInfo?: RnEventLogInfo;
  AffectedOrganizationId?: number;
  AffectedUserId?: number;
}
