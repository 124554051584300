<ng-container [formGroup]="parentForm">
  <div class="row">
    <div class="col-12">
      <div
        class="p-field"
        [ngClass]="{
          'has-error': !nameOnCard?.valid && (submitted || nameOnCard?.touched)
        }"
      >
        <label for="nameOnCard">Name on Card</label><br />
        <input
          appTrimInput
          class="form-control"
          matInput
          id="nameOnCard"
          name="nameOnCard"
          formControlName="nameOnCard"
          type="text"
          placeholder="Name on Card"
          maxlength="50"
          (focus)="$event.target.select()"
          data-testid="billinginfonameoncard"
        />
        <div *ngIf="submitted || nameOnCard?.touched">
          <div
            class="help-block pt-1"
            *ngIf="nameOnCard.errors?.required || nameOnCard.errors?.whitespace"
          >
            Required field
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div
        class="p-field"
        [ngClass]="{
          'has-error': !cardNumber?.valid && (submitted || cardNumber?.touched)
        }"
      >
        <label for="cardNumber">Card Number</label>
        <br />
        <input
          class="form-control"
          matInput
          [mask]="cardMask"
          id="cardNumber"
          name="cardNumber"
          formControlName="cardNumber"
          type="tel"
          autocomplete="cc-number"
          placeholder="xxxx xxxx xxxx xxxx"
          (onBlur)="onBlurCreditCardNumber(cardNumber)"
          (focus)="$event.target.select()"
          data-testid="billinginfocreditcardnumberinput"
        />
        <span class="visa-icon"><i class="{{ cardIcon }}"></i></span>

        <div *ngIf="submitted || cardNumber?.touched">
          <div
            class="help-block pt-1"
            *ngIf="cardNumber.errors?.ccInvalid || cardNumber.errors?.required"
          >
            Invalid Card Number
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div
        class="p-field"
        [ngClass]="{
          'has-error':
            (!mmExpirationDate?.valid || !validMonth) &&
            (submitted || mmExpirationDate?.touched)
        }"
      >
        <label for="expirationDate">Expiration Date</label><br />
        <input
          id="mmExpirationDate"
          name="mmExpirationDate"
          formControlName="mmExpirationDate"
          type="number"
          minlength="1"
          maxlength="2"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          (blur)="onBlurDate()"
          placeholder="MM"
          pInputText
          class="form-control"
          (focus)="$event.target.select()"
          data-testid="billinginfoexpirationdatemonthinput"
        />
        <div *ngIf="submitted || mmExpirationDate?.touched">
          <div
            class="help-block pt-1"
            *ngIf="
              mmExpirationDate.errors?.required ||
              mmExpirationDate.errors?.whitespace
            "
          >
            Required field
          </div>
          <div class="help-block pt-1" *ngIf="!validMonth">
            Invalid Expiration Month
          </div>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div
        class="p-field"
        [ngClass]="{
          'has-error':
            (!yyyyExpirationDate?.valid || !validYear) &&
            (submitted || yyyyExpirationDate?.touched)
        }"
      >
        <label for="expirationDate">&nbsp;</label><br />
        <input
          id="yyyyExpirationDate"
          name="yyyyExpirationDate"
          formControlName="yyyyExpirationDate"
          type="number"
          minlength="4"
          maxlength="4"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          (blur)="onBlurDate()"
          placeholder="YYYY"
          pInputText
          class="form-control"
          (focus)="$event.target.select()"
          data-testid="billinginfoexpirationdateyearinput"
        />
        <div *ngIf="submitted || yyyyExpirationDate?.touched">
          <div
            class="help-block pt-1"
            *ngIf="
              yyyyExpirationDate.errors?.required ||
              yyyyExpirationDate.errors?.whitespace
            "
          >
            Required field
          </div>
          <div class="help-block pt-1" *ngIf="!validYear">
            Invalid Expiration Year
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div
        class="p-field"
        [ngClass]="{ 'has-error': !CVV?.valid && (submitted || CVV?.touched) }"
      >
        <label for="CVV">CVV</label><br />
        <input
          id="CVV"
          name="CVV"
          formControlName="CVV"
          type="text"
          pInputText
          [minlength]="cvvminlength"
          [maxlength]="cvvmaxlength"
          pattern="[0-9]{3,4}"
          [placeholder]="cvvplaceholder"
          class="form-control"
          (focus)="$event.target.select()"
          data-testid="billinginfocvvinput"
        />
        <div *ngIf="submitted || CVV?.touched">
          <div
            class="help-block pt-1"
            *ngIf="CVV.errors?.required || CVV.errors?.whitespace"
          >
            Required field
          </div>
          <div
            class="help-block pt-1"
            *ngIf="
              CVV.errors?.pattern ||
              CVV.errors?.minlength ||
              CVV.errors?.maxlength
            "
          >
            Invalid CVV
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
