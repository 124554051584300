/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export class RnUserLogin {
  Email?: string | null;
  Password?: string | null;
  IPAddress?: string | null;
  BaseApiUrl?: string | null;
  LoginApp?: string | null;
  UseAdvancedLogin?: boolean;
}
