/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnPingVM } from "../models/./pingVM-d";

export class RnPingResponseVM {
  readonly Type?: string | null;
  Version?: string | null;
  BuildTime?: string;
  ServiceUpTime?: string | null;
  RedQueenConnection?: string | null;
  RedQueenVersion?: string | null;
  DSLPingReturn?: RnPingVM;
  ISPingReturn?: RnPingVM;
  PSPingReturn?: RnPingVM;
  PSPingReturn_EAST?: RnPingVM;
  PSPingReturn_WEST?: RnPingVM;
  PSLRPingReturn?: RnPingVM;
}
