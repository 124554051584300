import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "rnText",
})
export class RnTextPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (args && args.length > 0) {
      const textVal = value.toString();
      const param = args[0].toString().toLowerCase();
      switch (param) {
        case "nomatchregexnotequal": {
          const regx = args[1] as RegExp;
          const matches = textVal.match(regx);
          if (!matches) {
            const trimmed = textVal.trim();
            const compareStr = args[2].toString().toLowerCase();
            return textVal.toLowerCase() !== compareStr && trimmed.length > 0;
          }
          return false;
        }
        case "formatlowercasenospace": {
          return textVal.replace(/\s+/g, "").toLowerCase();
        }
      }
    }
    return null;
  }
}
