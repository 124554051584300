import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { OrganizationService } from "../rnapi2-service/apis/api";
import {
  RnOrganizationConfigurationVM,
  RnOrganizationTreeNode,
  RnOrganizationWLPContactInformationVM,
  RnOrganizationsProfileVM,
  RnOrganizationPaymentOnFileVM,
  RnCommonId,
  RnOrganizationPaymentOnFileVMRNResponseRelay,
  RnOrganizationFoldersVM,
} from "../rnapi2-service/models/models";

@Injectable({
  providedIn: "root",
})
export class SelectionService {
  public SelectedTopLevelOrg: Observable<RnOrganizationsProfileVM>;
  public SelectedOrgProfile: Observable<RnOrganizationsProfileVM>;
  public SelectedOrgTree: Observable<RnOrganizationTreeNode[]>;
  public SelectedOrgConfig: Observable<RnOrganizationConfigurationVM>;
  public SelectedOrgWLPConfig: Observable<RnOrganizationWLPContactInformationVM>;
  public SelectedFolder: Observable<RnOrganizationFoldersVM>;
  public OpenMatSlideBar: Observable<boolean>;
  public allowActions = true;

  public removeOrgFiredSubject = new BehaviorSubject<{ orgID: number }>({
    orgID: undefined,
  });

  constructor(private orgService: OrganizationService) {
    this.SelectedTopLevelOrg = this.SelectedTopLevelOrgSubject.asObservable();
    this.SelectedOrgProfile = this.SelectedOrgProfileSubject.asObservable();
    this.SelectedOrgTree = this.SelectedOrgTreeSubject.asObservable();
    this.SelectedOrgConfig = this.SelectedOrgConfigSubject.asObservable();
    this.SelectedOrgWLPConfig = this.SelectedOrgWLPConfigSubject.asObservable();
    this.SelectedFolder = this.SelectedFolderSubject.asObservable();
    this.OpenMatSlideBar = this.OpenMatSlideBarSubject.asObservable();
  }

  public selectedTopLevelOrgChange(
    topLevelOrg: RnOrganizationsProfileVM,
    orgTree: RnOrganizationTreeNode[],
    orgConfig: RnOrganizationConfigurationVM,
  ): void {
    this.SelectedTopLevelOrgSubject.next(topLevelOrg);
    this.SelectedOrgProfileSubject.next(topLevelOrg);
    if (orgTree) this.SelectedOrgTreeSubject.next(orgTree);
    this.SelectedOrgConfigSubject.next(orgConfig);
  }

  public onLogout() {
    this.SelectedTopLevelOrgSubject.next(null);
    this.SelectedOrgProfileSubject.next(null);
    this.SelectedOrgTreeSubject.next(null);
    this.SelectedOrgConfigSubject.next(null);
  }

  public selectedOrgChange(
    orgProfile: RnOrganizationsProfileVM,
    orgConfig: RnOrganizationConfigurationVM,
  ): void {
    this.SelectedOrgProfileSubject.next(orgProfile);
    this.SelectedOrgConfigSubject.next(orgConfig);
    this.SelectedFolderSubject.next(null);
  }

  public selectedFolderChange(folder: RnOrganizationFoldersVM): void {
    this.SelectedOrgProfileSubject.next(null);
    this.SelectedOrgConfigSubject.next(null);
    this.SelectedFolderSubject.next(folder);
  }

  public selectedOrgWLPConfigChange(
    orgWlpConfig: RnOrganizationWLPContactInformationVM,
  ): void {
    this.SelectedOrgWLPConfigSubject.next(orgWlpConfig);
  }

  public selectedOrgModified(): void {
    const current = this.SelectedOrgProfileSubject.value;
    this.SelectedOrgProfileSubject.next(current);
  }

  public selectedOrgTreeRefresh(orgTree: RnOrganizationTreeNode[]) {
    this.SelectedOrgTreeSubject.next(orgTree);
  }

  public openSlideBarPanel(value: boolean) {
    this.OpenMatSlideBarSubject.next(value);
  }

  public GetSelectedOrgPaymentInfo(): Observable<RnOrganizationPaymentOnFileVM> {
    const currentPOF = this.SelectedOrgPaymentOnFileSubject.value;
    const currentOrg = this.SelectedOrgProfileSubject.value;
    if (currentPOF && currentOrg) {
      return of(currentPOF);
    }

    if (currentOrg) {
      const payload = new RnCommonId();
      payload.Id = currentOrg.ID.toString();
      return this.orgService
        .apiV2OrganizationsCurrentpaymentinfoPost(payload, "response")
        .pipe(
          switchMap(
            (
              response: HttpResponse<RnOrganizationPaymentOnFileVMRNResponseRelay>,
            ) => {
              return of(response.body.data);
            },
          ),
        );
    }

    return of(null);
  }

  public getSelectedTopLevelOrg(): RnOrganizationsProfileVM {
    return this.SelectedTopLevelOrgSubject.value;
  }

  public getSelectedOrg(): RnOrganizationsProfileVM {
    return this.SelectedOrgProfileSubject.value;
  }

  public getUserCount(): Observable<number> {
    return this.userCountSubject.asObservable();
  }

  public updateUserCount(count: number): void {
    this.userCountSubject.next(count);
  }

  private SelectedTopLevelOrgSubject =
    new BehaviorSubject<RnOrganizationsProfileVM>(null);
  private SelectedOrgProfileSubject =
    new BehaviorSubject<RnOrganizationsProfileVM>(null);
  private SelectedOrgTreeSubject = new BehaviorSubject<
    RnOrganizationTreeNode[]
  >([]);
  private SelectedOrgConfigSubject =
    new BehaviorSubject<RnOrganizationConfigurationVM>(null);
  private SelectedOrgWLPConfigSubject =
    new BehaviorSubject<RnOrganizationWLPContactInformationVM>(null);
  private SelectedOrgPaymentOnFileSubject =
    new BehaviorSubject<RnOrganizationPaymentOnFileVM>(null);
  private SelectedFolderSubject = new BehaviorSubject<RnOrganizationFoldersVM>(
    null,
  );
  private OpenMatSlideBarSubject = new BehaviorSubject<boolean>(false);
  private userCountSubject = new BehaviorSubject<number>(0);
}
