import { Pipe, PipeTransform } from "@angular/core";
import { IdleStateTypes } from "../../idle/idle-state-types.enum";

@Pipe({
  name: "rnIdleService",
})
export class RnIdleServicePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (args && args.length > 0) {
      const param = args[0].toString().toLowerCase();
      switch (param) {
        case "iswarningmode": {
          const state = value as IdleStateTypes;
          return this.isWarningMode(state);
        }
        case "title": {
          const state = value as IdleStateTypes;
          return this.isWarningMode(state)
            ? "You Appear To Be Inactive."
            : "Your Session Has Expired.";
        }
      }
    }
    return null;
  }

  isWarningMode(state: IdleStateTypes): boolean {
    return state === IdleStateTypes.warning;
  }
}
