import { EventEmitter, Input, Output } from "@angular/core";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit {
  @Input() id = "";
  @Input() label = "";
  @Input() forwardArrowShow = false;
  @Input() backwardArrow = false;
  @Input() addSymbol = false;
  @Input() disabled = false;
  @Input() processing = false;
  @Input() showProcessing = false;
  @Input() showIcon = false;
  @Input() iconClass = "";
  @Input() imgSource = "";
  @Input() imgClass = "";
  @Input() buttonStyle = "";
  @Input() tooltip = "";
  @Input() tooltipsPosition: "top || bottom || right || left";
  @Input() tooltipStyleClass = "";
  @Input() tooltipDisabled = false;
  @Input() tooltipAppendTo = "body";
  @Input() additionalContent = "";
  @Input() additionalContentClass = "";
  @Input() additionalIconSource = "";
  @Input() width = "";
  @Input() isSubmitBtn = false;
  @Input() noContentPadding = false;
  @Input() isImageBackward = false;
  @Output() clickActionEmitter = new EventEmitter<MouseEvent>();
  @Output() blurEmitter = new EventEmitter<FocusEvent>();

  onBlur(event: FocusEvent) {
    this.blurEmitter.emit(event);
  }

  buttonType = "button";
  ngOnInit(): void {
    if (!this.id) throw new Error();
    this.buttonType = this.isSubmitBtn ? "submit" : "button";
  }

  handleClick(event: MouseEvent) {
    this.clickActionEmitter.emit(event);
  }
}
