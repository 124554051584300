<div class="confirmation" tabindex="-1">
  <div class="modal-header no-border">
    <h3 data-testid="folderPopupHeader" class="modal-header-text m-b-1rem">
      {{ dialogTitle }}
    </h3>
  </div>
  <div class="modal-body modal-content">
    <form #containerForm="ngForm" name="containerForm" novalidate>
      <fieldset>
        <div
          class="form-group"
          [ngClass]="{
            'has-error':
              ((folderNameField?.errors?.required &&
                (containerForm.submitted || folderNameField?.touched)) ||
                uniquefoldername ||
                (containerForm.invalid &&
                  !folderNameField?.errors?.required)) &&
              !folderNameField?.errors?.pendingvalidation,
            'has-pending-validation': folderNameField?.errors?.pendingvalidation
          }"
        >
          <div class="p-field">
            <label for="folderName" class="folder_name">Folder Name</label>
            <input
              #folderNameFocus
              id="folderName"
              #folderNameField="ngModel"
              data-testid="folderName"
              type="text"
              autocomplete="off"
              maxlength="64"
              pattern="((?![\x22<>:/\|\\\?\*,&\t]).)*$"
              minlength="1"
              name="folderName"
              class="form-control"
              [(ngModel)]="editfolderName"
              required
              placeholder="Folder Name"
              (keyup)="onFolderNameRename()"
              (paste)="onFolderNameRename()"
              pInputText
            />
            <div>
              <div
                data-testid="nameReqiredMsg"
                class="help-block"
                *ngIf="
                  folderNameField?.errors?.required &&
                  (containerForm.submitted || folderNameField?.touched) &&
                  !folderNameField?.errors?.pendingvalidation
                "
              >
                Required field
              </div>
              <div
                data-testid="nameLengthErr"
                class="help-block"
                *ngIf="
                  folderNameField?.errors?.maxlength &&
                  !folderNameField?.errors?.pendingvalidation
                "
              >
                Folder name is too long (must be less than 64 characters)
              </div>
              <div
                data-testid="specialCharErr"
                class="help-block"
                *ngIf="
                  folderNameField?.errors?.pattern &&
                  !folderNameField?.errors?.pendingvalidation
                "
              >
                Folder name cannot contain the following special characters:
                tab, < > " : / \ | ? * , &
              </div>
              <div
                data-testid="nameExistsErr"
                class="help-block"
                *ngIf="
                  uniquefoldername &&
                  !folderNameField?.errors?.pendingvalidation
                "
              >
                Folder with this name already exists at this level
              </div>
              <div
                class="help-block"
                *ngIf="folderNameField?.errors?.pendingvalidation"
              >
                <span data-testid="checkingNameMsg"
                  >Checking if folder name is unique... </span
                ><app-icon
                  data-testid="whiteLoadingSpinner"
                  source="rn-circle-notch"
                  imgClass="loading-spinner white-icon "
                ></app-icon>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
    <div *ngIf="dialogConfig.isEdit">
      <strong>Note:</strong> Updating the folder
      <strong>{{ dialogConfig.currentName }}</strong> will lock organizations
      that have users with access to this folder until the renaming process has
      completed.
    </div>
  </div>
  <div class="modal-footer">
    <app-button
      id="cancelButton"
      label="CANCEL"
      [disabled]="working"
      (clickActionEmitter)="!working && close()"
      buttonStyle="addUserTeritary"
    >
    </app-button>
    <app-button
      id="submitBtn"
      label="{{ submitButtonText }}"
      [disabled]="!containerForm.valid || submitDisabled"
      (clickActionEmitter)="submit()"
      [processing]="working"
      showProcessing="true"
      buttonStyle="primary"
    >
    </app-button>
  </div>
</div>
