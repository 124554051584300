import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import {
  RnFolderSearch,
  RnObjectRNResponseRelay,
  RnOrganizationContainerCreate,
  RnOrganizationFolderRename,
  RnRNOrderHeader,
  RnRNOrgContainer,
  RnUsersVM,
} from "../../../services/rnapi2-service/models/models";
import { OrganizationService } from "../../../services/rnapi2-service/apis/api";
import { CreateRenameFolderConfig } from "./create-rename-folder-config";
import { HttpErrorResponse } from "@angular/common/http";
import { RnErrorHandlerService } from "../../../services/rn-error-handler/rn-error-handler.service";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { NotificationDialogService } from "../../../services/notificationDialog/notification-dialog.service";
import { OrgRenameUserLoggedInConfig } from "../rename-error/rename-error-config";
import { DialogTrackingService } from "../../../services/dialog/dialog-tracking.service";

@Component({
  selector: "app-create-rename-folder",
  templateUrl: "./create-rename-folder.component.html",
  styleUrls: ["./create-rename-folder.component.scss"],
})
export class CreateRenameFolderComponent implements OnInit, AfterViewInit {
  @ViewChild("containerForm") public userFrm: NgForm;
  @ViewChild("folderNameFocus")
  emailInputElement!: ElementRef<HTMLInputElement>;

  public editfolderName: string;

  public dialogTitle: string;

  public working = false;

  submitButtonText: string;

  uniquefoldername = false;

  submitDisabled = false;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private orgService: OrganizationService,
    private rnErrorHandlerService: RnErrorHandlerService,
    private toastService: RnToastService,
    private notificationDialogService: NotificationDialogService,
    private dialogTrackService: DialogTrackingService,
  ) {}

  ngOnInit(): void {
    this.searchTextSubject = new Subject<string>();
    this.subscriptions.push(
      this.searchTextSubject
        .pipe(debounceTime(1000), distinctUntilChanged())
        .subscribe((inputText) => {
          const payload = new RnFolderSearch();
          if (this.dialogConfig.isEdit) {
            payload.ParentFolderId = -1;
            payload.Id = this.dialogConfig.ParentFolderID.toString();
          } else {
            payload.ParentFolderId = this.dialogConfig.ParentFolderID;
            payload.Id = "-1";
          }
          payload.search = inputText.trim();
          this.lookupNameValue = payload.search;
          this.orgService
            .apiV2OrganizationsIsfoldernameuniquePost(payload, "response")
            .subscribe((r) => {
              if (r.body.Success && !r.body.data) {
                this.userFrm.controls["folderName"].setErrors({
                  uniquefoldername: true,
                });
                this.uniquefoldername = true;
              } else {
                this.setNoErrorWithCheck();
                this.uniquefoldername = false;
              }
              this.setSubmitDisabled();
            });
        }),
    );

    this.dialogConfig = this.config.data as CreateRenameFolderConfig;
    this.dialogTitle = this.dialogConfig.isEdit
      ? "Rename Folder"
      : "Add New Folder";
    this.submitButtonText = this.dialogConfig.isEdit
      ? "RENAME FOLDER"
      : "ADD FOLDER";
    if (this.dialogConfig.isEdit) {
      this.editfolderName = this.dialogConfig.currentName;
    }
    this.setSubmitDisabled();
  }

  ngAfterViewInit(): void {
    this.emailInputElement.nativeElement.focus();
  }

  onFolderNameRename() {
    this.setSubmitDisabled();
    if (this.emptyAfterWhitespaceRemoval()) {
      return;
    }

    const folderNameField = this.userFrm.controls["folderName"];
    if (folderNameField.valid) {
      const valToCheck = folderNameField.value.toString().trim();
      if (
        folderNameField.valid &&
        valToCheck !== "" &&
        valToCheck !== this.lookupNameValue &&
        (!this.dialogConfig.isEdit ||
          valToCheck !== this.dialogConfig.currentName)
      ) {
        this.userFrm.controls["folderName"].setErrors({
          pendingvalidation: true,
        });
        this.searchTextSubject.next(valToCheck);
      }
    }
  }

  submit() {
    if (this.dialogConfig.isEdit) {
      this.submitEditFolderName();
    } else {
      this.createOrganizationContainer();
    }
  }

  setSubmitDisabled(): void {
    this.submitDisabled =
      this.working ||
      this.notEdited() ||
      this.emptyAfterWhitespaceRemoval() ||
      this.uniquefoldername;
  }
  createOrganizationContainer() {
    this.working = true;
    this.setSubmitDisabled();
    const payload = new RnOrganizationContainerCreate();
    payload.Header = new RnRNOrderHeader();
    payload.Header.Order_XID = "-1";
    payload.Header.Order_Source = "AppHub";
    payload.Header.Order_Date = new Date().toUTCString();
    payload.Header.SalesAgent_TAG = this.dialogConfig.loggedInUserID.toString();

    payload.OrgContainer = new RnRNOrgContainer();
    payload.OrgContainer.ParentFolderID = this.dialogConfig.ParentFolderID;
    payload.OrgContainer.Parent_ID = this.dialogConfig.ParentID;
    payload.OrgContainer.Display_Name = this.editfolderName.trim();
    this.subscriptions.push(
      this.orgService
        .apiV2OrganizationsCreateorgcontainerPost(payload, "response")
        .subscribe(
          (r) => {
            if (r.body.Success) {
              setTimeout(() => {
                this.dialogTrackService.closeDialog(
                  this.ref,
                  this.editfolderName,
                );
              }, 3000);
            }
          },
          (error) => {
            this.toastService.showError(
              "Cannot create a new folder at this time",
            );
            this.rnErrorHandlerService.handleError("", error.Messages);
            this.working = false;
          },
        ),
    );
  }

  submitEditFolderName() {
    this.working = true;
    const payload = new RnOrganizationFolderRename();
    payload.AffectedOrganizationId = this.dialogConfig.ParentID;
    payload.OrganizationID = this.dialogConfig.ParentID;
    payload.FolderID = this.dialogConfig.ParentFolderID;
    payload.UpdatedName = this.editfolderName.trim();

    this.subscriptions.push(
      this.orgService
        .apiV2OrganizationsFolderRenamePost(payload, "response")
        .subscribe(
          (r) => {
            if (r.body.Success) {
              setTimeout(() => {
                this.dialogTrackService.closeDialog(this.ref, true);
                window.location.reload();
              }, 3000);
            }
          },
          (error) => {
            const errResp = error as HttpErrorResponse;
            if (errResp) {
              if (errResp.error) {
                const rnErr = errResp.error as RnObjectRNResponseRelay;
                if (rnErr) {
                  if (rnErr.data) {
                    const loggedInUsers = rnErr.data as Array<RnUsersVM>;
                    if (loggedInUsers && loggedInUsers.length > 0) {
                      const errorMsgConfig = new OrgRenameUserLoggedInConfig();
                      errorMsgConfig.type = "folder";
                      errorMsgConfig.users = loggedInUsers;
                      this.notificationDialogService.ShowRenameFolderError(
                        errorMsgConfig,
                      );
                      this.working = false;
                      return;
                    }
                  }
                  this.rnErrorHandlerService.handleError(
                    "An error occurred attempting to change the top level folder name.",
                    rnErr.Messages,
                  );
                }
              }
            }
            this.toastService.showError(
              "There was an error renaming the folder.",
            );
            this.working = false;
          },
        ),
    );
  }

  close() {
    this.dialogTrackService.closeDialog(this.ref, false);
  }

  notEdited(): boolean {
    return (
      this.dialogConfig.isEdit &&
      this.editfolderName.trim() === this.dialogConfig.currentName
    );
  }

  emptyAfterWhitespaceRemoval(): boolean {
    const trimmedData = this.editfolderName?.trim();
    if (trimmedData?.length === 0) {
      this.userFrm?.controls["folderName"]?.setErrors({ required: true });
      return true;
    } else {
      this.setNoErrorWithCheck();
      return false;
    }
  }

  private setNoErrorWithCheck() {
    const requiredError =
      this.userFrm?.controls["folderName"]?.getError("required");
    const patternError =
      this.userFrm?.controls["folderName"]?.getError("pattern");
    const maxLengthError =
      this.userFrm?.controls["folderName"]?.getError("maxlength");
    if (!patternError && !requiredError && !maxLengthError) {
      this.userFrm?.controls["folderName"]?.setErrors(null);
    }
  }

  getCurrentFolderName(): string {
    return this.dialogConfig.currentName;
  }

  public isEdit(): boolean {
    return this.dialogConfig.isEdit;
  }

  private subscriptions: Subscription[] = [];
  public dialogConfig: CreateRenameFolderConfig;
  private searchTextSubject: Subject<string>;
  private lookupNameValue: string;
}
