import { Pipe, PipeTransform } from "@angular/core";
import { PackageGroup } from "../../components/rn-business-components/change-package-inventory/package-group";

@Pipe({
  name: "rnPackageGroup",
})
export class RnPackageGroupPipe implements PipeTransform {
  transform(pkgGroup: PackageGroup, ...args: unknown[]): unknown {
    if (pkgGroup && args && args.length > 0) {
      const prop = args[0].toString().toLowerCase();
      switch (prop) {
        case "isaddon": {
          return pkgGroup.Name.toLowerCase() === "add-ons";
        }
        case "ispackage": {
          return pkgGroup.Name.toLowerCase() === "packages";
        }
        case "isintuitupgrade": {
          return pkgGroup.Name.toLowerCase() == "upgrades for intuit customers";
        }
      }
    }

    return null;
  }
}
