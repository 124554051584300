/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnConnectPointsVM } from "../models/./connectPointsVM-d";

export class RnOrganizationFoldersVM {
  ID?: number;
  OrganizationID?: number;
  HomeFolderRoot?: string | null;
  ProfileFolderRoot?: string | null;
  ParentHomeRoot?: string | null;
  EffStartDate?: string;
  EffEndDate?: string;
  CreatedDate?: string;
  CreatedBy?: string | null;
  FolderName?: string | null;
  ConnectPoints?: Array<RnConnectPointsVM> | null;
}
