<div
  id="shoppingCartContainer"
  [ngClass]="{
    'wider-cart': !IsInline && UseWiderCart,
    notInline: !IsInline && !UseWiderCart
  }"
>
  <div *ngIf="!IsInline" class="borderBottom">
    <h4 class="titleText">Shopping Cart</h4>
    <div class="justifiedFlexEnd xCloseButton">
      <button
        data-testid="iconShoppingCartClose"
        class="close xToClose lmargin-auto"
        pTooltip="Close cart"
        tooltipPosition="bottom"
        (click)="hideShoppingCart()"
        aria-hidden="true"
      >
        x
      </button>
    </div>
  </div>
  <div
    *ngIf="IsInline && shoppingCart.items.length > 0"
    class="orderSummaryDivision"
  >
    <br />
    <h4 class="titleText">Your Order Summary</h4>
  </div>
  <div (ngClass)="({ NonInlineComponent: !IsInline })" *ngIf="showGridArea">
    <div class="shoppingCartScrollableArea">
      <rn-grid
        data-testid="ShoppingCartTableHeaders"
        [Configuration]="CartTableConfiguration"
      ></rn-grid>
    </div>
    <div *ngIf="shoppingCart.items.length === 0">
      <div class="shoppingCartTableEmpty">
        <b data-testid="emptyShoppingCart"
          >No items have been added to your cart.</b
        >
      </div>
    </div>
    <div class="totalsLightBlue" *ngIf="showTotalDue">
      <span class="pull-left"><b>Total Due Today</b></span>
      <span class="lmargin-auto"
        ><b data-testid="totalDueVaule">{{
          shoppingCart.CartTotal | currency
        }}</b></span
      >
      <span class="taxText">Plus tax where applicable</span>
    </div>
  </div>
  <div *ngIf="showFutureMonthlyBill" class="futureBillDivision dspy-flex">
    <span data-testid="futureMonthlyBill" class="pull-left"
      >Future Monthly Bill (starts
      {{ PaymentInfo?.NextBillDate | date: "MM/dd/yyyy" }})</span
    >
    <span
      data-testid="futureMonthlyBillValue"
      class="lmargin-auto"
      *ngIf="PaymentInfo?.NextBillAmount"
      >{{
        +PaymentInfo?.NextBillAmount + shoppingCart.CartMonthlyTotal | currency
      }}
      /month</span
    >
  </div>
  <div class="promoCodeDivision">
    <h4>Promo Code</h4>
  </div>
  <app-promo-code
    #promo
    *ngIf="canShowPromoCodeEntry"
    [IdThatPaysForThisOrg]="PaymentInfo?.OrganizationID"
    (PromoEvent)="applyPromo($event)"
  ></app-promo-code>
  <div class="shoppingCartPaymentInfo" *ngIf="showPaymentArea">
    <div class="subTitleText m-b-11 black">Current Payment Method</div>
    <div class="paymentDetails" *ngIf="showACHInfo">
      <div>ACH</div>
      <div>Account Type: {{ PaymentInfo?.PaymentMethods[0]?.ACHTypeName }}</div>
      <div>{{ PaymentInfo?.PaymentMethods[0]?.AccountLast4 }}</div>
    </div>
    <div class="paymentDetails" *ngIf="showCCInfo">
      <div data-testid="PaymentInfo">
        {{ PaymentInfo?.PaymentMethods[0]?.PaymentMethodType }}
        {{ PaymentInfo?.PaymentMethods[0]?.AccountLast4 }}
      </div>
      <div
        data-testid="expDate"
        *ngIf="PaymentInfo?.PaymentMethods[0]?.CCExpireOn"
      >
        Expires
        {{ PaymentInfo?.PaymentMethods[0]?.CCExpireOn | date: "MM/yyyy" }}
      </div>
    </div>
    <div class="m-t-14 paymentDetails">
      <span data-testid="changePaymentTxt"
        >You can change your payment and billing information on the Billing
        tab.</span
      >
    </div>
  </div>
  <div *ngIf="!IsInline" class="closeButton">
    <div class="m-t-33" *ngIf="!UsePurchaseButton">
      <app-button
        id="btnClose"
        buttonStyle="secondary"
        label="CLOSE"
        (clickActionEmitter)="hideShoppingCart()"
      >
      </app-button>
    </div>
    <div *ngIf="UsePurchaseButton" class="purchase-button-container">
      <app-button
        data-testid="btnShoppingCartClose"
        id="btnShoppingCartClose"
        label="CLOSE"
        buttonStyle="addUserTeritary"
        (clickActionEmitter)="hideShoppingCart()"
      >
      </app-button>
      <app-button
        id="btnPlaceOrder"
        [disabled]="purchaseDisabled"
        buttonStyle="primary"
        label="PLACE ORDER"
        showProcessing="true"
        [processing]="submitting"
        (clickActionEmitter)="placeOrder()"
      >
      </app-button>
    </div>
  </div>
</div>
