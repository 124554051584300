import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EnvService } from "../../../../core/services/env.service";
import * as dayjs from "dayjs";

@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.scss"],
})
export class DatePickerComponent implements OnInit {
  @Input() selectedDate: string | null;
  @Input() minDate: Date;
  @Input() maxDate: Date;

  @Output() selectedDateEvent = new EventEmitter<string>();

  public yearRange: string;
  public placeholderText = "";
  @Input() selectionMode = "single";
  @Input() isReadonly = false;

  ngOnInit(): void {
    let startYear = EnvService.EnvVariables().beginYearRange;
    if (!startYear || startYear === "") {
      startYear = "2018";
    }
    const endYear = dayjs(Date.now()).format("YYYY");
    this.yearRange = `${startYear}:${endYear}`;
    this.placeholderText =
      this.selectionMode === "range" ? "mm/dd/yyyy - mm/dd/yyyy" : "mm/dd/yyyy";
  }

  selectDate() {
    this.selectedDateEvent.emit(this.selectedDate);
  }
}
