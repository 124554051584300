import { OnDestroy } from "@angular/core";
import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { SignalRService } from "../../notifications/signalr.service";
import { Guid } from "../../utilities/guid/guid.utilities";
import { LoggedInInfoService } from "../loggedInInfo/logged-in-info.service";
import { UserService } from "../rnapi2-service/apis/user.service";
import {
  RnAddOrgIDToSignalRGroupPayload,
  RnAddUserIDToSignalRGroupPayload,
  RnOrganizationsProfileVM,
  RnRemoveOrgIDFromSignalRGroupPayload,
  RnRemoveUserIDFromSignalRGroupPayload,
  RnUsersProfileVM,
} from "../rnapi2-service/models/models";
import { SelectionService } from "../selection/selection.service";
import { SignalrTrackingData } from "./signalr-tracking-data";

class messageHandlerData {
  public handler: (string) => void;
  public subscription: Subscription;
  public Id: Guid;
}

@Injectable({
  providedIn: "root",
})
export class SignalrPollingService implements OnDestroy {
  constructor(
    private signalRService: SignalRService,
    private userService: UserService,
    private loggedInInfoService: LoggedInInfoService,
    private selectedService: SelectionService,
  ) {
    this.init();
  }

  ngOnDestroy() {
    this.messageHandlers.forEach((h) => {
      h.subscription.unsubscribe();
    });
  }

  public init(): void {
    this.overrideLoginSelection = true;
    this.loadData();
    this.loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    if (this.loggedInUser) {
      this.loggedInUserOrg = this.loggedInInfoService.GetLoggedInUserOrg();
      this.AddUserTrackingInfo(this.loggedInUser.UserID);
      this.AddOrgTrackingInfo(this.loggedInUserOrg.ID);
      this.selectedService.SelectedOrgProfile.subscribe((o) => {
        if (this.currOrg) {
          this.RemoveOrgTrackingInfo(this.currOrg.ID);
        }

        this.currOrg = o;
        if (this.currOrg) {
          this.AddOrgTrackingInfo(this.currOrg.ID);
        }
      });
      this.overrideLoginSelection = false;
    }
  }

  public AddUserTrackingInfo(userID: number) {
    if (userID !== this.loggedInUser.UserID || this.overrideLoginSelection) {
      if (
        !this.trackingData.find((d) => {
          return d.Area === "User" && d.AreaID === userID;
        })
      ) {
        const data = new SignalrTrackingData();
        data.Area = "User";
        data.AreaID = userID;
        this.trackingData.push(data);
        const payload = new RnAddUserIDToSignalRGroupPayload();
        payload.UserID = userID;
        payload.LoggedInUserID =
          this.loggedInInfoService.GetLoggedInUser().UserID;
        this.userService
          .apiV2UsersAdduseridtosignalrgroupPost(payload)
          .subscribe((s) => {
            if (s.Success) {
              console.log(`userId ${userID} registerd with Signalr`);
            }
          });
        this.storeData();
      }
    }
  }

  public AddOrgTrackingInfo(orgID: number) {
    if (orgID !== this.loggedInUserOrg.ID || this.overrideLoginSelection) {
      if (
        !this.trackingData.find((d) => {
          return d.Area === "Organization" && d.AreaID === orgID;
        })
      ) {
        const data = new SignalrTrackingData();
        data.Area = "Organization";
        data.AreaID = orgID;
        this.trackingData.push(data);
        const payload = new RnAddOrgIDToSignalRGroupPayload();
        payload.OrgID = orgID;
        payload.LoggedInUserID =
          this.loggedInInfoService.GetLoggedInUser().UserID;
        this.userService
          .apiV2UsersAddorgidtosignalrgroupPost(payload)
          .subscribe((s) => {
            if (s.Success) {
              console.log(`orgID ${orgID} registerd with Signalr`);
            }
          });
        this.storeData();
      }
    }
  }

  public RemoveUserTrackingInfo(userID: number): void {
    if (userID !== this.loggedInUser?.UserID || this.overrideLoginSelection) {
      const idx = this.trackingData.findIndex((d) => {
        return d.Area === "User" && d.AreaID === userID;
      });
      if (idx >= 0) {
        const data = this.trackingData[idx];
        const payload = new RnRemoveUserIDFromSignalRGroupPayload();
        payload.UserID = data.AreaID;
        payload.LoggedInUserID = this.loggedInUser?.UserID;
        this.userService
          .apiV2UsersRemoveuseridfromsignalrgroupPost(payload)
          .subscribe((s) => {
            if (s.Success) {
              console.log(`userId ${userID} removed from Signalr`);
            }
          });
        this.trackingData.splice(idx, 1);
        this.storeData();
      }
    }
  }

  public RemoveOrgTrackingInfo(orgID: number): void {
    if (orgID !== this.loggedInUserOrg.ID || this.overrideLoginSelection) {
      const idx = this.trackingData.findIndex((d) => {
        return d.Area === "Organization" && d.AreaID === orgID;
      });
      if (idx >= 0) {
        const data = this.trackingData[idx];
        const payload = new RnRemoveOrgIDFromSignalRGroupPayload();
        payload.OrgID = data.AreaID;
        payload.LoggedInUserID = this.loggedInUser.UserID;
        this.userService
          .apiV2UsersRemoveorgidfromsignalrgroupPost(payload)
          .subscribe((s) => {
            if (s.Success) {
              console.log(`orgId ${orgID} registerd with Signalr`);
            }
          });
        this.trackingData.splice(idx, 1);
        this.storeData();
      }
    }
  }

  public AddMessageHandler(handler: (string) => void): Guid {
    const sub = this.signalRService.messages.subscribe(handler);
    const data = new messageHandlerData();
    data.subscription = sub;
    data.Id = Guid.newGuid();
    data.handler = handler;
    this.messageHandlers.push(data);
    this.storeData();
    return data.Id;
  }

  public RemoveMessageHandler(handlerId: Guid): void {
    const idx = this.messageHandlers.findIndex((d) => {
      return d.Id === handlerId;
    });
    if (idx >= 0) {
      const data = this.messageHandlers[idx];
      data.subscription.unsubscribe();
      this.messageHandlers.splice(idx, 1);
      this.storeData();
    }
  }

  public ShutDown(): void {
    this.overrideLoginSelection = true;
    this.messageHandlers.forEach((h) => {
      h.subscription.unsubscribe();
    });
    this.messageHandlers = [];

    this.trackingData.forEach((d) => {
      switch (d.Area) {
        case "User":
          this.RemoveUserTrackingInfo(d.AreaID);
          break;
        case "Organization":
          this.RemoveOrgTrackingInfo(d.AreaID);
          break;
      }
    });
    this.clearData();
    this.trackingData = [];
    this.overrideLoginSelection = false;
  }

  private trackingData: SignalrTrackingData[] = [];
  private messageHandlers: messageHandlerData[] = [];
  private loggedInUser: RnUsersProfileVM;
  private loggedInUserOrg: RnOrganizationsProfileVM;
  private currOrg: RnOrganizationsProfileVM;
  private overrideLoginSelection = false;
  private localStorageKey = "signalrdata";

  private storeData(): void {
    const data = JSON.stringify(this.trackingData);
    localStorage.setItem(this.localStorageKey, data);
  }

  private loadData(): void {
    const storedData = localStorage.getItem(this.localStorageKey);
    this.trackingData = storedData
      ? (JSON.parse(storedData) as SignalrTrackingData[])
      : [];
  }

  private clearData(): void {
    localStorage.removeItem(this.localStorageKey);
  }
}
