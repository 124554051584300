import {
  RnOrganizationsProfileVM,
  RnOrganizationPaymentOnFileVM,
} from "../../../services/rnapi2-service/models/models";

export class BillingFormData {
  public organization: RnOrganizationsProfileVM;
  public pof: RnOrganizationPaymentOnFileVM;
  public missingBillingInfoOnLogin: boolean;
  public limitedRefresh: boolean;
}
