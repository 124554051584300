import { Injectable } from "@angular/core";
import { HttpResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, finalize, shareReplay } from "rxjs/operators";
import {
  RnCommonId,
  OrganizationService,
  RnOrganizationsProfileVMRNResponseRelay,
} from "../../services/rnapi2-service";

/** Organization cache duration in milliseconds */
const cacheTimeOut = 15000;

/**
 * CacheEntry is a data structure that holds the organization profile data and the expiration time
 */
interface CacheEntry {
  data: Observable<HttpResponse<RnOrganizationsProfileVMRNResponseRelay>>;
  expiration: number;
}

/**
 * The OrganizationCacheService is a service that caches organization profiles for a pre-determined amount of time
 * specified by the cacheTimeOut constant. It also handles ongoing requests to prevent multiple
 * simultaneous requests for the organization profile.
 */
@Injectable({
  providedIn: "root",
})
export class OrganizationCacheService {
  constructor(private organizationService: OrganizationService) {}

  private organizationProfileCache: { [organizationId: string]: CacheEntry } =
    {};
  private ongoingRequests: {
    [organizationId: string]: Observable<
      HttpResponse<RnOrganizationsProfileVMRNResponseRelay>
    >;
  } = {};

  getOrganizationProfile(
    orgId: string,
    forceBustCache: boolean = false,
  ): Observable<HttpResponse<RnOrganizationsProfileVMRNResponseRelay>> {
    const now = Date.now();
    const cacheEntry = this.organizationProfileCache[orgId];

    if (cacheEntry && cacheEntry.expiration > now && !forceBustCache) {
      return cacheEntry.data;
    }

    if (this.ongoingRequests[orgId]) {
      return this.ongoingRequests[orgId];
    }

    const payload: RnCommonId = {
      Id: orgId,
    };

    const organizationProfileObservable = this.organizationService
      .apiV2OrganizationsProfilePost(payload, "response")
      .pipe(
        shareReplay({
          bufferSize: 1,
          windowTime: cacheTimeOut,
          refCount: false,
        }),
        map((response) => {
          this.organizationProfileCache[orgId] = {
            data: of(response),
            expiration: now + cacheTimeOut,
          };
          return response;
        }),
        catchError((error) => {
          console.error(
            `Error fetching org profile with orgId: ${orgId}`,
            error,
          );
          delete this.organizationProfileCache[orgId];
          return of(null); // Return a fallback value
        }),
        finalize(() => {
          delete this.ongoingRequests[orgId];
        }),
      );

    this.ongoingRequests[orgId] = organizationProfileObservable;

    return organizationProfileObservable;
  }
}
