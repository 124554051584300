<div class="user-edit-addons dspy-flex flex-column" style="height: 99%">
  <div class="row flex-shrink-0">
    <div class="col-6">
      <br />
      <h2>Change Packages</h2>
      <div *ngIf="showPkgSubtextSection">
        <br />
        <h5 class="sectionTitle">{{ partnerName }} Packages</h5>
        <h6 class="packages-subtext">
          <span [innerHtml]="getWlpPackageText | safeHtml"></span>
        </h6>
      </div>
    </div>
    <div class="col-6 shoppingcartdiv">
      <div #cartIcon class="pull-right">
        <app-shopping-cart-icon
          (ToggleShoppingCart)="toggleShoppingCart()"
        ></app-shopping-cart-icon>
      </div>
      <div #cart>
        <app-shopping-cart
          [PaymentInfo]="paymentInfo"
          [IsInline]="false"
          (CloseCart)="toggleShoppingCart()"
          *ngIf="isShoppingCartVisible"
        ></app-shopping-cart>
      </div>
    </div>
  </div>
  <div class="row manage-packages-content">
    <div class="col-12 p-ml-3">
      <app-change-package
        data-id="appChangePackage"
        [packageSelectForm]="packagesForm"
        [OrganizationID]="organizationId"
        [orgProfile]="orgProfile"
        [orgConfig]="orgConfig"
        [userData]="userData"
        [orgWlpContactInfo]="orgWlpContactInfo"
        (PackageSelected)="packageSelected($event)"
        (PackagesLoaded)="packagesLoaded($event)"
        [CheckForInitialActiveMessage]="checkForInitialActiveMessage"
        [AssignedPackage]="assignedPackage"
        [hideTitles]="true"
        (PackageChanged)="PackageChanged($event)"
      ></app-change-package>
    </div>
  </div>

  <div class="rightAlignButtons flex-shrink-0">
    <a
      tabindex="0"
      (keyup.enter)="dismiss()"
      data-testid="btnManagePackagesCancel"
      class="cancel"
      (click)="dismiss()"
      >Cancel</a
    >
    &nbsp;
    <app-button
      id="btnSave"
      [disabled]="!canSubmit()"
      (clickActionEmitter)="saveChanges()"
      label="Save"
      showProcessing="true"
      [processing]="loading"
      buttonStyle="primary"
    >
    </app-button>
  </div>
</div>
