import { Component, OnInit } from "@angular/core";
import { EnvService } from "../../../../../core/services/env.service";

@Component({
  selector: "app-rn-footer",
  templateUrl: "./rn-footer.component.html",
  styleUrls: ["./rn-footer.component.scss"],
})
export class RnFooterComponent implements OnInit {
  public AppHubVersion = EnvService.EnvVariables().version;
  public copyrightYearString: string;

  ngOnInit(): void {
    const fullYear = new Date().getFullYear();
    this.copyrightYearString = `2002-${fullYear}`;
  }
}
