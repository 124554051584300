/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnEventLogInfo } from "../models/./eventLogInfo-d";

export class RnOrganizationUpdateBusinessAddress {
  OrganizationID?: number;
  Address1?: string | null;
  Address2?: string | null;
  City?: string | null;
  Country?: string | null;
  CreatedBy?: string | null;
  CreatedDate?: string;
  EffEndDate?: string;
  EffStartDate?: string;
  StateProvince?: string | null;
  PostalCodeID?: string | null;
  EventLogInfo?: RnEventLogInfo;
  AffectedOrganizationId?: number;
  AffectedUserId?: number;
}
