import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class AuthResponseInteceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    req = req.clone({
      withCredentials: true,
    });
    if (req.url.includes("oauth2/token")) {
      return next.handle(req);
    } else {
      const jsonReq: HttpRequest<any> = req.clone({
        setHeaders: { "Content-Type": "application/json" },
      });
      return next.handle(jsonReq);
    }
  }
}
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthResponseInteceptor, multi: true },
];
