import { Component, Inject } from "@angular/core";
import { IdleService } from "../idle.service";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { IdleStateTypes } from "../idle-state-types.enum";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  templateUrl: "./idle-dialog.component.html",
  styleUrls: ["./idle-dialog.component.scss"],
})
export class IdleDialogComponent {
  dialogRef: DynamicDialogRef;
  constructor(
    public idleService: IdleService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mdDialogRef: MatDialogRef<IdleDialogComponent>,
  ) {}

  public IsWarningMode(): boolean {
    return this.idleService.idleState === IdleStateTypes.warning;
  }

  public countdown(): number {
    return this.idleService.countdown;
  }

  public continue(): void {
    this.mdDialogRef.close(false);
    location.reload();
  }

  public logout(): void {
    this.mdDialogRef.close(true);
  }

  public getTitle(): string {
    return this.IsWarningMode()
      ? "You Appear To Be Inactive."
      : "Your Session Has Expired.";
  }
}
