export * from "./applications.service";
import { ApplicationsService } from "./applications.service";
export * from "./duo.service";
import { DuoService } from "./duo.service";
export * from "./entitlement.service";
import { EntitlementService } from "./entitlement.service";
export * from "./general.service";
import { GeneralService } from "./general.service";
export * from "./license.service";
import { LicenseService } from "./license.service";
export * from "./lookup.service";
import { LookupService } from "./lookup.service";
export * from "./ops.service";
import { OpsService } from "./ops.service";
export * from "./order.service";
import { OrderService } from "./order.service";
export * from "./organization.service";
import { OrganizationService } from "./organization.service";
export * from "./package.service";
import { PackageService } from "./package.service";
export * from "./thirdParty.service";
import { ThirdPartyService } from "./thirdParty.service";
export * from "./user.service";
import { UserService } from "./user.service";
export * from "./wLP.service";
import { WLPService } from "./wLP.service";
export * from "./zuora.service";
import { ZuoraService } from "./zuora.service";
export const APIS = [
  ApplicationsService,
  DuoService,
  EntitlementService,
  GeneralService,
  LicenseService,
  LookupService,
  OpsService,
  OrderService,
  OrganizationService,
  PackageService,
  ThirdPartyService,
  UserService,
  WLPService,
  ZuoraService,
];
