import { Injectable } from "@angular/core";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { AuthenticationService } from "../security/authentication.service";
import { IdleDialogComponent } from "./dialog/idle-dialog.component";
import { IdleStateTypes } from "./idle-state-types.enum";
import { NavigationService } from "../services/navigation/navigation.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { loginStateTypes } from "../../core/enums/loginStateTypes";
import { TimeoutSettings } from "../../core/models/timeoutSettings";
import { AuthService } from "@rn-platform/frontend-shared-feature-identity-auth";

@Injectable({
  providedIn: "root",
})
export class IdleService {
  constructor(
    private idle: Idle,
    private authorizationService: AuthenticationService,
    public navigationService: NavigationService,
    private dialog: MatDialog,
    private authService: AuthService
  ) {}
  public countdown: number;

  private warningDialogRef: MatDialogRef<IdleDialogComponent>;
  public idleState = IdleStateTypes.none;
  public setup(timeoutSettings: TimeoutSettings): void {
    this.idle.setIdle(timeoutSettings.idleInterval);
    const idleTimeoutInterval = timeoutSettings.idleTimeoutInterval;
    this.idle.setTimeout(idleTimeoutInterval);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      if (
        this.authorizationService.userIsLoggedIn() &&
        !this.warningDialogRef
      ) {
        this.showWarningDialog();
      }
    });
    this.idle.onIdleEnd.subscribe(() => {
      this.closeWarningDialog();
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = IdleStateTypes.warning;
      this.countdown = countdown;
    });

    this.idle.onTimeout.subscribe(async () => {
      this.idleState = IdleStateTypes.expired;
      this.CloseAllDialog();
      this.authService.clearTokens();
      this.authService.markSessionExpired();
      await this.authorizationService.logoutWithState(
        loginStateTypes.none,
        true,
        true,
      );
      this.stop();
    });
  }

  public start(): void {
    if (!this.isRunning()) {
      this.idleState = IdleStateTypes.none;
      this.idle.watch();
    }
  }

  public isRunning(): boolean {
    return this.idle.isRunning();
  }

  public stop(): void {
    this.idle.stop();
  }

  showWarningDialog(): void {
    this.navigationService.closeDownloadSetupDialog();
    const config = {
      header: "",
      width: "50%",
      height: "auto",
      "z-index": 10000,
      closable: false,
      showHeader: false,
      disableClose: true,
    };
    this.warningDialogRef = this.dialog.open(IdleDialogComponent, config);
    this.warningDialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.authorizationService.logout();
      }
      this.warningDialogRef = null;
    });
  }

  public closeWarningDialog(): void {
    if (this.warningDialogRef) {
      this.warningDialogRef.close(false);
      this.warningDialogRef = null;
    }
  }

  public CloseAllDialog() {
    const dialogs = this.dialog.openDialogs;
    dialogs.forEach((d) => {
      if (!(d.componentInstance instanceof IdleDialogComponent)) {
        d.close();
      }
    });
  }
}
