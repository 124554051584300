import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-rn-confirmation",
  templateUrl: "./rn-confirmation.component.html",
  styleUrls: ["./rn-confirmation.component.scss"],
})
export class RnConfirmationComponent {
  @Output() confirmationResult = new EventEmitter<boolean>();

  clickYes() {
    this.confirmationResult.emit(true);
  }

  clickNo() {
    this.confirmationResult.emit(false);
  }
}
