import { EventEmitter, Input, Output } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { CartService } from "../../../services/cart/cart.service";
import { LookupService } from "../../../services/rnapi2-service/apis/lookup.service";
import { RnToastService } from "../../../services/toast/rntoast.service";

@Component({
  selector: "app-promo-code",
  templateUrl: "./promo-code.component.html",
  styleUrls: ["./promo-code.component.scss"],
})
export class PromoCodeComponent implements OnInit {
  @Input() IdThatPaysForThisOrg;
  @Output() PromoEvent = new EventEmitter<any>();

  inputPromoCode = "";
  promoCodeIsValid = false;
  wait = false;
  error = false;
  validatedPromo: any = {};

  constructor(
    private cartService: CartService,
    private lookupService: LookupService,
    private toastService: RnToastService,
  ) {}

  ngOnInit(): void {
    this.validatedPromo = this.cartService.getPromo();
    if (
      Object.keys(this.validatedPromo).length !== 0 &&
      !Object.values(this.validatedPromo).every((v) => v == null)
    ) {
      this.promoCodeIsValid = true;
      this.inputPromoCode = this.validatedPromo.promoCode.PromoCode;
    }
  }

  public validatePromoCode(code) {
    //event.stopPropagation();
    if (this.wait == true || this.promoCodeIsValid == true) {
      return;
    }

    this.wait = true;
    this.promoCodeIsValid = false;
    this.error = false;
    this.validatedPromo = {};
    this.inputPromoCode = this.inputPromoCode.toUpperCase();

    //get package ids to validate
    const packageIDs = this.cartService.getAllIds();

    this.subscriptions.push(
      this.lookupService
        .apiV2LookupValidatepromocodeGet(
          code,
          packageIDs,
          this.IdThatPaysForThisOrg,
        )
        .subscribe(
          (response) => {
            if (response && response.data && response.Success) {
              this.validatedPromo = response.data;
              this.PromoEvent.emit(this.validatedPromo);
              this.wait = false;

              //promo code is good
              if (this.validatedPromo && this.validatedPromo.promoCodeIsValid) {
                this.promoCodeIsValid = true;
              }
              //promo code invalid
              else {
                this.error = true;
                this.wait = false;
                this.promoCodeIsValid = false;
              }
            }
            //not successful - something like timeout or 500
            else {
              this.wait = false;
              this.promoCodeIsValid = false;
              this.validatedPromo = {};
              this.PromoEvent.emit(this.validatedPromo);
              const content =
                "<strong>There was a problem checking the promo code. Please try again.<strong>";
              console.log("errorCallback", JSON.stringify(response));
              this.toastService.showError(content);
            }
          },
          //just in case
          (error) => {
            this.wait = false;
            this.promoCodeIsValid = false;
            this.validatedPromo = {};
            this.PromoEvent.emit(this.validatedPromo);
            const content =
              "<strong>There was a problem checking the promo code. Please try again.<strong>";
            console.log("errorCallback", JSON.stringify(error));
            this.toastService.showError(content);
          },
        ),
    );
  }

  public onPromoChange() {
    //if promocode was validated then got updated, then promo needs revalidation
    if (
      this.validatedPromo.promoCode != undefined &&
      this.validatedPromo.promoCode.PromoCode != this.inputPromoCode
    ) {
      this.clearPromo();
    }
  }

  private clearPromo() {
    const promoWasNotSet = Object.keys(this.validatedPromo).length === 0;
    this.promoCodeIsValid = false;
    this.validatedPromo = {};

    if (!promoWasNotSet) {
      this.PromoEvent.emit(this.validatedPromo);
    }
  }

  private subscriptions: Subscription[] = [];
}
