import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { OrganizationService } from "src/app/shared/services/rnapi2-service/apis/organization.service";
import { LoggedInInfoService } from "../../../../services/loggedInInfo/logged-in-info.service";
import { OrganizationLogicService } from "../../../../services/organization-logic/organization-logic.service";
import {
  RnCommonSearch,
  RnOrganizationPackagePoolsForListVM,
  RnOrganizationsProfileVM,
  RnOrganizationWLPContactInformationVM,
} from "../../../../services/rnapi2-service/models/models";
import { GridColumnConfiguration } from "../../../third-party-wrappers/grid/configuration/grid-column-configuration";
import { GridConfiguration } from "../../../third-party-wrappers/grid/configuration/grid-configuration";
import { GridHyperlinkColumnConfiguration } from "../../../third-party-wrappers/grid/configuration/grid-hyperlink-column-configuration";
import { PackageConfigInfo } from "../package-config-info";

@Component({
  selector: "app-wlp-inventory-table",
  templateUrl: "./wlp-inventory-table.component.html",
  styleUrls: ["./wlp-inventory-table.component.scss"],
})
export class WlpInventoryTableComponent implements OnInit, OnDestroy {
  constructor(
    private orgLogicService: OrganizationLogicService,
    private organizationService: OrganizationService,
    private loggedInUserService: LoggedInInfoService,
  ) {}

  getWlpPackageText = "";
  private subscriptions = [];
  @Input() WLPName: string;
  @Input() set WLPPackages(list: Array<RnOrganizationPackagePoolsForListVM>) {
    this._packageList = list;
    this.PackageTableConfiguration.GridData = list;
    this.ShowPackages = this._packageList?.length > 0;
  }

  @Input() set WLPAddOns(list: Array<RnOrganizationPackagePoolsForListVM>) {
    this._addOnList = list;
    this.AddOnTableConfiguration.GridData = list;
    this.ShowAddOns = this._addOnList?.length > 0;
  }

  @Input() Loading = false;
  @Input() set OrgProfile(profile: RnOrganizationsProfileVM) {
    this.orgProfile = profile;
    if (this.orgProfile) {
      this.setupColumnsForTables();
    }
  }

  get OrgProfile(): RnOrganizationsProfileVM {
    return this.orgProfile;
  }

  @Input() OrgWlpContactInfo: RnOrganizationWLPContactInformationVM;

  @Input() PkgConfigInfo: PackageConfigInfo;
  @Input() TopLevelOrgId: number;

  @Output() PackagePoolSelected =
    new EventEmitter<RnOrganizationPackagePoolsForListVM>();

  public PackageTableConfiguration = new GridConfiguration();
  public AddOnTableConfiguration = new GridConfiguration();
  public ShowPackages = false;
  public ShowAddOns = false;

  ngOnInit(): void {
    this.PackageTableConfiguration.ShowCountHeader = false;
    this.AddOnTableConfiguration.ShowCountHeader = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public SelectPackage(orgPackPool: RnOrganizationPackagePoolsForListVM) {
    if (this.PackagePoolSelected) {
      this.PackagePoolSelected.emit(orgPackPool);
    }
  }

  public GetWlpPackageText(): void {
    const payload = new RnCommonSearch();
    if (this.orgProfile?.CustomerType) {
      if (this.loggedInUserService.GetWLPConfig) {
        const wlpConfigData = this.loggedInUserService?.GetWLPConfig()?.data;
        if (wlpConfigData) {
          this.getWlpPackageText =
            Object.values(wlpConfigData)[
              Object.keys(wlpConfigData).indexOf("packages_addon_tab_message")
            ];
        } else {
          this.getWlpPackageText = `Missing message for packages_addon_tab_message`;
        }
      }
    }
  }

  private setupColumnsForTables() {
    this.GetWlpPackageText();
    this.PackageTableConfiguration.FirstRow = 0;
    this.PackageTableConfiguration.ColumnConfiguration = [];
    this.PackageTableConfiguration.UsePaginator = false;
    this.PackageTableConfiguration.IsLazyLoading = false;
    this.PackageTableConfiguration.ShowCountHeader = false;
    this.PackageTableConfiguration.GridClass = "wlp-inventory-table";
    this.PackageTableConfiguration.getClassForCell = (
      col: GridColumnConfiguration,
      rowData: any,
    ) => {
      return "wlp-inventory-table";
    };
    const loggedInUser = this.loggedInUserService.GetLoggedInUser();
    if (!loggedInUser) {
      return false;
    }

    const ASNRMALLPK = this.loggedInUserService.loggedInUserHasRights([
      "ASNRMALLPK",
    ]);
    const isInternal = loggedInUser.IsInternal();
    const loggedInUserOrg = this.loggedInUserService.GetLoggedInUserOrg();
    if (
      this.orgLogicService.CanOpenPackagesAddOns(
        ASNRMALLPK,
        isInternal,
        this.orgProfile,
        loggedInUserOrg,
      )
    ) {
      const packageNameCol = new GridHyperlinkColumnConfiguration(
        "PackageName",
        "Packages",
        "col-55-width pkg-inventory-table-header",
        "",
      );
      packageNameCol.ExtraData = {
        pkgComponent: this,
      };
      packageNameCol.LinkIsEnableHandler = (rowData: any) => {
        const pkg = rowData as RnOrganizationPackagePoolsForListVM;
        if (pkg) {
          return pkg.PackageType !== "BillOnly";
        }

        return false;
      };
      packageNameCol.ClickAvailableHandler = (rowData: any) => {
        const pkg = rowData as RnOrganizationPackagePoolsForListVM;
        if (pkg) {
          return pkg.PackageType !== "BillOnly";
        }

        return false;
      };

      packageNameCol.ClickCommandHandler = (rowData: any, extraData: any) => {
        const pkg = rowData as RnOrganizationPackagePoolsForListVM;
        if (pkg && extraData) {
          const pkgComponent =
            extraData.pkgComponent as WlpInventoryTableComponent;
          if (pkgComponent) {
            pkgComponent.SelectPackage(pkg);
          }
        }
      };
      this.PackageTableConfiguration.ColumnConfiguration.push(packageNameCol);
    } else {
      this.PackageTableConfiguration.ColumnConfiguration.push(
        new GridColumnConfiguration(
          "PackageName",
          "Packages",
          "col-55-width pkg-inventory-table-header",
          "",
        ),
      );
    }

    this.PackageTableConfiguration.ColumnConfiguration.push(
      new GridColumnConfiguration(
        "NumberAllowed",
        "Seats",
        "col-15-width-center pkg-inventory-table-header",
        "",
      ),
    );
    const assignedCol = new GridColumnConfiguration(
      "NumberUsed",
      "Assigned",
      "col-15-width-center pkg-inventory-table-header",
      "",
    );
    this.PackageTableConfiguration.ColumnConfiguration.push(assignedCol);
    const remainingCol = new GridColumnConfiguration(
      "Remaining",
      "Remaining",
      "col-15-width-center pkg-inventory-table-header",
      "",
    );
    remainingCol.CustomDataRetrieval = (dataRow: any) => {
      const pkg = dataRow as RnOrganizationPackagePoolsForListVM;
      if (pkg) {
        return (pkg.NumberAllowed - pkg.NumberUsed).toString();
      }
      return "";
    };
    this.PackageTableConfiguration.ColumnConfiguration.push(remainingCol);
    this.PackageTableConfiguration.RetrieveClassForCell = (col, rowData) => {
      let classText = "wlp-inventory-table-row";
      classText +=
        col.FieldName === "PackageName"
          ? " col-55-width"
          : " col-15-width-center";
      return classText;
    };

    this.AddOnTableConfiguration.FirstRow = 0;
    this.AddOnTableConfiguration.ColumnConfiguration = [];
    this.AddOnTableConfiguration.UsePaginator = false;
    this.AddOnTableConfiguration.IsLazyLoading = false;
    this.AddOnTableConfiguration.GridClass = "wlp-inventory-table";

    if (
      this.orgLogicService.CanOpenPackagesAddOns(
        ASNRMALLPK,
        isInternal,
        this.orgProfile,
        loggedInUserOrg,
      )
    ) {
      const addOnNameCol = new GridHyperlinkColumnConfiguration(
        "PackageName",
        "Add-ons",
        "col-55-width pkg-inventory-table-header",
        "",
      );
      addOnNameCol.ExtraData = {
        pkgComponent: this,
      };

      addOnNameCol.ClickAvailableHandler = (rowData: any) => {
        return true;
      };
      addOnNameCol.ClickCommandHandler = (rowData: any, extraData: any) => {
        const pkg = rowData as RnOrganizationPackagePoolsForListVM;
        if (pkg && extraData) {
          const pkgComponent =
            extraData.pkgComponent as WlpInventoryTableComponent;
          if (pkgComponent) {
            pkgComponent.SelectPackage(pkg);
          }
        }
      };
      this.AddOnTableConfiguration.ColumnConfiguration.push(addOnNameCol);
    } else {
      this.AddOnTableConfiguration.ColumnConfiguration.push(
        new GridColumnConfiguration(
          "PackageName",
          "Add-ons",
          "col-55-width pkg-inventory-table-header",
          "",
        ),
      );
    }

    this.AddOnTableConfiguration.ColumnConfiguration.push(
      new GridColumnConfiguration(
        "NumberAllowed",
        "Seats",
        "col-15-width-center pkg-inventory-table-header",
        "",
      ),
    );
    const addOnAssignedCol = new GridColumnConfiguration(
      "NumberUsed",
      "Assigned",
      "col-15-width-center pkg-inventory-table-header",
      "",
    );
    this.AddOnTableConfiguration.ColumnConfiguration.push(addOnAssignedCol);
    const addOnRemainingCol = new GridColumnConfiguration(
      "Remaining",
      "Remaining",
      "col-15-width-center pkg-inventory-table-header",
      "",
    );
    addOnRemainingCol.CustomDataRetrieval = (dataRow: any) => {
      const pkg = dataRow as RnOrganizationPackagePoolsForListVM;
      if (pkg) {
        return (pkg.NumberAllowed - pkg.NumberUsed).toString();
      }
      return "";
    };
    this.AddOnTableConfiguration.ColumnConfiguration.push(addOnRemainingCol);
    this.AddOnTableConfiguration.RetrieveClassForCell = (col, rowData) => {
      let classText = "wlp-inventory-table-row";
      classText +=
        col.FieldName === "PackageName"
          ? " col-55-width"
          : " col-15-width-center";
      return classText;
    };
  }

  private orgProfile: RnOrganizationsProfileVM;
  private _packageList: Array<RnOrganizationPackagePoolsForListVM>;
  private _addOnList: Array<RnOrganizationPackagePoolsForListVM>;
}
