/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnUsersForOrgSubOrgSearchForLicenseVM } from "../models/./usersForOrgSubOrgSearchForLicenseVM-d";

export class RnLicensesWithUsersVM {
  Users?: Array<RnUsersForOrgSubOrgSearchForLicenseVM> | null;
  LicenseCount?: number;
  License1?: string | null;
  License2?: string | null;
  IsAutoAssignable?: boolean;
  CreatedDate?: string;
  IsProAdvisor?: boolean;
  LicenseID?: number;
  ItemName?: string | null;
  LeasedPackagePoolID?: number | null;
  OrganizationID?: number;
  readonly DisplayName?: string | null;
  CountBlockingUsers?: number;
  PackageId?: number;
  PackageName?: string | null;
  FlavorId?: number;
  ItemFlavorId?: number;
  Version?: string | null;
  Country?: string | null;
  Edition?: string | null;
  Flavor?: string | null;
  Type?: string | null;
  TypeId?: number;
  Price?: number;
  LicenseTypeId?: number;
  LicenseTypeName?: string | null;
  LicenseEditTypeID?: number;
  LicenseEditTypeName?: string | null;
  ItemId?: number;
  PackageTypeName?: string | null;
  TypeDesc?: string | null;
}
