import { Injectable } from "@angular/core";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { CopyActivationLinkConfiguration } from "../../../core/models/copyActivationLinkConfiguration";
import { AccessManagementConfiguration } from "../../../core/models/access-management-configuration";
import { OrganizationInfoConfig } from "src/app/core/models/organization-info-configuration";
import {
  RnOrganizationConfigurationVM,
  RnOrganizationsProfileVM,
  RnOrganizationWLPContactInformationVM,
  RnUsersVM,
  RnUserTypesVM,
} from "../rnapi2-service/models/models";
import { CopyActivationComponent } from "../../components/rn-business-components/copy-activation/copy-activation.component";
import { AccessManagementComponent } from "../../components/rn-business-components/access-management/access-management.component";
import { OrganizationInfoCreateComponent } from "../../components/rn-business-components/organization-info-create/organization-info-create.component";
import { UserActionDialogComponent } from "../../components/rn-business-components/user-action-dialog/user-action-dialog.component";
import { UserKeepDropPackageDialogComponent } from "../../components/rn-business-components/user-keep-drop-package-dialog/user-keep-drop-package-dialog.component";
import { AddUserComponent } from "../../components/rn-business-components/add-user/add-user.component";
import { OrganizationChangeStatusComponent } from "../../components/rn-business-components/organization-change-status/organization-change-status.component";
import { MaintenanceModeConfiguration } from "../../../core/models/maintenance-mode-configuration";
import { MaintenanceModeNotificationComponent } from "../../components/rn-business-components/maintenance-mode-notification/maintenance-mode-notification.component";
import { BillingFormComponent } from "../../components/rn-business-components/billing-form/billing-form.component";
import { BillingSeparationComponent } from "../../components/rn-business-components/billing-separation/billing-separation.component";
import { OrgRenameUserLoggedInDialogComponent } from "../../components/rn-business-components/rename-error/rename-error.component";
import { OrgRenameUserLoggedInConfig } from "../../components/rn-business-components/rename-error/rename-error-config";
import { PackageAssignConfigData } from "../../components/rn-business-components/package-assignment/package-assign-config-data";
import { PackageAssignmentComponent } from "../../components/rn-business-components/package-assignment/package-assignment.component";
import { LicenseAddEditComponent } from "../../components/rn-business-components/license-add-edit/license-add-edit.component";
import { LicenseAddEditConfig } from "../../components/rn-business-components/license-add-edit/license-add-edit-config";
import { LicensePurchaseComponent } from "../../components/rn-business-components/license-purchase/license-purchase.component";
import { LicensePurchaseConfig } from "../../components/rn-business-components/license-purchase/license-purchase-config";
import { CreateRenameFolderConfig } from "../../components/rn-business-components/create-rename-folder/create-rename-folder-config";
import { CreateRenameFolderComponent } from "../../components/rn-business-components/create-rename-folder/create-rename-folder.component";
import { DeleteFldrConfig } from "../../components/rn-business-components/delete-fldr-confirm/delete-fldr-config";
import { DeleteFldrConfirmComponent } from "../../components/rn-business-components/delete-fldr-confirm/delete-fldr-confirm.component";
import { QuantityCounts } from "../../../feature-modules/welcomewizard/quantity-counts";
import { LicenseAssignConfig } from "../../components/rn-business-components/license-assign/license-assign-config";
import { LicenseAssignComponent } from "../../components/rn-business-components/license-assign/license-assign.component";
import { EditMfaDialogComponent } from "../../components/rn-business-components/edit-mfa-dialog/edit-mfa-dialog.component";
import { UserFeedbackDialogComponent } from "../../components/rn-business-components/user-feedback-dialog/user-feedback-dialog.component";
import { DialogTrackingService } from "./dialog-tracking.service";
import { BillingFormData } from "../../components/rn-business-components/billing-form/billing-form.data";
import { DuoDialogComponent } from "../../components/rn-business-components/duo-dialog/duo-dialog.component";
import { DuoDialogConfiguration } from "../../../core/models/duo-dialog-configuration";
import { DisableOrgMfaDialogComponent } from "../../components/rn-business-components/disable-org-mfa-dialog/disable-org-mfa-dialog.component";
import { MustSetupMfaDialogComponent } from "../../components/rn-business-components/must-setup-mfa-dialog/must-setup-mfa-dialog.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ManageQboConfig } from "../../components/rn-business-components/license-management/qbo-subscription-management/manage-qbo-config";
import { QboSubscriptionManagementComponent } from "../../components/rn-business-components/license-management/qbo-subscription-management/qbo-subscription-management.component";
import { RnLicenseFullVM } from "../rnapi2-service/models/models";
import { LicenseEditComponent } from "../../components/rn-business-components/license-edit/license-edit.component";
import { UserDetailsModalComponent } from "../../components/rn-business-components/sidebar/user-details-modal/user-details-modal.component";
import { AzureCredentialsComponent } from "../../components/rn-business-components/azure-credentials/azure-credentials.component";
@Injectable({
  providedIn: "root",
})
export class RnDialogService {
  constructor(
    private dialogService: DialogService,
    private dialogTrackingService: DialogTrackingService,
    private dialog: MatDialog,
  ) {}

  public MaintenanceModeDialog(configuration: MaintenanceModeConfiguration) {
    const dialog = this.dialogService.open(
      MaintenanceModeNotificationComponent,
      {
        header: "",
        width: "677px",
        baseZIndex: 1000,
        closable: false,
        showHeader: false,
        data: configuration,
      },
    );

    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public CopyActivationLinkDialog(
    configuration: CopyActivationLinkConfiguration,
  ) {
    const dialog = this.dialogService.open(CopyActivationComponent, {
      header: "",
      width: "600px",
      baseZIndex: 1000,
      closable: false,
      showHeader: false,
      modal: true,
      dismissableMask: true,
      data: configuration,
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public AccessManagementDialog(
    accessMgmtConfig: AccessManagementConfiguration,
  ) {
    const dialog = this.dialogService.open(AccessManagementComponent, {
      header: "",
      width: "600px",
      baseZIndex: 1000,
      closable: false,
      showHeader: false,
      modal: true,
      data: accessMgmtConfig,
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public OrganizationInfoDialog(
    OrgInfoConfig: OrganizationInfoConfig,
  ): DynamicDialogRef {
    const dialog = this.dialogService.open(OrganizationInfoCreateComponent, {
      header: "",
      width: "600px",
      contentStyle: { "max-height": "100%", overflow: "auto" },
      baseZIndex: 10000,
      closable: false,
      showHeader: false,
      modal: true,
      data: OrgInfoConfig,
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }
  public EditLicenseDetailDialog(
    licenseInfoConfig: RnLicenseFullVM,
  ): DynamicDialogRef {
    const dialog = this.dialogService.open(LicenseEditComponent, {
      header: "",
      width: "500px",
      contentStyle: { "max-height": "100%", overflow: "auto" },
      baseZIndex: 10000,
      closable: false,
      showHeader: false,
      modal: true,
      data: licenseInfoConfig,
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }
  // org user dialogs
  public PasswordResetDialog(selectedUsers: RnUsersVM[]) {
    const dialog = this.dialogService.open(UserActionDialogComponent, {
      header: "",
      width: "600px",
      contentStyle: { "max-height": "700px", overflow: "auto" },
      baseZIndex: 1000,
      closable: false,
      showHeader: false,
      modal: true,
      dismissableMask: true,
      data: {
        actionTitle: "Password reset sent",
        actionHeader:
          selectedUsers.length > 1
            ? "We sent password reset emails to the following accounts:"
            : "We sent a password reset email to <b>" +
              selectedUsers[0].FirstName +
              " " +
              selectedUsers[0].LastName +
              "</b> at <i>" +
              selectedUsers[0].Email +
              "</i>",
        selectedUsers: selectedUsers,
      },
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public SendWelcomeEmailDialog(selectedUsers: RnUsersVM[]) {
    const dialog = this.dialogService.open(UserActionDialogComponent, {
      header: "",
      width: "600px",
      contentStyle: { "max-height": "700px", overflow: "auto" },
      baseZIndex: 10000,
      closable: false,
      showHeader: false,
      modal: true,
      dismissableMask: true,
      data: {
        actionTitle: "Welcome email resent",
        actionHeader:
          selectedUsers.length > 1
            ? "We resent welcome emails to the following accounts:"
            : "We resent a welcome email to <b>" +
              selectedUsers[0].FirstName +
              " " +
              selectedUsers[0].LastName +
              "</b> at <i>" +
              selectedUsers[0].Email +
              "</i>",
        selectedUsers: selectedUsers,
      },
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public UnlockUserDialog(selectedUsers: RnUsersVM[]) {
    const dialog = this.dialogService.open(UserActionDialogComponent, {
      header: "",
      width: "600px",
      contentStyle: { "max-height": "700px", overflow: "auto" },
      baseZIndex: 10000,
      closable: false,
      showHeader: false,
      modal: true,
      dismissableMask: true,
      data: {
        actionTitle: "Account unlocked",
        actionHeader:
          selectedUsers.length > 1
            ? "We unlocked the following accounts:"
            : "We unlocked <b>" +
              selectedUsers[0].FirstName +
              " " +
              selectedUsers[0].LastName +
              "</b> (<i>" +
              selectedUsers[0].Email +
              "</i>)'s account.",
        selectedUsers: selectedUsers,
      },
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public PreviousPackageActionDialog(
    selectedUsersWithPackage: RnUsersVM[],
    mode = "",
    isPurchasable: string,
    showDeleteUserText = true,
  ): DynamicDialogRef {
    const dialog = this.dialogService.open(UserKeepDropPackageDialogComponent, {
      header: "",
      width: "600px",
      contentStyle: { overflow: "auto" },
      baseZIndex: 10000,
      closable: false,
      showHeader: false,
      data: {
        isPurchasable: isPurchasable,
        showDeleteUserText: showDeleteUserText,
        mode: mode,
        users: selectedUsersWithPackage,
        packageTypes: selectedUsersWithPackage.reduce((r, a) => {
          for (const b of a.DirectBilledPackages) {
            if (!r[b]) {
              r[b] = [a.UserID];
            } else {
              r[b].push(a.UserID);
            }
          }
          return r;
        }, {}),
        totalPackages: selectedUsersWithPackage
          .filter((p) => p.DirectBilledPackages.length)
          .reduce((r, a) => r + a.DirectBilledPackages.length, 0),
      },
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public ChangeStatusDialog(): DynamicDialogRef {
    const dialog = this.dialogService.open(OrganizationChangeStatusComponent, {
      header: "",
      width: "1400px",
      contentStyle: { overflow: "auto" },
      baseZIndex: 10000,
      closable: false,
      showHeader: false,
      data: {},
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public BillingSeparationDialog(resp: RnOrganizationsProfileVM) {
    const dialog = this.dialogService.open(BillingSeparationComponent, {
      header: "",
      width: "35%",
      contentStyle: { overflow: "auto" },
      baseZIndex: 10000,
      closable: false,
      showHeader: false,
      data: resp,
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public OrganizationRenameUserLoggedInDialog(
    config: OrgRenameUserLoggedInConfig,
  ) {
    const dialog = this.dialogService.open(
      OrgRenameUserLoggedInDialogComponent,
      {
        header: "",
        width: "800px",
        height: "auto",
        baseZIndex: 10000,
        closable: false,
        showHeader: false,
        data: config,
      },
    );
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public PackageAssignmentDialog(
    config: PackageAssignConfigData,
  ): DynamicDialogRef {
    const dialog = this.dialogService.open(PackageAssignmentComponent, {
      header: "",
      width: "80%",
      height: "auto",
      baseZIndex: 10000,
      styleClass: "packageAssigmentDialog",
      closable: false,
      showHeader: false,
      modal: true,
      data: config,
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public AddEditLicenseDialog(config: LicenseAddEditConfig): DynamicDialogRef {
    const dialog = this.dialogService.open(LicenseAddEditComponent, {
      header: "",
      width: "40%",
      baseZIndex: 10000,
      closable: false,
      showHeader: false,
      modal: true,
      data: config,
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public AddQuickbooksSubs(config: LicenseAddEditConfig): DynamicDialogRef {
    const dialog = this.dialogService.open(LicenseAddEditComponent, {
      header: "",
      width: "80%",
      baseZIndex: 10000,
      closable: false,
      showHeader: false,
      data: config,
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public ManageQuickbooksSubs(config: ManageQboConfig): DynamicDialogRef {
    const dialog = this.dialogService.open(QboSubscriptionManagementComponent, {
      header: "",
      width: "70%",
      baseZIndex: 10000,
      closable: false,
      showHeader: false,
      data: config,
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public PurchaseLeaseLicenseDialog(
    config: LicensePurchaseConfig,
  ): DynamicDialogRef {
    const dialog = this.dialogService.open(LicensePurchaseComponent, {
      header: "",
      width: "82%",
      height: "85%",
      baseZIndex: 10000,
      closable: false,
      showHeader: false,
      data: config,
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public AssignLicenseDialog(config: LicenseAssignConfig): DynamicDialogRef {
    const dialog = this.dialogService.open(LicenseAssignComponent, {
      header: "",
      width: "80%",
      baseZIndex: 10000,
      closable: false,
      showHeader: false,
      data: config,
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public CreateRenameFolderDialog(
    config: CreateRenameFolderConfig,
  ): DynamicDialogRef {
    const dialog = this.dialogService.open(CreateRenameFolderComponent, {
      header: "",
      width: "40%",
      height: "auto",
      baseZIndex: 10000,
      closable: false,
      showHeader: false,
      modal: true,
      data: config,
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public DeleteFolderDialog(config: DeleteFldrConfig): DynamicDialogRef {
    const dialog = this.dialogService.open(DeleteFldrConfirmComponent, {
      header: "",
      width: "40%",
      height: "auto",
      baseZIndex: 10000,
      closable: false,
      showHeader: false,
      data: config,
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public EditMfaDialog() {
    const dialog = this.dialogService.open(EditMfaDialogComponent, {
      header: "",
      contentStyle: {
        height: "auto",
        "max-width": "753px",
        overflow: "auto",
      },
      closable: true,
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: true,
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public MustSetupMfaDialog(userId) {
    const dialog = this.dialogService.open(MustSetupMfaDialogComponent, {
      header: "",
      width: "40%",
      height: "auto",
      closable: false,
      showHeader: false,
      data: {
        UserId: userId,
      },
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public DisableOrgMfaDialog() {
    const dialog = this.dialogService.open(DisableOrgMfaDialogComponent, {
      header: "",
      width: "30%",
      height: "auto",
      closable: true,
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: true,
    });
    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public SendFeedbackDialog() {
    const dialog = this.dialogService.open(UserFeedbackDialogComponent, {
      header: "",
      width: "500px",
      height: "auto",
      closable: false,
      showHeader: false,
      modal: true,
    });

    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public DuoLogin(
    duoDialogConfiguration: DuoDialogConfiguration,
  ): DynamicDialogRef {
    const dialog = this.dialogService.open(DuoDialogComponent, {
      header: "",
      width: "40%",
      contentStyle: { "max-height": "550px", overflow: "auto" },
      baseZIndex: 10000,
      closable: true,
      showHeader: false,
      data: duoDialogConfiguration,
      closeOnEscape: true,
      dismissableMask: true,
    });

    this.dialogTrackingService.addDialog(dialog);
    return dialog;
  }

  public BillingFormDialog(
    data: BillingFormData,
  ): MatDialogRef<BillingFormComponent> {
    const config = {
      header: "",
      width: "752px",
      contentStyle: { "max-height": "90%", overflow: "auto" },
      baseZIndex: 10000,
      height: "auto",
      "z-index": 2000,
      closable: false,
      showHeader: false,
      disableClose: true,
      closeOnNavigation: false,
      data: data,
    };
    return this.dialog.open(BillingFormComponent, config);
  }

  public CreateNewUserDialog(
    userTypes: RnUserTypesVM[],
    orgId: number,
    orgProfile: RnOrganizationsProfileVM,
    orgConfig: RnOrganizationConfigurationVM,
    orgWlpContactInfo: RnOrganizationWLPContactInformationVM,
    isUsingWelcomeWizard?: boolean,
    ownerActions?: Array<QuantityCounts>,
    userActions?: Array<QuantityCounts>,
  ): MatDialogRef<AddUserComponent> {
    const dialogData = {
      UserTypes: userTypes,
      OrganizationID: orgId,
      IsUsingWelcomeWizard: !!isUsingWelcomeWizard,
      OwnerPackages: ownerActions,
      UserPackages: userActions,
      OrgProfile: orgProfile,
      OrgConfig: orgConfig,
      OrgWlpContactInfo: orgWlpContactInfo,
    };
    const config = {
      header: "",
      width: "60%",
      contentStyle: { overflow: "auto" },
      baseZIndex: 10000,
      height: "auto",
      "z-index": 2000,
      closable: false,
      showHeader: false,
      disableClose: true,
      data: dialogData,
    };
    return this.dialog.open(AddUserComponent, config);
  }

  public EditUserProfileDialog(userId: number, orgId: number, title: string = ''): MatDialogRef<UserDetailsModalComponent> {
    const dialogData = {
      UserID: userId,
      OrganizationID: orgId,
      Title: title
    };
    const config = {
      header: "",
      width: "500px",
      contentStyle: { overflow: "auto" },
      baseZIndex: 10000,
      height: "auto",
      "z-index": 2000,
      closable: false,
      showHeader: false,
      disableClose: true,
      data: dialogData
    };

    return this.dialog.open(UserDetailsModalComponent, config);
  }

  public AzureCredentialsDialog(upn: string, azureLink: string): MatDialogRef<AzureCredentialsComponent> {
    const dialogData = {
      entraUPN: upn,
      azureVirtualDesktopLink: azureLink
    };
    const config = {
      header: "",
      width: "500px",
      contentStyle: { overflow: "auto" },
      baseZIndex: 10000,
      height: "auto",
      "z-index": 2000,
      closable: false,
      showHeader: false,
      disableClose: true,
      data: dialogData
    };


    return this.dialog.open(AzureCredentialsComponent, config);
  }
}
