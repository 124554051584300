import { GridColumnBaseConfiguration } from "./grid-column-base-configuration";

export class GridQuantityColumnConfiguration extends GridColumnBaseConfiguration {
  constructor(
    fieldName: string,
    title: string,
    classname: string,
    sortOrder: string,
  ) {
    super("quantity", fieldName, title, classname, sortOrder);
  }

  onQuantityChanged: (dataRow, $event) => void;
  onEdit: (dataRow, $event) => void;

  getValue(dataRow): number {
    return dataRow[this.FieldName] as number;
  }
}
