<ng-template #popContent>
  <div>
    <button
      type="button"
      class="popover-close close"
      (click)="handleClose()"
      aria-hidden="true"
    >
      <span tooltipPosition="top" pTooltip="Close">×</span>
    </button>
  </div>
  <div class="popover-text">
    <span [innerHtml]="popoverText | safeHtml"></span>
  </div>
</ng-template>
<div (mouseleave)="rnpopover.close()">
  <img
    alt="{{ clickIcon }} icon"
    [src]="clickIconSource"
    class="popover-click-img"
    [ngbPopover]="popContent"
    [triggers]="'mouseenter'"
    [autoClose]="'outside'"
    [popoverClass]="popoverClass()"
    #rnpopover="ngbPopover"
  />
</div>
