import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LoggedInInfoService } from "../../../../services/loggedInInfo/logged-in-info.service";
import { OrganizationLogicService } from "../../../../services/organization-logic/organization-logic.service";
import {
  RnOrganizationPackagePoolsForListVM,
  RnOrganizationsProfileVM,
} from "../../../../services/rnapi2-service/models/models";
import { GridColumnConfiguration } from "../../../third-party-wrappers/grid/configuration/grid-column-configuration";
import { GridConfiguration } from "../../../third-party-wrappers/grid/configuration/grid-configuration";
import { GridHyperlinkColumnConfiguration } from "../../../third-party-wrappers/grid/configuration/grid-hyperlink-column-configuration";
import { PackageConfigInfo } from "../package-config-info";

@Component({
  selector: "app-direct-inventory-table",
  templateUrl: "./direct-inventory-table.component.html",
  styleUrls: ["./direct-inventory-table.component.scss"],
})
export class DirectInventoryTableComponent {
  @Input() Heading: string;
  @Input() Subtext: string;
  @Input() QuantityColumnHeading = "Seats";
  @Input() set PackageList(list: Array<RnOrganizationPackagePoolsForListVM>) {
    this._packageList = list;
    this.TableConfiguration.GridData = list;
  }
  @Input() Loading = false;
  @Input() set OrgProfile(profile: RnOrganizationsProfileVM) {
    this.orgProfile = profile;
    if (this.orgProfile) {
      this.setupColumnsForTables();
    }
  }
  @Input() set TopLevelOrgId(topLevelOrgId: number) {
    this.topLevelOrgId = topLevelOrgId;
  }

  get TopLevelOrgId(): number {
    return this.topLevelOrgId;
  }

  @Input() PkgConfigInfo: PackageConfigInfo;
  @Input() set IsAddOns(isAddOns: boolean) {
    this.isAddOns = isAddOns;
    if (this.orgProfile) {
      this.setupColumnsForTables();
    }
  }

  @Output() PackagePoolSelected =
    new EventEmitter<RnOrganizationPackagePoolsForListVM>();

  public TableConfiguration = new GridConfiguration();
  constructor(
    private orgLogicService: OrganizationLogicService,
    private loggedInUserService: LoggedInInfoService,
  ) {}

  public SelectPackage(orgPackPool: RnOrganizationPackagePoolsForListVM) {
    if (this.PackagePoolSelected) {
      this.PackagePoolSelected.emit(orgPackPool);
    }
  }

  private setupColumnsForTables() {
    const loggedInUser = this.loggedInUserService.GetLoggedInUser();
    if (!loggedInUser) {
      return;
    }
    this.TableConfiguration.FirstRow = 0;
    this.TableConfiguration.ColumnConfiguration = [];
    this.TableConfiguration.UsePaginator = false;
    this.TableConfiguration.IsLazyLoading = false;
    this.TableConfiguration.ShowCountHeader = false;
    this.TableConfiguration.GridClass = "packageAddonTable";
    if (!this.TableConfiguration.GridData) {
      this.TableConfiguration.GridData = [];
    }

    const ASNRMALLPK = this.loggedInUserService.loggedInUserHasRights([
      "ASNRMALLPK",
    ]);
    const isInternal = loggedInUser.IsInternal();
    const loggedInUserOrg = this.loggedInUserService.GetLoggedInUserOrg();
    if (
      this.orgLogicService.CanOpenPackagesAddOns(
        ASNRMALLPK,
        isInternal,
        this.orgProfile,
        loggedInUserOrg,
      )
    ) {
      const packageNameCol = new GridHyperlinkColumnConfiguration(
        "PackageName",
        "Type",
        "col-55-width direct-inventory-table-header",
        "",
      );
      packageNameCol.ExtraData = {
        pkgComponent: this,
      };
      if (!this.IsAddOns) {
        packageNameCol.LinkIsEnableHandler = (rowData: any) => {
          const pkg = rowData as RnOrganizationPackagePoolsForListVM;
          if (pkg) {
            return pkg.PackageType !== "BillOnly";
          }

          return false;
        };
      }
      packageNameCol.ClickAvailableHandler = (rowData: any) => {
        const pkg = rowData as RnOrganizationPackagePoolsForListVM;
        if (pkg) {
          return pkg.PackageType !== "BillOnly";
        }

        return false;
      };

      packageNameCol.ClickCommandHandler = (rowData: any, extraData: any) => {
        const pkg = rowData as RnOrganizationPackagePoolsForListVM;
        if (pkg && extraData) {
          const pkgComponent =
            extraData.pkgComponent as DirectInventoryTableComponent;
          if (pkgComponent) {
            pkgComponent.SelectPackage(pkg);
          }
        }
      };

      this.TableConfiguration.ColumnConfiguration.push(packageNameCol);
    } else {
      this.TableConfiguration.ColumnConfiguration.push(
        new GridColumnConfiguration(
          "PackageName",
          "Type",
          "col-55-width direct-inventory-table-header",
          "",
        ),
      );
    }

    this.TableConfiguration.ColumnConfiguration.push(
      new GridColumnConfiguration(
        "NumberAllowed",
        this.QuantityColumnHeading,
        "col-15-width-center direct-inventory-table-header",
        "",
      ),
    );
    const assignedCol = new GridColumnConfiguration(
      "NumberUsed",
      "Assigned",
      "col-15-width-center direct-inventory-table-header",
      "",
    );
    if (this.isAddOns) {
      assignedCol.CustomDataRetrieval = (dataRow: any) => {
        const pkg = dataRow as RnOrganizationPackagePoolsForListVM;
        if (pkg) {
          if (pkg.PackageType === "BillOnly") {
            return "N/A";
          } else {
            return pkg.NumberUsed.toString();
          }
        }
        return "";
      };
    }
    this.TableConfiguration.ColumnConfiguration.push(assignedCol);
    const remainingCol = new GridColumnConfiguration(
      "Remaining",
      "Remaining",
      "col-15-width-center direct-inventory-table-header",
      "",
    );
    if (this.isAddOns) {
      remainingCol.CustomDataRetrieval = (dataRow: any) => {
        const pkg = dataRow as RnOrganizationPackagePoolsForListVM;
        if (pkg) {
          if (pkg.PackageType === "BillOnly") {
            return "N/A";
          } else {
            return (pkg.NumberAllowed - pkg.NumberUsed).toString();
          }
        }
        return "";
      };
    } else {
      remainingCol.CustomDataRetrieval = (dataRow: any) => {
        const pkg = dataRow as RnOrganizationPackagePoolsForListVM;
        if (pkg) {
          return (pkg.NumberAllowed - pkg.NumberUsed).toString();
        }
        return "";
      };
    }
    this.TableConfiguration.ColumnConfiguration.push(remainingCol);
    this.TableConfiguration.RetrieveClassForCell = (col, rowData) => {
      return col.FieldName === "PackageName"
        ? "col-55-width"
        : "col-15-width-center";
    };
  }

  private _packageList: Array<RnOrganizationPackagePoolsForListVM>;
  private orgProfile: RnOrganizationsProfileVM;
  private isAddOns: boolean;
  private topLevelOrgId: number;
}
