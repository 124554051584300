import { Component, OnInit } from "@angular/core";
import { LoggedInInfoService } from "../../../../services/loggedInInfo/logged-in-info.service";
import { NavigationService } from "../../../../services/navigation/navigation.service";
import { AuthService } from "@rn-platform/frontend-shared-feature-identity-auth";

@Component({
  selector: "app-rn-sidebar",
  templateUrl: "./rn-sidebar.component.html",
  styleUrls: ["./rn-sidebar.component.scss"],
})
export class RnSidebarComponent implements OnInit {
  public showRNSupportItems: boolean;
  public isUserLoggedIn: boolean;

  constructor(
    private navigationService: NavigationService,
    private loggedInInfoService: LoggedInInfoService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.isLoggedIn().subscribe((b) => {
      this.isUserLoggedIn = b;
    });
    this.loggedInInfoService.LoggedInuserOrgConfig.subscribe((c) => {
      if (c) {
        this.showRNSupportItems = !c.DisallowRNSupport;
      }
    });
  }

  public launchDownloadSetupWizard(): void {
    this.navigationService.showDownloadSetupDialog(false);
  }
}
