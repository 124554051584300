/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnStringStringKeyValuePair } from "../models/./stringStringKeyValuePair-d";
import { RnEventLogInfo } from "../models/./eventLogInfo-d";

export class RnLicenseUpdateDTO {
  ItemsToUpdate?: Array<RnStringStringKeyValuePair> | null;
  IsByol?: boolean;
  LicensesID?: number;
  UpdatedItems?: { [key: string]: string } | null;
  EventLogInfo?: RnEventLogInfo;
  AffectedOrganizationId?: number;
  AffectedUserId?: number;
}
