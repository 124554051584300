/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnRNOrderHeader } from "../models/./rNOrderHeader-d";
import { RnRNWWAssignedItem } from "../models/./rNWWAssignedItem-d";
import { RnRNWWAssignedLicense } from "../models/./rNWWAssignedLicense-d";
import { RnRNWWOrderItem } from "../models/./rNWWOrderItem-d";
import { RnEventLogInfo } from "../models/./eventLogInfo-d";

export class RnRNWelcomeWizardOrderPayload {
  Header: RnRNOrderHeader;
  OrganizationID: number;
  PaymentOnFileID: number;
  OrderItems: Array<RnRNWWOrderItem>;
  AssignedItems: Array<RnRNWWAssignedItem>;
  RemovedItems?: Array<RnRNWWAssignedItem> | null;
  AssignedLicenses: Array<RnRNWWAssignedLicense>;
  PromotionCode: string | null;
  PromotionCodeID: number;
  EventLogInfo?: RnEventLogInfo;
  AffectedOrganizationId?: number;
  AffectedUserId?: number;
}
