import { Component } from "@angular/core";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/dynamicdialog";
import { DialogTrackingService } from "../../../services/dialog/dialog-tracking.service";

@Component({
  selector: "app-organization-change-status",
  templateUrl: "./organization-change-status.component.html",
  styleUrls: ["./organization-change-status.component.scss"],
})
export class OrganizationChangeStatusComponent {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogTrackService: DialogTrackingService,
  ) {}

  public canSubmit(): boolean {
    return true;
  }

  public close(): void {
    this.dialogTrackService.closeDialog(this.ref, null);
  }
}
