<div *ngIf="!loading && nonPurchasablePackages?.length > 0">
  <div class="col-lg-12 no-padding" *ngIf="!hideTitles">
    <h3 class="select-package-addon-panel-title">{{ partnerName }} Packages</h3>
    <div class="step-desc">{{ infoText }}</div>
  </div>
  <div class="package-cards m-l-neg15 p-b-25 border-bottom-faded">
    <app-package-card
      [attr.data-testid]="'npPackageCard' + i"
      *ngFor="let package of nonPurchasablePackages; let i = index"
      pTooltip="<span data-testid='tooltipMsg'>{{
        package
          | rnPackage
            : 'pkgTooltip'
            : canPurchase
            : canPurchaseNeedsPOF
            : selectedPackage?.PackageID
            : userData
      }}</span>"
      tooltipStyleClass="nonPurchasableCardTooltip"
      [escape]="false"
      (click)="packageSelected(package)"
      [ngClass]="{
        disabled:
          package
          | rnPackage
            : 'isDisabled'
            : canPurchase
            : selectedPackage?.PackageID
            : userData,
        'card-selected-not-purchasable': !package.Selected,
        'card-selected': package.Selected
      }"
      class="package-card"
      [Package]="package"
      CardType="selectable"
    >
    </app-package-card>
  </div>
</div>
<div *ngIf="!loading">
  <div class="step-desc" *ngIf="!hideTitles">
    If you do not need a package for this user, select the No Package option and
    click Next.
  </div>
  <div class="package-cards m-l-neg15 p-b-25">
    <app-package-card
      [attr.data-testid]="'pPackageCard' + i"
      *ngFor="let package of purchasablePackages; let i = index"
      [escape]="false"
      [Package]="package"
      pTooltip="<span data-testid='tooltipMsg'>{{
        package
          | rnPackage
            : 'pkgTooltip'
            : canPurchase
            : canPurchaseNeedsPOF
            : selectedPackage?.PackageID
            : userData
      }}</span>"
      [tooltipDisabled]="
        package.PackageName === 'No Package' || package.isTooltipDisabled
      "
      class="package-card"
      tooltipStyleClass="purchasableCardTooltip"
      [ngClass]="{
        disabled:
          package
          | rnPackage
            : 'isDisabled'
            : canPurchase
            : selectedPackage?.PackageID
            : userData,
        'card-selected': package.Selected
      }"
      (click)="packageSelected(package)"
      CardType="selectable"
    ></app-package-card>
  </div>
</div>
