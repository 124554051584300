/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export class RnApplicationVM {
  ItemFlavorId?: number;
  BlockingFlavorId?: number | null;
  ItemId?: number;
  Name?: string | null;
  Installed?: boolean;
  InstalledDate?: string | null;
  ProvisioningState?: string | null;
  FlavorString?: string | null;
  DescriptionHtml?: string | null;
  ImageUrl?: string | null;
  CanBeLicensed?: boolean;
  LicenseAvailable?: boolean;
  IsManualRemove?: boolean;
  IsManualInstall?: boolean;
  FlavorId?: number;
  FlavorRegEdition?: string | null;
  RemoteAppEnabled?: boolean;
  FlavorUICategoryDesc?: string | null;
  FlavorUICategoryName?: string | null;
  FlavorUICategoryID?: number;
  FlavorUICategorySortOrder?: number;
  LocalAppDownloadURL?: string | null;
}
