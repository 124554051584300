import { Injectable } from "@angular/core";
import { CountryInfo } from "../../../core/models/country-info";
import { CountryCommonInfo } from "../../../core/models/country.common.info";
import { ZipCodeInfo } from "../../../core/models/zip-code-info";
import { Observable, of } from "rxjs";
import { ZipCodeService } from "../rnapi2-service/apis/zipCode.service";
import { CityStateLookupError } from "src/app/core/models/city-state-lookup-error";

@Injectable({
  providedIn: "root",
})
export class AddressService {
  // #region Constructor
  constructor(private zipCodeService: ZipCodeService) {}
  // #endregion
  private subscriptions = [];
  private res: ZipCodeInfo = null;
  // #region public members
  public getCountryInfo(): CountryCommonInfo {
    const result = new CountryCommonInfo();

    result.allCountries = this.getCountryList();
    const usCountry = result.allCountries.filter((c) => {
      return c.code === "USA";
    });
    result.usStates = usCountry.length > 0 ? usCountry[0].regions : null;
    const caCountry = result.allCountries.filter((c) => {
      return c.code === "CAN";
    });
    result.caRegions = caCountry.length > 0 ? caCountry[0].regions : null;

    return result;
  }
  public getZipCodeInfo(zipCode: string): Observable<ZipCodeInfo> {
    const found = zipCode.match(/^[+ 0-9]{5}$/);
    if (!zipCode || zipCode === "" || !found) {
      return new Observable<ZipCodeInfo>(null);
    }

    this.subscriptions.push(
      this.zipCodeService
        .apiV2GeneralZipCodeGet(zipCode, "response")
        .subscribe((r) => {
          if (r.body) {
            if (r.body.ZipCode) {
              const addressServiceResponse = r.body.ZipCode;
              if (addressServiceResponse) {
                this.res = addressServiceResponse;
              }
              if (!this.res) {
                this.res = new ZipCodeInfo();
                this.res.Error = new CityStateLookupError();
                this.res.Error.Description = "Data not retrieved.";
              }
            }
          }
        }),
    );
    return of(this.res);
  }

  // #endregion

  // #region private members
  private getCountryList(): CountryInfo[] {
    const countries = [];

    countries.push({ name: "Afghanistan", code: "AFG" });
    countries.push({ name: "Åland Islands", code: "ALA" });
    countries.push({ name: "Albania", code: "ALB" });
    countries.push({ name: "Algeria", code: "DZA" });
    countries.push({ name: "American Samoa", code: "ASM" });
    countries.push({ name: "Andorra", code: "AND" });
    countries.push({ name: "Angola", code: "AGO" });
    countries.push({ name: "Anguilla", code: "AIA" });
    countries.push({ name: "Antigua and Barbuda", code: "ATG" });
    countries.push({ name: "Argentina", code: "ARG" });
    countries.push({ name: "Armenia", code: "ARM" });
    countries.push({ name: "Aruba", code: "ABW" });
    countries.push({
      name: "Australia",
      code: "AUS",
      regions: [
        {
          code: "NSW",
          name: "New South Wales",
        },
        {
          code: "QLD",
          name: "Queensland",
        },
        {
          code: "SA",
          name: "South Australia",
        },
        {
          code: "TAS",
          name: "Tasmania",
        },
        {
          code: "VIC",
          name: "Victoria",
        },
        {
          code: "WA",
          name: "Western Australia",
        },
        {
          code: "ACT",
          name: "Australian Capital Territory",
        },
        {
          code: "NT",
          name: "Northern Territory",
        },
      ],
    });
    countries.push({ name: "Austria", code: "AUT" });
    countries.push({ name: "Azerbaijan", code: "AZE" });
    countries.push({ name: "The Bahamas", code: "BHS" });
    countries.push({ name: "Bahrain", code: "BHR" });
    countries.push({ name: "Bangladesh", code: "BGD" });
    countries.push({ name: "Barbados", code: "BRB" });
    countries.push({ name: "Belarus", code: "BLR" });
    countries.push({ name: "Belgium", code: "BEL" });
    countries.push({ name: "Belize", code: "BLZ" });
    countries.push({ name: "Benin", code: "BEN" });
    countries.push({ name: "Bermuda", code: "BMU" });
    countries.push({ name: "Bhutan", code: "BTN" });
    countries.push({ name: "Bolivia", code: "BOL" });
    countries.push({ name: "Bonaire", code: "BES" });
    countries.push({ name: "Bosnia and Herzegovina", code: "BIH" });
    countries.push({ name: "Botswana", code: "BWA" });
    countries.push({ name: "Bouvet Island", code: "BVT" });
    countries.push({ name: "Brazil", code: "BRA" });
    countries.push({ name: "British Indian Ocean Territory", code: "IOT" });
    countries.push({ name: "British Virgin Islands", code: "VGB" });
    countries.push({ name: "Brunei", code: "BRN" });
    countries.push({ name: "Bulgaria", code: "BGR" });
    countries.push({
      name: "Burkina Faso",
      code: "BFA",
    });
    countries.push({
      name: "Burundi",
      code: "BDI",
    });
    countries.push({
      name: "Cambodia",
      code: "KHM",
    });
    countries.push({
      name: "Cameroon",
      code: "CMR",
    });
    countries.push({
      name: "Canada",
      code: "CAN",
      regions: [
        {
          code: "AB",
          name: "Alberta",
        },
        {
          code: "BC",
          name: "British Columbia",
        },
        {
          code: "MB",
          name: "Manitoba",
        },
        {
          code: "NB",
          name: "New Brunswick",
        },
        {
          code: "NL",
          name: "Newfoundland and Labrador",
        },
        {
          code: "NS",
          name: "Nova Scotia",
        },
        {
          code: "NT",
          name: "Northwest Territories",
        },
        {
          code: "NU",
          name: "Nunavut",
        },
        {
          code: "ON",
          name: "Ontario",
        },
        {
          code: "PE",
          name: "Prince Edward Island",
        },
        {
          code: "QC",
          name: "Quebec",
        },
        {
          code: "SK",
          name: "Saskatchewan",
        },
        {
          code: "YT",
          name: "Yukon",
        },
      ],
    });
    countries.push({
      name: "Cape Verde",
      code: "CPV",
    });
    countries.push({
      name: "Cayman Islands",
      code: "CYM",
    });
    countries.push({
      name: "Central African Republic",
      code: "CAF",
    });
    countries.push({
      name: "Chad",
      code: "TCD",
    });
    countries.push({
      name: "Chile",
      code: "CHL",
    });
    countries.push({
      name: "China",
      code: "CHN",
    });
    countries.push({
      name: "Christmas Island",
      code: "CXR",
    });
    countries.push({
      name: "Cocos (Keeling) Islands",
      code: "CCK",
    });
    countries.push({
      name: "Colombia",
      code: "COL",
    });
    countries.push({
      name: "Comoros",
      code: "COM",
    });
    countries.push({
      name: "Republic of the Congo",
      code: "COG",
    });
    countries.push({
      name: "Democratic Republic of the Congo",
      code: "COD",
    });
    countries.push({
      name: "Cook Islands",
      code: "COK",
    });
    countries.push({
      name: "Costa Rica",
      code: "CRI",
    });
    countries.push({
      name: "Croatia",
      code: "HRV",
    });
    countries.push({
      name: "Cuba",
      code: "CUB",
    });
    countries.push({
      name: "Curaçao",
      code: "CUW",
    });
    countries.push({
      name: "Cyprus",
      code: "CYP",
    });
    countries.push({
      name: "Czech Republic",
      code: "CZE",
    });
    countries.push({
      name: "Denmark",
      code: "DNK",
    });
    countries.push({
      name: "Djibouti",
      code: "DJI",
    });
    countries.push({
      name: "Dominica",
      code: "DMA",
    });
    countries.push({
      name: "Dominican Republic",
      code: "DOM",
    });
    countries.push({
      name: "Ecuador",
      code: "ECU",
    });
    countries.push({
      name: "Egypt",
      code: "EGY",
    });
    countries.push({
      name: "El Salvador",
      code: "SLV",
    });
    countries.push({
      name: "Equatorial Guinea",
      code: "GNQ",
    });
    countries.push({
      name: "Eritrea",
      code: "ERI",
    });
    countries.push({
      name: "Estonia",
      code: "EST",
    });
    countries.push({
      name: "Ethiopia",
      code: "ETH",
    });
    countries.push({
      name: "Falkland Islands",
      code: "FLK",
    });
    countries.push({
      name: "Faroe Islands",
      code: "FRO",
    });
    countries.push({
      name: "Fiji",
      code: "FJI",
    });
    countries.push({
      name: "Finland",
      code: "FIN",
    });
    countries.push({
      name: "France",
      code: "FRA",
    });
    countries.push({
      name: "French Polynesia",
      code: "PYF",
    });
    countries.push({
      name: "French Southern and Antarctic Lands",
      code: "ATF",
    });
    countries.push({
      name: "Gabon",
      code: "GAB",
    });
    countries.push({
      name: "Gambia",
      code: "GMB",
    });
    countries.push({
      name: "Georgia",
      code: "GEO",
    });
    countries.push({
      name: "Germany",
      code: "DEU",
    });
    countries.push({
      name: "Ghana",
      code: "GHA",
    });
    countries.push({
      name: "Gibraltar",
      code: "GIB",
    });
    countries.push({
      name: "Greece",
      code: "GRC",
    });
    countries.push({
      name: "Greenland",
      code: "GRL",
    });
    countries.push({
      name: "Grenada",
      code: "GRD",
    });
    countries.push({
      name: "Guam",
      code: "GUM",
    });
    countries.push({
      name: "Guatemala",
      code: "GTM",
    });
    countries.push({
      name: "Guernsey",
      code: "GGY",
    });
    countries.push({
      name: "Guinea",
      code: "GIN",
    });
    countries.push({
      name: "Guinea-Bissau",
      code: "GNB",
    });
    countries.push({
      name: "Guyana",
      code: "GUY",
    });
    countries.push({
      name: "Haiti",
      code: "HTI",
    });
    countries.push({
      name: "Heard Island and McDonald Islands",
      code: "HMD",
    });
    countries.push({
      name: "Vatican City",
      code: "VAT",
    });
    countries.push({
      name: "Honduras",
      code: "HND",
    });
    countries.push({
      name: "Hong Kong",
      code: "HKG",
    });
    countries.push({
      name: "Hungary",
      code: "HUN",
    });
    countries.push({
      name: "Iceland",
      code: "ISL",
    });
    countries.push({
      name: "India",
      code: "IND",
    });
    countries.push({
      name: "Indonesia",
      code: "IDN",
    });
    countries.push({
      name: "Ivory Coast",
      code: "CIV",
    });
    countries.push({
      name: "Iran",
      code: "IRN",
    });
    countries.push({
      name: "Iraq",
      code: "IRQ",
    });
    countries.push({
      name: "Republic of Ireland",
      code: "IRL",
    });
    countries.push({
      name: "Isle of Man",
      code: "IMN",
    });
    countries.push({
      name: "Israel",
      code: "ISR",
    });
    countries.push({
      name: "Italy",
      code: "ITA",
    });
    countries.push({
      name: "Jamaica",
      code: "JAM",
    });
    countries.push({
      name: "Japan",
      code: "JPN",
    });
    countries.push({
      name: "Jersey",
      code: "JEY",
    });
    countries.push({
      name: "Jordan",
      code: "JOR",
    });
    countries.push({
      name: "Kazakhstan",
      code: "KAZ",
    });
    countries.push({
      name: "Kenya",
      code: "KEN",
    });
    countries.push({
      name: "Kiribati",
      code: "KIR",
    });
    countries.push({
      name: "Kuwait",
      code: "KWT",
    });
    countries.push({
      name: "Kyrgyzstan",
      code: "KGZ",
    });
    countries.push({
      name: "Laos",
      code: "LAO",
    });
    countries.push({
      name: "Latvia",
      code: "LVA",
    });
    countries.push({
      name: "Lebanon",
      code: "LBN",
    });
    countries.push({
      name: "Lesotho",
      code: "LSO",
    });
    countries.push({
      name: "Liberia",
      code: "LBR",
    });
    countries.push({
      name: "Libya",
      code: "LBY",
    });
    countries.push({
      name: "Liechtenstein",
      code: "LIE",
    });
    countries.push({
      name: "Lithuania",
      code: "LTU",
    });
    countries.push({
      name: "Luxembourg",
      code: "LUX",
    });
    countries.push({
      name: "Macau",
      code: "MAC",
    });
    countries.push({
      name: "Republic of Macedonia",
      code: "MKD",
    });
    countries.push({
      name: "Madagascar",
      code: "MDG",
    });
    countries.push({
      name: "Malawi",
      code: "MWI",
    });
    countries.push({
      name: "Malaysia",
      code: "MYS",
    });
    countries.push({
      name: "Maldives",
      code: "MDV",
    });
    countries.push({
      name: "Mali",
      code: "MLI",
    });
    countries.push({
      name: "Malta",
      code: "MLT",
    });
    countries.push({
      name: "Marshall Islands",
      code: "MHL",
    });
    countries.push({
      name: "Mauritania",
      code: "MRT",
    });
    countries.push({
      name: "Mauritius",
      code: "MUS",
    });
    countries.push({
      name: "Mexico",
      code: "MEX",
    });
    countries.push({
      name: "Federated States of Micronesia",
      code: "FSM",
    });
    countries.push({
      name: "Moldova",
      code: "MDA",
    });
    countries.push({
      name: "Monaco",
      code: "MCO",
    });
    countries.push({
      name: "Mongolia",
      code: "MNG",
    });
    countries.push({
      name: "Montenegro",
      code: "MNE",
    });
    countries.push({
      name: "Montserrat",
      code: "MSR",
    });
    countries.push({
      name: "Morocco",
      code: "MAR",
    });
    countries.push({
      name: "Mozambique",
      code: "MOZ",
    });
    countries.push({
      name: "Myanmar",
      code: "MMR",
    });
    countries.push({
      name: "Namibia",
      code: "NAM",
    });
    countries.push({
      name: "Nauru",
      code: "NRU",
    });
    countries.push({
      name: "Nepal",
      code: "NPL",
    });
    countries.push({
      name: "Netherlands",
      code: "NLD",
    });
    countries.push({
      name: "New Caledonia",
      code: "NCL",
    });
    countries.push({
      name: "New Zealand",
      code: "NZL",
    });
    countries.push({
      name: "Nicaragua",
      code: "NIC",
    });
    countries.push({
      name: "Niger",
      code: "NER",
    });
    countries.push({
      name: "Nigeria",
      code: "NGA",
    });
    countries.push({
      name: "Niue",
      code: "NIU",
    });
    countries.push({
      name: "Norfolk Island",
      code: "NFK",
    });
    countries.push({
      name: "North Korea",
      code: "PRK",
    });
    countries.push({
      name: "Northern Mariana Islands",
      code: "MNP",
    });
    countries.push({
      name: "Norway",
      code: "NOR",
    });
    countries.push({
      name: "Oman",
      code: "OMN",
    });
    countries.push({
      name: "Pakistan",
      code: "PAK",
    });
    countries.push({
      name: "Palau",
      code: "PLW",
    });
    countries.push({
      name: "Palestine",
      code: "PSE",
    });
    countries.push({
      name: "Panama",
      code: "PAN",
    });
    countries.push({
      name: "Papua New Guinea",
      code: "PNG",
    });
    countries.push({
      name: "Paraguay",
      code: "PRY",
    });
    countries.push({
      name: "Peru",
      code: "PER",
    });
    countries.push({
      name: "Philippines",
      code: "PHL",
    });
    countries.push({
      name: "Pitcairn Islands",
      code: "PCN",
    });
    countries.push({
      name: "Poland",
      code: "POL",
    });
    countries.push({
      name: "Portugal",
      code: "PRT",
    });
    countries.push({
      name: "Puerto Rico",
      code: "PRI",
    });
    countries.push({
      name: "Qatar",
      code: "QAT",
    });
    countries.push({
      name: "Republic of Kosovo",
      code: "XKX",
    });
    countries.push({
      name: "Romania",
      code: "ROU",
    });
    countries.push({
      name: "Russia",
      code: "RUS",
    });
    countries.push({
      name: "Rwanda",
      code: "RWA",
    });
    countries.push({
      name: "Saint Barthélemy",
      code: "BLM",
    });
    countries.push({
      name: "Saint Kitts and Nevis",
      code: "KNA",
    });
    countries.push({
      name: "Saint Lucia",
      code: "LCA",
    });
    countries.push({
      name: "Saint Martin",
      code: "MAF",
    });
    countries.push({
      name: "Saint Pierre and Miquelon",
      code: "SPM",
    });
    countries.push({
      name: "Saint Vincent and the Grenadines",
      code: "VCT",
    });
    countries.push({
      name: "Samoa",
      code: "WSM",
    });
    countries.push({
      name: "San Marino",
      code: "SMR",
    });
    countries.push({
      name: "São Tomé and Príncipe",
      code: "STP",
    });
    countries.push({
      name: "Saudi Arabia",
      code: "SAU",
    });
    countries.push({
      name: "Senegal",
      code: "SEN",
    });
    countries.push({
      name: "Serbia",
      code: "SRB",
    });
    countries.push({
      name: "Seychelles",
      code: "SYC",
    });
    countries.push({
      name: "Sierra Leone",
      code: "SLE",
    });
    countries.push({
      name: "Singapore",
      code: "SGP",
    });
    countries.push({
      name: "Sint Maarten",
      code: "SXM",
    });
    countries.push({
      name: "Slovakia",
      code: "SVK",
    });
    countries.push({
      name: "Slovenia",
      code: "SVN",
    });
    countries.push({
      name: "Solomon Islands",
      code: "SLB",
    });
    countries.push({
      name: "Somalia",
      code: "SOM",
    });
    countries.push({
      name: "South Africa",
      code: "ZAF",
    });
    countries.push({
      name: "South Korea",
      code: "KOR",
    });
    countries.push({
      name: "South Sudan",
      code: "SSD",
    });
    countries.push({
      name: "Spain",
      code: "ESP",
    });
    countries.push({
      name: "Sri Lanka",
      code: "LKA",
    });
    countries.push({
      name: "Sudan",
      code: "SDN",
    });
    countries.push({
      name: "Suriname",
      code: "SUR",
    });
    countries.push({
      name: "Swaziland",
      code: "SWZ",
    });
    countries.push({
      name: "Sweden",
      code: "SWE",
    });
    countries.push({
      name: "Switzerland",
      code: "CHE",
    });
    countries.push({
      name: "Syria",
      code: "SYR",
    });
    countries.push({
      name: "Taiwan",
      code: "TWN",
    });
    countries.push({
      name: "Tajikistan",
      code: "TJK",
    });
    countries.push({
      name: "Tanzania",
      code: "TZA",
    });
    countries.push({
      name: "Thailand",
      code: "THA",
    });
    countries.push({
      name: "East Timor",
      code: "TLS",
    });
    countries.push({
      name: "Togo",
      code: "TGO",
    });
    countries.push({
      name: "Tokelau",
      code: "TKL",
    });
    countries.push({
      name: "Tonga",
      code: "TON",
    });
    countries.push({
      name: "Trinidad and Tobago",
      code: "TTO",
    });
    countries.push({
      name: "Tunisia",
      code: "TUN",
    });
    countries.push({
      name: "Turkey",
      code: "TUR",
    });
    countries.push({
      name: "Turkmenistan",
      code: "TKM",
    });
    countries.push({
      name: "Turks and Caicos Islands",
      code: "TCA",
    });
    countries.push({
      name: "Tuvalu",
      code: "TUV",
    });
    countries.push({
      name: "Uganda",
      code: "UGA",
    });
    countries.push({
      name: "Ukraine",
      code: "UKR",
    });
    countries.push({
      name: "United Arab Emirates",
      code: "ARE",
    });
    countries.push({
      name: "United Kingdom",
      code: "GBR",
    });
    countries.push({
      name: "United States",
      code: "USA",
      regions: [
        {
          name: "Alabama",
          code: "AL",
        },
        {
          name: "Alaska",
          code: "AK",
        },
        {
          name: "Arizona",
          code: "AZ",
        },
        {
          name: "Arkansas",
          code: "AR",
        },
        {
          name: "California",
          code: "CA",
        },
        {
          name: "Colorado",
          code: "CO",
        },
        {
          name: "Connecticut",
          code: "CT",
        },
        {
          name: "Delaware",
          code: "DE",
        },
        {
          name: "District of Columbia",
          code: "DC",
        },
        {
          name: "Florida",
          code: "FL",
        },
        {
          name: "Georgia",
          code: "GA",
        },
        {
          name: "Hawaii",
          code: "HI",
        },
        {
          name: "Idaho",
          code: "ID",
        },
        {
          name: "Illinois",
          code: "IL",
        },
        {
          name: "Indiana",
          code: "IN",
        },
        {
          name: "Iowa",
          code: "IA",
        },
        {
          name: "Kansas",
          code: "KS",
        },
        {
          name: "Kentucky",
          code: "KY",
        },
        {
          name: "Louisiana",
          code: "LA",
        },
        {
          name: "Maine",
          code: "ME",
        },
        {
          name: "Maryland",
          code: "MD",
        },
        {
          name: "Massachusetts",
          code: "MA",
        },
        {
          name: "Michigan",
          code: "MI",
        },
        {
          name: "Minnesota",
          code: "MN",
        },
        {
          name: "Mississippi",
          code: "MS",
        },
        {
          name: "Missouri",
          code: "MO",
        },
        {
          name: "Montana",
          code: "MT",
        },
        {
          name: "Nebraska",
          code: "NE",
        },
        {
          name: "Nevada",
          code: "NV",
        },
        {
          name: "New Hampshire",
          code: "NH",
        },
        {
          name: "New Jersey",
          code: "NJ",
        },
        {
          name: "New Mexico",
          code: "NM",
        },
        {
          name: "New York",
          code: "NY",
        },
        {
          name: "North Carolina",
          code: "NC",
        },
        {
          name: "North Dakota",
          code: "ND",
        },
        {
          name: "Ohio",
          code: "OH",
        },
        {
          name: "Oklahoma",
          code: "OK",
        },
        {
          name: "Oregon",
          code: "OR",
        },
        {
          name: "Pennsylvania",
          code: "PA",
        },
        {
          name: "Rhode Island",
          code: "RI",
        },
        {
          name: "South Carolina",
          code: "SC",
        },
        {
          name: "South Dakota",
          code: "SD",
        },
        {
          name: "Tennessee",
          code: "TN",
        },
        {
          name: "Texas",
          code: "TX",
        },
        {
          name: "Utah",
          code: "UT",
        },
        {
          name: "Vermont",
          code: "VT",
        },
        {
          name: "Virginia",
          code: "VA",
        },
        {
          name: "Washington",
          code: "WA",
        },
        {
          name: "West Virginia",
          code: "WV",
        },
        {
          name: "Wisconsin",
          code: "WI",
        },
        {
          name: "Wyoming",
          code: "WY",
        },
        {
          code: "AS",
          name: "American Samoa outlying territory",
        },
        {
          code: "GU",
          name: "Guam outlying territory",
        },
        {
          code: "MP",
          name: "Northern Mariana Islands outlying territory",
        },
        {
          code: "PR",
          name: "Puerto Rico  outlying territory",
        },
        {
          code: "UM",
          name: "United States Minor Outlying Islands outlying territory",
        },
        {
          code: "VI",
          name: "Virgin Islands, U.S. outlying territory",
        },
      ],
    });
    countries.push({
      name: "Uruguay",
      code: "URY",
    });
    countries.push({
      name: "Uzbekistan",
      code: "UZB",
    });
    countries.push({
      name: "Vanuatu",
      code: "VUT",
    });
    countries.push({
      name: "Venezuela",
      code: "VEN",
    });
    countries.push({
      name: "Vietnam",
      code: "VNM",
    });
    countries.push({
      name: "Wallis and Futuna",
      code: "WLF",
    });
    countries.push({
      name: "Western Sahara",
      code: "ESH",
    });
    countries.push({
      name: "Yemen",
      code: "YEM",
    });
    countries.push({
      name: "Zambia",
      code: "ZMB",
    });
    countries.push({
      name: "Zimbabwe",
      code: "ZWE",
    });
    return countries;
  }
  // #endregion
}
