<div class="row">
  <div class="col-md-6 no-padding headerContainer">
    <h3 class="headerText">Client Events</h3>
  </div>
</div>
<div class="user-events-wrapper">
  <app-loader *ngIf="LoadingEvents" text="Loading..."></app-loader>
  <app-events
    [EventsSearchResults]="UserEvents"
    [IsLoadingEvents]="LoadingEvents"
    (SearchOccurred)="GetEvents($event)"
    [ClearSearchCriteria]="clearSearch"
  ></app-events>
</div>
