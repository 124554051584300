import { Component } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { DialogTrackingService } from "../../../services/dialog/dialog-tracking.service";

@Component({
  selector: "app-user-action-dialog",
  templateUrl: "./user-action-dialog.component.html",
  styleUrls: ["./user-action-dialog.component.scss"],
})
export class UserActionDialogComponent {
  actionTitle: string;
  actionHeader: string;
  selectedUsers: any[];
  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private dialogTrackService: DialogTrackingService,
  ) {
    this.actionTitle = this.config.data.actionTitle;
    this.actionHeader = this.config.data.actionHeader;
    this.selectedUsers = this.config.data.selectedUsers;
  }

  public close(): void {
    this.dialogTrackService.closeDialog(this.ref, false);
  }
}
