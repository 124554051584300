import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "rnPurchaseQbo",
})
export class RnPurchaseQboPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value && args && args.length > 0) {
      const prop = args[0].toString().toLowerCase();
      switch (prop) {
        case "islaststep": {
          const currentStep = Number.parseInt(value.toString());
          const maxStep = Number.parseInt(args[1].toString());
          return currentStep === maxStep;
        }
        case "isfirststep": {
          const currentStep = Number.parseInt(value.toString());
          return currentStep === 1;
        }
        case "getnextlabel": {
          const currentStep = Number.parseInt(value.toString());
          const maxStep = Number.parseInt(args[1].toString());
          const forwardArrow = String.fromCharCode(8594);
          return currentStep === maxStep - 1
            ? "PURCHASE SUBSCRIPTION"
            : "NEXT " + forwardArrow;
        }
      }
    }
    return null;
  }
}
