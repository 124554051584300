import { RnApplicationVM } from "../../shared/services/rnapi2-service/models/models";
import { EnvService } from "../services/env.service";

export class ApplicationCard extends RnApplicationVM {
  thumbnailUrl: string;
  canInstall: boolean | null;
  requestType: RequestType;
  percentComplete = 0;
  isOpening: boolean;
  BlockingFlavorName?: string;
  popoverText = "";
  constructor(model: RnApplicationVM) {
    super();
    this.ItemFlavorId = model.ItemFlavorId;
    this.BlockingFlavorId = model.BlockingFlavorId;
    this.ItemId = model.ItemId;
    this.Name = model.Name;
    this.Installed = model.Installed;
    this.InstalledDate = model.InstalledDate;
    this.ProvisioningState = model.ProvisioningState;
    this.FlavorString = model.FlavorString;
    this.DescriptionHtml = model.DescriptionHtml;
    this.ImageUrl = model.ImageUrl;
    this.CanBeLicensed = model.CanBeLicensed;
    this.LicenseAvailable = model.LicenseAvailable;
    this.IsManualRemove = model.IsManualRemove;
    this.IsManualInstall = model.IsManualInstall;
    this.FlavorId = model.FlavorId;
    this.FlavorRegEdition = model.FlavorRegEdition;
    this.FlavorUICategoryID = model.FlavorUICategoryID;
    this.FlavorUICategoryDesc = model.FlavorUICategoryDesc;
    this.FlavorUICategoryName = model.FlavorUICategoryName;
    this.FlavorUICategorySortOrder = model.FlavorUICategorySortOrder;
    this.LocalAppDownloadURL = model.LocalAppDownloadURL;
    const i = this.ImageUrl?.lastIndexOf(".");
    //todo move baseurl to config value?

    this.thumbnailUrl =
      EnvService.EnvVariables().assetUrl +
      "/thumbnails/" +
      (this.ImageUrl?.substr(0, i) + "_tn" + this.ImageUrl?.substr(i))
        .replace("png", "jpg")
        .toLowerCase();
    this.canInstall =
      this.ProvisioningState == null ||
      this?.ProvisioningState == "Removed" ||
      this?.ProvisioningState == "InstallError"
        ? true
        : false;
    this.RemoteAppEnabled = model.RemoteAppEnabled;
  }
  isInstalled(): boolean {
    return this?.ProvisioningState === "Installed" ||
      this?.ProvisioningState === "Removing" ||
      this?.ProvisioningState === "RemoveError" ||
      this?.ProvisioningState === "RemoveFailure"
      ? true
      : false;
  }
  displayType(): string {
    if (this?.ProvisioningState == null) {
      return "Removed";
    } else {
      return this?.ProvisioningState;
    }
  }
}

export enum RequestType {
  Install = "INSTALL",
  Uninstall = "UNINSTALL",
  Open = "OPEN",
}
