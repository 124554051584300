/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export class RnUsersSearchVM {
  UserID?: number;
  LastFirstName?: string | null;
  Email?: string | null;
  OrganizationName?: string | null;
  OrganizationID?: number;
  UserState?: string | null;
  UserType?: string | null;
  FullName?: string | null;
}
