<div class="row not-auth-container">
  <div class="col-sm-12">
    <h1 class="text-danger">Access Denied</h1>
    <div class="alert alert-danger">
      <span
        >Access to this resource has been denied. Please contact your
        administrator for more information.</span
      >
    </div>
  </div>
</div>
