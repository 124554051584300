<div class="purch-items-container + {{ styleClass }}">
  <table width="100%">
    <tbody>
      <tr *ngFor="let item of purchases">
        <td class="item-table-first-col">
          <div class="license-purchase-item">
            <div [ngClass]="{ boldItem: useBold }">{{ item.typeName }}</div>
            <div>{{ item.license.PackageName }}</div>
            <div>
              <span [ngClass]="{ boldItem: useBold }">Organization:</span
              >&nbsp;{{ item.orgName }}
            </div>
            <div>
              <span [ngClass]="{ boldItem: useBold }">Quantity:</span>&nbsp;{{
                item.quantity
              }}
            </div>
          </div>
        </td>
        <td class="item-table-last-col item-total-text">
          <div class="license-purchase-item">
            {{ item.quantity * item.license.Price | currency
            }}<span *ngIf="item | rnLicensePurchaseItems: 'itemismonthly'">
              /month</span
            >
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="total-due-row">
        <td class="item-table-first-col total-due-text">Total Due Today</td>
        <td class="item-table-last-col total-due-amount-col">
          <span class="total-due-amount">{{
            purchaseChange
              | rnLicensePurchaseItems: "gettotal" : purchases : true
              | currency
          }}</span>
          <br />
          <span class="applicable-tax-text">Plus tax where applicable</span>
        </td>
      </tr>
      <tr>
        <td class="next-bill-text">
          Future Monthly Bill (starts {{ nextBillDate }})
        </td>
        <td class="item-table-last-col">
          {{
            purchaseChange
              | rnLicensePurchaseItems
                : "nextbilldue"
                : purchases
                : this.licenseConfig.paymentInfo?.NextBillAmount
                : false
              | currency
          }}
        </td>
      </tr>
    </tfoot>
  </table>
</div>
