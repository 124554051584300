import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "rnChangePackage",
})
export class RnChangePackagePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }
}
