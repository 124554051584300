import { Component, Input } from "@angular/core";
import { OrganizationAddressesVM } from "../../../../core/models/OrganizationAddressesVM";

@Component({
  selector: "app-organization-address",
  templateUrl: "./organization-address.component.html",
  styleUrls: ["./organization-address.component.scss"],
})
export class OrganizationAddressComponent {
  @Input() OrganizationAdress: OrganizationAddressesVM;

  showAddress2(): boolean {
    return this.OrganizationAdress && this.OrganizationAdress.Address2
      ? this.OrganizationAdress.Address2 !== ""
      : false;
  }
}
