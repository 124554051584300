/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnCommonOrderByTypes } from "../models/./commonOrderByTypes-d";
import { RnEventLogInfo } from "../models/./eventLogInfo-d";

export class RnFolderSearch {
  _pageSize?: number;
  _pageNumber?: number;
  _isDescendingOrder?: boolean;
  ParentFolderId?: number;
  Id?: string | null;
  search?: string | null;
  xId?: string | null;
  status?: string | null;
  createdDate?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  pageSize?: number;
  pageNumber?: number;
  isDescendingOrder?: boolean;
  OrderByTypes?: RnCommonOrderByTypes;
  OrderByText?: string | null;
  ExtraSearchText?: string | null;
  EventLogInfo?: RnEventLogInfo;
  AffectedOrganizationId?: number;
  AffectedUserId?: number;
}
