/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RnUsersVM } from "../models/./usersVM-d";

export class RnOrganizationsVM {
  ID?: number;
  Name?: string | null;
  ParentID?: number | null;
  WhiteLabelPartnerID?: number;
  IsFSPEnabled?: boolean;
  WhileLabelPartnerName?: string | null;
  WhileLabelPartnerDesc?: string | null;
  AccountOwnerName?: string | null;
  AccountOwnerID?: number | null;
  CAN?: Array<string> | null;
  OrgStateID?: number;
  OrgStateName?: string | null;
  Status?: string | null;
  WlpStatus?: string | null;
  PaymentOnFileID?: number;
  IdThatPaysForThisOrg?: number;
  EffStartDate?: string;
  EffEndDate?: string;
  CreatedDate?: string;
  CreatedBy?: string | null;
  Users?: Array<RnUsersVM> | null;
  Children?: Array<RnOrganizationsVM> | null;
  Parent?: RnOrganizationsVM;
  OrganizationThatPaysAccountOwnerFullName?: string | null;
  OrganizationThatPaysAccountOwnerEmail?: string | null;
  HasDirectPaymentOnFile?: boolean;
  DirectPaymentOnFileStateName?: string | null;
  IsInsightsInstalledForAO?: boolean;
}
