export class UserProfileUpdateEvent {
  constructor(
    public userId: number,
    public firstName: string,
    public lastName: string,
    public email: string,
  ) {}

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
