import { Pipe, PipeTransform } from "@angular/core";
import { LicensePurchaseResponse } from "../../components/rn-business-components/license-purchase/license-purchase-info";

@Pipe({
  name: "rnLicensePurchaseItems",
})
export class RnLicensePurchaseItemsPipe implements PipeTransform {
  transform(data: any, ...args: any[]): any {
    if (args && args.length > 0) {
      const param = args[0].toString().toLowerCase();
      switch (param) {
        case "gettotal":
          {
            if (args.length > 1) {
              const purchases = args[1] as LicensePurchaseResponse[];
              const fullAmount = args[2] as boolean;
              const total = this.getTotal(purchases, fullAmount);
              return total;
            }
          }
          break;
        case "nextbilldue":
          {
            if (args.length > 1) {
              const purchases = args[1] as LicensePurchaseResponse[];
              const nextBillAmt = parseFloat(args[2].toString());
              const fullAmount = args[3] as boolean;
              const total = this.getTotal(purchases, fullAmount) + nextBillAmt;
              return total;
            }
          }
          break;
        case "itemismonthly": {
          const item = data as LicensePurchaseResponse;
          return item.typeName === "Leased";
        }
      }
    }
    return null;
  }

  getTotal(purchases: LicensePurchaseResponse[], fullAmount: boolean): number {
    let total = 0;
    purchases.forEach((p) => {
      if (fullAmount || p.typeName === "Leased") {
        total += p.quantity * p.license.Price;
      }
    });

    return total;
  }
}
