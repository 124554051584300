import { Component, OnDestroy, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subscription } from "rxjs";
import { UserService } from "../../../services/rnapi2-service/apis/api";
import { RnUserNotifyOrgMFA } from "../../../services/rnapi2-service/models/models";
import { EnvService } from "../../../../core/services/env.service";

@Component({
  selector: "must-setup-mfa-dialog",
  templateUrl: "./must-setup-mfa-dialog.component.html",
  styleUrls: ["./must-setup-mfa-dialog.component.scss"],
})
export class MustSetupMfaDialogComponent implements OnInit, OnDestroy {
  assetUrl: string;
  userID: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private ref: DynamicDialogRef,
    private userService: UserService,
    public config: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.assetUrl = EnvService.EnvVariables().assetUrl;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  enableMfa() {
    const payload: RnUserNotifyOrgMFA = new RnUserNotifyOrgMFA();
    payload.User_ID = this.config.data.UserId.toString();
    payload.Enabled = false;

    this.userService
      .apiV2UsersNotifyorgmfaenabledPost(payload)
      .subscribe((result) => {
        this.ref.close();
      });
  }
}
