<div class="address-text">
  <div data-testid="address1" class="break">
    {{ OrganizationAdress?.Address1 }}
  </div>
  <div
    ng-if="OrganizationAddress?.Address2"
    data-testid="address2"
    class="break"
  >
    {{ OrganizationAdress?.Address2 }}
  </div>
  <div data-testid="city/state">
    {{ OrganizationAdress?.City }}, {{ OrganizationAdress?.StateProvince }}
  </div>
  <div data-testid="postCode">{{ OrganizationAdress?.PostalCodeID }}</div>
  <div data-testid="country">{{ OrganizationAdress?.Country }}</div>
</div>
