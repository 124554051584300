import { NgModule, ModuleWithProviders, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IdleService } from "./testing/IdleService";
import { DynamicDialogConfig } from "./testing/DynamicDialogConfig";
import { DynamicDialogRef } from "./testing/DynamicDialogRef";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  schemas: [NO_ERRORS_SCHEMA],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [IdleService, DynamicDialogConfig, DynamicDialogRef],
    };
  }
}
