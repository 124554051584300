// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: "http://localhost:52772",
  //baseUrl: 'https://rn-rnapi-dev.rightnetworks.com/rnapi',
  //baseUrl: 'https://rn-rnapi-1a.rightnetworks.com/rnapi',
  //baseUrl: 'https://rn-rnapi-stg.rightnetworks.com/rnapi',
  appInsights: {
    instrumentationKey: "d89cec26-8c6e-4147-ba29-c7bea94300c7",
  },
  duoInstance: "api-61963421.duosecurity.com",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
