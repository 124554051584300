import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LoggedInInfoService } from "../services/loggedInInfo/logged-in-info.service";

@Injectable({
  providedIn: "root",
})
export class AdminGuardService {
  constructor(
    private router: Router,
    private loggedInInfoService: LoggedInInfoService,
  ) {}

  async canActivate() {
    if (
      this.loggedInInfoService.GetLoggedInUser().role.indexOf("VIEWORGDET") >= 0
    ) {
      return true;
    }

    await this.router.navigate(["unauthorized"]);
    return false;
  }
}
