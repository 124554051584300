import { Component, Input } from "@angular/core";
import { RnsidebarService } from "../../../../services/sidebar/rnsidebar.service";

@Component({
  selector: "app-rn-right-sidebar",
  templateUrl: "./rn-right-sidebar.component.html",
  styleUrls: ["./rn-right-sidebar.component.scss"],
})
export class RightSideBarComponent {
  @Input() componentName = "";
  @Input() data: any;
  @Input() additionalData: any;
  public Loading = true;

  constructor(private rnsidebarService: RnsidebarService) {}

  componentLoaded(loaded: boolean) {
    this.Loading = !loaded;
  }
}
