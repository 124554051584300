/*
This class will be used for to send payload for the RNAPI2 API "api/v2/orders/modifysubscription"
*/

import { RnRNModifyVendorSubscriptionItem } from "./rNModifyVendorSubscriptionItem-d";

export class RnRNModifyVendorSubscriptionPayload {
  VendorName: string;
  AffectedOrganizationId: number;
  Items: Array<RnRNModifyVendorSubscriptionItem>;
}
