import { EventEmitter, Output, ViewChild } from "@angular/core";
import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Dropdown } from "primeng/dropdown";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
})
export class SelectComponent implements OnInit {
  @Input() frmComponent: FormGroup;
  @Input() componentId = "";
  @Input() overlayClass: string;
  @Input() required = false;
  @Input() loading = false;
  @Input() showIcon = false;
  @Input() userClass = "";
  @Output() clickEmitter = new EventEmitter<MouseEvent>();

  click(event: MouseEvent) {
    this.clickEmitter.emit(event);
  }

  @ViewChild(Dropdown, { static: false })
  dropdownComponent: Dropdown;

  @Input() get hasDefaultItem(): boolean {
    return this._hasDefaultItem;
  }
  set hasDefaultItem(value: boolean) {
    this._hasDefaultItem = value;
    this.dataChanges();
  }
  @Input() get defaultText(): string {
    return this._defaultText;
  }
  set defaultText(value: string) {
    this._defaultText = value;
    this.dataChanges();
  }
  @Input() get selectedItem(): string {
    return this._selectedItem;
  }
  set selectedItem(value: string) {
    this._selectedItem = value;
    this.dataChanges();
  }
  @Input() get items(): any[] {
    return this._items;
  }
  set items(value: any[]) {
    this._items = value;
    this.dataChanges();
  }
  @Input() value = "";
  @Input() get text() {
    return this._text;
  }
  set text(value: any) {
    this._text = value;
    this.dataChanges();
  }
  @Input() isShort = false;
  cssClasses = "";

  @Output() changed = new EventEmitter<string>();

  @Input() get test(): string {
    return this.testVal;
  }
  set test(value: string) {
    this.testVal = value;
  }
  public testVal = "123";

  itemsArray = [];

  public _hasDefaultItem: boolean;
  public _defaultText: string;
  private _items: any[];
  public _selectedItem: string;
  private _text: string;

  ngOnInit(): void {
    if (!this.componentId) throw new Error();
    if (this.isShort) this.cssClasses += "short" + " input-field";
  }

  dataChanges() {
    this.itemsArray = [];
    if (this.hasDefaultItem) {
      this.itemsArray.push({
        value: null,
        label: this.defaultText,
        disabled: true,
      });
    }

    if (this.selectedItem) {
      if (this.selectedItem != "") {
        this.component.setValue(this.selectedItem);
      }
    }

    this._items?.forEach((item) => {
      this.itemsArray.push({
        value: this.value ? item[this.value] : item,
        label: this._text ? item[this._text] : item,
      });
    });
  }

  selectionChanged() {
    this.changed.emit(this.component.value);
  }

  get component() {
    if (this.frmComponent === undefined) {
      this.frmComponent = new FormGroup({});
    }
    return this.frmComponent.get(this.componentId);
  }

  onDropdownShow() {
    const overlayElement = document.querySelector(".p-overlay");
    if (overlayElement && this.overlayClass) {
      overlayElement.classList.add(this.overlayClass);
    }
  }
}
