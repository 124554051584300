import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AppInsightsService } from "../../../appInsights/app-insights.service";
import { DialogTrackingService } from "../../../services/dialog/dialog-tracking.service";
import { MatDialogRef } from "@angular/material/dialog";
import { RnUserCustomFieldCreateUpdate } from "../../../services/rnapi2-service/models/models";
import { LoggedInInfoService } from "../../../services/loggedInInfo/logged-in-info.service";
import { UserService } from "../../../services/rnapi2-service/apis/user.service";
import { UserLoginState } from "../../../../core/models/user.login.state";
import { EnvService } from "../../../../core/services/env.service";

@Component({
  selector: "app-download-setup-wizard",
  templateUrl: "./download-setup-wizard.component.html",
  styleUrls: ["./download-setup-wizard.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DownloadSetupWizardComponent implements OnInit {
  public windowsInstallerUrl: string;
  public asset_url: string;
  public macInstallerUrl: string;
  public mobileHelp =
    "<p>We don't have a Setup Wizard for these devices, but we can still help you set them up to connect to your new desktop.</p><p>Check out our knowledge base articles for a walkthrough:</p><p><a href='https://helpdesk.rightnetworks.com/s/article/Connect-from-an-iPhone-iPad'>iPhone or iPad</a></p><p><a href='https://helpdesk.rightnetworks.com/s/article/Connect-from-an-Android-Device'>Android</a></p><p><a href='https://helpdesk.rightnetworks.com/s/article/Connect-from-a-Google-Chromebook'>Chromebook</a></p>";
  public continueHelp =
    "<p>Before you continue, we suggest you take the time to download and run the Setup Wizard now.</p><p>Once you do the setup, you will be ready to start working right away!</p>";

  constructor(
    private appInsightsService: AppInsightsService,
    private mdDialogRef: MatDialogRef<DownloadSetupWizardComponent>,
    public dialogTrackService: DialogTrackingService,
    private loggedInInfoService: LoggedInInfoService,
    private userService: UserService,
  ) {
    this.asset_url = EnvService.EnvVariables().assetUrl;
    this.windowsInstallerUrl =
      "https://portals.rightnetworks.com/support/RightNetworksSetupWizard.exe";
    this.macInstallerUrl = this.asset_url + "/downloads/RNSetupWizard.dmg";
  }

  ngOnInit(): void {
    this.loggedInUser = this.loggedInInfoService.GetLoggedInUser();
  }

  public close(): void {
    this.mdDialogRef.close();
    if (!this.loggedInUser.ShowSetupWizardDownload) {
      const payload = new RnUserCustomFieldCreateUpdate();

      payload.UserID = this.loggedInUser.UserID;
      payload.CustomFieldName = "ShowSetupWizardDownload";
      payload.CustomFieldValues = ["true"];
      this.userService
        .apiV2UsersSetusercustomvaluePost(payload, "response")
        .subscribe((v) => {
          if (v) {
            this.loggedInUser.ShowSetupWizardDownload = true;
            this.loggedInInfoService.SetLoggedInUser(this.loggedInUser);
          }
        });
    }
  }

  public trackWindowsDownload(): void {
    window.location.href = this.windowsInstallerUrl;
  }

  public trackMacDownload(): void {
    window.location.href = this.macInstallerUrl;
  }

  public getWindowsHelp(): void {
    this.appInsightsService.logEvent("Windows Setup Wizard - Get Help");
    window.open(
      "https://player.vimeo.com/video/247142852",
      "window",
      "toolbar=no, menubar=no, resizable=yes, width=1024, height=768",
    );
  }

  public getMacHelp(): void {
    this.appInsightsService.logEvent("Mac OS Setup Wizard - Get Help");
    window.open(
      "https://rightnetworkscustomercare.force.com/Support/s/article/Connect-from-a-Macintosh-Computer",
      "window",
      "toolbar=no, menubar=no, resizable=yes, width=1024, height=768",
    );
  }

  private loggedInUser: UserLoginState;
}
