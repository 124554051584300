<form [formGroup]="parentForm">
  <mat-slide-toggle
    [attr.data-testid]="
      parentForm.value.enabled ? 'switchEnabled' : 'switchDisabled'
    "
    [formControlName]="id"
    [disabled]="disabled"
    (change)="handleChange($event)"
  >
    <span *ngIf="parentForm.value.enabled">ON</span>
    <span *ngIf="!parentForm.value.enabled">OFF</span>
  </mat-slide-toggle>
</form>
