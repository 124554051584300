export class CartItem {
  Name: string;
  Quantity: number;
  DiscountPercent: number;
  Price: number;
  NextBillPrice: number;
  PackageID: number;
  ItemType: string;
  OrgID: number;
  OrgName: string;
  TypeName: string;
  IsMonthly = true;
}
