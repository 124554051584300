<div class="confirmationContainer">
  <span data-testid="leaveConfirmationText" class="title"
    >Are you sure you want to leave?</span
  >
  <br />
  <span data-testid="unsavedChangesAlertText" class="sub-title"
    >You haven’t saved your changes yet, and you will lose them if you leave the
    page.</span
  >
  <br />
  <app-button
    id="btnDontLeave"
    buttonStyle="addUserTeritary"
    label="CANCEL"
    (clickActionEmitter)="clickNo()"
  >
  </app-button>
  <app-button
    id="btnLeave"
    buttonStyle="primary"
    label="LEAVE"
    (clickActionEmitter)="clickYes()"
  >
  </app-button>
</div>
