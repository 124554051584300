import { Subject } from "rxjs";
import {
  RnCommonOrderByTypes,
  RnCommonSearch,
} from "../../shared/services/rnapi2-service/models/models";

export class EventModel {
  selectedEventType: string;
  inputSearchText: Subject<string>;
  currentSearchText: string;
  beginDate: Date | null;
  endDate: Date | null;
  minDate: Date | null;
  maxDate: Date | null;
  pageSize: number;
  pageNumber: number;
  sortOrder: string;
  isDescendingOrder: boolean;
  isDirty: boolean;
  constructor() {
    this.selectedEventType = null;
    this.inputSearchText = null;
    this.currentSearchText = "";
    this.beginDate = null;
    this.endDate = null;
    this.minDate = undefined;
    this.maxDate = undefined;
    this.pageSize = 10;
    this.pageNumber = 0;
    this.sortOrder = RnCommonOrderByTypes.OrderByCreateDate;
    this.isDescendingOrder = true;
    this.isDirty = false;
  }
  set(updateValue: EventModel): void {
    this.selectedEventType = updateValue?.selectedEventType;
    this.currentSearchText =
      updateValue?.currentSearchText != null
        ? updateValue?.currentSearchText
        : this.currentSearchText;
    this.beginDate =
      updateValue?.beginDate != null &&
      updateValue?.beginDate.toString() !== "Invalid Date"
        ? updateValue?.beginDate
        : this.beginDate;
    this.endDate =
      updateValue?.endDate != null &&
      updateValue?.endDate.toString() !== "Invalid Date"
        ? updateValue?.endDate
        : this.endDate;
    this.minDate = this.beginDate;
    this.maxDate = this.endDate;
    this.pageSize =
      updateValue?.pageSize != null ? updateValue?.pageSize : this.pageSize;
    this.pageNumber =
      updateValue?.pageNumber != null
        ? updateValue?.pageNumber
        : this.pageNumber;
    this.sortOrder =
      updateValue?.sortOrder != null ? updateValue?.sortOrder : this.sortOrder;
    this.isDescendingOrder =
      updateValue?.isDescendingOrder != null
        ? updateValue?.isDescendingOrder
        : this.isDescendingOrder;
  }
  isDifferent(compareValue: EventModel): boolean {
    const isSame =
      this.currentSearchText == compareValue?.currentSearchText &&
      this.selectedEventType == compareValue?.selectedEventType &&
      this.beginDate == compareValue?.beginDate &&
      this.endDate == compareValue?.endDate &&
      this.minDate == compareValue?.minDate &&
      this.maxDate == compareValue?.maxDate &&
      this.pageSize == compareValue?.pageSize &&
      this.pageNumber == compareValue?.pageNumber &&
      this.sortOrder == compareValue?.sortOrder &&
      this.isDescendingOrder == compareValue?.isDescendingOrder;

    return !isSame;
  }
  toRequestPayload(): RnCommonSearch {
    const startDate = this.getDateString(this.beginDate);
    const endDate = this.getDateString(this.endDate);

    if (startDate === "" || endDate === "") {
      return null;
    }

    const payload: RnCommonSearch = new RnCommonSearch();
    payload.AffectedOrganizationId = null;
    payload.ExtraSearchText = this.selectedEventType;
    payload.search = this.currentSearchText;
    payload.pageNumber = this.pageNumber;
    payload.pageSize = this.pageSize;
    payload.isDescendingOrder = this.isDescendingOrder;
    payload.OrderByText = this.sortOrder;
    payload.startDate = startDate;
    payload.endDate = endDate;
    payload.UTCDiff = this.beginDate.getTimezoneOffset();
    return payload;
  }

  getDateString(dateVal: Date): string {
    let response = "";
    const yearVal = dateVal.getFullYear();
    const monthVal = dateVal.getMonth() + 1;
    const dayVal = dateVal.getDate();
    if (
      yearVal > 2000 &&
      monthVal >= 1 &&
      monthVal <= 12 &&
      dayVal >= 1 &&
      dayVal <= 31
    ) {
      const yearStr = yearVal.toString();
      const monthStr = monthVal.toString().padStart(2, "0");
      const dayStr = dayVal.toString().padStart(2, "0");

      response = `${yearStr}/${monthStr}/${dayStr}`;
      const testDate = new Date(response);
      if (testDate.getFullYear() !== yearVal) {
        response = "";
      }
    }

    return response;
  }

  toUtcDate(newDate: Date | null): Date | null {
    if (newDate == null) {
      return null;
    }

    const now = new Date();
    const currentTimeZoneOffsetInHours = now.getTimezoneOffset() / 60;
    newDate.setUTCHours(currentTimeZoneOffsetInHours);
    return newDate;
  }
}
