<div class="modal-header">
  <h3 class="modal-title">
    {{ idleService.idleState | rnIdleService: "title" }}
  </h3>
</div>
<div
  idle-countdown="countdown"
  class="modal-body idle-dialog-content"
  *ngIf="idleService.idleState | rnIdleService: 'iswarningmode'"
>
  <p>
    <strong>Are you still using your Self-Service Portal?</strong> Due to
    inactivity, you will be automatically logged out in
    <strong>{{ idleService.countdown }}</strong> second(s).
  </p>
</div>
<div
  idle-countdown="countdown"
  class="modal-body idle-dialog-content"
  *ngIf="!(idleService.idleState | rnIdleService: 'iswarningmode')"
>
  <p>
    Due to inactivity and to help prevent unauthorized use of your account, you
    have been logged out of the Self-Service Portal.
  </p>
</div>
<div class="modal-footer" *ngIf="false">
  <a tabindex="0" (keyup.enter)="logout()" (click)="logout()">Log Out</a>
  &nbsp;
  <app-button
    id="btnContinue"
    (clickActionEmitter)="continue()"
    label="Continue"
    buttonStyle="primary"
  >
  </app-button>
</div>

<div
  class="modal-footer"
  *ngIf="!(idleService.idleState | rnIdleService: 'iswarningmode')"
>
  <app-button
    id="btnDismiss"
    (clickActionEmitter)="continue()"
    label="Dismiss"
    buttonStyle="secondary"
  >
  </app-button>
</div>
